"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _StarRating = require("./StarRating");

var _StarRating2 = _interopRequireDefault(_StarRating);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _StarRating2.default;