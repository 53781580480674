/* eslint-disable complexity */
import { getInsurancePageTitle } from '../constants/insuranceTypeConstants'
import { DIRECT_DEBIT_STORAGE,GET_QUOTE_RESPONSE_STORAGE } from '../constants/sessionStorage/genericStorageIdentifiers';
import { BOI_WALLET_USER_STORAGE } from '../constants/sessionStorage/userStorageIdentifiers';
import {
  getObjectFromSessionStorage
} from '../helpers';
import { routes } from '../routes';

export const quantcastDynamicScript = (url) => {
  const insuranceType = getInsuranceTypeFromUrl(url)
  const email = getEmail()
  if (url.includes(routes.paymentThankYou.url)) {
    // Sign Up confirm page code
    const orderId = getOrderId(insuranceType)
    const revenue = getRevenue(insuranceType)
    const element = document.createElement('script');
    element.setAttribute('type', 'text/javascript');
    element.innerHTML = `window._qevents = window._qevents || [];
    (function() {
      var elem = document.createElement('script');
      elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
      elem.async = true;
      elem.type = "text/javascript";
      var scpt = document.getElementsByTagName('script')[0];
      scpt.parentNode.insertBefore(elem, scpt);
    })();
      window._qevents.push({qacct:"p-Grz6nEYU7cQnu"${email ? `,uid:"${email}"` : ''},labels:"_fp.event.Sign Up Confirmation "${orderId ? `,orderid:"${orderId}"` : ''}${revenue ? `,revenue:"${revenue}"` : ''},event:"refresh"});`
      return element

  } else if (url.includes(routes.viewQuote.url)) {
    // Get Quote code
    const orderId = getOrderId(insuranceType)
    const element = document.createElement('script');
    element.setAttribute('type', 'text/javascript');
    element.innerHTML = `window._qevents = window._qevents || [];
    (function() {
      var elem = document.createElement('script');
      elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
      elem.async = true;
      elem.type = "text/javascript";
      var scpt = document.getElementsByTagName('script')[0];
      scpt.parentNode.insertBefore(elem, scpt);
    })();
      window._qevents.push({qacct:"p-Grz6nEYU7cQnu"${email ? `,uid:"${email}"` : ''},labels:"_fp.event.Get a Quote Tag"${orderId ? `,orderid:"${orderId}"` : ''},event:"refresh"});`
      return element
  } else {
    // Default code
    const element = document.createElement('script');
    element.setAttribute('type', 'text/javascript');
    element.innerHTML = `window._qevents = window._qevents || [];
    (function() {
      var elem = document.createElement('script');
      elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
      elem.async = true;
      elem.type = "text/javascript";
      var scpt = document.getElementsByTagName('script')[0];
      scpt.parentNode.insertBefore(elem, scpt);
    })();
      window._qevents.push({qacct:"p-Grz6nEYU7cQnu"${email ? `,uid:"${email}"` : ''}});`
      return element
  }
}

const getEmail = () => {
  const object = getObjectFromSessionStorage(BOI_WALLET_USER_STORAGE)
  return (Object.keys(object).length > 0) ? object.attributes?.email : false
}

const getRevenue = (insuranceType) => {
  const object = getObjectFromSessionStorage(`${insuranceType}${DIRECT_DEBIT_STORAGE}`)
  return object?.price
}

const getOrderId = (insuranceType) => {
  const object = getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`)
  if (Array.isArray(object)) {
    return object[0].quote_reference
  }
  if (object.hasOwnProperty('response')) {
    return object?.response?.quote_reference
  }
  return object?.quote_reference
}

const getInsuranceTypeFromUrl = (url) => {
  const journeys = Object.keys(getInsurancePageTitle)
  let current
  journeys.forEach(journey => {
    if (url.includes(journey)) current = journey
  })
  return current
}
