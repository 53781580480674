"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PhoneIcon = require("./PhoneIcon");

var _PhoneIcon2 = _interopRequireDefault(_PhoneIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PhoneIcon2.default;