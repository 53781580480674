"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _HandIcon = require("./HandIcon");

var _HandIcon2 = _interopRequireDefault(_HandIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _HandIcon2.default;