/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *

 */

export const isFieldError = (fieldName, touched, errors) => {
  return touched[fieldName] && errors[fieldName] !== undefined;
};

export const isValidResourceValue = (value) => !!value && !!value.alias && !!value.id;

export const isValidDynamicResourceValue = (value) => value === undefined || (!!value && !!value.alias && !!value.id)