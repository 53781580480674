/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './InstallPrompt.scss';

// BOILink probaly has to be renamed at somepoint
// It should also use react-router link under the hood
import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';

import iphoneShareIcon from '../../assets/icons8-share-rounded-30.png';
import { primaryBlue } from '../../constants/colors';
import Popup from '../Popup';

const className = `c-InstallPrompt`;

const InstallPrompt = () => {
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  useEffect(() => {
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    if (isIOS && !isInStandaloneMode()) {
      setShowInstallPrompt(true);
    }
  }, []);

  const installPromptHeading = () => {
    return (
      <div className={`${className}__installPromptHeading`} >
        Install the App now!
      </div>
    );
  };

  if (!showInstallPrompt) return null;
  return (
    <div className={className}>
      <Popup
        color={primaryBlue}
        heading={installPromptHeading}
      >
        <img
          src={iphoneShareIcon}
          className={`${className}__installPromptImage`}
        />
        <span className={`${className}__installPromptMessage`} >
          <p className={`${className}__installPromptText`} >Install the App by tapping {"'Add to Homescreen' "} in your browser. </p>
        </span>
      </Popup>
    </div>
  );
};

export default InstallPrompt;
