// Form Names
export const aboutYouFormIdentifier = 'aboutYou';
export const yourHomeFormIdentifier = 'yourHome';
export const yourHomeCoverFormIdentifier = 'yourHomeCover';
export const yourCarFormIdentifier = 'yourCar';
export const yourCarCoverFormIdentifier = 'yourCarCover';
export const yourTravelFormIdentifier = 'yourTravel';
export const yourTravelCoverFormIdentifier = 'yourTravelCover';
export const yourDeviceFormIdentifier = 'yourDevice';
export const yourDeviceCoverFormIdentifier = 'yourDeviceCover';
export const yourVanFormIdentifier = 'yourVan';
export const yourVanCoverFormIdentifier = 'yourVanCover';
