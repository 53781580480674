/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, iconsAndTitles, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants';
import { arachasHomePhoneNumber, localPhoneNumber, vanArachasAgentNumber } from '../../constants/phoneNumbers';

const RenewPolicyPage = ({ match, history }: { match: Match, history: RouterHistory; }) => {
  const insuranceType = match.params.type || '';

  const getNumber = {
    [CAR_INSURANCE_TYPE]: arachasHomePhoneNumber,
    [VAN_INSURANCE_TYPE]: vanArachasAgentNumber,
    [HOME_INSURANCE_TYPE]: localPhoneNumber,
    [TRAVEL_INSURANCE_TYPE]: localPhoneNumber,
    ['']: ''
  };

  return <CallUsPage
    history={history}
    icon={insuranceType && iconsAndTitles[insuranceType].icon}
    cardTitle={insuranceType && iconsAndTitles[insuranceType].title}
    title="Renew"
    afterContent="Our Personal Lines team are available to assist you with renewing your policy. Our agents are available Monday – Friday 9am to 5pm."
    phoneNumber={getNumber[insuranceType]}
  />;
};

export default withRouter(RenewPolicyPage);