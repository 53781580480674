/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './UnspecifiedItems.scss';

import { noop } from '@arachas/core/lib/utils';
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import ResourceDropdown from '../ResourceDropdown';

interface Props {
  isFieldError: noop;
  handleChangeWRecalculate: noop;
  handleBlur: noop;
}

const UnspecifiedItems = (props: Props & FormikProps) => {
  const className = 'c-UnspecifiedItems';
  const { isFieldError, handleChangeWRecalculate, handleBlur, errors, value, resources } = props;
  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}>
        <label htmlFor='unspecifiedItems' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Please select your amount with Dropdown
        </label>
        <ResourceDropdown
          error={isFieldError('unspecifiedItems')}
          errorMessage={errors.unspecifiedItems}
          placeholder="Type here"
          name="unspecifiedItems"
          onChange={(value: SyntheticEvent<any>) => handleChangeWRecalculate('unspecifiedItems', value)}
          onBlur={handleBlur}
          value={value}
          id="unspecifiedItems"
          test-id="unspecifiedItems"
          values={resources.dynamic_unspecified_items_amount}
        />
      </div>
    </div>
  );

};

export default UnspecifiedItems;
