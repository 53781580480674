"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./Label.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * 
 */
var rootClass = "c-Label";

var Label = function Label(_ref) {
  var htmlFor = _ref.htmlFor,
      className = _ref.className,
      children = _ref.children;
  return _react2.default.createElement("label", {
    htmlFor: htmlFor,
    className: className ? className : rootClass
  }, children);
};

exports.default = Label;