"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BandaidIcon = require("./BandaidIcon");

var _BandaidIcon2 = _interopRequireDefault(_BandaidIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BandaidIcon2.default;