"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SecureIcon = require("./SecureIcon");

var _SecureIcon2 = _interopRequireDefault(_SecureIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SecureIcon2.default;