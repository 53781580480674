export const yourVanFormInitialValues = {
  vanReg: '',
  vanType: '',
  vanMake: '',
  vanModel: '',
  vanYear: '',
  vanWeight: '',
  vanDescription: '',
  vehicleSeats: '',
  vehicleValue: '',
  annualKm: '',
  signage: '',
  areaUse: '',
  ncdYears: '',
  ncdInsurer: '',
  vanNCDProctection: '',
  ncdProctection: '',
  privateCar: '',
};

export const yourVanFormFieldsList = [
  'vanReg',
  'vanType',
  'vanMake',
  'vanModel',
  'vanYear',
  'vanWeight',
  'vanDescription',
  'vehicleSeats',
  'vehicleValue',
  'annualKm',
  'signage',
  'areaUse',
  'ncdYears',
  'ncdInsurer',
  'ncdProctection',
  'privateCar',
];
