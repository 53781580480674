// @flow

import { AccordionText } from "@arachas/core/lib";
import React from 'react';

import { marketingPhoneNumber } from '../../constants/phoneNumbers';

const className = "c-MarketingConsentForm";

const ConsentText = () => {

  return (
    <div className={`${className}__readMoreContainer`}>
      <div className={`${className}__visibleText`}>
        We{"'"}d like to be able to share news about services, products and offers that we believe may be of interest to
        you
        from Arachas Group as well as from specially selected trusted partners.
      </div>
      <AccordionText
        label="Find out more"
        labelColor="inherit"
        openLabel="Read less"
        underline
        inverted
      >
        <div className={`${className}__readMoreContent`}>
          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>
              When you give us permission to get in touch, you are allowing Arachas Group to send you
              information
              about relevant services, offers and products.
            </span>
          </div>

          <div className={`${className}--spaceBellow`}>
            We will only send you information that we believe can make your life easier, or offer you value for money,
            based on the information we hold about you. This may include account transactions, spending habits, your
            income bracket and other insights we get from your data.
          </div>

          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>You are always in control of your data.</span> <br />
            You can contact us on <a href={`tel:${marketingPhoneNumber}`}>{marketingPhoneNumber}</a> to change your
            marketing
            preferences.
          </div>

          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>You choose the way we contact you.</span> <br />
            This can be through any combination of email, phone, post, text or through social media in a secure format.
          </div>

          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>Arachas Group includes the following.</span> <br />
            Arachas, Arachas Mortgage Bank, Arachas Insurance Services Limited, Arachas
            Leasing Limited and New Ireland Assurance Company plc.
          </div>

          <div className={`${className}--spaceBellow`}>
            Our <a href='https://www.bankofireland.com/privacy/data-protection-notice/' target='_blank' rel='noopener noreferrer'>Data Privacy Notice</a> details how, when and why we may use your personal information. It also outlines
            your rights relating to that information.
          </div>
        </div>
      </AccordionText>
    </div>
  );
};

export default ConsentText;

