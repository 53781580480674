export const zurichRent = [
  {
    name: 'minimum_security',
    label: 'Can you confirm that your home meets the Minimum Security Requirements?',
    bulletPoints: [
      { label: 'All external doors are fitted with appropriate locking mechanisms' },
      { label: 'All French doors, patio doors and windows are fitted with appropriate security locks' }
    ]
  },
  {
    name: 'owner',
    label: 'Is it correct that You (the Proposer(s)) are the owner(s) of the contents?',
  },
  {
    name: 'in_good_state',
    label: 'Is it correct that the premises is in a good state of repair and will be so maintained and is free from any signs of external or internal cracking?'
  },
  {
    name: 'constructed_brick',
    label: `Is it correct that that the premises is constructed entirely of brick, stone or concrete or is timber framed and clad entirely of brick, stone or concrete?`
  },
  {
    name: 'constructed_roof',
    label: 'Is it correct that the roof is constructed (at least 80%) with one of the following standard materials only: slate, tile or concrete?'
  },
  {
    name: 'not_exceed_total_roof_area',
    label: 'Is it correct that the total area of flat or non-standard roof does not exceed 20% of the total roof area and is constructed with one of the following materials only: asphalt, trocal or felt on timber?'
  },
  {
    name: 'original_inception',
    label: 'Is it correct that prior to the original inception of this policy, you held home insurance for these premises within the preceding 12 months or you are a first time buyer?'
  },
  {
    name: 'rewired_premises',
    label: 'Is it correct that the premises is under 100 years old and maintained or if it is between 100 years and 120 years then it has been re-wired and re-plumbed within the previous 25 years and re-roofed within the previous 40 years?'
  },
  {
    name: 'not_old_premises',
    label: 'Is it correct that the premises is not more than 120 years old?'
  },
  {
    name: 'not_unoccupied_premises',
    label: 'Is it correct that the premises is not:',
    bulletPoints: [
      { label: 'Unoccupied?' },
      { label: 'Undergoing any construction or renovation works?' },
      { label: 'A listed building or subject to a preservation order?' },
      { label: 'On a site that exceeds 2 acres in size?' },
      { label: 'Used in connection with any commercial activities?' }
    ]
  },
  {
    name: 'not_suffered_loss_or_damage_on_flood',
    label: 'Is it correct that the premises has never suffered loss or damage as a result of flood, whether insured or not, nor is there a history of flooding in the area?'
  },
  {
    name: 'not_suffered_loss_or_damage_on_subsidence',
    label: 'Is it correct that the premises has never suffered loss or damage as a result of subsidence, heave or landslip, whether insured or not, nor is there a history of subsidence, heave or landslip in the area ? or The premises has never been:',
    bulletPoints: [
      { label: 'monitored for subsidence, ground heave or landslip?' },
      { label: 'the subject of a survey which mentions subsidence, heave or landslip?' },
      { label: 'Underpinned?' }
    ]
  },
  {
    name: 'declined_insurance',
    label: 'Is it correct that neither you, nor any others residing with you, have ever been refused or declined insurance or had any special terms, restrictions or conditions imposed by an insurer? (this includes any refusal of insurance or special terms, restrictions or conditions previously imposed by Us). Examples include increased excess and reduced level of policy cover.'
  },
  {
    name: 'no_criminal_offence',
    label: `Is it correct that the Neither you, nor any others residing with you, have ever been cautioned for, convicted of, or charged but not yet tried for, a criminal offence? This does not apply in respect of spent convictions under the 'Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016' or driving offences under the Road Traffic Acts.`
  },
  {
    name: 'full_replacement_cost',
    label: `Is it correct that the Sum insured proposed represents the full replacement cost as new of the property to be insured subject to an allowance for wear and tear in respect of clothing, furs and linen?`
  },
  {
    name: 'all_questions_answered',
    label: `Is it correct that the This Statement of Fact accurately records the information which you or anyone acting on your behalf provided to Zurich in advance of the inception or renewal of this Policy and that all questions asked in connection with your application for insurance have been answered honestly and with reasonable care and any information voluntarily provided was provided honestly and with reasonable care?`
  },
];

export const zurichLiveIn = [
  {
    name: 'minimum_security',
    label: 'Can you confirm that your home meets the Minimum Security Requirements?',
    bulletPoints: [
      { label: 'All external doors are fitted with appropriate locking mechanisms' },
      { label: 'All French doors, patio doors and windows are fitted with appropriate security locks' }
    ]
  },
  {
    name: 'owner',
    label: 'Is it correct that You (the Proposer(s)) are the owner(s) of the property?',
  },
  {
    name: 'in_good_state',
    label: 'Is it correct that the premises is in a good state of repair and will be so maintained and is free from any signs of external or internal cracking?'
  },
  {
    name: 'constructed_brick',
    label: `Is it correct that that the premises is constructed entirely of brick, stone or concrete or is timber framed and clad entirely of brick, stone or concrete?`
  },
  {
    name: 'constructed_roof',
    label: 'Is it correct that the roof is constructed (at least 80%) with one of the following standard materials only: slate, tile or concrete?'
  },
  {
    name: 'not_exceed_total_roof_area',
    label: 'Is it correct that the total area of flat or non-standard roof does not exceed 20% of the total roof area and is constructed with one of the following materials only: asphalt, trocal or felt on timber?'
  },
  {
    name: 'original_inception',
    label: 'Is it correct that prior to the original inception of this policy, you held home insurance for these premises within the preceding 12 months or you are a first time buyer?'
  }, {
    name: 'rewired_premises',
    label: 'Is it correct that the premises is under 100 years old and maintained or if it is between 100 years and 120 years then it has been re-wired and re-plumbed within the previous 25 years and re-roofed within the previous 40 years?'
  },
  {
    name: 'not_old_premises',
    label: 'Is it correct that the premises is not more than 120 years old?'
  },
  {
    name: 'not_unoccupied_premises',
    label: 'Is it correct that the premises is not:',
    bulletPoints: [
      { label: 'Unoccupied?' },
      { label: 'Undergoing any construction or renovation works?' },
      { label: 'A listed building or subject to a preservation order?' },
      { label: 'On a site that exceeds 2 acres in size?' },
      { label: 'Used in connection with any commercial activities?' }
    ]
  },
  {
    name: 'not_suffered_loss_or_damage_on_flood',
    label: 'Is it correct that the premises has never suffered loss or damage as a result of flood, whether insured or not, nor is there a history of flooding in the area?'
  },
  {
    name: 'not_suffered_loss_or_damage_on_subsidence',
    label: 'Is it correct that the premises has never suffered loss or damage as a result of subsidence, heave or landslip, whether insured or not, nor is there a history of subsidence, heave or landslip in the area ? or The premises has never been:',
    bulletPoints: [
      { label: 'monitored for subsidence, ground heave or landslip?' },
      { label: 'the subject of a survey which mentions subsidence, heave or landslip?' },
      { label: 'Underpinned?' }
    ]
  },
  {
    name: 'declined_insurance',
    label: 'Is it correct that neither you, nor any others residing with you, have ever been refused or declined insurance or had any special terms, restrictions or conditions imposed by an insurer? (this includes any refusal of insurance or special terms, restrictions or conditions previously imposed by Us). Examples include increased excess and reduced level of policy cover.'
  },
  {
    name: 'no_criminal_offence',
    label: `Is it correct that the Neither you, nor any others residing with you, have ever been cautioned for, convicted of, or charged but not yet tried for, a criminal offence? This does not apply in respect of spent convictions under the 'Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016' or driving offences under the Road Traffic Acts.`
  },
  {
    name: 'full_replacement_cost',
    label: `Is it correct that the Sum insured proposed represents the full replacement cost as new of the property to be insured subject to an allowance for wear and tear in respect of clothing, furs and linen?`
  },
  {
    name: 'all_questions_answered',
    label: `Is it correct that the This Statement of Fact accurately records the information which you or anyone acting on your behalf provided to Zurich in advance of the inception or renewal of this Policy and that all questions asked in connection with your application for insurance have been answered honestly and with reasonable care and any information voluntarily provided was provided honestly and with reasonable care?`
  },
];

export const zurichLandlord = [
  {
    name: 'minimum_security',
    label: 'Can you confirm that your home meets the Minimum Security Requirements?',
    bulletPoints: [
      { label: 'All external doors are fitted with appropriate locking mechanisms' },
      { label: 'All French doors, patio doors and windows are fitted with appropriate security locks' }
    ]
  },
  {
    name: 'owner',
    label: 'Is it correct that You (the Proposer(s)) are the owner(s) of the property?',
  },
  {
    name: 'occupied_by_tenants',
    label: 'Is it correct that the property is occupied by tenants?',
  },
  {
    name: 'rental_agreements',
    label: 'Is it correct that there is a formal rental agreement for a period of no less than 12 months in place directly between you (the proposer(s)) and the tenant(s)?',
  },
  {
    name: 'in_good_state',
    label: 'Is it correct that the premises is in a good state of repair and will be so maintained and is free from any signs of external or internal cracking?'
  },
  {
    name: 'constructed_brick',
    label: `Is it correct that that the premises is constructed entirely of brick, stone or concrete or is timber framed and clad entirely of brick, stone or concrete?`
  },
  {
    name: 'constructed_roof',
    label: 'Is it correct that the roof is constructed (at least 80%) with one of the following standard materials only: slate, tile or concrete?'
  },
  {
    name: 'not_exceed_total_roof_area',
    label: 'Is it correct that the total area of flat or non-standard roof does not exceed 20% of the total roof area and is constructed with one of the following materials only: asphalt, trocal or felt on timber?'
  },
  {
    name: 'original_inception',
    label: 'Is it correct that prior to the original inception of this policy, you held home insurance for these premises within the preceding 12 months or you are a first time buyer?'
  }, {
    name: 'rewired_premises',
    label: 'Is it correct that the premises is under 100 years old and maintained or if it is between 100 years and 120 years then it has been re-wired and re-plumbed within the previous 25 years and re-roofed within the previous 40 years?'
  },
  {
    name: 'not_old_premises',
    label: 'Is it correct that the premises is not more than 120 years old?'
  },
  {
    name: 'not_unoccupied_premises',
    label: 'Is it correct that the premises is not:',
    bulletPoints: [
      { label: 'Unoccupied?' },
      { label: 'Undergoing any construction or renovation works?' },
      { label: 'A listed building or subject to a preservation order?' },
      { label: 'On a site that exceeds 2 acres in size?' },
      { label: 'Used in connection with any commercial activities?' }
    ]
  },
  {
    name: 'not_suffered_loss_or_damage_on_flood',
    label: 'Is it correct that the premises has never suffered loss or damage as a result of flood, whether insured or not, nor is there a history of flooding in the area?'
  },
  {
    name: 'not_suffered_loss_or_damage_on_subsidence',
    label: 'Is it correct that the premises has never suffered loss or damage as a result of subsidence, heave or landslip, whether insured or not, nor is there a history of subsidence, heave or landslip in the area ? or The premises has never been:',
    bulletPoints: [
      { label: 'monitored for subsidence, ground heave or landslip?' },
      { label: 'the subject of a survey which mentions subsidence, heave or landslip?' },
      { label: 'Underpinned?' }
    ]
  },
  {
    name: 'declined_insurance',
    label: 'Is it correct that neither you, nor any others residing with you, have ever been refused or declined insurance or had any special terms, restrictions or conditions imposed by an insurer? (this includes any refusal of insurance or special terms, restrictions or conditions previously imposed by Us). Examples include increased excess and reduced level of policy cover.'
  },
  {
    name: 'no_criminal_offence',
    label: `Is it correct that the Neither you, nor any others residing with you, have ever been cautioned for, convicted of, or charged but not yet tried for, a criminal offence? This does not apply in respect of spent convictions under the 'Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016' or driving offences under the Road Traffic Acts.`
  },
  {
    name: 'full_replacement_cost',
    label: `Is it correct that the Sum insured proposed represents the full replacement cost as new of the property to be insured subject to an allowance for wear and tear in respect of clothing, furs and linen?`
  },
  {
    name: 'all_questions_answered',
    label: `Is it correct that the This Statement of Fact accurately records the information which you or anyone acting on your behalf provided to Zurich in advance of the inception or renewal of this Policy and that all questions asked in connection with your application for insurance have been answered honestly and with reasonable care and any information voluntarily provided was provided honestly and with reasonable care?`
  },
];
