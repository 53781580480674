"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AnimatedSpinnerWhite = require("./AnimatedSpinnerWhite");

var _AnimatedSpinnerWhite2 = _interopRequireDefault(_AnimatedSpinnerWhite);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _AnimatedSpinnerWhite2.default;