/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import './IconAndTitle.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { iconsAndTitles } from '../../constants';
import { DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';


const className = 'c-IconAndTitle';


export const IconAndTitle = ({
  insuranceType, title }) => {

  const getCustomClassName = () => {
    switch (insuranceType) {
      case DEVICE_INSURANCE_TYPE: return `${className}__icon ${className}__icon--device`
      case VAN_INSURANCE_TYPE: return `${className}__icon ${className}__icon--van`
      case HOME_INSURANCE_TYPE: return `${className}__icon ${className}__icon--home`
      default: return `${className}__icon`
    }
  }

  return (
    insuranceType ?
      <div className={`${className}__iconContainer`}>
        <div className={getCustomClassName()}>{iconsAndTitles[insuranceType].icon}</div>
        <div className={`${className}__customTitle`}>
          <div className={`${className}__titleText`}>
            {title}
          </div>
        </div>
      </div > :
      <></>
  );
};

IconAndTitle.propTypes = {
  insuranceType: PropTypes.string,
  title: PropTypes.string
};

export default IconAndTitle;
