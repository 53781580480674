import Auth from '@aws-amplify/auth';

export const checkExistingEmail = (email) => {
  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const fakePw = '000000'
  return new Promise((resolve) => {
    if (!emailRegex.test(email)) return resolve(false)
    Auth.confirmSignUp(email, fakePw).then(() => resolve(false)).catch((err) => {
      if (err.code === 'NotAuthorizedException')
        resolve(true);
      else
        resolve(false);
    })
  })
}