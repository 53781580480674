"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Title = require("./Title");

var _Title2 = _interopRequireDefault(_Title);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Title2.default;