import { extractBIC, extractIBAN } from 'ibantools';

// Valid IBAN SEPA codes for the EU and UK
export const AllowableIBANCodes = [
  // Ireland
  'IE',
  // United Kingdom
  'GB',
  'DE'
];


export const isBICValidForOurCountries = (bic = '') => {
  const bicDetails = extractBIC(bic);

  return bicDetails.valid &&
    bicDetails.countryCode &&
    AllowableIBANCodes.includes(bicDetails.countryCode);
};

export const isIBANValidForOurCountries = (iban = '') => {
  const ibanDetails = extractIBAN(iban);

  return ibanDetails.valid &&
    ibanDetails.countryCode &&
    AllowableIBANCodes.includes(ibanDetails.countryCode);
};
