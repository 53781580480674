/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './SpecifiedItems.scss';

import { Button, InputField, Text, WideDivider, XIcon } from '@arachas/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { createNumberMask } from 'text-mask-addons';

import { dividerColor } from '../../constants/colors';
import specifiedItemsDetailsList from '../../constants/specifiedItemsDetailsList';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import type { SpecifiedItem, SpecifiedItemDetails } from '../../types';
import ResourceDropdown from '../ResourceDropdown';

interface Props {
}

const emptySpecifiedItem = {
  item: '',
  value: '',
  description: ''
};

const untouchedSpecifiedItem = {
  item: false,
  value: false,
  description: false
};

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: false
});

let itemCount = 0;
const maxItemAllowed = 6;

const SpecifiedItems = (props: Props & FormikProps) => {
  const className = 'c-SpecifiedItems';
  const { errors, handleChangeWRecalculate, setFieldValue, setTouched, touched, values, resources } = props;

  useEffect(() => {
    setFieldValue('specifiedItems', values.specifiedItems || [{ ...emptySpecifiedItem }]);
    setTouched({ specifiedItems: [{ ...untouchedSpecifiedItem }] });
  }, [setFieldValue, setTouched]);

  const checkItemExists = (itemNumber: number) => {
    return (
      errors !== undefined &&
      errors[itemNumber] !== undefined &&
      touched !== undefined &&
      touched[itemNumber] !== undefined
    );
  };

  const checkFieldError = (fieldName: string, index: number) => {
    if (checkItemExists(index)) {
      return (
        errors[index][fieldName] !== undefined && touched[index][fieldName]
      );
    }
  };

  const getErrorMessage = (fieldName: string, index: number) => {
    return checkFieldError(fieldName, index) ? errors[index][fieldName] : null;
  };

  const handleBlur = (fieldName: string, itemNumber: number) => {
    const newTouched = [...touched];
    newTouched[itemNumber][fieldName] = true;
    setTouched({ specifiedItems: newTouched });
  };

  const handleChange = (value: any, fieldName: string, index: number) => {
    const newValues = [...values.specifiedItems];
    newValues[index][fieldName] = value;
    handleChangeWRecalculate('specifiedItems', newValues);
  };

  const addItem = () => {
    const newValues = [...values.specifiedItems];
    const newTouched = [...touched];
    newValues.push({ ...emptySpecifiedItem });
    newTouched.push({ ...untouchedSpecifiedItem });
    setTouched({ specifiedItems: newTouched });
    setFieldValue('specifiedItems', newValues);
  };

  const removeItem = (index: number) => {
    const newValues = [...values.specifiedItems];
    const newTouched = [...touched];
    newValues.splice(index, 1);
    newTouched.splice(index, 1);
    setTouched({ specifiedItems: newTouched });
    handleChangeWRecalculate('specifiedItems', newValues);
  };

  const getDescriptionPrompt = (selectedItem: any) => {
    if (selectedItem) {
      const specItemDetails = specifiedItemsDetailsList.find(
        (itemDetails: SpecifiedItemDetails) => itemDetails.dropdownValue === selectedItem.description);
      return specItemDetails ?
        <div className={`${className}__itemPromptContainer`}><Text>{specItemDetails.descriptionPrompt}</Text>
        </div> : null;
    }
  };

  const renderSpecItem = () => {
    const specItemsElements = [];
    const specifiedItems = values.specifiedItems || [];
    const specItemsCount = specifiedItems.length;
    itemCount = specItemsCount;
    specifiedItems.forEach((specifiedItem: SpecifiedItem, index: number) => {
      specItemsElements.push(
        <div key={`SpecifiedItem_${index}`}>
          <div className={`${className}__itemPromptAndNumberContainer`}>
            <div className={`${className}__itemNumberContainer`}>
              <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
                Item {index + 1}
              </label>
              {specItemsCount > 1 &&
                <div
                  className={`${className}__closeIcon`}
                  onClick={() => removeItem(index)}
                  id={`SpecifiedItems__closeIcon${index + 1}`}
                  data-testid={`SpecifiedItems__closeIcon${index + 1}`}
                >
                  <XIcon />
                </div>
              }
            </div>
            {getDescriptionPrompt(specifiedItem.item)}
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='item' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Item type
            </label>
            <ResourceDropdown
              placeholder="Select here"
              error={checkFieldError('item', index)}
              errorMessage={getErrorMessage('item', index)}
              name="item"
              value={specifiedItem.item}
              onChange={(value: any) =>
                handleChange(value, 'item', index)
              }
              onBlur={() => handleBlur('item', index)}
              values={resources[0].specified_item_type}
            />
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='value' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Item value
            </label>
            <InputField
              error={checkFieldError('value', index)}
              errorMessage={getErrorMessage('value', index)}
              prefix={faEuroSign}
              name="value"
              value={specifiedItem.value}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                handleChange(e.currentTarget.value, 'value', index)
              }
              onBlur={() => handleBlur('value', index)}
              masked
              mask={numberMask}
              placeholder="Enter an amount"
            />
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='description' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Item description
            </label>
            <InputField
              error={checkFieldError('description', index)}
              errorMessage={getErrorMessage('description', index)}
              placeholder="Type here"
              name="description"
              value={specifiedItem.description}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                handleChange(e.currentTarget.value, 'description', index)
              }
              onBlur={() => handleBlur('description', index)}
            />
          </div>
          {index < specItemsCount - 1 &&
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
          }
        </div>
      );
    });
    return specItemsElements;
  };

  return (
    <div className={className}>
      {renderSpecItem()}
      {itemCount < maxItemAllowed ?
        <Button
          fluid={true}
          onClick={() => {
            addItem();
          }}
          id='SpecifiedItems__addAnotherItemButton'
          data-testid='SpecifiedItems__addAnotherItemButton'
        >
          Add another item
        </Button>
        : ' '}
    </div>
  );
};

export default SpecifiedItems;
