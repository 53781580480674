/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ButtonWithIcons.scss';

import { noop } from '@arachas/core/lib/utils';
import type { Node } from 'react';
import React from 'react';

type Props = {
  children: Node;
  IconLeft?: noop;
  IconRight?: noop; 
  id?: string;  
  onClick?: noop;
  disabled?: boolean;
  type?: string;
};

const ButtonWithIcons = ({
  children,
  IconLeft,
  IconRight,
  onClick = noop,
  disabled = false,
  id = '',
  type = 'button'
}: Props) => {
  const className = 'c-ButtonWithIcons';

  const activeClassNames = [
    className,
    disabled ? `${className}--disabled` : ''
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      disabled={disabled}
      tabIndex={0}
      onClick={onClick}
      type={type}
      className={activeClassNames}
      id={id}
    >
      {IconLeft ? 
        <div className={`${className}__leftIcon`}>
          <IconLeft />
        </div> 
      : ''}
      {children}
      {IconRight ?
        <div className={`${className}__rightIcon`}> 
          <IconRight />
        </div>     
      : ''}
    </button>
  );
};

export default ButtonWithIcons;
