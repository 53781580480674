/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { getItemFromSessionStorage, makeRequest } from '../../helpers';
const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

export const getAppliedAddress = (address, type, cognitoAccessTokenKey) => {
  const authToken = cognitoAccessTokenKey
    ? getItemFromSessionStorage(cognitoAccessTokenKey)
    : undefined;
  return makeRequest({
    url: `${basePath}/${type}/${type}_quotes/address`,
    authToken: authToken,
    method: 'POST',
    payload: address,
  }).then(response => {
    // todo: implement error handling
    return response;
  });
};
