/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './HelpPage.scss';

import { AccordionCard, PhoneButton } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { primaryPurple } from '../../constants/colors';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import { getContent, getLabel, getUrl } from './TextAndNumbers';

const HelpDetails = (props) => {
  const className = 'c-HelpPage';
  const {
    insuranceType,
  } = props;

  return (
    <>
      <div className={`${className}--spacing`}>
        <AccordionCard
          status="none"
          childContainerClass={`${className}__noPaddingAccordion`}
          customLabelClass={`${className}__accordionLabel`}
          label={getLabel[insuranceType]}
        >
          {getContent[insuranceType].map((item, index) => {
            return (
              <> <div className={`${className}__customContent`} key={index}>
                <p className={`${className}__customContentText`}>{item.text}</p>
                <div className={`${className}__phoneButtonContainer`}>
                  <PhoneButton
                    color={primaryPurple}
                    onClick={() => {
                      fbqTrackEvent('Contact')
                    }}
                    phoneNumber={item.hoursDaysLabel ?
                      '(24 hours, 365 days)' :
                      '(from 09:00 to 17:00 on weekdays)'}
                    isWhite>{item.number}</PhoneButton>
                </div>
              </div>
              </>
            );
          })}
          <div className={`${className}__visitSiteContent`}>
            View Website for more details
          </div>
          <div className={`${className}__buttonContainer`}>
            <a className={`${className}__customAncher`}
              href={getUrl[insuranceType]}
              target="_blank"
              rel="noopener noreferrer">
              Visit Website
            </a>
          </div>

        </AccordionCard>
      </div>
    </>
  );
};

HelpDetails.propTypes = {
  insuranceType: PropTypes.object,
  history: PropTypes.object
};

export default HelpDetails;