
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './AmendProductPage.scss';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { dustyGrayColor } from '../../constants/colors';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';


const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

const MotorAmendProductPage = () => {
  const className = 'c-AmendProductPage';
  return (
    <>
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.addingDriver.url}${CAR_INSURANCE_TYPE}`}>
          <ButtonWithIcons
            id={'MotorAmendProductPage__addingADriverButton'}
            IconRight={RightArrowIcon}
          >
            Adding a driver
          </ButtonWithIcons>
        </Link>
      </div>
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.changeofVehicle.url}${CAR_INSURANCE_TYPE}`}>
          <ButtonWithIcons
            id={'MotorAmendProductPage__changeOfVehicleButton'}
            IconRight={RightArrowIcon}
          >
            Change of Vehicle
          </ButtonWithIcons>
        </Link>
      </div>
    </>

  );
};

export default MotorAmendProductPage;