/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from './insuranceTypeConstants';

export const HOME_POLICY_TYPE = 'HOME';
export const MOTOR_POLICY_TYPE = 'MOTOR';
export const TRAVEL_POLICY_TYPE = 'TRAVEL';
export const DEVICE_POLICY_TYPE = 'DEVICE';
export const VAN_POLICY_TYPE = 'VAN';

export const COMPREHENSIVE_TYPE = 'COMPREHENSIVE';
export const THIRD_PARTY_AND_THEFT_TYPE = 'THIRD_PARTY_AND_THEFT';

export const policyTypeIdentifierLookup = {
  [HOME_INSURANCE_TYPE]: HOME_POLICY_TYPE,
  [CAR_INSURANCE_TYPE]: MOTOR_POLICY_TYPE,
  [TRAVEL_INSURANCE_TYPE]: TRAVEL_POLICY_TYPE,
  [DEVICE_INSURANCE_TYPE]: DEVICE_POLICY_TYPE,
  [VAN_INSURANCE_TYPE]: VAN_POLICY_TYPE
};

export const policyTypeToInsuranceTypeLookup = {
  [HOME_POLICY_TYPE]: HOME_INSURANCE_TYPE,
  [MOTOR_POLICY_TYPE]: CAR_INSURANCE_TYPE,
  [TRAVEL_POLICY_TYPE]: TRAVEL_INSURANCE_TYPE,
  [DEVICE_POLICY_TYPE]: DEVICE_INSURANCE_TYPE,
  [VAN_POLICY_TYPE]: VAN_INSURANCE_TYPE
};
