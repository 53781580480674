/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import React from 'react';

import ContactUs from '../../components/ContactUs/ContactUs';
const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
  isCardError?: boolean;
}

const MotorErrorPage = (props: Props) => {
  const { firstName, isCardError } = props;

  const firstNameMessage = () =>
    firstName ? `We're sorry ${firstName}` : 'We\'re sorry';

  const redTextMessage = 'We have not been able to get an online quote from our insurance partner.';
  const quoteReferenceLine = 'Based on the information provided, the risk you have submitted has fallen outside our acceptance criteria.';
  const cardErrorMessage = 'We have been unable to connect to our payment service. You have not been charged. Please try again.';
  
  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      {
        !isCardError &&
        (<div className={`${className}__warningMessage`} id='MotorErrorPage__warningMessage' data-testid='MotorErrorPage__warningMessage'>
          {redTextMessage}
        </div>)
      }
      <div className={`${className}__referenceTextContainer`}>
        {isCardError ? (
          <div className={`${className}__referenceTextContainer`}>
            {isCardError ? cardErrorMessage : quoteReferenceLine}
          </div>
        ) : (
          <div className={`${className}__referralNotice`}>
            <div>
              If you can provide us with some additional information we may be able to provide you with a quote over the
              phone.
            </div>
            <div>You can contact us on {<ContactUs Number={'01 592 0898'}/>}. Our lines are open Monday to Friday 09:00 - 17:00.
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default MotorErrorPage;
