/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './YourHomeCoverForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, Checkbox, DatePicker, InputField, Text } from '@arachas/core/lib';
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import React from 'react';

import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { isFieldError } from '../../../helpers';
import { rebuildPartOfQuote } from '../../../helpers/BuildingCostHelper';
import { decimalNumberMask } from '../../../helpers/NumberMask';
import { MarketingConsent } from '../../MarketingConsent';

const className = 'c-YourHomeCoverForm';

const checkboxLabelFontSize = 16;

const dutyOfDisclosureURL = 'https://www.arachas.ie/duty-of-disclosure.pdf';
const termsOfUse = 'https://www.arachas.ie/terms-of-use.pdf';

const YourHomeCoverForm = ({ handleBlur, values, errors, touched, handleChange, setFieldValue }) => {
  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="policyStart" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Policy start date
        </label>
        <DatePicker
          error={errors.policyStart}
          name="policyStart"
          onBlur={handleBlur}
          onChange={(e) => setFieldValue('policyStart', e.currentTarget.value)}
          placeholder="Type here"
          touched={touched.policyStart}
          value={values.policyStart}
        />
      </div>
      {rebuildPartOfQuote(values.homeType, values.coverType) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="rebuildCost"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            <AccordionText
              label="How much would it cost to rebuild your home?"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${className}__InfoText`}>
                You can find out more information about calculating the cost of rebuilding your home
                {' '} <a
                  href="https://scsi.ie/consumer/build/house-rebuild-calculator/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </div>
            </AccordionText>
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('rebuildCost', touched, errors)}
              placeholder="Enter an amount"
              errorMessage={errors.rebuildCost}
              prefix={faEuroSign}
              name="rebuildCost"
              value={values.rebuildCost}
              onBlur={(event) => {
                setFieldValue("rebuildCost", event.currentTarget.value);
                handleBlur(event);
              }}
              masked
              onChange={handleChange}
              mask={decimalNumberMask}
            />
          </span>
        </div>
      ) : <></>}
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="contentsCoverOf"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          <AccordionText
            label="Contents cover"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__InfoText`}>
              This covers your household goods and personal belongings
              within the home. Please note permanent fittings such as fitted
              kitchens and bathroom fittings fall under your buildings sum
              insured.
            </div>
          </AccordionText>
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('contentsCoverOf', touched, errors)}
            placeholder="Enter an amount"
            errorMessage={errors.contentsCoverOf}
            prefix={faEuroSign}
            name="contentsCoverOf"
            value={values.contentsCoverOf}
            onChange={handleChange}
            onBlur={handleBlur}
            masked
            mask={decimalNumberMask}
          />
        </span>
      </div>
      <div
        className={`${commonFormStylesIdentifier}__termsAndConditionsContainer`}
      >
        <Text className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Accepting T&Cs:
        </Text>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedAssumptions"
            name="acceptedAssumptions"
            label={
              <>
                Your quote and cover is based on your
                <a
                  href={dutyOfDisclosureURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Duty of Disclosure
                </a>, Please tick to confirm you have read and accepted these.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedAssumptions}
            onChange={() => setFieldValue('acceptedAssumptions', !values.acceptedAssumptions)}
            value={values.acceptedAssumptions}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            label={
              <>
                Please tick to confirm you have read and accepted our
                <a
                  href="https://www.arachas.ie/tob"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Terms of Business
                </a>,
                <a href={termsOfUse}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Use,
                </a>
                &nbsp;
                <a
                  href="//www.arachas.ie/compliance/privacy-policy/"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Data Privacy
                </a>,{' '}
                <a
                  href="https://www.arachas.ie/schedule-of-fees.pdf"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Schedule of Fees
                </a>{' '}
                and
                <a
                  href="//www.arachas.ie/compliance/remuneration/  "
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Remuneration documents
                </a>{' '}
                before proceeding.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedTermsAndConditions}
            onChange={() => setFieldValue('acceptedTermsAndConditions', !values.acceptedTermsAndConditions)}
            value={values.acceptedTermsAndConditions}
          />
        </div>
        <MarketingConsent values={values} checkboxLabelFontSize={checkboxLabelFontSize} setFieldValue={setFieldValue} />

      </div>
    </div>
  );
};

YourHomeCoverForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default YourHomeCoverForm;
