export const youConditions = [
  {
    name: 'main_driver',
    label: 'Is it correct that you are the main driver and do not have full time use of a company car or full time use of another car?'
  },

  {
    name: 'resident_ireland',
    label: 'Is it correct that you or any of the named drivers are permanently residing in Ireland?',
  },
  {
    name: 'no_restrictions',
    label: 'Is it correct that you or any of the named drivers have no restrictions on your driving licence(s)?'
  },
  {
    name: 'no_medical_condition',
    label: `Is it correct that you or any of the named drivers do not suffer from any disability or 
    medical condition that may impair your/their ability to drive, and if you/they do, 
    it has been reported to the relevant driver licensing authority and they have issued a licence to drive?`
  },

  {
    name: 'insurance_refused',
    label: 'Is it correct that neither you or any of the named drivers, have ever had a proposal form or renewal for car insurance refused, a car policy cancelled by an insurer, or any additional terms applied by an insurer?'
  },

  {
    name: 'resides_with_you',
    label: 'Is it correct that any named driver resides with you?'
  }
];

export const yourCarConditions = [
  {
    name: 'registered_in_your_name',
    label: `Is the vehicle registered in your name or your spouse's name?`
  },

  {
    name: 'not_modified',
    label: 'Is it correct that the vehicle has not been modified from the manufacturers standard specifications?'
  },

  {
    name: 'right_hand_drive',
    label: 'Is it correct that the vehicle is right hand drive?'
  },

  {
    name: 'not_imported',
    label: 'Is it correct that the vehicle has not been imported except where the vehicle was originally registered in the United Kingdom?'
  },

  {
    name: 'main_vehicle',
    label: 'Is it correct that this is your main vehicle?'
  }];

export const yourHistoryConditions = [
  {
    name: 'gap_in_cover',
    label: `Is it correct that there is no gap in cover between the expiry date of your previous policy and the start date of this policy?`
  },

  {
    name: 'no_claim_bonus_two_years',
    label: 'Is it correct that your No Claims Bonus was earned within the last two years in your name?',
    tooltip: 'NCB must be earned on a car, not a taxi, van, moped or motorcycle.'
  },

  {
    name: 'effect_ncb',
    label: 'Is it correct that any claim or accident notified did not affect your NCB or the NCB of any named driver?'
  }
];

export const allConditions = [...youConditions, ...yourCarConditions, ...yourHistoryConditions];