/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { AnimatedSpinner } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import {
  SINGLE_SCHEMA_STORAGE
} from '../../constants';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  getAccessToken,
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  makeRequest,
} from '../../helpers';
import { isAgent } from '../../helpers/AgentCheck';
import ViewQuote from './ViewQuote';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

const dynamicResouceList = [
  'dynamic_excess_amount',
  'dynamic_unspecified_items_amount',
];

const className = 'c-ViewQuoteWrapper';

const ViewQuoteWrapper = props => {
  const [resources, setResources] = useState(null);
  const [specifiedItemsResources, setSpecifiedItemsResources] = useState(null);
  const [schemeId, setSchemeId] = useState(null);
  const isAnAgent = isAgent();
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    if (!resources) {
      let authToken = undefined;
      if (isAnAgent) {
        authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      }

      let singlePromise = makeRequest({
        url: `${basePath}/home/resources/specified_item_type`,
        authToken: authToken,
      });


      const selectedSchema = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
      const currentSchemeId = props.schemeId !== null ? props.schemeId : selectedSchema.scheme_code;
      setSchemeId(currentSchemeId);

      let promiseListDynamic = dynamicResouceList.map(dynanmicResource =>
        makeRequest({
          url: `${basePath}/home/resources/${dynanmicResource}/${currentSchemeId}`,
          authToken: authToken,
        }),
      );

      singlePromise.then(response => {
        setSpecifiedItemsResources([{ 'specified_item_type': [...response] }]);
      });

      Promise.all(promiseListDynamic).then(response => {
        const resourcesMap = dynamicResouceList.reduce((result, resource, index) => {
          result[resource] = response[index];
          return result;
        }, {});
        setResources(resourcesMap);
      });
    }
  }, [resources]);

  if (!resources)
    return (
      <div className={`${className}__spinnerContainer`}>
        <AnimatedSpinner />
      </div>
    );
  return <ViewQuote {...props} resources={resources} schemeId={schemeId} specifiedItemsResources={specifiedItemsResources} />;
};


ViewQuoteWrapper.propTypes = {
  schemeId: PropTypes.string,
};

export default ViewQuoteWrapper;
