"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MapIcon = function MapIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22 22",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("path", {
    style: {
      opacity: .3
    },
    d: "M5 18.3l3-1.2V5.5l-3 1zm11 .3l3-1V5.7l-3 1.2z"
  }), _react2.default.createElement("path", {
    d: "M20.5 3h-.2L15 5.1 9 3 3.4 4.9a.51.51 0 0 0-.4.5v15.1a.472.472 0 0 0 .5.5h.2L9 18.9l6 2.1 5.6-1.9c.2-.1.4-.2.4-.5V3.5a.472.472 0 0 0-.5-.5zM8 17.2l-3 1.2V6.5l3-1zm6 1.3l-4-1.4V5.5l4 1.4zm5-1l-3 1V6.9l3-1.2z"
  }));
};

exports.default = MapIcon;