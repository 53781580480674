/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { getItemFromSessionStorage, makeRequest } from '../../helpers';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

export const postVanQuote = async (deviceQuoteData, cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/van/van_quotes`,
    authToken: authToken,
    payload: deviceQuoteData,
    history: props.history
  });
};

export const getVanRegLookup = async (vanReg, cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/van/vehicles/vandetails/${vanReg}`,
    authToken: authToken,
    history: props.history
  });
};

