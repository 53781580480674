/**
 * @flow
 */
import './BrandLogo.scss'

import React from 'react';

type BrandLogoProps = {
    theme?: string
};

const BrandLogo = ({ theme }: BrandLogoProps) => {
    return (
        <img
            id="Brand__Logo"
            src={theme === "dark" ? '/asset/Arachas_2019.png' : '/asset/Arachas_White_2019.png'}
            alt="Brand__Logo"
        />
    );
};

export default BrandLogo;