/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { CAR_INSURANCE_TYPE, iconsAndTitles, SINGLE_POLICY_STORAGE, VAN_INSURANCE_TYPE } from '../../constants';
import { axaHelpNumber, insurerNumber, vanOutsideIrelandHelp } from '../../constants/phoneNumbers';
import {
  breakdownPhoneNumber,
} from '../../constants/phoneNumbers';
import {
  getObjectFromSessionStorage,
} from '../../helpers';


const BreakdownAssistance = ({ match, history }) => {
  const insuranceType = match.params.type || '';

  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);

  const getCarInsurerNumber = () => {
    return policyDetails && policyDetails.insurer ?
      insurerNumber[policyDetails.insurer] :
      breakdownPhoneNumber;
  };

  const getNumber = {
    [CAR_INSURANCE_TYPE]: getCarInsurerNumber(),
    [VAN_INSURANCE_TYPE]: axaHelpNumber
  };

  const afterContent = {
    [CAR_INSURANCE_TYPE]: `Our Breakdown Assistance service is available 24hrs 365 days a year.
    By calling the emergency number you will receive assistance by the roadside or in your driveway.
    One of our agents will assess your needs and arrange the service most suited to your emergency.
    We will ensure you can safely complete your journey or secure accommodation if required.
    We will also ensure you get access to the repair services you need.`,
    [VAN_INSURANCE_TYPE]: `Our breakdown Assistance service is available 24hrs 365 days a year. 
    Call us at these numbers day or night and we will arrange the service most suited to your emergency`
  };

  const getInternationalPhoneNumber = {
    [VAN_INSURANCE_TYPE]: vanOutsideIrelandHelp
  };

  return (

    <CallUsPage
      history={history}
      icon={insuranceType && iconsAndTitles[insuranceType].icon}
      cardTitle={insuranceType && iconsAndTitles[insuranceType].title}
      title="Breakdown Assistance"
      contentText="For breakdown assistance please call us on"
      phoneNumber={getNumber[insuranceType]}
      afterContent={afterContent[insuranceType]}
      internationalPhoneNumber={getInternationalPhoneNumber[insuranceType]}
    />
  );
};

BreakdownAssistance.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(BreakdownAssistance);
