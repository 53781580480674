"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Switch = require("./Switch");

var _Switch2 = _interopRequireDefault(_Switch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Switch2.default;