/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { getItemFromSessionStorage, makeRequest } from '../../helpers';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

export const getCarRegLookup = async (carReg, cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/motor/resources/vehicle/lookup?registrationNumber=${carReg}`,
    authToken: authToken,
    history: props.history
  });
};
