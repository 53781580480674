/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './ErrorBoundary.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';

import { routes } from '../../routes';
import DesktopHeader from '../DesktopHeader';
import MobileHeader from '../MobileHeader';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    console.error(error, info)
  }

  render() {
    const className = 'c-ErrorBoundary';
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="c-App">
          {isMobile ?
            <MobileHeader routes={routes} /> :
            <DesktopHeader routes={routes} />
          }
          <div className={`${className}__welcomeContainer`}>
            <div className={`${className}__welcomeText`}>
              <h1>Something went wrong.</h1>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = { children: PropTypes.node.isRequired };

export default ErrorBoundary;
