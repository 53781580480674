/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { getItemFromSessionStorage, makeRequest } from '../../helpers';

export const postChangeCar = async (changeCarPayload, cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/motor/mta/change_car`,
    authToken: authToken,
    payload: changeCarPayload,
    history: props.history
  });
};
