/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../PolicyLinksPage.scss';

import { AnimatedSpinner } from "@arachas/core/lib";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import PdfIcon from '../../../components/PdfIcon/PdfIcon';
import { offWhite } from '../../../constants/colors';
import {
  CAR_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
} from '../../../constants/insuranceTypeConstants';
import { SINGLE_POLICY_STORAGE } from '../../../constants/sessionStorage';
import { getObjectFromSessionStorage } from '../../../helpers';
import { routes } from '../../../routes';
import { getDocumentLinks } from '../../../services/common/commonServices';


const className = 'c-PolicyLinksPage';

const LinksPage = ({ cognitoAccessTokenKey, history, insuranceType, staticLinks = [] }) => {

  const getDocumentsLookup = (insuranceType) => insuranceType === CAR_INSURANCE_TYPE ?
    `/motor/motor_quotes/documents?policyReference=` : `/${insuranceType}/${insuranceType}_quotes/documents?policyReference=`;

  const [documentLinks, setDocumentLinks] = useState([]);
  const [loadingDocumentLinks, setLoadingDocumentLinks] = useState(true);

  const getPolicyDocuments = async () => {
    const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);
    const policyNumber = policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;

    const documentsResponse = await getDocumentLinks(cognitoAccessTokenKey, `${getDocumentsLookup(insuranceType)}${policyNumber}`, { history });
    setDocumentLinks(getPolicyDocumentsFromResponse(policyNumber, documentsResponse));
    setLoadingDocumentLinks(false);
  };

  useEffect(() => {
    if (cognitoAccessTokenKey) {
      getPolicyDocuments();
    }
  }, [cognitoAccessTokenKey]);

  const getPolicyDocumentsFromResponse = (policyNumber, documentsResponse = {}) => {
    if (documentsResponse.documents && documentsResponse.documents.length > 0) {
      // We're querying for a single policy so there should only ever be one
      return documentsResponse.documents;
    }
    return [];
  };


  const getStaticLinks = () => {
    return staticLinks.map((labelsAndUrl, index) => (
      <div className={`${className}__linksContainer`} key={`StaticLinks_${index}`}>
        {labelsAndUrl ?
          labelsAndUrl.url ?
            <a href={labelsAndUrl.url} className={`${className}__link`} rel="noopener noreferrer"
              target="_blank">
              {labelsAndUrl.label}
              <PdfIcon id="Icon_PDF" className={`${className}__linkIcon`} />
            </a> :
            <Link to={`${routes.comingSoon.url}`} className={`${className}__link`}>
              {labelsAndUrl.label}
              <PdfIcon id="Icon_PDF" className={`${className}__linkIcon`} />
            </Link>
          :
          <></>}
      </div>
    ));
  };

  if (loadingDocumentLinks) {
    return <div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner backgroundColor={offWhite} />
    </div>;
  } else if (documentLinks.length === 0 && insuranceType === TRAVEL_INSURANCE_TYPE) {
    return <div className={`${className}__noDocumentsMessage`}>Failed to load the documents</div>;
  }

  return (
    <>
      {getStaticLinks()}
      {documentLinks.map((document, index) => {
        return (
          <div className={`${className}__linksContainer`} key={`travel_document_${index}`}>
            <a href={document.url} className={`${className}__link`} rel="noopener noreferrer" target="_blank">
              {document.name}
              <PdfIcon id="Icon_PDF" className={`${className}__linkIcon`}
              />
            </a>
          </div>);
      })}
    </>
  );
};

LinksPage.propTypes = {
  cognitoAccessTokenKey: PropTypes.string,
  history: PropTypes.object,
  insuranceType: PropTypes.string,
  staticLinks: PropTypes.array
};

export default LinksPage;
