/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MtaUploadDocumentsThankYou.scss';

import { Button, DesktopCard, Title } from '@arachas/core/lib';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { backToWallet } from '../../constants/buttonLabelConstants';
import { routes } from '../../routes';
import UserContext from '../../UserContext';

type MtaUploadDocumentsThankYouProps = {
  insuranceType: string;
};

const className = 'c-MtaUploadDocumentsThankYou';

const MtaUploadDocumentsThankYou = ({ insuranceType }: MtaUploadDocumentsThankYouProps) => {
  const { getUserName } = useContext(UserContext);


  const getTitleText = () => {
    const name = getUserName();
    return name ? `Thanks ${name},` : 'Thanks!';
  };

  const renderDefaultBody = () => (
    <div>
      <DesktopCard>
        <div className={className}>
          <div className={`${className}__cancelPolicyPageTextContainer`}>
            <div className={`${className}__primaryTextContainerOne`}>
              <Title type="h1" weight="500">
                <span id="PaymentsThankYouPage__thankYouText">{getTitleText()}</span>
              </Title>
            </div>
            <div className={`${className}__primaryTextContainerTwo`}>
              You have successfully uploaded documents to your policy.
              To view your documents please select the &quot;Uploaded Documents&quot; button below.
            </div>
          </div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={`${routes.viewUploadedDocumentsPage.url}${insuranceType}`}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  UPLOADED DOCUMENTS
                </Button>
              </Link>
            </div>
          </div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.Wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToWallet}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </div>
  );

  return renderDefaultBody();
};

export default MtaUploadDocumentsThankYou;
