import {
    CAR_INSURANCE_TYPE,
    HOME_INSURANCE_TYPE,
    TRAVEL_INSURANCE_TYPE,
    VAN_INSURANCE_TYPE
} from '../../constants';
import {
    arachasAgent,
    arachasHomeAgent,
    avivaBreakdownNumber,
    axaHelpNumber,
    breakdownPhoneNumber,
    chubbEmergency,
    chubbIreland,
    insurerDirect,
    travelInsurancePhoneNumber,
    vanArachasAgentNumber,
    vanOutsideIrelandHelp,
    zurichClaimsHelpline
} from '../../constants/phoneNumbers';

export const homeTextAndNumber = [
    {
        "text": "Need to talk to an Arachas agent",
        "number": arachasHomeAgent
    },
    {
        "text": "Make a claim by calling Zurich directly",
        "number": insurerDirect
    },
    //Hidden as part of story AR-3775 
    // {
    //     "text": "Make a claim by calling Allianz directly",
    //     "number": allianzPhoneNumber,
    //     "hoursDaysLabel": true,
    // },
    // {
    //     "text": "Make a claim by calling Axa directly",
    //     "number": axaHelpNumber,
    //     "hoursDaysLabel": true,
    // },
    // {
    //     "text": "Make a claim by calling Aviva directly",
    //     "number": avivaHelpNumber,
    //     "hoursDaysLabel": true,
    // }
];

export const travelTextAndNumber = [
    {
        "text": "Need to talk to an Chubb agent",
        "number": travelInsurancePhoneNumber
    },
    {
        "text": "Or if calling from outside Ireland",
        "number": chubbIreland
    },
    {
        "text": "If you need emergency assistance contact Chubb",
        "number": chubbEmergency
    }
];

export const motorTextAndNumber = [
    {
        "text": "Need to talk to an Arachas agent",
        "number": arachasAgent
    },
    {
        "text": "Contact Allianz Breakdown Assistance",
        "number": breakdownPhoneNumber,
        hoursDaysLabel: true
    },
    {
        "text": "Contact Zurich Breakdown Assistance",
        "number": zurichClaimsHelpline,
        hoursDaysLabel: true
    },
    {
        "text": "Contact Aviva Breakdown Assistance",
        "number": avivaBreakdownNumber,
        hoursDaysLabel: true
    },
    {
        "text": "Contact Axa Breakdown Assistance",
        "number": axaHelpNumber,
        hoursDaysLabel: true
    }
];

export const vanTextAndNumber = [
    {
        "text": "Need to talk to an Arachas agent",
        "number": vanArachasAgentNumber,
        "hoursDaysLabel": false
    },
    {
        "text": "Contact Axa Breakdown Assistance",
        "number": axaHelpNumber,
        "hoursDaysLabel": true,
    },
    {
        "text": "If calling from outside ROI",
        "number": vanOutsideIrelandHelp,
        "hoursDaysLabel": true
    }
];

export const getUrl = {
    [HOME_INSURANCE_TYPE]: 'https://www.arachas.ie/home-insurance',
    [CAR_INSURANCE_TYPE]: 'https://www.arachas.ie/car-insurance',
    [TRAVEL_INSURANCE_TYPE]: 'https://www.arachas.ie/personal-insurance/travel-insurance',
    [VAN_INSURANCE_TYPE]: 'https://www.arachas.ie/business/commercial-vehicle-insurance'
};

export const getContent = {
    [HOME_INSURANCE_TYPE]: homeTextAndNumber,
    [CAR_INSURANCE_TYPE]: motorTextAndNumber,
    [TRAVEL_INSURANCE_TYPE]: travelTextAndNumber,
    [VAN_INSURANCE_TYPE]: vanTextAndNumber
};

export const getLabel = {
    [HOME_INSURANCE_TYPE]: 'Home Insurance',
    [CAR_INSURANCE_TYPE]: 'Car Insurance',
    [TRAVEL_INSURANCE_TYPE]: 'Travel Insurance',
    [VAN_INSURANCE_TYPE]: 'Van Insurance'
};