import './AddAdditionalDriverPage.scss';

import PropTypes from "prop-types";
import React from 'react';

import AddAdditionalDriver from '../../components/AddAdditionalDriver';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { SINGLE_POLICY_STORAGE } from '../../constants/sessionStorage';
import { getObjectFromSessionStorage } from '../../helpers';
import { routes } from '../../routes';

const AddAdditionalDriverPage = ({ history }) => {
  const policyDetails = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${SINGLE_POLICY_STORAGE}`);
  const policyNumber = policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;

  if (!policyNumber) history.push(routes.Wallet.url);

  return (
    <AddAdditionalDriver history={history} policyNumber={policyNumber} />
  );
};

AddAdditionalDriverPage.propTypes = {
  history: PropTypes.object,
};

export default AddAdditionalDriverPage;
