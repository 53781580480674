/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */


import { AnimatedSpinner } from '@arachas/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { whiteColor } from '../../constants/colors';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getInsuranceProviders, getTripTypeOptions, getZonesForTripType } from '../../helpers/TravelHelper';
import { routes } from '../../routes';
import { getTravelResources } from '../../services/travel/travelResourcesServices';
import type { RouterPropsType } from '../../types/commonTypes';
import type { TravelResourcesType } from '../../types/travel/YourTravelTypes';
import TravelDestinationPage from './TravelDestinationPage';
const className = 'c-YourTravelForm';

const TravelDestinationPageWrapper = (props: RouterPropsType & FormikProps) => {
  const { history } = props;
  const [resources, setResources] = useState(null);

  useEffect(() => {
    getTravelResources(props).then((res: TravelResourcesType) => {
      res.trip_types ? setResources(res) :
        /* If the trip types haven't been loaded correctly we can't proceed with
           the journey so redirect the user to the error page */
        history.push(`${routes.genericErrorPage.url}${TRAVEL_INSURANCE_TYPE}`);
    });

  }, []);

  if (!resources) return <div className={`${className}__spinnerContainer`}><AnimatedSpinner backgroundColor={whiteColor} /></div>;


  return (
    <TravelDestinationPage
      {...props}
      zonesForTripType={getZonesForTripType(resources.trip_types)}
      tripTypeOptions={getTripTypeOptions(resources.trip_types)}
      insuranceOptions={getInsuranceProviders(resources.trip_types)}
      destinationCountryList={resources.destination_country_list}
    />
  );
};

export default TravelDestinationPageWrapper;
