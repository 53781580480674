import './MessagesPage.scss';

import { Title } from '@arachas/core/lib';
import { EnvelopeIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import Messages from '../../components/Messages';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';

const className = 'c-MessagesPage';
const titleText = 'Messages';

const MessagesPage = (props) => {
  const { history } = props;

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop ${className}__backButtonPadding`} >
        <MobileBackButton history={history} />
      </div>

      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <Title align="left" type="h1" weight="weight500" variant="greyBrown">
                <div className={`${className}__title`}>
                  <span className={`${className}__titleIcon`}>
                    <EnvelopeIcon />
                  </span> {titleText}
                </div>
              </Title>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle ${className}__title`}>
              <span className={`${className}__titleIcon`}>
                <EnvelopeIcon />
              </span> {titleText}
            </div>
          </div>

          <div className={`${className}__messagesContainer`}>
            <Messages />
          </div>

        </div>
      </div>
    </div>
  );
};

MessagesPage.propTypes = {
  history: PropTypes.object,
};

export default MessagesPage;
