export const dividerColor = '#c0c1c3';
export const whiteColor = '#FFFFFF';
export const midGrayColor = '#a0a1a3';
export const primaryBlue = '#106988';
export const dustyGrayColor = '#979797';
export const blueNotification = '#1081a6';
export const darkOrange = '#ef6c00';
export const offWhite = '#fafbfc';
export const primaryPurple = '#600060';
export const macAndCheese = '#f2b53b';
export const fontColorSecondary = '#474747';
export const white = '#ffffff'