import '../../../styles/commonPageStyles.scss';
import "../AssumptionsPage.scss"

import { Button, DesktopCard } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import { deviceInsurancePhoneNumber } from '../../../constants/phoneNumbers';

const noAgreeInfo =
  `Unfortunately, if you cannot confirm the above statements we will not be able to offer cover, therefore you will ` +
  `unable to proceed any further, please call ${deviceInsurancePhoneNumber} for assistance.`;
  
const className = 'c-AssumptionsPage';

const DeviceAssumptionsPage = (props) => {
  const [noAgreement, setNoAgreement] = useState(false);

  const {
    goToThankYouPage
  } = props;

  return (
    <DesktopCard>
      <div className={`${className}__title`}>
      Device Declaration
      </div>
      <p className={`${className}__note`}>
      You are responsible for answering honestly and with reasonable care all questions posed by the insurer. 
      Failure to do so may (i) affect the policy terms and/or claims under the policy; and/or 
      (ii) entitle the insurer to terminate the policy or treat the policy as if it never existed.
      </p>
      <div className={`${className}__declarationContent`}> 
        <ol type="a"> 
          <li value="a"> You have a legal duty prior to entering into this 
                policy to provide responses to questions asked by Zurich in relation to the risk(s) to be insured.  </li>
          <li> a matter about which Zurich asks a specific question 
                is material to the risk undertaken by Zurich or the calculation of the premium by Zurich, or both. </li>
          <li> you have a legal duty to answer all questions asked by Zurich honestly and with reasonable care. </li>
          <li> while Zurich acknowledges that you have no legal duty of voluntary disclosure, you shall ensure 
                that information which is voluntarily provided by you or on your behalf is provided honestly 
                and with reasonable care. </li>
        </ol>    
        <p>
        Before we provide you with a policy, please make sure you can confirm the following statements:
        </p>
        <div className={`${className}__subHeading`}>About The device</div>
          <ol> 
            <li> The device was purchased no more than 12 months ago or was insured previously with us 
              within 12 months of the purchase date. </li>
            <li> The device has been purchased as new (not previously owned) from a Republic of Ireland 
              VAT registered company (not from online auctions or device exchange outlets) </li>
            <li> I can provide a valid proof of purchase in the name of the insured, in the event of a claim. </li>
          </ol>   
          <div className={`${className}__subHeading`}>About You</div>
          <ol> 
            <li value="4"> I have not made more than two gadget insurance claims in the last 12 months. </li>
            <li> I have never had any insurances declined or cancelled, made subject to special terms 
              or been requested to take additional precautions. </li>
            <li> I have never been cautioned for, convicted or, or charged but not yet tried 
              for a criminal offence other than a driving offence or a spent conviction. </li>
            <li> I am over the age of 18 years. </li>
          </ol>   
      </div>
      <div className={`${className}__confirmButtonContainer`}>
        <Button
            quaternary
            fluid
            secondary
            onClick={() => goToThankYouPage({"assumptions": true})}
            >
            I confirm
          </Button>
        </div>
        <div className={`${className}__denyButtonContainer`}>
          <Button
            fluid
            secondary    
            onClick={() => {
            setNoAgreement(true);
          }}
          >
            {`I do not confirm`}
          </Button>
          {noAgreement && (
          <p className={`${className}__noAgreeInfo`}>{noAgreeInfo}</p>
        )}
        </div>
      </DesktopCard> 
  );
};

DeviceAssumptionsPage.propTypes = {
  goToThankYouPage: PropTypes.func,
};

export default DeviceAssumptionsPage;

