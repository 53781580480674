"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPhoneNumberPayload = exports.createEmailPayload = undefined;

require("./ContactButton.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utils = require("../../utils");

var _PhoneIcon = require("../Icons/PhoneIcon/PhoneIcon");

var _PhoneIcon2 = _interopRequireDefault(_PhoneIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * 
 */
var PHONE_TYPE = 'ContactButton/PHONE_TYPE';
var EMAIL_TYPE = 'ContactButton/EMAIL_TYPE';

var getTelLink = function getTelLink(_ref) {
  var phoneNumber = _ref.phoneNumber;
  return "tel:".concat(phoneNumber.replace(/ /g, ''));
};

var getMailtoLink = function getMailtoLink(_ref2) {
  var email = _ref2.email;
  return "mailto:".concat(email.replace(/ /g, ''));
};

var getContactLink = function getContactLink(payload) {
  return payload.typename === EMAIL_TYPE && getMailtoLink(payload) || payload.typename === PHONE_TYPE && getTelLink(payload);
};

var getContactString = function getContactString(payload) {
  return payload.typename === EMAIL_TYPE && payload.email || payload.typename === PHONE_TYPE && payload.phoneNumber;
};

var createEmailPayload = exports.createEmailPayload = function createEmailPayload(email) {
  return {
    typename: EMAIL_TYPE,
    email: email
  };
};

var createPhoneNumberPayload = exports.createPhoneNumberPayload = function createPhoneNumberPayload(phoneNumber) {
  return {
    typename: PHONE_TYPE,
    phoneNumber: phoneNumber
  };
};

var BaseContactButton = function BaseContactButton(_ref3) {
  var _ref3$onClick = _ref3.onClick,
      onClick = _ref3$onClick === void 0 ? _utils.noop : _ref3$onClick,
      id = _ref3.id,
      _ref3$disabled = _ref3.disabled,
      disabled = _ref3$disabled === void 0 ? false : _ref3$disabled,
      _ref3$type = _ref3.type,
      type = _ref3$type === void 0 ? 'button' : _ref3$type,
      _ref3$label = _ref3.label,
      label = _ref3$label === void 0 ? '' : _ref3$label,
      _ref3$isWhite = _ref3.isWhite,
      isWhite = _ref3$isWhite === void 0 ? false : _ref3$isWhite,
      children = _ref3.children,
      contact = _ref3.contact,
      _ref3$color = _ref3.color,
      color = _ref3$color === void 0 ? '#ffffff' : _ref3$color;
  var className = 'c-ContactButton';

  var getLightBackgroundClassName = function getLightBackgroundClassName(baseClass, isWhite) {
    return isWhite ? "".concat(baseClass, "--whiteBackground") : '';
  };

  return _react2.default.createElement("a", {
    href: getContactLink(contact),
    className: className
  }, _react2.default.createElement("button", {
    disabled: disabled,
    tabIndex: 0,
    onClick: onClick,
    type: type,
    className: "".concat(className, "__button ").concat(getLightBackgroundClassName("".concat(className, "__button"), isWhite)),
    id: id
  }, _react2.default.createElement("span", {
    className: "".concat(className, "__suffix ").concat(getLightBackgroundClassName("".concat(className, "__suffix"), isWhite))
  }, _react2.default.createElement(_PhoneIcon2.default, {
    color: color
  })), _react2.default.createElement("div", {
    className: "".concat(className, "__textContainer")
  }, _react2.default.createElement("span", {
    className: "".concat(className, "__text")
  }, children), _react2.default.createElement("span", {
    className: "".concat(className, "__contactLabel")
  }, label ? label : getContactString(contact)))));
};

var ContactButton = (0, _react.memo)(BaseContactButton);
ContactButton.displayName = 'ContactButton';
exports.default = ContactButton;