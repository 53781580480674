import * as yup from "yup";

import { isValidDynamicResourceValue, isValidResourceValue } from '../../../helpers/FieldErrorHelper';

export const yourVanFormYupSchema = {
  isManualSearch: yup
    .boolean()
    .required(),
  vanReg: yup
    .string()
    .when('isManualSearch', {
      is: false,
      then: yup.string()
        .required('Van registration is required')
    }),
  vanType: yup
    .string()
    .required('Van body type is required'),
  vanMake: yup
    .string()
    .required('Van make is required'),
  vanModel: yup
    .string()
    .required('Van model is required'),
  vanYear: yup
    .string()
    .required('Van year is required'),
  vanWeight: yup
    .string()
    .required('Van payload weight is required'),
  vanDescription: yup
    .string()
    .required('Van description is required'),
  vehicleValue: yup
    .string()
    .required('Vehicle value required'),
  annualKm: yup
    .mixed().test('annualKm',
      'Please Select', isValidResourceValue)
    .required('Please select'),
  signage: yup
    .boolean()
    .oneOf([true, false])
    .required('Please select'),
  areaUse: yup
    .object().test('areaUse',
      'Please Select', isValidResourceValue)
    .required('Please select'),
  subAreaUse: yup
    .object()
    .test('subAreaUse',
      'Please Select', isValidDynamicResourceValue),
  ncdYears: yup
    .object().test('ncdYears',
      'Please Select', isValidResourceValue)
    .required('Please select'),
  ncdInsurer: yup
    .object().test('ncdInsurer',
      'Please Select', isValidResourceValue)
    .required('Please select'),
  ncdProctection: yup
    .boolean()
    .oneOf([true, false])
    .required('Please provide an answer.'),
  privateCar: yup
    .boolean()
    .oneOf([true, false])
    .required('Please provide an answer.'),
};

export default yourVanFormYupSchema;
