"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./Modal.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _utils = require("../../utils");

var _Button = require("../Button");

var _Button2 = _interopRequireDefault(_Button);

var _Icons = require("../Icons");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var className = 'c-Modal';

var Modal = function Modal(_ref) {
  var children = _ref.children,
      _ref$modalElementId = _ref.modalElementId,
      modalElementId = _ref$modalElementId === void 0 ? 'modal' : _ref$modalElementId,
      title = _ref.title,
      toggle = _ref.toggle,
      _ref$showModalHeader = _ref.showModalHeader,
      showModalHeader = _ref$showModalHeader === void 0 ? true : _ref$showModalHeader,
      onSecondaryButtonClick = _ref.onSecondaryButtonClick,
      _ref$secondaryButtonL = _ref.secondaryButtonLabel,
      secondaryButtonLabel = _ref$secondaryButtonL === void 0 ? 'Cancel' : _ref$secondaryButtonL,
      _ref$primaryButtonLab = _ref.primaryButtonLabel,
      primaryButtonLabel = _ref$primaryButtonLab === void 0 ? 'Proceed' : _ref$primaryButtonLab,
      onPrimaryButtonClick = _ref.onPrimaryButtonClick,
      _ref$showSecondaryBut = _ref.showSecondaryButton,
      showSecondaryButton = _ref$showSecondaryBut === void 0 ? true : _ref$showSecondaryBut,
      show = _ref.show,
      _ref$closeOnBackgroun = _ref.closeOnBackgroundClick,
      closeOnBackgroundClick = _ref$closeOnBackgroun === void 0 ? false : _ref$closeOnBackgroun,
      _ref$showCloseIcon = _ref.showCloseIcon,
      showCloseIcon = _ref$showCloseIcon === void 0 ? true : _ref$showCloseIcon,
      onCloseClick = _ref.onCloseClick,
      _ref$fullPageModal = _ref.fullPageModal,
      fullPageModal = _ref$fullPageModal === void 0 ? true : _ref$fullPageModal;

  var _useState = (0, _react.useState)(null),
      _useState2 = _slicedToArray(_useState, 2),
      wrapperRef = _useState2[0],
      setWrapperRef = _useState2[1];

  var modalEl = document.getElementById(modalElementId);
  (0, _react.useLayoutEffect)(function () {
    // Get original body overflow
    var originalStyle = window.getComputedStyle(document.body).overflow; // Prevent scrolling on mount

    if (document.body && show) {
      document.body.style.overflow = "hidden";
    } // Re-enable scrolling when component unmounts


    return function () {
      if (document.body) return document.body.style.overflow = originalStyle;
    };
  }, [show]);
  if (!modalEl) return null;

  var getModalTitle = function getModalTitle() {
    return title ? _react2.default.createElement("div", {
      className: "".concat(className, "__modalTitle")
    }, title) : _react2.default.createElement(_react2.default.Fragment, null);
  };

  var getHeaderCancelIcon = function getHeaderCancelIcon() {
    return showCloseIcon ? _react2.default.createElement("div", {
      className: "".concat(className, "__closeModalButton"),
      onClick: onCloseClick
    }, _react2.default.createElement(_Icons.XIcon, null)) : _react2.default.createElement(_react2.default.Fragment, null);
  };

  var getModalHeader = function getModalHeader() {
    return showModalHeader ? _react2.default.createElement("div", {
      className: "".concat(className, "__modalHeader")
    }, getModalTitle(), getHeaderCancelIcon()) : _react2.default.createElement(_react2.default.Fragment, null);
  };

  var getFooterSecondaryButton = function getFooterSecondaryButton() {
    return showSecondaryButton ? _react2.default.createElement("div", {
      className: "".concat(className, "__cancelButtonContainer")
    }, _react2.default.createElement(_Button2.default, {
      secondary: true,
      onClick: onSecondaryButtonClick
    }, secondaryButtonLabel)) : _react2.default.createElement(_react2.default.Fragment, null);
  };

  var checkModalClose = function checkModalClose(event) {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      onCloseClick ? onCloseClick() : toggle();
    }
  };

  var getClassNameContainer = function getClassNameContainer() {
    return fullPageModal ? "".concat(className, "__modalContainer ").concat(className, "__fullPageMobileContainer") : "".concat(className, "__modalContainer ").concat(className, "__halfPageMobileContainer");
  };

  var getModalJSX = function getModalJSX() {
    return show ? _reactDom2.default.createPortal(_react2.default.createElement("div", {
      className: className,
      onClick: function onClick(event) {
        return closeOnBackgroundClick ? checkModalClose(event) : null;
      }
    }, _react2.default.createElement("div", {
      className: getClassNameContainer(),
      ref: function ref(current) {
        return setWrapperRef(current);
      }
    }, getModalHeader(), _react2.default.createElement("div", {
      className: "".concat(className, "__modalBody")
    }, children), _react2.default.createElement("div", {
      className: "".concat(className, "__modalFooter")
    }, primaryButtonLabel === null ? _react2.default.createElement(_react2.default.Fragment, null) : _react2.default.createElement("div", {
      className: "".concat(className, "__proceedButtonContainer")
    }, _react2.default.createElement(_Button2.default, {
      onClick: onPrimaryButtonClick
    }, primaryButtonLabel)), getFooterSecondaryButton()))), modalEl) : null;
  };

  return getModalJSX();
};

exports.default = Modal;