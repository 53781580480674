/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './DocumentsUploadedPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  AnimatedSpinner,
  Button,
  DeleteIcon,
  DesktopCard,
  Modal
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { IconAndTitle } from '../../components/IconAndTitle';
import { MobileBackButton } from '../../components/MobileBackButton';
import PdfIcon from '../../components/PdfIcon/PdfIcon';
import { commonPageStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { backToWallet } from '../../constants/buttonLabelConstants';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE }
  from '../../constants/insuranceTypeConstants';
import { getAccessToken, getObjectFromSessionStorage } from '../../helpers';
import { getTimeOnly, rawToIrishFormat } from "../../helpers/DateHelper";
import { routes } from '../../routes';
import { deleteUploadedDocument, getUploadedDocument, getUploadedDocumentsList } from "../../services/uploadService";
import { DeleteModal, ErrorModal } from "./ModalsInfo";
const className = 'c-DocumentsUploadedPage';

const pageTitle = 'Uploaded Documents';

const DocumentsUploadedPage = (props) => {
  const { match, history } = props;
  const insuranceType = match.params.type || '';

  const { cognitoUser } = useContext(CognitoContext);
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);
  const getPolicyNumber = () => policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;

  const [uploadedDocuments, setUploadedDocuments] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [errorDeleteMsg, setErrorDeleteMsg] = useState(null);

  useEffect(() => {
    if (!uploadedDocuments) {

      getUploadedDocumentsList(getAccessToken(cognitoUser), insuranceType, getPolicyNumber())
        .then(response => {
          response.document_details.sort((a, b) =>
            new Date(b.file_uploaded_at).getTime() - new Date(a.file_uploaded_at).getTime()
          );
          setUploadedDocuments(response);
        });
    }
  }, [uploadedDocuments]);

  const getPageTitle = {
    [HOME_INSURANCE_TYPE]: 'Home',
    [CAR_INSURANCE_TYPE]: 'Car',
    [TRAVEL_INSURANCE_TYPE]: 'Travel',
    [DEVICE_INSURANCE_TYPE]: 'Device'
  };

  const toggleModal = () => {
    setErrorMessage(!errorMessage);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteId(null);
    setErrorDeleteMsg(null);
  };


  const deleteDoc = () => {
    deleteUploadedDocument(getAccessToken(cognitoUser), insuranceType, getPolicyNumber(), deleteId).then(
      response => {
        if (response.errorMessage === "Unexpected end of JSON input" || response.errorMessage === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data") {
          toggleDeleteModal();
          getUploadedDocumentsList(getAccessToken(cognitoUser), insuranceType, getPolicyNumber())
            .then(response => {
              setUploadedDocuments(response);
            });
        }
        else {
          setErrorDeleteMsg("There was an error deleting the file try again");
        }
      }

    );
  };

  const openFile = (response, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(response);
    link.download = fileName;
    window.open(link);
  };

  const deleteModal = () => {
    return (
      showDeleteModal ?
        <Modal
          show={true}
          showModalHeader={false}
          toggle={toggleDeleteModal}
          closeOnBackgroundClick={false}
          primaryButtonLabel='Yes'
          onPrimaryButtonClick={deleteDoc}
          secondaryButtonLabel='No'
          onSecondaryButtonClick={toggleDeleteModal}
          fullPageModal={false}
        >
          <DeleteModal errorDeleteMsg={errorDeleteMsg} />
        </Modal> :
        <></>);
  };

  const showErrorModal = () => {
    return (
      errorMessage ?
        <Modal
          show={true}
          showModalHeader={false}
          toggle={toggleModal}
          closeOnBackgroundClick={true}
          primaryButtonLabel={null}
          secondaryButtonLabel='Close'
          onSecondaryButtonClick={toggleModal}
          fullPageModal={false}
        >
          <ErrorModal />
        </Modal> :
        <></>);
  };

  const hasDocuments = (uploadedDocuments) => uploadedDocuments === [] || uploadedDocuments.error;

  const getTimeDate = (uploadedDoc) =>
    `${getTimeOnly(uploadedDoc.file_uploaded_at)} ${rawToIrishFormat(uploadedDoc.file_uploaded_at)}`;


  if (!uploadedDocuments) {
    return (
      <div>
        <AnimatedSpinner />
      </div>
    );
  }

  return (
    <>
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {getPageTitle[insuranceType]}
        </div>
      </div>
      <DesktopCard>
        <div className={className}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          <IconAndTitle insuranceType={insuranceType} title={pageTitle} />

          <div className={`${className}__contentContainer`}>
            {
              !hasDocuments(uploadedDocuments) ?

                uploadedDocuments.document_details.map((uploadedDoc, index) => {

                  const file = async () => await getUploadedDocument(getAccessToken(cognitoUser), insuranceType, getPolicyNumber(), uploadedDoc.id);
                  return (
                    <div className={`${className}__linksContainer`} key={index}>
                      <div
                        className={`${className}__link`}
                        onClick={() => {
                          file().then(response => {
                            if (response.error) {
                              setErrorMessage(true);
                              return;
                            }
                            else {
                              setErrorMessage(false);
                              openFile(response, uploadedDoc.file_name);
                            }
                          });
                        }} >
                        <div className={`${className}__fileLink`}>{uploadedDoc.file_name}
                          <PdfIcon
                            id="Icon_PDF"
                            className={`${className}__linkIcon`}
                          />
                        </div>
                      </div>

                      <div className={`${className}__date`}>
                        {getTimeDate(uploadedDoc)}
                      </div>

                      <div
                        className={`${className}__closeIcon`}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setDeleteId(uploadedDoc.id);

                        }}
                        id={`file__closeIcon${index}`}
                        data-testid={`file__closeIcon${index}`}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  );
                }
                )
                : <div className={`${className}__contentMessage`}>
                  No Documents to show.
                </div>}

            {showErrorModal()}
            {deleteModal()}

            < div className={`${className}__buttonContainer`}>

              <Link to={`${routes.uploadDocumentsPage.url}${insuranceType}`}>
                <Button fluid data-ga id="PaymentsThankYouPage__uploadDocumentsButton">
                  UPLOAD ANOTHER DOCUMENT
                  </Button>
              </Link>
            </div>
            <div className={`${className}__buttonContainer`}>
              <Link to={routes.Wallet.url}>
                <Button fluid={true}>{backToWallet}</Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </>
  );

};

DocumentsUploadedPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(DocumentsUploadedPage);