"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./MarginWrapper.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MarginWrapper = function MarginWrapper(_ref) {
  var children = _ref.children;
  return _react2.default.createElement("div", {
    className: "l-MarginWrapper"
  }, children);
};

exports.default = MarginWrapper;