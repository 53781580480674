import * as yup from 'yup';

import { PAYMENT_FREQUENCY } from '../values';

export default {
  authorisation: yup
    .boolean()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.boolean().required()
        .oneOf([true], 'You must accept the Terms and Conditions')
    }),
  confirmation: yup
    .boolean()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.boolean().required()
        .oneOf([true], 'You must accept the Terms and Conditions')
    }),
  refundTOC: yup
    .boolean()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.boolean().required()
        .oneOf([true], 'You must accept the Terms and Conditions')
    }),
};
