"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AddIcon = function AddIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    version: "1.1",
    id: "icons",
    xmlns: "http://www.w3.org/2000/svg",
    x: "0px",
    y: "0px",
    viewBox: "0 0 63 63",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("g", null, _react2.default.createElement("g", null, _react2.default.createElement("g", null, _react2.default.createElement("g", null, _react2.default.createElement("path", {
    className: "st0",
    d: "M31.5,63C48.9,63,63,48.9,63,31.5C63,14.1,48.9,0,31.5,0C14.1,0,0,14.1,0,31.5C0,48.9,14.1,63,31.5,63zM31.5,5.98c14.09,0,25.51,11.42,25.51,25.52c0,14.09-11.42,25.51-25.51,25.51S5.98,45.59,5.98,31.5C5.98,17.41,17.41,5.98,31.5,5.98z"
  })))), _react2.default.createElement("polygon", {
    className: "st0",
    points: "27.48,13.41 27.48,27.48 13.41,27.48 13.41,35.52 27.48,35.52 27.48,49.59 35.52,49.59 35.52,35.52 49.59,35.52 49.59,27.48 35.52,27.48 35.52,13.41 \t"
  })));
};

exports.default = AddIcon;