// @flow

import './MobileHeader.scss';

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { getAccessToken, isAgent } from '../../helpers';
import { routes } from '../../routes';
import BrandLogo from '../BrandLogo';
import Navigation from '../Navigation';
import MessagesNavigationItem from './MessagesNavigationItem';

const MobileHeader = () => {
  const className = 'c-MobileHeader';

  const { cognitoUser } = useContext(CognitoContext);
  const isLoggedIn = !!getAccessToken(cognitoUser);

  const getLoggedInNavigationItems = () => {
    return [
      [
        { displayText: 'Wallet', link: routes.Wallet.url },
        { displayText: 'Manage my insurance', link: routes.manageMyInsuranceDashboard.url },
        { displayText: 'Help', link: routes.helpPage.url },
      ],
      [
        { displayText: 'Messages', link: routes.messages.url, Component: MessagesNavigationItem }
      ],
      [{ displayText: 'Logout', link: routes.logoutPage.url }],
    ];
  };


  const getLoggedOutNavigationItems = () => {
    return [
      [
        { displayText: 'Wallet', link: routes.Wallet.url },
        { displayText: 'Manage my insurance', link: routes.loginPage.url },
        { displayText: 'Help', link: routes.helpPage.url },
      ],
      [{ displayText: 'Login', link: routes.loginPage.url }],
    ];
  };

  const navigationItems = () => {
    let items = isLoggedIn ? getLoggedInNavigationItems() : getLoggedOutNavigationItems()
    const myProfileItem = { displayText: 'My Profile', link: routes.myProfile.url }
    if (!isAgent() && isLoggedIn) {
      items[1].push(myProfileItem)
    }
    return items
  };

  return (
    <div className={`${className}`}>
      <div className={`${className}__logo`}>
        <BrandLogo theme="light" />
      </div>
      <div className={`${className}__barIcon`}>
        <Navigation menuItems={navigationItems()} />
      </div>
    </div>
  );
};

export default withRouter(MobileHeader);
