/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { noop } from '@arachas/core/lib/utils';

const checkEntryExists = (errors: { string: boolean; }, baseFieldTouched: { string: boolean; }, entryIndex: number) => {
  return (
    errors !== undefined &&
    errors[entryIndex] &&
    baseFieldTouched !== undefined &&
    baseFieldTouched[entryIndex]
  );
};

export const checkRepeatingFieldError = (errors: { string: boolean; }, baseFieldTouched: { string: boolean; }, fieldName: string, index: number) => {
  if (checkEntryExists(errors, baseFieldTouched, index)) {
    return (
      errors[index][fieldName] !== undefined && baseFieldTouched[index][fieldName]
    );
  }
};

export const getRepeatingFieldErrorMessage = (errors: { string: boolean; }, baseFieldTouched: { string: boolean; }, fieldName: string, index: number) => {
  return checkRepeatingFieldError(errors, baseFieldTouched, fieldName, index) ? errors[index][fieldName] : null;
};

export const getRepeatingFieldChangeHandler = (handleChange: noop, customHandleChange: noop, fieldName: string, identifier: number | string) => {
  if (typeof customHandleChange === 'function') {
    return (e: SyntheticEvent<HTMLInputElement>) => customHandleChange(fieldName, e.currentTarget.value, identifier);
  }
  return handleChange;
};

export const getRepeatingFieldName = (fieldName: string, fieldNamePrefix: ?string) => {
  return fieldNamePrefix ? `${fieldNamePrefix}${fieldName}` : fieldName;
};
