/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import React from 'react';

const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
  isCardError?: boolean;
}

const TravelErrorPage = ({ isCardError = false, firstName = "" }: Props) => {


  const firstNameMessage = () =>
    firstName ? `We're sorry ${firstName}` : 'We\'re sorry';

  const redTextMessage = 'Unfortunately, we are unable to provide you with a quote.';
  const quoteReferenceLine = 'Based on the information provided, the risk you have submitted has fallen outside our acceptance criteria.';
  const cardErrorMessage = 'We have been unable to connect to our payment service. You have not been charged. Please try again.';

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      {
        !isCardError && (<div className={`${className}__warningMessage`}
          id='TravelErrorPage__warningMessage'
          data-testid='TravelErrorPage__warningMessage'>
          {redTextMessage}
        </div>)
      }
      <div className={`${className}__referenceTextContainer`}>
        {isCardError ? cardErrorMessage : quoteReferenceLine}
      </div>
    </div>
  );
};

export default TravelErrorPage;
