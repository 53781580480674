/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonFormStyles.scss';
import './MotorClaims.scss';

import {
  ButtonGroup,
  DatePicker,
  InputField,
  WideDivider,
} from '@arachas/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier, VAN_INSURANCE_TYPE } from '../../constants';
import { dividerColor } from '../../constants/colors';
import { isFieldError } from '../../helpers';
import { decimalNumberMask } from '../../helpers/NumberMask';
import ResourceDropdown from '../ResourceDropdown';

const fieldPrefix = 'motorClaims';
const emptyClaim = {
  claim_type: undefined,
  claim_status: undefined,
  claim_amount: undefined,
  claim_date: undefined,
};

const untouchedEmptyClaim = {
  claim_type: false,
  claim_status: false,
  claim_amount: false,
  claim_date: false,
};

const MotorClaims = (props) => {
  const className = 'c-MotorClaims';
  const { fieldName, errors, onBlur, setFieldValue, setTouched, touched, values, resources, insuranceType } = props;
  const componentFieldName = fieldName;

  useEffect(() => {
    if (values.motorClaims.length > 0) {
      updateTouchedClaims();

      resources.no_of_accident_values.map((claimVal) => {
        if (claimVal.description === values.motorClaims.length.toString()) {

          handleCountChange(claimVal);
        }
      });
    }
  }, []);


  const updateTouchedClaims = () => {
    const touchedArraySize = touched.motorClaims ? touched.motorClaims.length : 0;
    const newTouchedClaims = touched.motorClaims ? [...touched.motorClaims] : [];
    const arraysSizeDiff = values.motorClaims.length - touchedArraySize;
    for (let i = 0; i < arraysSizeDiff; ++i) {
      newTouchedClaims.push({ ...untouchedEmptyClaim });
    }
    setTouched({ ...touched, motorClaims: newTouchedClaims });
  };

  const handleIndexChange = (value, fieldName, index) => {
    if (values.motorClaims && values.motorClaims[index]) {
      const newValues = [...values.motorClaims];
      const claim = { ...values.motorClaims[index] };
      claim[fieldName] = value;
      newValues[index] = claim;
      setFieldValue(componentFieldName, newValues);
    }
  };

  const handleBlurCustom = (fieldName, claimNumber) => {
    const newTouched = touched[componentFieldName] ? [...touched[componentFieldName]] : [untouchedEmptyClaim];
    if (claimNumber < newTouched.length) {
      newTouched[claimNumber][fieldName] = true;
      setTouched({ ...touched, motorClaims: newTouched });
    }
  };

  const checkNewCount = (newCount) => newCount !== undefined && (newCount.description !== " " || newCount.description !== "0");

  const handleCountChange = (newCount) => {
    setFieldValue('claimsCount', newCount);

    if (checkNewCount(newCount)) {
      let i = 0, max = Number(newCount.description);
      const newValues = [];
      const newTouched = [];
      while (i !== max) {
        if (values.motorClaims[i]) {
          newValues.push(values.motorClaims[i]);
          //newTouched.push(touched.motorClaims[i]);
        } else {
          newValues.push(Object.assign({}, emptyClaim));
          newTouched.push(Object.assign({}, emptyClaim));
        }
        ++i;
      }
      setFieldValue('motorClaims', newValues);
    } else setFieldValue('motorClaims', []);
  };

  const checkClaimExists = (claimNumber) => {
    return (
      errors[componentFieldName] !== undefined &&
      errors[componentFieldName][claimNumber] &&
      touched[componentFieldName] !== undefined &&
      touched[componentFieldName][claimNumber]
    );
  };


  const checkFieldError = (fieldName, index) => {
    if (checkClaimExists(index)) {
      return (
        errors[componentFieldName][index][fieldName] !== undefined && touched[componentFieldName][index][fieldName]
      );
    }
  };

  const getErrorMessage = (fieldName, index) => {
    return checkFieldError(fieldName, index) ? errors[componentFieldName][index][fieldName] : null;
  };

  const getClaimValue = (claims, claimId, fieldName) => {
    if (claims.length > claimId && fieldName !== '') {
      return claims[claimId][fieldName];
    }
    return '';
  };

  const checkIfShownLastField = (index) => {
    return index + 1 === values.claimsCount ? `${commonFormStylesIdentifier}__fieldContainerWithNoMargin` : `${commonFormStylesIdentifier}__fieldContainer`;
  };

  const getClaimTitle = (index) => `${insuranceType === VAN_INSURANCE_TYPE ? 'Van' : 'Motor'} Claim ${index + 1}`;

  const checkClaimsCount = values.claimsCount && (values.claimsCount.description === undefined || Number(values.claimsCount.description.toString() === "0"));

  const renderClaim = (motorClaims) => {

    if (checkClaimsCount) {
      setFieldValue('motorClaims', []);
      return <></>;
    }

    const claims = [];
    for (let index = 0; index < Number(values.claimsCount.description.toString()); index++) {
      claims.push(
        <div
          key={'Claim_' + (index + 1)}
          className={`${className}__claimSection`}
        >
          {index > 0 ? <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
            <WideDivider height={1} color={dividerColor} />
          </div> : null}
          <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
            <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>{getClaimTitle(index)}</label>
          </div>

          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={`claim_date[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Date of Claim
            </label>
            <DatePicker
              touched={checkFieldError('claim_date', index)}
              error={getErrorMessage('claim_date', index)}
              key={`claim_date[${index}]`}
              name={`${fieldPrefix}[${index}].claim_date`}
              value={getClaimValue(motorClaims, index, 'claim_date')}
              onChange={(e) =>
                handleIndexChange(e.currentTarget.value, 'claim_date', index)
              }
              placeholder="Type here"
              onBlur={() => handleBlurCustom('claim_date', index)}
            />
          </div>

          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={`claim_type[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Type of claim
            </label>
            <ResourceDropdown
              placeholder="Select here"
              error={checkFieldError('claim_type', index)}
              errorMessage={getErrorMessage('claim_type', index)}
              key={`claim_type[${index}]`}
              name={`claim_type[${index}]`}
              value={getClaimValue(motorClaims, index, 'claim_type')}
              // eslint-disable-next-line flowtype/require-parameter-type
              onChange={(value, name, index) => {
                handleIndexChange(value, 'claim_type', index);
              }
              }
              index={index}
              //onChange={value => {setFieldValue("homeType", value)}}
              onBlur={() => handleBlurCustom('claim_type', index)}
              // eslint-disable-next-line react/prop-types
              values={resources.claim_type}
            />

          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={`claim_status[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Claim status
            </label>
            <ButtonGroup
              error={checkFieldError('claim_status', index)}
              errorMessage={getErrorMessage('claim_status', index)}
              key={`claim_status[${index}]`}
              name={`${componentFieldName}[${index}].claim_status`}
              onClick={(value) => {
                handleIndexChange(value, 'claim_status', index);
                handleBlurCustom('claim_status', index);
              }}
              selected={getClaimValue(motorClaims, index, 'claim_status')}
              options={[
                { label: 'Closed', value: true },
                { label: 'Open', value: false },
              ]}
            >
            </ButtonGroup>
          </div>
          <div className={checkIfShownLastField(index)}>
            <label htmlFor={`claim_amount[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Estimated claim amount
            </label>
            <InputField
              error={checkFieldError('claim_amount', index)}
              placeholder="Enter an amount"
              prefix={faEuroSign}
              errorMessage={getErrorMessage('claim_amount', index)}
              key={`claim_amount[${index}]`}
              name={`${fieldPrefix}[${index}].claim_amount`}
              value={getClaimValue(motorClaims, index, 'claim_amount')}
              onChange={(e) =>
                handleIndexChange(e.currentTarget.value, 'claim_amount', index)
              }
              onBlur={() => handleBlurCustom('claim_amount', index)}
              masked
              mask={decimalNumberMask}
            />
          </div>
        </div>,
      );
    }
    return <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}>
      {claims}
    </div>;
  };

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__counterPadding`}>
        <ResourceDropdown
          placeHolder={'Select Here'}
          values={resources.no_of_accident_values}
          name="claimsCount"
          value={values.claimsCount}
          onChange={(e) => handleCountChange(e)}
          onBlur={(e) => onBlur('claimsCount', e)}
          error={isFieldError('claimsCount', touched, errors)}
          errorMessage={errors.claimsCount}
        >
        </ResourceDropdown>
      </div>
      {values.motorClaims.length > 0 ? renderClaim(values.motorClaims) : <></>}
    </div>
  );
};

MotorClaims.propTypes = {
  fieldName: PropTypes.string,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setTouched: PropTypes.func,
  touched: PropTypes.any,
  values: PropTypes.object,
  onBlur: PropTypes.func,
  resources: PropTypes.object,
  insuranceType: PropTypes.string
};

export default MotorClaims;