/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from "yup";

import specifiedItemsDetails from '../../constants/specifiedItemsDetailsList';
import type { SpecifiedItemDetails } from '../../types/Home';

const specifiedItemValueSchema = yup.string().test(
  'checkIfValueValidForSpecifiedItem',
  'The value you have entered is below the minimum amount for this item',
  function (itemValueAsString: string): boolean {
    const { item } = this.parent;
    const { path, createError } = this;
    const itemValue = itemValueAsString !== '' ? parseInt(itemValueAsString.replace(/,/g, ``), 10) : 0;
    if (item) {
      const specItemDetails = specifiedItemsDetails.find(
        (itemDetails: SpecifiedItemDetails) => (itemDetails.dropdownValue === item.description));
      const checkItemValue = () => specItemDetails && itemValue > specItemDetails.maxRequiredItemValue;
      if (checkItemValue())
        return createError({
          path,
          message: `You cannot specify this item if more than €${specItemDetails.maxRequiredItemValue} in value!`
        });
    }
    return true;
  }
).required('Please enter an item value');

const specifiedItemsSchema = yup.array().when('specifiedItemsSwitch', {
  is: true,
  then: yup.array().of(
    yup.object().shape({
      item: yup.object().nullable().required('Please select an item type'),
      value: specifiedItemValueSchema,
      description: yup.string().required('Please enter an item description')
    })
  ).required('Specified Items required'),
  otherwise: yup.array().notRequired()
});

export const viewQuoteYupSchema = yup.object().shape({
  excess: yup.object().required(),
  unspecifiedItemsSwitch: yup.boolean().required(),
  unspecifiedItems: yup.object().when('unspecifiedItemsSwitch', {
    is: true,
    then: yup.object().nullable().required('Unspecified Items value is required'),
    otherwise: yup.object().nullable()
  }),
  specifiedItemsSwitch: yup.boolean().required(),
  specifiedItems: specifiedItemsSchema,
  promoCode: yup.string().when('hasPromoCode', {
    is: 'true',
    then: yup.string().required('Promo code is required'),
    otherwise: yup.string().notRequired()
  }),
});
