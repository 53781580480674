"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CardWrapper = require("./CardWrapper");

var _CardWrapper2 = _interopRequireDefault(_CardWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _CardWrapper2.default;