/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import './DeviceLookupPage.scss';
import '../../styles/commonPageStyles.scss';

import { Button, Title } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';

import DeviceLookup from '../../components/DeviceLookup';
import { MobileBackButton } from '../../components/MobileBackButton';
import { GET_QUOTE_VALUES_STORAGE } from '../../constants/sessionStorage';
import { commonFormStylesIdentifier, commonPageStylesIdentifier } from '../../constants/styleConstants';
import { saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';


const className = 'c-DeviceLookup';
const titleText = 'Get a Quote';

const insuranceType = 'device';

const DeviceLookupForm = (props) => {
  const {
    history,
    isValid,
    setFieldValue,
    values,
  } = props;

  const handleOnSelect = selectedDevice => {
    if (selectedDevice)
      Object.entries(selectedDevice).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
  };

  const goToGenericQuotePage = () => {
    saveInSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    props.history.push({
      pathname: `${routes.getGenericQuotePage.url}${insuranceType}`,
      state: {
        insuranceType: insuranceType,
      },
    });
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history}/>
      </div>

      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <Title align="left" type="h1" weight="weight500" variant="greyBrown">
                {titleText}
              </Title>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
              {titleText}
            </div>
          </div>

          <Form className={`${className}__form`}>
            <DeviceLookup {...props} handleOnSelect={handleOnSelect}/>

            <div className={`${commonFormStylesIdentifier}--withMarginTop`}>
              <div className={`${className}__button`}>
                <Button
                  id={`${className}__nextButton`}
                  fluid
                  quaternary
                  disabled={!isValid}
                  onClick={() => {
                    goToGenericQuotePage();
                  }}
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const DeviceLookupPage = withFormik({
  mapPropsToValues() {
    return {};
  },
  validationSchema: yup.object().shape({
    'deviceType': yup.string().required('Required field'),
    'deviceMake': yup.string().required('Required field'),
    'deviceModel': yup.string().required('Required field'),
  }),
  displayName: 'DeviceLookupForm',
})(DeviceLookupForm);


DeviceLookupForm.propTypes = {
  history: PropTypes.object,
  setFieldValue: PropTypes.func,
  isValid: PropTypes.bool,
  values: PropTypes.object,
};

export default DeviceLookupPage;
