"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _MapIcon = require("./MapIcon");

var _MapIcon2 = _interopRequireDefault(_MapIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _MapIcon2.default;