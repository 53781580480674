"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SunIcon = function SunIcon(props) {
  var color = props.color,
      solid = props.solid;
  var style = solid ? {
    fill: color,
    enableBackground: 'new 0 0 63 63'
  } : {
    fill: 'none',
    stroke: color,
    strokeWidth: '1.5px'
  };
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 60 59.727",
    style: style
  }, _react2.default.createElement("g", {
    id: "prefix__wb_sunny-24px",
    transform: "translate(-1 -.6)"
  }, _react2.default.createElement("path", {
    id: "prefix__Path_1572",
    d: "M18.909 7.5a10.909 10.909 0 1 0 10.909 10.909A10.941 10.941 0 0 0 18.909 7.5z",
    "data-name": "Path 1572",
    transform: "translate(12.091 11.918)",
    style: {
      opacity: .3
    }
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1573",
    d: "M12.727 15.873l3.818-3.818-4.636-4.637-3.818 3.818zM1 27.6h8.182v5.455H1zM28.273.6h5.455v8.182h-5.455zm21 15.545l-3.818-3.818 4.636-4.909 3.818 3.818zM45.182 48.6l4.909 4.909 3.818-3.818L49 44.782zm7.636-21H61v5.455h-8.182zM31 13.964a16.364 16.364 0 1 0 16.364 16.363A16.412 16.412 0 0 0 31 13.964zm0 27.273a10.909 10.909 0 1 1 10.909-10.91A10.941 10.941 0 0 1 31 41.236zm-2.727 10.908h5.455v8.182h-5.455zM8.091 49.418l3.818 4.091 4.909-4.909L13 44.782z",
    "data-name": "Path 1573"
  })));
};

SunIcon.defaultProps = {
  color: _colors.fontColorSecondary,
  solid: true
};
SunIcon.propTypes = {
  color: _propTypes2.default.string,
  solid: _propTypes2.default.bool
};
exports.default = SunIcon;