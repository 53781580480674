// @flow

import { Checkbox, WideDivider } from "@arachas/core/lib";
import { FormikProps } from 'formik';
import React, {
  useEffect
} from 'react';

import { dividerColor } from '../../constants/colors';
import { marketingPhoneNumber } from '../../constants/phoneNumbers';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';

const className = "c-MarketingConsentForm";
const checkboxLabelFontSize = 16;

const ContactType = (props: FormikProps) => {
  const { values, setFieldValue } = props;

  useEffect(() => {
    if (![values.email, values.post, values.text, values.phone].includes(false)) {
      setFieldValue('selectAll', true);
    }
  }, []);

  return (
    <div>
      <h4 className={`${className}__consentSubtitle`}>How should we get in touch?</h4>
      <div className={`${className}__checkboxContainer`}>
        <Checkbox
          id="selectAll"
          name="selectAll"
          label="Select All"
          labelFontSize={checkboxLabelFontSize}
          isChecked={(values.email && values.post && values.text && values.phone)}
          onChange={() => {
            ['email', 'post', 'text', 'phone', 'selectAll'].forEach((field: string) => setFieldValue(field, !values.selectAll));
          }}
          value={values.selectAll}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
        <WideDivider height={1} color={dividerColor} />
      </div>

      <div>
        <div className={`${className}__checkboxContainer`}>
          <Checkbox
            id="contactTypeEmail"
            label="Email"
            labelFontSize={checkboxLabelFontSize}
            name="email"
            isChecked={values.email}
            onChange={() => {
              setFieldValue('email', !values.email);
            }}
            value={values.email}
          />
        </div>
        <div className={`${className}__checkboxContainer`}>
          <Checkbox
            id="contactTypeText"
            label="Text/Digital"
            labelFontSize={checkboxLabelFontSize}
            name="text"
            isChecked={values.text}
            onChange={() => {
              setFieldValue('text', !values.text);
            }}
            value={values.text}
          />
        </div>
        <div className={`${className}__checkboxContainer`}>
          <Checkbox
            id="contactTypePhone"
            label="Phone"
            labelFontSize={checkboxLabelFontSize}
            name="phone"
            isChecked={values.phone}
            onChange={() => {
              setFieldValue('phone', !values.phone);
            }}
            value={values.phone}
          />
        </div>
        <div className={`${className}__checkboxContainer`}>
          <Checkbox
            id="contactTypePost"
            label="Post"
            labelFontSize={checkboxLabelFontSize}
            name="post"
            isChecked={values.post}
            onChange={() => {
              setFieldValue('post', !values.post);
            }}
            value={values.post}
          />
        </div>
      </div>

      <div className={`${className}__contactTypeWarning`}>
        This will update any previous marketing preferences you have given. You can change your mind at any
        time
        by
        calling us on <a href={`tel:${marketingPhoneNumber}`}>{marketingPhoneNumber}</a>.
      </div>
    </div>
  );
};

export default ContactType;

