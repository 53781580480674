/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { yourCarCoverMainDriverPrefix } from '../../components/Forms/YourCarCoverForm/YourCarCoverForm';
import {
  MYSELF_AND_ADDITIONAL_DRIVERS,
  MYSELF_AND_SPOUSE,
} from '../../constants/motor';
import { irishToRawFormat } from '../../helpers/DateHelper';
import type {
  AboutYouFormType,
  MotorViewQuoteTypes,
  YourCarCoverFormTypes,
  YourCarFormTypes,
} from '../../types';
import { formatClaims, getPenaltyDetails, getStartDate } from '../mappingHelper';



const getRegistration = (
  values: AboutYouFormType &
    YourCarFormTypes &
    YourCarCoverFormTypes &
    MotorViewQuoteTypes
) => (values.isManualSearch ? undefined : values.carReg);

const isThereAdditonalDrivers = (values: any) =>
  values.whoIsDrivingYourCar.alias === MYSELF_AND_SPOUSE ||
  values.whoIsDrivingYourCar.alias === MYSELF_AND_ADDITIONAL_DRIVERS;

const mapAdditionalDrivers = (
  additionalDrivers: Array<any> = []
): Array<{ [string]: number | string | boolean | any; }> => {
  // This driver order starts at 2 because the main driver is given the value 1
  //$FlowFixMe
  return additionalDrivers.map((driver: any) => {
    return {
      title: driver.title,
      first_name: driver.firstName,
      last_name: driver.lastName,
      date_of_birth: irishToRawFormat(driver.dob),
      employment_status: driver.employmentStatus,
      occupation: driver.mainOccupation === "" ? null : driver.mainOccupation,
      licence: {
        type: driver.driversLicenseType,
        years: driver.yearsHeld
      },
      relationship_to_proposer: driver.relationshipToProposer,
      penalty_details: getPenaltyDetails(
        driver.additionalDriverPenaltyPointOffences
      ),
      full_time_use_of_other_car: driver.useOfOtherCar
    };
  });
};

export const mapMotorQuote = (
  values: AboutYouFormType &
    YourCarFormTypes &
    YourCarCoverFormTypes &
    MotorViewQuoteTypes
) => {
  return {
    motor_quote: {
      carrier_id: 'Arachas',
      quote_mode: 'WEB',
      promotion_code: values.promoCode,
      main_driver: {
        first_name: values.firstName,
        last_name: values.lastName,
        title: values.title,
        email: values.email,
        date_of_birth: irishToRawFormat(values.dob),
        mobile_phone: values.phoneNo,
        employment_status: values.employmentStatus,
        occupation: values.mainOccupation === "" ? null : values.mainOccupation,
        licence: {
          type: values.driversLicenseType,
          years: values.yearsHeld,
        },
        experience: {
          country: values.ncbCountry,
        },
        primary_address: {
          address_line1: values.addressLine1,
          address_line2: values.addressLine2,
          address_line3: values.addressLine3,
          town: values.addressTown,
          county: values.addressCounty,
          eircode: values.addressEircode,
          geo_code: values.geo_code,
          match_level: values.match_level,
          match_type: values.match_type,
          provided_by: values.provided_by,
          small_area_code: values.small_area_code,
          small_area_identifier: values.small_area_identifier,
          ecad_identifier: values.ecad_identifier,
          ecad_match_level: values.ecad_match_level,
          ecad_match_result: values.ecad_match_result,
        },
        ncb: {
          type: values.drivingExpType,
          country: values.ncbCountry,
          years: values.noClaimsBonus,
        },
        claims: formatClaims(values.motorClaims),
        penalty_details: getPenaltyDetails(
          values[`${yourCarCoverMainDriverPrefix}PenaltyPointOffences`]
        ),
        convictions: values.mainDriverDisqualifiedOrConvicted,
        data_protection_consent: values.acceptedTermsAndConditions,
        assumption_confirmation: values.disclosureRequirements,
      },
      cover_details: {
        start_date: getStartDate(values.policyStart),
        type: values.coverType,
        who_driving: values.whoIsDrivingYourCar,
        vehicle_details: {
          model: values.carModel,
          engine_cc: values.carEngineSize,
          doors: values.doors,
          body_type: values.body_type,
          fuel_type: values.carFuelType,
          year_manufacture: values.carYear,
          version: values.carVersion,
          make: values.carMake,
          transmission: null,
          colour: null,
          number_seats: values.number_seats,
          gross_vehicle_weight: null,
          value: values.vehicleValue
            ? values.vehicleValue.replace(/,/gi, '')
            : 0,
          imported: false,
          use: values.carUsedForWork,
          years_owned_vehicle: getStartDate(values.carPurchased),
          registration: getRegistration(values),
          km_per_year: {
            //backend to update to be removed from frontend
            id: 82,
            alias: '17500',
            description: '15001 - 20000',
          },
        },
      },
      additional_drivers: isThereAdditonalDrivers(values)
        ? mapAdditionalDrivers(values.additionalDrivers)
        : [],
      marketing: {
        mailing_consent: values.postConsent,
        email_consent: values.emailConsent,
        mobile_sms_consent: values.smsConsent,
        telephone_consent: values.phoneConsent,
      }
    },
  };
};
