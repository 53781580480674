"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PersonChildrenIcon = function PersonChildrenIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 90 50",
    width: "90",
    height: "50",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("g", {
    id: "supervisor_account-24px"
  }, _react2.default.createElement("ellipse", {
    id: "Ellipse_20",
    cx: "5.2",
    cy: "5.4",
    style: {
      opacity: .3
    },
    rx: "5.2",
    ry: "5.4",
    transform: "translate(18.685 7.1)"
  }), _react2.default.createElement("path", {
    id: "Path_1573",
    d: "M11.7 47.9h15a12.412 12.412 0 0 1 1.1-3.6h-1.1a35.945 35.945 0 0 0-15 3.6z",
    style: {
      opacity: .3
    },
    transform: "translate(-2 -5.1)"
  }), _react2.default.createElement("path", {
    id: "Path_1574",
    d: "M25.842 29.95a12.351 12.351 0 0 0 12.215-12.475A12.351 12.351 0 0 0 25.842 5a12.351 12.351 0 0 0-12.214 12.475A12.288 12.288 0 0 0 25.842 29.95zm0-17.764a5.393 5.393 0 0 1 0 10.778 5.393 5.393 0 0 1 0-10.778zm.391 35.629H11.576a35.948 35.948 0 0 1 14.364-3.593h1.075a16.364 16.364 0 0 1 5.472-6.387 46.958 46.958 0 0 0-6.547-.7C18.123 37.136 2 41.427 2 49.611V55h23.94v-5.389a2.933 2.933 0 0 1 .293-1.797zm25.308-8.882c-6.156 0-18.761 3.593-18.761 10.679V55H70.4v-5.389c-.1-7.186-12.7-10.679-18.859-10.679zm4.1-6.487a8.376 8.376 0 0 0 4.4-7.884 8.553 8.553 0 1 0-17.1-.3v.4a8.933 8.933 0 0 0 4.495 7.884 8.43 8.43 0 0 0 4.1 1.1 8.856 8.856 0 0 0 4.1-1.2z",
    transform: "translate(-2 -5)"
  })), _react2.default.createElement("g", {
    id: "supervisor_account-24px_1_",
    transform: "translate(51.5 10.598)"
  }, _react2.default.createElement("path", {
    id: "Path_1574_1_",
    d: "M74.3 39c-6.3 0-19.2 3.6-19.2 10.7v5.4h38.5v-5.4c0-7.2-13-10.7-19.3-10.7zm4.2-6.5a8.359 8.359 0 0 0 4.5-7.9 8.751 8.751 0 1 0-17.5-.3v.4a9.028 9.028 0 0 0 4.5 7.9 8.769 8.769 0 0 0 4.2 1.1 8.94 8.94 0 0 0 4.3-1.2z",
    transform: "translate(-55.1 -15.698)"
  })));
};

exports.default = PersonChildrenIcon;