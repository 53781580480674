/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { makeRequest } from '../../helpers';

export const getTravelResources = async (props = {}) => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/travel/quote_parameters`,
    history: props.history
  });
};
