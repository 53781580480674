/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './PaymentsThankYouPage.scss';

import { Button, DesktopCard, Title } from '@arachas/core/lib';
import React, { useCallback,useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Link, Redirect } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import PaymentThankYouLoggedInContent from '../../components/PaymentThankYouLoggedInContent';
import { SINGLE_POLICY_STORAGE } from '../../constants';
import { backToWallet } from '../../constants/buttonLabelConstants';
import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { insurerNames } from '../../constants/insurers';
import { TRIP_TYPE_CODES } from '../../constants/travel/TravelConstants';
import {
  getPayloadObjFromSessionStorage,
  getSinglePayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage,
  removeCarJourneyStorage,
  removeDeviceJourneyStorage,
  removeHomeJourneyStorage,
  removeTravelJourneyStorage,
  removeVanJourneyStorage,
  saveObjectSessionStorage} from '../../helpers';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import UserContext from '../../UserContext';


type SuccessProps = {
  bindDetails: Object,
  insuranceType: string;
};

const className = 'c-PaymentsThankYouPage';

const clearStorageFunctions = {
  [HOME_INSURANCE_TYPE]: removeHomeJourneyStorage,
  [CAR_INSURANCE_TYPE]: removeCarJourneyStorage,
  [TRAVEL_INSURANCE_TYPE]: removeTravelJourneyStorage,
  [VAN_INSURANCE_TYPE]: removeVanJourneyStorage,
  [DEVICE_INSURANCE_TYPE]: removeDeviceJourneyStorage
};

const Success = ({ bindDetails, insuranceType }: SuccessProps) => {
  const { getUserName } = useContext(UserContext);
  const { cognitoUser } = useContext(CognitoContext);
  const [showViewDocuments, setShowViewDocuments] = useState(false);

  const [schemeNumber, setSchemeNumber] = useState(0);

  const callFbEvent = useCallback((price: any) => {
    const fbTracking = {
      value: price || 0,
      currency: 'EUR'
    }
    fbqTrackEvent('Purchase', fbTracking);
  }, [])
  
  // eslint-disable-next-line complexity
  const getCoverPremium = () => {
    let price, payload
    switch (insuranceType) {
      case HOME_INSURANCE_TYPE:
        payload = getSinglePayloadObjFromSessionStorage(insuranceType)
        price =  payload?.cover_premium || 0;
        break;
      case CAR_INSURANCE_TYPE:
        payload = getSinglePayloadObjFromSessionStorage(insuranceType)
        price = payload?.premium?.total_amount || 0;
        break;
      case DEVICE_INSURANCE_TYPE:
        payload = getPayloadObjFromSessionStorage(insuranceType)
        price  = payload?.annual_total || 0;
        break;
      case TRAVEL_INSURANCE_TYPE:
          payload = getPayloadObjFromSessionStorage(insuranceType)
          // eslint-disable-next-line no-case-declarations
          const values = getValuesObjFromSessionStorage(TRAVEL_INSURANCE_TYPE);
          // eslint-disable-next-line no-case-declarations
          const quote = payload.response.quotes.find((quote: any) => quote.name === TRIP_TYPE_CODES[values.tripType]);
          price = quote && quote.breakdown && quote.breakdown.total_payable || 0;
        break;
      case VAN_INSURANCE_TYPE:
        payload = getPayloadObjFromSessionStorage(insuranceType)
        price  = payload.premium && payload.premium.single_payment;
        break;
    }
    return price && payload ? price : 0
  }

  useEffect(() => {
    const payload = getSinglePayloadObjFromSessionStorage(CAR_INSURANCE_TYPE);
    setSchemeNumber(payload.scheme);
    const cover_premium_value = getCoverPremium()
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnThankYouPage({
        insuranceType,
        quote_reference: bindDetails.reference_number,
        cover_premium: cover_premium_value
      })
    })
    setTimeout(() => clearStorageFunctions[insuranceType](), 1000);
    callFbEvent(cover_premium_value)
  }, []);

  const loggedInContent = {
    [HOME_INSURANCE_TYPE]: <PaymentThankYouLoggedInContent insuranceType={insuranceType} />,
    [CAR_INSURANCE_TYPE]: <PaymentThankYouLoggedInContent insuranceType={insuranceType} />,
    [TRAVEL_INSURANCE_TYPE]: '',
    [DEVICE_INSURANCE_TYPE]: '',
    [VAN_INSURANCE_TYPE]: ''
  };

  const getTitleText = () => {
    const name = getUserName();
    return name ? `Thanks ${name},` : 'Thanks!';
  };

  const isLoggedIn = !!cognitoUser;
  //survey removed for the moment date 17th October 2019
  // const showFeedbackLink = insuranceType !== 'travel';

  const referenceNumberText =
    insuranceType === CAR_INSURANCE_TYPE || insuranceType === VAN_INSURANCE_TYPE
      ? `Your policy reference number is ${bindDetails.reference_number}. `
      + `Your ${insuranceType} certificate and disc will be sent to you by post and your 
       policy schedule will be emailed. You can view your welcome pack now in your Documents.`

      : `Your policy reference number is ${bindDetails.reference_number}. `
      + `You can view your welcome pack now in your Documents.`;

  const goToViewDocumentsPage = () => {
    setShowViewDocuments(true);
  };

  const getInsurerName = () => {
    if (insuranceType === HOME_INSURANCE_TYPE || insuranceType === CAR_INSURANCE_TYPE)
      return insurerNames[insuranceType][schemeNumber];
    else {
      return null;
    }
  };


  const registrationPropmpt = `To access your policy documents you will need \
  to ensure that you register your account, please select the 'register now' \
  option below, alternatively we have emailed you the relevant link. `;

  const renderText = () =>
    <div className={`${className}__contentText`}>
      {isLoggedIn ? loggedInContent[insuranceType] : registrationPropmpt}
    </div>;

  const renderRegisterButton = () =>
    !isLoggedIn ? (
      <Link to={`${routes.signup.url}`}>
        <Button fluid data-ga id="PaymentsThankYouPage__registerNowButton">
          REGISTER NOW
        </Button>
      </Link>
    ) : <></>;

  //survey removed for the moment date 17th October 2019
  // const renderFeedbackButton = () =>
  //   isLoggedIn && showFeedbackLink ? (
  //     <div className={`${className}__buttonSpacing`}>
  //       <Link to={`${routes.surveyPage.url}${insuranceType}`}>
  //         <Button
  //           quaternary*
  //           fluid
  //           data-ga
  //           id="PaymentsThankYouPage__giveFeedbackButton"
  //         >
  //           Give Feedback
  //         </Button>
  //       </Link>
  //     </div>
  //   ) : <></>;

  const renderDocumentsButton = () =>
    isLoggedIn ? (
      <div className={`${className}__buttonSpacing`}>
        <Button
          fluid
          data-ga
          id="PaymentsThankYouPage__viewDocumentsButton"
          onClick={goToViewDocumentsPage}
        >
          VIEW DOCUMENTS
        </Button>
      </div>
    ) : <></>;

  const renderDefaultBody = () => (
    <div>
      <DesktopCard>
        <div className={className}>
          <div className={`${className}__thankYouPageTextContainer`}>
            <div className={`${className}__primaryTextContainerOne`}>
              <Title type="h1" weight="500">
                <span id="PaymentsThankYouPage__thankYouText">{getTitleText()}</span>
              </Title>
            </div>
            <div className={`${className}__primaryTextContainerTwo`}>
              <Title type="h1" weight="500">
                <span id="PaymentsThankYouPage__youAreCoveredText">You&#39;re covered.</span>
              </Title>
            </div>
            {renderText()}
            <div className={`${className}__contentText`}>{referenceNumberText}</div>
          </div>
          <div className={`${className}__buttonContainer`}>
            {renderRegisterButton()}
            {/*
            survey removed for the moment date 17th October 2019
            {renderFeedbackButton()} 
            */}
            {renderDocumentsButton()}
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.Wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToWallet}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </div>
  );


  if (showViewDocuments) {
    return <Redirect push to={{
      pathname: `${routes.policyLinks.url}${insuranceType}`,
      state: {
        insurerName: getInsurerName()
      }
    }} />;
  }


  const policyDetails = {
    "end_date": "",
    "insurer": bindDetails.insurer,
    "reference_number": bindDetails.reference_number,
    "start_date": bindDetails.start_date,
  };

  saveObjectSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`, policyDetails);

  return renderDefaultBody();
};

export default Success;
