/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './YourDeviceCoverForm.scss';
import '../../../styles/commonFormStyles.scss';

import { Checkbox, DatePicker, Text } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React from 'react';

import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { MarketingConsent } from '../../MarketingConsent';

const className = 'c-YourDeviceCoverForm';

const checkboxLabelFontSize = 16;

const YourDeviceCoverForm = ({ handleBlur, values, errors, touched, setFieldValue }) => {
  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="policyStart" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Policy start date
        </label>
        <DatePicker
          error={errors.policyStart}
          name="policyStart"
          onBlur={handleBlur}
          onChange={(e) => setFieldValue('policyStart', e.currentTarget.value)}
          placeholder="Type here"
          touched={touched.policyStart}
          value={values.policyStart}
        />
      </div>


      <div
        className={`${commonFormStylesIdentifier}__termsAndConditionsContainer`}
      >
        <Text className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Accepting T&Cs:
        </Text>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedAssumptions"
            name="acceptedAssumptions"
            label={
              <>
                Your quote and cover is based on the
                <a
                  href='//www.arachas.ie/arachas-zurich-device-policy-document'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Policy Document
                </a>.
                Please tick to confirm you have read and accepted these.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedAssumptions}
            onChange={() => setFieldValue('acceptedAssumptions', !values.acceptedAssumptions)}
            value={values.acceptedAssumptions}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            label={
              <>
                Please tick to confirm you have read and accepted our
                <a
                  href='https://www.arachas.ie/tob'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Terms of Business
                </a>,
                <a href='//www.arachas.ie/terms-of-use.pdf'
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Use,
                </a>
                &nbsp;
                <a
                  href='//www.arachas.ie/media/ga3n3tzk/duty-of-disclosure.pdf'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Duty of Disclosure
                </a>,&nbsp;
                <a
                  href='//www.arachas.ie/compliance/privacy-policy/'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Data Privacy
                </a>,&nbsp;
                <a
                  href='https://www.arachas.ie/schedule-of-fees.pdf'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Schedule of Fees
                </a>&nbsp;
                and
                <a
                  href='//www.arachas.ie/compliance/remuneration/'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Remuneration documents
                </a>&nbsp;
                before proceeding.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedTermsAndConditions}
            onChange={() => setFieldValue('acceptedTermsAndConditions', !values.acceptedTermsAndConditions)}
            value={values.acceptedTermsAndConditions}
          />
        </div>
        <MarketingConsent values={values} checkboxLabelFontSize={checkboxLabelFontSize} setFieldValue={setFieldValue} />

      </div>
    </div>
  );
};

YourDeviceCoverForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default YourDeviceCoverForm;
