/* eslint-disable complexity */

import { isHoliday, isLandlord, isLiving } from '../../constants/home';
import { isAllianzHomeInsurance, isAvivaHomeInsurance,isAxaHomeInsurance, isZurichHomeInsurance } from '../../constants/insurers/homeInsurerIDs';

export const getQuoteFeatures = (coverType, schemeId) => {
  if (isAllianzHomeInsurance(schemeId)) {
    if (isLiving(coverType)) return allianzLiving;
    if (isHoliday(coverType)) return allianzHoliday;
    if (isLandlord(coverType)) return allianzLandlord;
  }
  if (isZurichHomeInsurance(schemeId)) {
    if (isLiving(coverType)) return zurichLiving;
    if (isHoliday(coverType)) return zurichHoliday;
    if (isLandlord(coverType)) return zurichLandlord;
  }
  if (isAxaHomeInsurance(schemeId)) {
    if (isLiving(coverType)) return axaLiving;
    if (isHoliday(coverType)) return axaHoliday;
    if (isLandlord(coverType)) return axaLandlord;
  }
  if (isAvivaHomeInsurance(schemeId)) {
    if (isLiving(coverType)) return avivaLiving;
    if (isLandlord(coverType)) return avivaLandlord;
  }
};

const allianzLiving = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Automatic 10% increase in contents cover for Christmas period', tick: true },
  { label: () => '10% increase in contents cover when notified of a wedding', tick: true },
  { label: () => 'Alternative Accommodation up to 15% of combined Building & Contents Sums Insured', tick: true },
  { label: () => 'Contents covered in the open e.g., garden furniture up to €1000', tick: true }
];

const allianzHoliday = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true }
];

const allianzLandlord = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true }
];

const zurichLiving = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included as standard', tick: true },
  { label: () => '€1500 unspecified items all risk cover included as standard', tick: true },
  { label: () => 'Cover for Business Equipment up to €4000 automatically included', tick: true },
  { label: () => 'Automatic 10% increase in contents cover for Christmas period', tick: true },
  { label: () => '10% increase in contents cover when notified of a wedding', tick: true },
  { label: () => 'Public Liability Cover to provide childcare for up to 2 children', tick: true },
  { label: () => 'Alternative Accommodation up to 20% of Building Sums Insured', tick: true }
];

const zurichHoliday = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true }
];

const zurichLandlord = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true }
];

const axaLiving = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Automatic 10% increase in contents cover for Christmas period', tick: true },
  { label: () => '10% increase in contents cover when notified of a wedding', tick: true },
  { label: () => 'Alternative Accommodation up to 20% of Building Sums Insured', tick: true },
  { label: () => 'Contents covered in the open e.g. garden furniture up to €750', tick: true },
];

const axaLandlord = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true }
];

const axaHoliday = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true }
];

const avivaLiving = [
  { label: () => '24 Hour Emergency Helpline with up to €250 cover per incident', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Automatic 10% increase in contents cover for Christmas period', tick: true },
  { label: () => '10% increase in contents cover when notified of a wedding', tick: true },
  { label: () => 'Alternative Accommodation up to 15% of combined Building & Contents Sums Insured', tick: true },
  { label: () => 'Jury Service - €40 per day up to a max of €650 with no loss of NCD', tick: true },
];

const avivaLandlord = [
  { label: () => '24 Hour Emergency Helpline', tick: true },
  { label: () => 'Accidental Damage on building and contents included', tick: true },
  { label: () => 'Liability cover for up to €3m for third party & domestic employee liability', tick: true },
  { label: () => 'Efficient Claims Process and settlement directly with approved repairers', tick: true },
]