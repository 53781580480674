"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AnimatedSuccessIcon = require("./AnimatedSuccessIcon2");

var _AnimatedSuccessIcon2 = _interopRequireDefault(_AnimatedSuccessIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _AnimatedSuccessIcon2.default;