/* eslint-disable complexity */
import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../constants';
import { getLatestUnpurchasedDeviceQuote, getRetrieveLatestUnpurchasedDeviceQuote } from '../services/device/latestDeviceQuoteService';
import { getLatestUnpurchasedHomeQuote, getRetrieveLatestUnpurchasedHomeQuote } from '../services/latestHomeQuoteService';
import { getLatestUnpurchasedMotorQuote, getRetrieveLatestUnpurchasedMotorQuote } from '../services/motor/latestMotorQuoteService';
import { getLatestUnpurchasedTravelQuote, getRetrieveLatestUnpurchasedTravelQuote } from '../services/travel/latestTravelQuoteService';
import { getLatestUnpurchasedVanQuote, getRetrieveLatestUnpurchasedVanQuote } from '../services/van/latestVanQuoteService';


// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const getLatestQuoteFunctionForInsuranceType = (insuranceType: string) => {
  switch (insuranceType) {
    case HOME_INSURANCE_TYPE:
      return getLatestUnpurchasedHomeQuote;
    case CAR_INSURANCE_TYPE:
      return getLatestUnpurchasedMotorQuote;
    case TRAVEL_INSURANCE_TYPE:
      return getLatestUnpurchasedTravelQuote;
    case DEVICE_INSURANCE_TYPE:
      return getLatestUnpurchasedDeviceQuote;
    case VAN_INSURANCE_TYPE:
      return getLatestUnpurchasedVanQuote;
  }
};

export const getRetreiveQuoteFunctionForInsuranceType = (
    // eslint-disable-next-line flowtype/no-types-missing-file-annotation
    insuranceType: string
  ) => {
    switch (insuranceType) {
      case HOME_INSURANCE_TYPE:
        return getRetrieveLatestUnpurchasedHomeQuote;
      case CAR_INSURANCE_TYPE:
        return getRetrieveLatestUnpurchasedMotorQuote;
      case TRAVEL_INSURANCE_TYPE:
        return getRetrieveLatestUnpurchasedTravelQuote;
      case DEVICE_INSURANCE_TYPE:
        return getRetrieveLatestUnpurchasedDeviceQuote;
      case VAN_INSURANCE_TYPE:
        return getRetrieveLatestUnpurchasedVanQuote;
    }
  };
