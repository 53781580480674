import { getItemFromSessionStorage, makeRequest } from '../../helpers';
import { rawToIrishFormat } from '../../helpers/DateHelper';

const defaultProfileResponse = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
  phone: '',
  address: {},
  policies: [],
  isDefaultResponse: true,
};

const getDob = (date_of_birth) => {
  if (date_of_birth) {
    return rawToIrishFormat(date_of_birth, 'YYYY-MM-DD')
  } 
  return date_of_birth;
}

const formatProfileResponse = profile => {
  return {
    ...profile,
    date_of_birth: getDob(profile.date_of_birth)
  };
};

export const getUserProfile = async cognitoAccessTokenKey => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const url = `${basePath}/profile/profile`;
  return await makeRequest({
    method: 'GET',
    url: url,
    authToken: authToken,
  }).then(
    response =>
      new Promise(resolve =>
        resolve(
          response.error
            ? defaultProfileResponse
            : formatProfileResponse(response)
        )
      ),
    () => new Promise(resolve => resolve(defaultProfileResponse))
  );
};
