/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * 
 */

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { CognitoContext } from '../../CognitoUtils';
import { DEVICE_INSURANCE_TYPE, GET_QUOTE_RESPONSE_STORAGE, HOME_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants';
import { offWhite, whiteColor } from '../../constants/colors';
import { ASSUMPTIONS } from '../../constants/sessionStorage';
import { getObjectFromSessionStorage } from '../../helpers';
import { getAccessToken } from '../../helpers';
import {
  getSinglePayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { sendAssumptionsDetails } from '../../services/common/assumptionsService';
import { GenericErrorPage } from '../GenericErrorPage';
import Loading from '../Loading/Loading';


const REQUEST_LOADING = 'PaymentThankYou/LOADING';
const REQUEST_FAILURE = 'PaymentThankYou/FAILURE';


const AssumptionsThankYouPage = (props) => {

  const spinnerBackgroundColor = isMobile ? offWhite : whiteColor;
  const [requestStatus, setRequestStatus] = useState(REQUEST_LOADING);
  const { cognitoUser } = useContext(CognitoContext);

  const insuranceType = props.match.params.type || '';

  // eslint-disable-next-line complexity
  useEffect(() => {
    const policyDetails = getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`);
    const assumptionsValues = getObjectFromSessionStorage(`${insuranceType}${ASSUMPTIONS}`);
    let referenceNumber = (insuranceType === VAN_INSURANCE_TYPE || insuranceType === DEVICE_INSURANCE_TYPE) ? policyDetails.quote_reference : policyDetails[0].quote_reference;

    sendAssumptions(referenceNumber, assumptionsValues);
  }, []);

  const sendAssumptions = async (referenceNumber, assumptionsValues) => {

    const payload = (insuranceType) => {
      if (insuranceType === DEVICE_INSURANCE_TYPE) {
        return {
          "quote_reference": referenceNumber,
          ...assumptionsValues
        };
      } else if (insuranceType === HOME_INSURANCE_TYPE) {
        const values = getValuesObjFromSessionStorage(HOME_INSURANCE_TYPE);
        const coverType = values.coverType;

        const payload = getSinglePayloadObjFromSessionStorage(HOME_INSURANCE_TYPE);
        const insurerName = payload.insurer;
        const assumptionsName = `${insurerName.toLowerCase()}_assumptions`;
        return {
          "quote_reference": referenceNumber,
          "assumptions": {
            [assumptionsName]:
              { ...assumptionsValues }
          },
          "carrier_id": insurerName.toUpperCase(),
          "dwelling_type": coverType,
        };
      } else {
        return {
          "quote_reference": referenceNumber,
          "assumptions": { ...assumptionsValues }
        };
      }
    };

    const result = await sendAssumptionsDetails(payload(insuranceType), getAccessToken(cognitoUser), insuranceType);

    if (result.error) {
      setRequestStatus(REQUEST_FAILURE);
    }
    if (result.proceed) {
      redirectToPaymentsPage();
    }

    setRequestStatus(REQUEST_FAILURE);

  };

  const redirectToPaymentsPage = () => {
    props.history.push({ pathname: `${routes.MakePayment.url}${insuranceType}` });
  };


  const getLoadingComponent = () =>
    <Loading
      spinnerBackgroundColor={spinnerBackgroundColor} />;

  const getErrorComponent = () => <GenericErrorPage errorType={insuranceType} />;

  const paths = {
    [REQUEST_LOADING]: getLoadingComponent,
    [REQUEST_FAILURE]: getErrorComponent
  };

  const Result = paths[requestStatus];

  return <Result />;
};

AssumptionsThankYouPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default AssumptionsThankYouPage;
