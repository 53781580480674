/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { AccordionText, InputField } from '@arachas/core/lib';
import React, { useContext } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { CAR_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import {
  InsuranceTypeContext,
} from '../../../../../pages/GetGenericQuotePage';
import { Address } from './AddressInterface';

interface Props {
  fieldNamePrefix: string;
}

const AddressInfo = (props: Address & Props) => {
  const {
    fieldNamePrefix,
    addressLine1,
    addressLine2,
    addressLine3,
    town,
    county,
    eircode,
  } = props;

  const insuranceType = useContext(InsuranceTypeContext);

  const renderAdressLines = () => {
    return <> <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
      <label
        htmlFor={`${fieldNamePrefix}Line2`}
        className={`${commonFormStylesIdentifier}__fieldLabel`}
      >
        Address line 2
      </label>
      <InputField
        name={`${fieldNamePrefix}Line2`}
        value={addressLine2}
        readOnly={true}
      />
    </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor={`${fieldNamePrefix}Line3`}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Address line 3
      </label>
        <InputField
          name={`${fieldNamePrefix}Line3`}
          value={addressLine3}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor={`${fieldNamePrefix}Town`}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Town
      </label>
        <InputField
          name={`${fieldNamePrefix}Town`}
          value={town}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor={`${fieldNamePrefix}County`}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          County
      </label>
        <InputField
          name={`${fieldNamePrefix}County`}
          value={county}
          readOnly={true}
        />
      </div>
    </>;
  };

  return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor={`${fieldNamePrefix}Eircode`}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Eircode
       </label>
        <InputField
          name={`${fieldNamePrefix}Eircode`}
          readOnly={true}
          value={eircode}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor={`${fieldNamePrefix}Line1`}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Address line 1
        </label>
        <InputField
          name={`${fieldNamePrefix}Line1`}
          value={addressLine1}
          readOnly={true}
        />
      </div>
      {insuranceType === CAR_INSURANCE_TYPE ?
        <AccordionText
          label="SEE MORE"
          openLabel="SEE LESS"
          inverted
          customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}>
          {renderAdressLines()}</AccordionText> : <>{renderAdressLines()} </>}
    </div>
  );
};

export default AddressInfo;
