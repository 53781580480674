/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonFormStyles.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier } from '../../constants';
import { getAccessToken, getItemFromSessionStorage, isAgent, makeRequest } from '../../helpers';
import { checkRepeatingFieldError, getRepeatingFieldErrorMessage } from '../../helpers';
import ResourceDropdown from '../ResourceDropdown';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
const LicenceTypeLicenceCountryAdditionalDrivers = (props) => {
  const className = 'c-LicenceTypeLicenceCountryAdditionalDrivers';
  const {
    errors, handleBlur, setFieldValue, touched, values
  } = props;
  const { resources, index } = props;
  const { cognitoUser } = useContext(CognitoContext);
  const [licenceCountryResources, setLicenceCountryResources] = useState([]);
  const [hideSubfield, setHideSubfield] = useState(false);

  const handleChangeResource = (value, name) => {
    setFieldValue(name, value || {});
    if (name === 'driversLicenseType') {
      setFieldValue('licenceCountry', {});
    }
  };

  useEffect(() => {
    const id = values.additionalDrivers[index].driversLicenseType && values.additionalDrivers[index].driversLicenseType.id;
    if (id) {
      let authToken = undefined;
      if (isAgent) {
        authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      }
      setHideSubfield(true);
      makeRequest({
        url: `${basePath}/van/resources/licence_country/${id}`,
        authToken: authToken
      }).then(response => {
        setLicenceCountryResources(response);
        if (response.length === 1) {
          setFieldValue(`additionalDrivers[${index}].licenceCountry`, response[0]);
        } else if (response.length === 0) {
          setFieldValue(`additionalDrivers[index].licenceCountry`, undefined);
        }
        setHideSubfield(false);
      });
    } else {
      setLicenceCountryResources([]);
      setFieldValue(`additionalDrivers[index].licenceCountry`, undefined);
    }
  }, [values.additionalDrivers[index].driversLicenseType]);

  const fieldNameLicense = `additionalDrivers[${index}].driversLicenseType`;
  const fieldNameCountry = `additionalDrivers[${index}].licenceCountry`;
  const baseFieldName = 'additionalDrivers';

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer `}
      >
        <label htmlFor={fieldNameLicense} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Drivers Licence Type
       </label>
        <ResourceDropdown
          placeholder="Select here"
          error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'driversLicenseType', index)}
          errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'driversLicenseType', index)}
          name={`additionalDrivers[${index}].driversLicenseType`}
          value={values.additionalDrivers[index].driversLicenseType}
          onChange={handleChangeResource}
          onBlur={handleBlur}
          values={resources.licence_type}

        />
      </div>
      {licenceCountryResources.length > 1 && !hideSubfield && (
        <div className={`${commonFormStylesIdentifier}__fieldContainer `}>
          <label htmlFor={fieldNameCountry} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Licence Country of Issue
          </label>
          <ResourceDropdown
            placeholder="Select here"
            error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'licenceCountry', index)}
            errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'licenceCountry', index)}
            name={`additionalDrivers[${index}].licenceCountry`}
            value={values.additionalDrivers[index].licenceCountry}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={licenceCountryResources} />
        </div>)}
    </div>
  );
};

LicenceTypeLicenceCountryAdditionalDrivers.propTypes = {
  formik: PropTypes.object,
  resources: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  index: PropTypes.number,
};

export default LicenceTypeLicenceCountryAdditionalDrivers;