/**
 * @flow
 */

import React from 'react';

interface Props {
    color: string;
    solid?: boolean;
}

const HelpIcon = ({
    color = ' #600460',
    solid = true
}: Props) => {
    const style = solid
        ? { fill: color, enableBackground: 'new 0 0 63 63' }
        : { fill: 'none', stroke: color, strokeWidth: '1.5px' };
    return (
        <svg xmlns="http://www.w3.org/2000/svg" margin="auto" width="50" height="50" viewBox="0 0 80.99 80.99" style={style}>
            <g id="help-24px" fill="#600460" transform="translate(-2 -2)">
                <path id="Path_1599" d="M36.4 4a32.4 32.4 0 1 0 32.4 32.4A32.491 32.491 0 0 0 36.4 4zm4.049 56.693h-8.1v-8.1h8.1zm0-12.148h-8.1C32.346 35.586 44.5 36.4 44.5 28.3a8.1 8.1 0 0 0-16.2 0h-8.1a16.2 16.2 0 0 1 32.4 0c-.006 10.121-12.154 11.336-12.154 20.245z" data-name="Path 1599" opacity="0.3" transform="translate(6.099 6.099)" />
                <path id="Path_1600" d="M38.445 58.693h8.1v8.1h-8.1zM42.5 2a40.495 40.495 0 1 0 40.49 40.5A40.614 40.614 0 0 0 42.5 2zm0 72.891a32.4 32.4 0 1 1 32.4-32.4 32.491 32.491 0 0 1-32.4 32.4zm0-56.693A16.246 16.246 0 0 0 26.3 34.4h8.1a8.1 8.1 0 0 1 16.2 0c0 8.1-12.149 7.289-12.149 20.248h8.1c0-8.909 12.148-10.124 12.148-20.248A16.246 16.246 0 0 0 42.5 18.2z" data-name="Path 1600" />
            </g>
        </svg>
    );
};
export default HelpIcon;
