/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import './SubmitClaimDefaultPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  DesktopCard,
  MapIcon,
  MobileIcon,
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants';
import { primaryPurple } from '../../constants/colors';
import { DEVICE_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import DefaultClaimContent from './DefaultClaimContent';

const className = 'c-SubmitClaimDefaultPage';

const getIcon = {
  [DEVICE_INSURANCE_TYPE]: <MobileIcon />,
  [TRAVEL_INSURANCE_TYPE]: <MapIcon color={primaryPurple} />
};

const getPageTitle = {
  [DEVICE_INSURANCE_TYPE]: 'Device',
  [TRAVEL_INSURANCE_TYPE]: 'Travel'
};

const SubmitClaimDefaultPage = (props) => {
  const { match, history, } = props;
  const insuranceType = match.params.type || '';

  return (
    <><div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {getPageTitle[insuranceType]}
        </div>
      </div>

      <DesktopCard>
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>
            {getIcon[insuranceType]}
          </div>
        </div>
        <div className={`${className}__customTitle`}>
          <h3>Claims</h3>
        </div>
        <DefaultClaimContent />
      </DesktopCard >
    </div></>
  );
};



SubmitClaimDefaultPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(SubmitClaimDefaultPage);