"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PlanesIcon = require("./PlanesIcon");

var _PlanesIcon2 = _interopRequireDefault(_PlanesIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PlanesIcon2.default;