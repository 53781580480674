/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

export const emptyAdditionalDriver = {
  title: '',
  firstName: '',
  lastName: '',
  dob: '',
  motorClaims: [],
  additionalDriverPenaltyPointOffenceCount: '',
  additionalDriverPenaltyPointOffences: [],
  relationshipToProposer: '',
  driversLicenseType: '',
  licenceCountry: '',
  employmentStatus: '',
  mainOccupation: '',
  yearsHeld: '',
  useOfOtherCar: ''
};

export const untouchedAdditionalDriver = {
  title: false,
  firstName: false,
  lastName: false,
  dob: false,
  motorClaims: false,
  additionalDriverPenaltyPointOffenceCount: false,
  additionalDriverPenaltyPointOffences: false,
  relationshipToProposer: false,
  driversLicenseType: false,
  licenceCountry: false,
  employmentStatus: false,
  mainOccupation: false,
  yearsHeld: false,
  useOfOtherCar: false
};

export const emptyVanAdditionalDriver = {
  title: '',
  firstName: '',
  lastName: '',
  dob: '',
  additionalDriverPenaltyPointOffenceCount: 0,
  additionalDriverPenaltyPointOffences: [],
  relationshipToProposer: '',
  driversLicenseType: '',
  licenceCountry: '',
  traderOccupation: '',
  yearsHeld: '',
  penaltyPoints: ''
};

export const untouchedVanAdditionalDriver = {
  title: false,
  firstName: false,
  lastName: false,
  dob: false,
  additionalDriverPenaltyPointOffenceCount: false,
  additionalDriverPenaltyPointOffences: false,
  relationshipToProposer: false,
  driversLicenseType: false,
  licenceCountry: false,
  traderOccupation: false,
  yearsHeld: false,
  penaltyPoints: ''
};