export const TRIP_TYPES = {
  "SingleTrip": "Single Trip",
  "AnnualMultiTrip": "Annual Multi-Trip"
};

export const TRIP_TYPES_ICONS = {
  "SingleTrip": "plane",
  "AnnualMultiTrip": "planes"
};

export const TRIP_TYPE_CODES = {
  "1": "SingleTrip",
  "2": "AnnualMultiTrip"
};

export const ZONE_IDS = {
  "Europe": "1"
};

export const ZONE_KEYS = {
  "Europe": "Europe",
  "WorldWideExc": "Worldwide exc. USA, Canada and the Caribbean",
  "WorldWideInc": "Worldwide inc. USA, Canada and the Caribbean (excluding Cuba)"
};

export const ZONE_KEYS_ICONS = {
  "Europe": "globe_europe",
  "WorldWideExc": "globe_americas",
  "WorldWideInc": "globe_americas"
};

export const ZONE_CHUBB_LABELS = {
  "Europe": "Europe",
  "Worldwide exc. USA, Canada and the Caribbean": "Worldwide exc US/Canada/Caribbean",
  "Worldwide inc. USA, Canada and the Caribbean (excluding Cuba)": "Worldwide inc US/Canada/Caribbean"
};

export const INSURED_PERSON_TYPE = {
  "YOU": "1",
  "YOU_AND_PARTNER": "2",
  "YOU_AND_FAMILY": "3",
  "YOU_AND_CHILDREN": "4"
};

export const TRIP_TYPE_CODE = {
  "SINGLE_TRIP": "1",
  "MULTI_TRIP": "2"
};
