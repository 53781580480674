/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../PremiumBreakdown.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionCard } from '@arachas/core/lib';
import React, { PureComponent } from 'react';

import { PremiumBreakdownConfigs } from '../../../configs/PremiumBreakdownConfigs';
import {
  HOME_COVER_TYPE,
  PROPERTY_TYPE_APARTMENT_DESCRIPTION,
} from '../../../constants/home';
import { isAllianzHomeInsurance } from '../../../constants/insurers/homeInsurerIDs';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';

interface Props {
  premiumDetails: [{
    id: null,
    updated_date: null,
    item_name: string,
    sequence: number,
    amount: number,
    percentage: number,
    product_variant: string;
  }];
  unspecifiedItemsValue: number;
  specifiedItemsValue: number;
  //totalPrice: number; - TODO Will be needed in future -Paul.K
  monthlyPrice: number;
}

class HomePremiumBreakdown extends PureComponent<Props> {
  render() {
    const className = 'c-PremiumBreakdown';
    const { premiumDetails, quoteValues = {}, unspecifiedItemsValue, specifiedItemsValue, schemeId } = this.props;
    let buildingsSectionPremiumPrice = 0;
    let contentsSectionPremiumPrice = 0;
    let unspecifiedPPSectionPremiumPrice = 0;
    let specifiedPPSectionPremiumPrice = 0;
    let pedalCyclePrice = 0;
    let governmentLevyPrice = 0;
    let serviceFee = 0;
    let totalPremium = 0;
    let totalAmount = 0;
    let subTotalAmount = 0;

    const coverType = quoteValues.coverType.description;

    const setPrices = () => {
      const setPriceActions = {
        'Gov Levy': (price) => (governmentLevyPrice = price),
        'Service Fee': (price) => (serviceFee = price),
        'Buildings': (price) =>
          (buildingsSectionPremiumPrice = price),
        'Contents': (price) =>
          (contentsSectionPremiumPrice = price),
        'Unspecified Items': (price) =>
          (unspecifiedPPSectionPremiumPrice = price),
        'Specified All Risks': (price) =>
          (specifiedPPSectionPremiumPrice = price),
        'Pedal Cycle': (price) =>
          (pedalCyclePrice = price),
        'Total Premium': (price) =>
          (totalPremium = price),
        'Total Amount With Service Fee': (price) =>
          (totalAmount = price),
        'Sub Total': (price) => (subTotalAmount = price),
      };

      if (premiumDetails.length > 0) {
        premiumDetails.forEach((premium) => {
          if (setPriceActions[premium.item_name]) {
            setPriceActions[premium.item_name](premium.amount);
          }
        });
      }
    };

    const hasUnspecifiedItems = () => unspecifiedPPSectionPremiumPrice !== 0 && unspecifiedItemsValue !== null;

    const hasOptionalCover = () => hasUnspecifiedItems() || specifiedPPSectionPremiumPrice > 0 || pedalCyclePrice > 0;

    const showPedalCycle = () => pedalCyclePrice !== 0 ? <PremiumBreakdownRow
      label={`Pedal Cycles premium`}
      price={pedalCyclePrice}
    /> : <></ >;

    const getAllianzText = () => isAllianzHomeInsurance(schemeId) && (<div className={`${className}__label`} style={{marginBottom: '7px'}}>
      Optional extras are priced separately. The amount displayed on screen represents the total price of your selected options added together
    </div>)

    const getOptionalCoverComponents = () => {
      return hasOptionalCover() ? (
        <>
          <div className={`${className}__heading`}> Optional Cover</div>
          {getAllianzText()}
          {
            specifiedPPSectionPremiumPrice !== 0 ?
              <PremiumBreakdownRow label={`Specified items premium of €${specifiedItemsValue}`}
                price={specifiedPPSectionPremiumPrice}
              /> : <></>
          } {
            hasUnspecifiedItems() ?
              <>
                < PremiumBreakdownRow
                  label={`Unspecified items premium of ${unspecifiedItemsValue.description} `}
                  price={unspecifiedPPSectionPremiumPrice}
                /></> : <></ >
          } {showPedalCycle()}
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
        </>
      ) : <> </>;
    };

    setPrices();

    const showRebuildCost = () => coverType !== HOME_COVER_TYPE.RENT && quoteValues.rebuildCost && quoteValues.rebuildCost !== 'null';
    const isPropertyApartment = () => quoteValues.homeType.description === PROPERTY_TYPE_APARTMENT_DESCRIPTION;

    return (<AccordionCard id={`HomePremiumBreakdown__viewPremium`}
      label="Premium Breakdown"
      status="none"
      labelStyle="black"
      childContainerClass={`${className}__noPaddingAccordion`}
      customLabelClass={`${className}__accordionLabel`}
      on={true}
      toggleByDefault={!PremiumBreakdownConfigs.accordionCollapse.home}
      customClass={`${className}__customAccordionContainer`}>
      <div className={`${className}`}>
        <div className={`${className}__section`}>
          <div className={`${className}__headingNoPadding`}> Main cover</div>
          {
            // don't  show buildings type if
            // type of property is apartment or
            // cover type is "i rent from someone"
            (showRebuildCost() && !isPropertyApartment()) &&
            (<PremiumBreakdownRow label={`Buildings cover of €${quoteValues.rebuildCost} `}
              price={buildingsSectionPremiumPrice}
            />)} <PremiumBreakdownRow label={`Contents cover of €${quoteValues.contentsCoverOf} `}
              price={contentsSectionPremiumPrice}
          />
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
          {getOptionalCoverComponents()}
          {
            /* <div className={`${className}__section`}>   - Will be needed in future -Paul.K

                        <div className={`${className}__heading`}>Payment Options</div>
                        <PremiumBreakdownRow label={'Single Payment'} price={totalPremiumPrice} />
                      </div>  - Will be needed in future -Paul.K*/
          }
          {
            /*         <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
                      <div className={`${className}__section`}>
                        <div className={`${className}__heading`}>If paid monthly</div>
                        <PremiumBreakdownRow
                          label={'Monthly payment'}
                          price={monthlyPrice}
                        />
                        <PremiumBreakdownRow
                          label={'Total annual charge for payments by installment'}
                          price={0.0}
                        />
                        <PremiumBreakdownRow
                          label={'Total if paid by installment'}
                          price={totalPremiumPrice}
                        />
                      </div> */
          } { /* -TODO Will be needed in future -Paul.K */}
          <div className={`${className}__rowPadding`} />

          <PremiumBreakdownRow label={'Sub Total Premium:'}
            price={subTotalAmount} />

          <PremiumBreakdownRow label={'Government Levy amount:'}
            price={governmentLevyPrice} />

          <PremiumBreakdownRow label={'Total premium due:'}
            price={totalPremium} />

          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />

          <div className={`${className}__rowPadding`} />
          <PremiumBreakdownRow label={'Broker set up fee'}
            price={serviceFee} />

          <PremiumBreakdownRow label={'Total amount due'}
            price={totalAmount} />

          <div className={`${className}__label`}>
            The above premium includes an embedded allowance
            for any claims free years you have declared as per the attached scale
            <a href='//www.arachas.ie/home-insurance-discount-scale.pdf '
              target="_blank"
              rel="noopener noreferrer"> here</a>
          </div>
        </div>
      </div>
    </AccordionCard>
    );
  }
}

export default HomePremiumBreakdown;
