/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import * as yup from "yup";

export const homeUpdateSpecifiedItemsYupSchema = yup.object().shape({
  specifiedItems: yup.array().of(
    yup.object().shape({
      item: yup.object().nullable().required('Please select an item type'),
      value: yup
        .string()
        .required('Specified item value is required'),
      description: yup.string().required('Please enter an item description')
    })
  )
});
