"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DeleteIcon = require("./DeleteIcon");

var _DeleteIcon2 = _interopRequireDefault(_DeleteIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _DeleteIcon2.default;