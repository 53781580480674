"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _HeartbeatIcon = require("./HeartbeatIcon");

var _HeartbeatIcon2 = _interopRequireDefault(_HeartbeatIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _HeartbeatIcon2.default;