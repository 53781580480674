// @flow

import './DesktopHeader.scss';

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { getAccessToken } from '../../helpers';
import { routes } from '../../routes';
import { UserProfileContext } from '../../UserProfile';
import BrandLogo from '../BrandLogo'
import SubHeading from './SubHeading';

const DesktopHeader = () => {

  const className = 'c-DesktopHeader';
  const { cognitoUser } = useContext(CognitoContext);
  const { userProfile } = useContext(UserProfileContext);
  const isLoggedIn = !!getAccessToken(cognitoUser);

  const getAccessUrl = () => {
    return isLoggedIn ? <Link to={routes.logoutPage.url}>
      <div className={`${className}__logoutLabel`}>Logout</div>
    </Link> : <Link to={routes.loginPage.url}>
        <div className={`${className}__logoutLabel`}>Login</div>
      </Link>
  };

  return (
    <>
      <div className={`${className}`}>
        <div className={`${className}__logo`} >
          <BrandLogo theme={"light"} />
        </div>
        {getAccessUrl()}
      </div>
      <SubHeading routes={routes} userProfile={userProfile} isLoggedIn={isLoggedIn} />
    </>
  );
};

export default withRouter(DesktopHeader);
