import { ALLIANZ_HOME_SCHEMA_ID, AVIVA_HOME_SCHEMA_ID,AXA_HOME_SCHEMA_ID, ZURICH_HOME_SCHEMA_ID } from '../../constants/insurers/homeInsurerIDs';

export const homeFeatureList = {
  [ALLIANZ_HOME_SCHEMA_ID]: [
    { icon: 'bullet-phone', label: '24 Hour Emergency Helpline' },
    { icon: 'bullet-business', label: 'Accidental Damage on building and contents included' },
    { icon: 'bullet-category', label: 'Automatic Increase in contents cover for special occasions such as Christmas period & weddings' }
  ],
  [ZURICH_HOME_SCHEMA_ID]: [
    { icon: 'bullet-phone', label: '24 Hour Emergency Helpline' },
    { icon: 'bullet-business', label: 'Accidental Damage on building and contents included as standard' },
    { icon: 'bullet-category', label: '€1500 unspecified items all risk cover included as standard' }
  ],
  [AXA_HOME_SCHEMA_ID]: [
    { icon: 'bullet-phone', label: '24 Hour Emergency Helpline' },
    { icon: 'bullet-business', label: 'Accidental Damage on building and contents included' },
    { icon: 'bullet-category', label: 'Automatic Increase in contents cover for special occasions such as Christmas period & weddings' }
  ],
  [AVIVA_HOME_SCHEMA_ID]: [
    { icon: 'bullet-phone', label: '24 Hour Emergency Helpline' },
    { icon: 'bullet-business', label: 'Accidental Damage on building and contents included' },
    { icon: 'bullet-category', label: 'Automatic Increase in contents cover for special occasions such as Christmas period & weddings' }
  ],
};

