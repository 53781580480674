/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const isAgent = () => {
  const isAnAgent = process.env.REACT_APP_AGENT_ENABLED;
  return isAnAgent === 'TRUE';
};
