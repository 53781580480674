/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonFormStyles.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier } from '../../constants';
import { getAccessToken, getItemFromSessionStorage, isAgent, isFieldError, makeRequest } from '../../helpers';
import ResourceDropdown from '../ResourceDropdown';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
const AreaOfUse = (props) => {
  const className = 'c-AreaOfUse';
  const {
    errors, handleBlur, setFieldValue, touched, values, setFieldTouched
  } = props.formik;
  const { resources } = props;
  const { cognitoUser } = useContext(CognitoContext);
  const [subAreaUseResources, setSubAreaUseResources] = useState([])
  const [hideSubfield, setHideSubfield] = useState(false)

  const handleChangeResource = (value, name) => {
    setFieldValue(name, value || {});
    if (name === 'areaUse') {
      setFieldValue('subAreaUse', {})
      setFieldTouched('subAreaUse', false)
    }
  };

  useEffect(() => {
    const id = values.areaUse && values.areaUse.id
    if (id) {
      let authToken = undefined;
      if (isAgent) {
        authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      }
      setHideSubfield(true)
      makeRequest({
        url: `${basePath}/van/resources/subarea_use/${id}`,
        authToken: authToken
      }).then(response => {
        setSubAreaUseResources(response);
        setHideSubfield(false)
        if (response.length === 0) {
          setFieldValue('subAreaUse', undefined);
        }
      })
    } else {
      setSubAreaUseResources([])
      setFieldValue('subAreaUse', undefined);
    }
  }, [values.areaUse])

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer `}
      >
        <label htmlFor="areaUse" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Main Area of Use
      </label>
        <ResourceDropdown
          error={isFieldError('areaUse', touched, errors)}
          errorMessage={errors.areaUse}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Select here"
          name="areaUse"
          value={values.areaUse}
          onChange={handleChangeResource}
          values={resources.area_use}
        />
      </div>
      {subAreaUseResources.length > 0 && !hideSubfield && (
        <div className={`${commonFormStylesIdentifier}__fieldContainer `}
        >
          <label htmlFor="subAreaUse" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Sub Area of Use
      </label>
          <ResourceDropdown
            error={isFieldError('subAreaUse', touched, errors)}
            errorMessage={errors.subAreaUse}
            touched={touched}
            onBlur={handleBlur}
            placeholder="Select here"
            name="subAreaUse"
            value={values.subAreaUse}
            onChange={handleChangeResource}
            values={subAreaUseResources}
          />
        </div>
      )}
    </div>
  )
}

AreaOfUse.propTypes = {
  formik: PropTypes.object,
  resources: PropTypes.object
}

export default AreaOfUse;
