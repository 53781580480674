/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import {
  ButtonGroup,
  Dropdown,
  InputField,
  WideDivider
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { dividerColor } from '../../../../../constants/colors';
import { decimalNumberMask } from '../../../../../helpers';
import ResourceDropdown from '../../../../ResourceDropdown';

const NOT_SETTLED = 'NotSettled';
const SETTLED = 'Settled';

const untouchedEmptyClaim = {
  claim_type: false,
  claim_date: false,
  claim_status: false,
  claim_amount: false
};

const getClaimYearOptions = () => {
  const claimYearOptions = [
    <option key={'buildYear_empty'} value="">
      Select here
    </option>
  ];
  const currentYear = new Date().getFullYear();
  //one of the previous 5 years.
  const previousFiveYears = currentYear - 5;
  for (let year = previousFiveYears; year <= currentYear; year++) {
    claimYearOptions.push(
      <option key={`buildYear_${year}`} value={`${year}`}>
        {year}
      </option>
    );
  }
  return claimYearOptions;
};

const Claims = (props) => {
  const className = 'c-Claims';
  const { errors, setFieldValue, setFieldTouched, claimsTouched, claimsValues, index } = props;

  useEffect(() => {
    updateTouchedClaims();
  }, [claimsValues]);

  const updateTouchedClaims = () => {
    const touchedArraySize = claimsTouched ? claimsTouched.length : 0;
    const newTouchedClaims = claimsTouched ? [...claimsTouched] : [];
    const arraysSizeDiff = claimsValues.length - touchedArraySize;
    for (let i = 0; i < arraysSizeDiff; ++i) {
      newTouchedClaims.push({ ...untouchedEmptyClaim });
    }
    setFieldTouched('claims', newTouchedClaims);
  };

  const claimYearOptions = getClaimYearOptions();

  const handleChange = (value, fieldName, claimNumber) => {
    const newValues = claimsValues;
    newValues[claimNumber][fieldName] = value;
    setFieldValue('claims', newValues);
  };

  const handleBlur = (fieldName, claimNumber) => {
    const newTouched = claimsTouched && claimsTouched[claimNumber] ? [...claimsTouched] : [{ ...untouchedEmptyClaim }];
    newTouched[claimNumber][fieldName] = true;
    setFieldTouched('claims', newTouched);
  };

  const checkClaimExists = (claimNumber) => {
    return (
      errors.claims && errors[claimNumber] &&
      claimsTouched && claimsTouched[claimNumber]
    );
  };

  const checkFieldError = (fieldName, index) => {
    if (checkClaimExists(index)) {
      return (errors.claims[index][fieldName] && claimsTouched[index][fieldName]);
    }
  };

  const getErrorMessage = (fieldName, index) => {
    return checkFieldError(fieldName, index) ? errors.claims[index][fieldName] : '';
  };

  const renderClaimAmount = (index) => {
    return claimsValues[index].claim_status === SETTLED ? (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_amount[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Amount of claim / material loss
        </label>
        <InputField
          error={checkFieldError('claim_amount', index)}
          placeholder="Enter an amount"
          errorMessage={getErrorMessage('claim_amount', index)}
          key={`claim_amount[${index}]`}
          name="claim_amount"
          value={claimsValues[index]['claim_amount']}
          onChange={(e) =>
            handleChange(e.currentTarget.value, 'claim_amount', index)
          }
          onBlur={() => handleBlur('claim_amount', index)}
          masked
          mask={decimalNumberMask}
        />
      </div>
    ) : <></>;
  };

  return (
    <div
      key={'Claim_' + (index + 1)}
      className={`${className}__claimSection`}
    >
      {index > 0 ? <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
        <WideDivider height={1} color={dividerColor}/>
      </div> : <></>}
      <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
        <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
          Claim {index + 1}
        </label>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_type[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Name of claim / material loss
        </label>
        <ResourceDropdown
          error={checkFieldError('claim_type', index)}
          errorMessage={getErrorMessage('claim_type', index)}
          key={`claim_type[${index}]`}
          name="claim_type"
          value={claimsValues[index].claim_type}
          onChange={(e) => {
            handleChange(e, 'claim_type', index);
          }
          }
          onBlur={() => handleBlur('claim_type', index)}
          values={props.claimTypes}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_date[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Year of claim / material loss
        </label>
        <Dropdown
          error={checkFieldError('claim_date', index)}
          errorMessage={getErrorMessage('claim_date', index)}
          key={`claim_date[${index}]`}
          name="claim_date"
          onChange={(e) =>
            handleChange(e.currentTarget.value, 'claim_date', index)
          }
          value={claimsValues[index].claim_date}
          onBlur={() => handleBlur('claim_date', index)}
        >
          {claimYearOptions}
        </Dropdown>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_status[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Has your claim/material loss been settled?
        </label>
        <ButtonGroup
          error={checkFieldError('claim_status', index)}
          errorMessage={getErrorMessage('claim_status', index)}
          key={`claim_status[${index}]`}
          name="claim_status"
          onClick={(value) => {
            handleChange(value, 'claim_status', index);
            if (value === NOT_SETTLED) {
              // If value set as NOT_SETTLED then revert claim_amount to it's initial value which is undefined
              handleChange(undefined, 'claim_amount', index);
            }
          }}
          selected={claimsValues[index].claim_status}
          onBlur={() => handleBlur('claim_status', index)}
          options={[
            { label: 'No', value: NOT_SETTLED },
            { label: 'Yes', value: SETTLED }
          ]}
        >
          {claimYearOptions}
        </ButtonGroup>
      </div>
      {renderClaimAmount(index)}
    </div>
  );
};

Claims.propTypes = {
  claimsValues: PropTypes.array,
  claimsTouched: PropTypes.array,
  errors: PropTypes.array,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  isFieldError: PropTypes.func,
  claimTypes: PropTypes.array,
  touched: PropTypes.object,
  index: PropTypes.number
};

export default Claims;
