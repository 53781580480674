/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import './PolicyLinksPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  Button,
  DesktopCard,
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import IconAndTitle from '../../components/IconAndTitle/IconAndTitle';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { backToWallet } from '../../constants/buttonLabelConstants';
import { CAR_INSURANCE_TYPE, getInsurancePageTitle, HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getAccessToken } from '../../helpers';
import {
  getObjectFromSessionStorage,
} from '../../helpers';
import { routes } from '../../routes';
import { LinksPage } from './PolicyInsuranceLinksPages';
import { genericProviderLinksCar, getLinksPage, insurerLinksCar, staticPolicyLinksPage } from './PolicyInsuranceLinksPages/policyLinks';

const className = 'c-PolicyLinksPage';
const pageTitle = 'Policy Documents';

const PolicyLinksPage = (props) => {
  const { cognitoUser } = useContext(CognitoContext);
  const { match, history, coverType } = props;
  const insuranceType = match.params.type || '';

  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);
  const getInsurerName = () => policyDetails !== null ? policyDetails.insurer : props.location.state.insurerName;

  const getInsurerLinks = () => {
    if (insuranceType === CAR_INSURANCE_TYPE) {
      const insurerName = getInsurerName();
      return genericProviderLinksCar.concat(insurerLinksCar[insurerName]);
    }
    else if (insuranceType === HOME_INSURANCE_TYPE) {
      const insurerName = getInsurerName();
      const homeLinks = getLinksPage(coverType.description, insurerName);
      const staticLinks = staticPolicyLinksPage[insuranceType];
      const allLinks = staticLinks.concat(homeLinks);

      return allLinks;
    }
    else {
      return staticPolicyLinksPage[insuranceType];
    }
  };

  return (
    <div className={className}>
      <><div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {getInsurancePageTitle[insuranceType]}
        </div>
      </div>
        <DesktopCard>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          <IconAndTitle
            insuranceType={insuranceType}
            title={pageTitle}
          />
          <div className={`${className}__contentContainer`}>
            <LinksPage
              cognitoAccessTokenKey={getAccessToken(cognitoUser)}
              history={history}
              insuranceType={insuranceType}
              staticLinks={getInsurerLinks()}
            />
            <div className={`${className}__buttonContainer`}>
              <Link to={routes.Wallet.url}>
                <Button fluid={true}>{backToWallet}</Button>
              </Link>
            </div>
          </div>
        </DesktopCard></>
    </div>
  );
};

PolicyLinksPage.propTypes = {
  coverType: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(PolicyLinksPage);