/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './ClaimContent.scss';
import '../../styles/commonPageStyles.scss';

import React from 'react';


const className = 'c-ClaimContent';

const HomeClaimHelpContent = () => {

  return (
    <>
      <div className={`${className}__customBullets`}>
        <ul>
          <li>The insurer will take some details of your claim and appoint any necessary specialists immediately</li>
          <li>The insurer will appoint a member of their claims team to handle your claim and forward you their contact information</li>
          <li>You will be sent an accident/incident report form within a maximum of 24 hours</li>
          <li>You will need to sign and complete this so your claims can be processed as quickly as possible</li>
        </ul>
      </div>
    </>
  );
};

export default HomeClaimHelpContent;