/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonPageStyles.scss';
import './GetGenericQuotePage.scss';

import { AccordionCard, Button, Title } from '@arachas/core/lib';
import { Form, Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import * as yup from 'yup';

import AboutYouForm, {
  aboutYouFormInitialValues,
  aboutYouFormYupSchema,
} from '../../components/Forms/AboutYouForm';
import {
  aboutYouFormIdentifier,
  yourCarCoverFormIdentifier,
  yourCarFormIdentifier,
  yourDeviceCoverFormIdentifier,
  yourDeviceFormIdentifier,
  yourHomeCoverFormIdentifier,
  yourHomeFormIdentifier,
  yourTravelCoverFormIdentifier,
  yourTravelFormIdentifier,
  yourVanCoverFormIdentifier,
  yourVanFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';
import YourCarCoverForm, {
  yourCarCoverFormInitialValues,
  yourCarCoverFormYupSchema,
} from '../../components/Forms/YourCarCoverForm';
import YourCarForm, {
  yourCarFormInitialValues,
  yourCarFormYupSchema,
} from '../../components/Forms/YourCarForm';
import YourDeviceCoverForm, {
  YourDeviceCoverFormInitialValues,
  YourDeviceCoverFormYupSchema,
} from '../../components/Forms/YourDeviceCoverForm';
import YourDeviceForm, {
  yourDeviceFormInitialValues,
  yourDeviceFormYupSchema,
} from '../../components/Forms/YourDeviceForm';
import YourHomeCoverForm, {
  yourHomeCoverFormInitialValues,
  yourHomeCoverFormYupSchema,
} from '../../components/Forms/YourHomeCoverForm';
import YourHomeForm, {
  yourHomeFormInitialValues,
  yourHomeFormYupSchema,
} from '../../components/Forms/YourHomeForm';
import
YourTravelCoverForm,
{
  yourTravelCoverFormInitialValues,
  yourTravelCoverFormYupSchema,
} from '../../components/Forms/YourTravelCoverForm';
import
YourTravelForm,
{
  yourTravelFormInitialValues,
  yourTravelFormYupSchema,
} from '../../components/Forms/YourTravelForm';
import YourVanCoverForm, { yourVanCoverFormInitialValues, YourVanCoverFormYupSchema } from '../../components/Forms/YourVanCoverForm';
import YourVanForm, {
  yourVanFormInitialValues,
  yourVanFormYupSchema,
} from '../../components/Forms/YourVanForm';
import LegalText from '../../components/LegalText';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import {
  BOI_WALLET_USER_NAME_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  HOME_QUOTE_RECALCULATED_VALUES_STORAGE
} from '../../constants';
import { commonPageStylesIdentifier, scrollPixelValue } from '../../constants';
import {
  CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import { JOURNEY_GET_QUOTE } from '../../constants/journey';
import { SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import {
  getObjectFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { mapDeviceQuote } from '../../mappings/device/MapDeviceQuote';
import { mapHomeQuote } from '../../mappings/home/MapHomeQuote.js';
import { mapMotorQuote } from '../../mappings/motor/MapMotorQuote';
import { mapTravelQuote } from '../../mappings/travel/MapTravelQuote';
import { mapVanQuote } from '../../mappings/van/MapVanQuote';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import UserContext from '../../UserContext';
import errorFields from './errorFields';
import { getQuoteFormStructure } from './getQuoteFormStructure';
import { getFormikObject } from './helpers';

const AWAITING_PREPOPULATION_CHECK =
  'GetGenericQuotePage/AWAITING_PREPOPULATION_CHECK';
const NO_PREPOPULATION = 'GetGenericQuotePage/NO_PREPOPULATION';
const PREPOPULATION_REQUESTED = 'GetGenericQuotePage/PREPOPULATION_REQUESTED';
const PREPOPULATION_FINISHED = 'GetGenericQuotePage/PREPOPULATION_FINISHED';

export const InsuranceTypeContext = createContext(HOME_INSURANCE_TYPE);

const checkIfObjectEmpty = obj =>
  typeof obj !== 'object' || Object.keys(obj).length === 0;

const GetGenericQuotePage = props => {
  const [hasFormBeenOpened, setFormHasBeenOpened] = useState({
    [aboutYouFormIdentifier]: false,
    [yourCarFormIdentifier]: false,
    [yourCarCoverFormIdentifier]: false,
    [yourDeviceFormIdentifier]: false,
    [yourDeviceCoverFormIdentifier]: false,
    [yourTravelFormIdentifier]: false,
    [yourTravelCoverFormIdentifier]: false,
    [yourVanFormIdentifier]: false,
    [yourVanCoverFormIdentifier]: false,
  });
  const [prepopulationStatus, setPrepopulationStatus] = useState(
    AWAITING_PREPOPULATION_CHECK,
  );

  const className = 'c-GetGenericQuotePageForm';
  const {
    insuranceType = '',
    history,
    latestQuote = { values: {} },
  } = props;
  const formStructure = { ...getQuoteFormStructure[insuranceType] };

  const getAboutYouLabel = () => {
    return insuranceType === VAN_INSURANCE_TYPE ? 'About You / Your Main Driver' : 'About You';
  };

  const sessionStoredValues = insuranceType
    ? getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`)
    : {};

  const latestValues = latestQuote.values;

  const addressLookupFields = [
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressTown',
    'addressCounty',
    'addressEircode',
    'eircode',
    'geo_code',
    'match_level',
    'match_type',
    'ecad_identifier',
    'ecad_match_level',
    'ecad_match_result',
    'street_name',
    'post_code',
    'reference',
    'street_name',
    'lookup_response',
    'reference',
  ];

  const carRegLookupFields = [
    'carReg',
    'carMake',
    'carModel',
    'carYear',
    'carFuelType',
    'carEngineSize',
    'carVersion',
    'carABICode',
    'isManualSearch',
    'doors',
    'body_type',
    'number_seats',
  ];

  const travelDestinationFields = [
    'tripType',
    'destinationZone',
    'destinationCountry',
  ];

  const deviceLookupFields = ['deviceMake', 'deviceModel', 'deviceType', 'id', 'device_type', 'manufacturer', 'model', 'monthly_price', 'annual_price', 'effective_date'];

  const vanLookupFields = ['vanReg', 'vanMake', 'vanModel', 'vanType', 'vanYear', 'vanWeight', 'vanDescription', 'isManualSearch'];

  const isSessionStorageEmpty = {
    [HOME_INSURANCE_TYPE]: () =>
      Object.keys(sessionStoredValues).filter(
        fieldName => addressLookupFields.indexOf(fieldName) < 0,
      ).length === 0,
    [CAR_INSURANCE_TYPE]: () =>
      Object.keys(sessionStoredValues).filter(
        fieldName => carRegLookupFields.indexOf(fieldName) < 0,
      ).length === 0,
    [TRAVEL_INSURANCE_TYPE]: () => {
      return Object.keys(sessionStoredValues).filter(
        fieldName => travelDestinationFields.indexOf(fieldName) < 0,
      ).length === 0;
    },
    [DEVICE_INSURANCE_TYPE]: () => {
      return Object.keys(sessionStoredValues).filter(
        fieldName => deviceLookupFields.indexOf(fieldName) < 0,
      ).length === 0;
    },
    [VAN_INSURANCE_TYPE]: () => {
      return Object.keys(sessionStoredValues).filter(
        fieldName => vanLookupFields.indexOf(fieldName) < 0,
      ).length === 0;
    },
    ['']: () => checkIfObjectEmpty(sessionStoredValues),
  };


  useEffect(() => {
    if (insuranceType === HOME_INSURANCE_TYPE) {
      sessionStorage.removeItem(HOME_QUOTE_RECALCULATED_VALUES_STORAGE);
    }

    const latestIsEmpty = checkIfObjectEmpty(latestValues);
    if (!latestIsEmpty || !checkIfObjectEmpty(sessionStoredValues)) {
      setPrepopulationStatus(PREPOPULATION_REQUESTED);
    } else {
      setPrepopulationStatus(NO_PREPOPULATION);
    }
  }, [latestValues]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnGetQuotePage({ insuranceType }),
      dataLayerName: 'dataLayer'
    })
  }, [])

  const mapDataAndRouteToThankYouPage = (insuranceType, values) => {
    const mapDataDependingOnInsuranceType = {
      [HOME_INSURANCE_TYPE]: mapHomeQuote,
      [TRAVEL_INSURANCE_TYPE]: mapTravelQuote,
      [CAR_INSURANCE_TYPE]: mapMotorQuote,
      [DEVICE_INSURANCE_TYPE]: mapDeviceQuote,
      [VAN_INSURANCE_TYPE]: mapVanQuote
    };
    const mappingsData = mapDataDependingOnInsuranceType[insuranceType](values);
    const url = `${routes.getQuoteThankYouPage.url}${insuranceType}`;
    // Used to ensure a call to the backend is only made going forward through the journey
    saveInSessionStorage(
      `${insuranceType}${SUBMITTED_GET_QUOTE_STORAGE}`,
      true,
    );
    history.push({
      pathname: url,
      state: {
        values: values,
        insuranceType: insuranceType,
        mappingsData: mappingsData,
      },
    });
  };

  const { userDispatch } = useContext(UserContext);

  const updateFirstName = value => {
    userDispatch({
      type: 'setFirstName',
      payload: { firstName: value },
    });
    saveInSessionStorage(BOI_WALLET_USER_NAME_STORAGE, value);
  };

  const getFormInterior = formProps => {
    const { errors, touched, setTouched, setValues, values } = formProps;
    if (prepopulationStatus === PREPOPULATION_REQUESTED) {
      const newValues = {
        ...values,
        ...latestValues,
        ...sessionStoredValues,
      };

      setValues(newValues);
      setTouched(
        setNestedObjectValues(
          newValues,
          !isSessionStorageEmpty[insuranceType]()
        ),
      );
      const shouldOpenForms = (isSessionEmpty, latestIsEmpty) =>
        !isSessionEmpty || !latestIsEmpty;
      const latestIsEmpty = checkIfObjectEmpty(latestValues);
      const openForms = shouldOpenForms(
        isSessionStorageEmpty[insuranceType](),
        latestIsEmpty,
      );
      setFormHasBeenOpened({
        [aboutYouFormIdentifier]: openForms,
        [yourHomeFormIdentifier]: openForms,
        [yourHomeCoverFormIdentifier]: openForms,
        [yourCarFormIdentifier]: openForms,
        [yourDeviceFormIdentifier]: openForms,
        [yourDeviceCoverFormIdentifier]: openForms,
        [yourVanFormIdentifier]: openForms,
        [yourVanCoverFormIdentifier]: openForms,
        [yourCarCoverFormIdentifier]: openForms,
        [yourTravelFormIdentifier]: openForms,
        [yourTravelCoverFormIdentifier]: openForms,
      });
      setPrepopulationStatus(PREPOPULATION_FINISHED);
    }

    const isFormValid = formName => {
      if (!hasFormBeenOpened[formName]) {
        return false;
      }
      const errorFields = formStructure[formName];
      let valid = true;
      errorFields.forEach(field => {
        if (errors[field]) {
          valid = false;
        }
      });
      return valid;
    };

    const getFormStatus = formName => {
      if (hasFormBeenOpened[formName]) {
        return isFormValid(formName) ? 'success' : 'warning';
      }
      return 'default';
    };

    const setFormTouched = () => {
      /* If the user has never touched the form before then store that they have touched the form
         This touched form is used for form validation */
      if (!touched) {
        setTouched({ form: true });
      } else if (!touched.form) {
        setTouched({ ...touched, form: true });
      }
    };

    const toggleFormHasBeenOpened = formName => {
      if (!hasFormBeenOpened[formName]) {
        setFormTouched();
        const newValue = { ...hasFormBeenOpened };
        newValue[formName] = true;
        setFormHasBeenOpened(newValue);
      }
    };

    const generateForm = ({
      formIdentifier,
      accordionLabel,
      accordionChildClass,
      FormComponent,
      formProps,
    }) => {
      return formStructure[formIdentifier] ? (
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__${formIdentifier}`}
            on={
              prepopulationStatus === PREPOPULATION_FINISHED &&
              hasFormBeenOpened[formIdentifier]
            }
            label={accordionLabel}
            onToggle={() => toggleFormHasBeenOpened(formIdentifier)}
            status={getFormStatus(formIdentifier)}
            childContainerClass={accordionChildClass}
          >
            <FormComponent {...formProps} resources={props.resources} travelResources={props.travelResources} />
          </AccordionCard>
        </div>
      ) : (
        <></>
      );
    };

    generateForm.propTypes = {
      formIdentifier: PropTypes.string,
      accordionLabel: PropTypes.string,
      accordionChildClass: PropTypes.string,
      FormComponent: PropTypes.func,
      formProps: PropTypes.object,
      onToggle: PropTypes.func,
    };

    const scrollToElement = yOffset => {
      const currentY = document.querySelector('.c-App').scrollTop;

      if (Math.abs(yOffset) > 15) {
        const nextY = yOffset > 0 ? currentY + 15 : currentY - 15;
        document.querySelector('.c-App').scrollTop = nextY;
        setTimeout(() => {
          scrollToElement(yOffset > 0 ? yOffset - 15 : yOffset + 15);
        }, 1);
      }
    };

    const getFieldId = (field) => {
      const autoAddressId = insuranceType === CAR_INSURANCE_TYPE ?
        'autoAddressValueErrorMessage' :
        'addressEircodeErrorMessage';

      const addressFields = ['addressLine1',
        'addressLine2',
        'addressLine3',
        'addressEircode',
        'addressTown',
        'addressCounty'];
      return addressFields.includes(field) ? autoAddressId : field;
    };

    const checkIfElementIsArrayAndScroll = (field) => {
      const fieldErrorsObject = errors[field];
      if (Array.isArray(fieldErrorsObject)) {
        fieldErrorsObject.forEach((eachElement, index) => {
          const id = `${field}[${index}].${Object.keys(eachElement)[0]}`;
          const yOffset = document.getElementById(id).getClientRects()[0].y;
          scrollToElement(yOffset - scrollPixelValue);
        });
      } else {
        const yOffset = document.getElementById(getFieldId(field)).getClientRects()[0].y;
        scrollToElement(yOffset - scrollPixelValue);
      }
    };

    const scrollToFirstErrorElement = () => {
      setValues(values);
      setTouched(setNestedObjectValues(values, true));
      setFormHasBeenOpened({
        [aboutYouFormIdentifier]: true,
        [yourHomeFormIdentifier]: true,
        [yourHomeCoverFormIdentifier]: true,
        [yourCarFormIdentifier]: true,
        [yourCarCoverFormIdentifier]: true,
        [yourTravelFormIdentifier]: true,
        [yourDeviceFormIdentifier]: true,
        [yourDeviceCoverFormIdentifier]: true,
        [yourVanFormIdentifier]: true,
        [yourVanCoverFormIdentifier]: true,
      });
      setPrepopulationStatus(PREPOPULATION_FINISHED);

      setTimeout(() => {
        for (let field of errorFields[insuranceType]) {
          if (errors[field]) {
            try {
              checkIfElementIsArrayAndScroll(field);
              break;
            } catch (e) {
              console.log(e); // TODO handle this
            }
          }
        }
      }, 500);
    };

    const handleSubmitButtonClick = () => {
      if (formProps.isValid) formProps.handleSubmit();
      else {
        scrollToFirstErrorElement();
      }
    };

    const showForm =
      prepopulationStatus === NO_PREPOPULATION ||
      prepopulationStatus === PREPOPULATION_FINISHED;

    return (
      <div
        className={className}
        style={{
          display: `${showForm ? 'block' : 'hidden'}`,
        }}
      >
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <ProgressBar stage={JOURNEY_GET_QUOTE} />
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <Title align="left" type="h1" weight="weight500" variant="greyBrown">
            Get a Quote
          </Title>
        </div>
        <div className={`${className}__subHeading`}>
          <Title type="h4" align="left" variant="black">
            Just a few details to get your quote
          </Title>
        </div>
        <Form>
          <div className={`${className}__accordions`}>
            {generateForm({
              formIdentifier: aboutYouFormIdentifier,
              accordionLabel: getAboutYouLabel(),
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: AboutYouForm,
              formProps: { insuranceType, ...formProps },
            })}
            {generateForm({
              formIdentifier: yourHomeFormIdentifier,
              accordionLabel: 'Your Home',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourHomeForm,
              formProps: { ...formProps, ...props },
            })}
            {generateForm({
              formIdentifier: yourHomeCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              FormComponent: YourHomeCoverForm,
              formProps: { ...formProps, ...props },
            })}
            {generateForm({
              formIdentifier: yourCarFormIdentifier,
              accordionLabel: 'Your Car',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourCarForm,
              formProps: { ...formProps, ...props },
            })}
            {generateForm({
              formIdentifier: yourCarCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourCarCoverForm,
              formProps: {
                ...formProps,
                ...props,
                formFilledBefore: !!latestValues,
              },
            })}
            {generateForm({
              formIdentifier: yourVanFormIdentifier,
              accordionLabel: 'Your Van',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourVanForm,
              formProps: {
                ...formProps,
                ...props,
                formFilledBefore: !!latestValues,
              },
            })}
            {generateForm({
              formIdentifier: yourVanCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourVanCoverForm,
              formProps: {
                ...formProps,
                ...props,
                formFilledBefore: !!latestValues,
              },
            })}
            {generateForm({
              formIdentifier: yourTravelFormIdentifier,
              accordionLabel: 'Your Trip',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourTravelForm,
              formProps: { ...formProps, ...props },
            })}
            {generateForm({
              formIdentifier: yourDeviceFormIdentifier,
              accordionLabel: 'Your Device',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourDeviceForm,
              formProps: { ...formProps, ...props },
            })}
            {generateForm({
              formIdentifier: yourTravelCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourTravelCoverForm,
              formProps: { ...formProps, ...props },
            })}
            {generateForm({
              formIdentifier: yourDeviceCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourDeviceCoverForm,
              formProps: { ...formProps, ...props },
            })}
          </div>

          <div className={`${className}__button`}>
            <Button
              id="GenericQuotePageForm__getQuote"
              data-testid="GenericQuotePageForm__getQuote"
              fluid={true}
              quaternary
              onClick={handleSubmitButtonClick}
              type="submit"
            >
              GET COVERED
            </Button>
          </div>
        </Form>
        <div className={`${className}__legalTextContainer`}>
          <LegalText insuranceType={insuranceType} />
        </div>
      </div>
    );
  };

  const initValues = useMemo(
    () => ({
      ...getFormikObject(
        insuranceType,
        aboutYouFormIdentifier,
        aboutYouFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourHomeFormIdentifier,
        yourHomeFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourHomeCoverFormIdentifier,
        yourHomeCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourCarFormIdentifier,
        yourCarFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourVanFormIdentifier,
        yourVanFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourVanCoverFormIdentifier,
        yourVanCoverFormInitialValues
      ),
      ...getFormikObject(
        insuranceType,
        yourCarCoverFormIdentifier,
        yourCarCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourTravelFormIdentifier,
        yourTravelFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourTravelCoverFormIdentifier,
        yourTravelCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourDeviceFormIdentifier,
        yourDeviceFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourDeviceCoverFormIdentifier,
        YourDeviceCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourVanFormIdentifier,
        yourVanFormInitialValues,
      )
    }),
    [insuranceType],
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        ...getFormikObject(
          insuranceType,
          aboutYouFormIdentifier,
          aboutYouFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourHomeFormIdentifier,
          yourHomeFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourHomeCoverFormIdentifier,
          yourHomeCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourCarFormIdentifier,
          yourCarFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourVanFormIdentifier,
          yourVanFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourVanCoverFormIdentifier,
          YourVanCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourCarCoverFormIdentifier,
          yourCarCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourTravelFormIdentifier,
          yourTravelFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourTravelCoverFormIdentifier,
          yourTravelCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourDeviceFormIdentifier,
          yourDeviceFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourDeviceCoverFormIdentifier,
          YourDeviceCoverFormYupSchema,
        ),
      }),
    [insuranceType],
  );

  const handleSubmit = (values, { setSubmitting }) => {
    mapDataAndRouteToThankYouPage(insuranceType, values);
    updateFirstName(values.firstName);
    setSubmitting(true);
  };

  const setEntireFormIsValid = () => {
    const newFormHasBeenOpened = { ...hasFormBeenOpened };
    Object.keys(hasFormBeenOpened).forEach(
      formName => (newFormHasBeenOpened[formName] = true),
    );
    if (
      JSON.stringify(hasFormBeenOpened) !== JSON.stringify(newFormHasBeenOpened)
    ) {
      setFormHasBeenOpened(newFormHasBeenOpened);
    }
  };

  const initialFormValues = { ...initValues };
  return (

    <InsuranceTypeContext.Provider value={insuranceType}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        isInitialValid={() => {
          if (schema.isValidSync(initialFormValues)) {
            setEntireFormIsValid();
            return true;
          }
          return false;
        }}
        onSubmit={handleSubmit}
      >
        {getFormInterior}
      </Formik>
    </InsuranceTypeContext.Provider>
  );
};

GetGenericQuotePage.propTypes = {
  insuranceType: PropTypes.string,
  history: PropTypes.object,
  cognitoUserData: PropTypes.object,
  latestQuote: PropTypes.object,
  resources: PropTypes.object,
  travelResources: PropTypes.object,
};

export default GetGenericQuotePage;
