import PropTypes from 'prop-types';
import React, { useContext,useEffect } from 'react';

import { CognitoContext } from '../../../../CognitoUtils';
import { HOME_INSURANCE_TYPE } from '../../../../constants/insuranceTypeConstants';
import { getAccessToken,isAgent,isFieldError } from '../../../../helpers';
import AddressLookup from '../../../AddressLookup';
import { getAppliedAddress } from '../../../AddressLookup/addressLookupService';
import HomeAddressDetails from './HomeAddressDetails';

const HomeAddress = props => {
  const { values, errors, touched, setFieldValue } = props;
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    if (values.eircode) {
      const accessToken = isAgent() ? getAccessToken(cognitoUser) : null;
      getAppliedAddress(values.eircode, HOME_INSURANCE_TYPE, accessToken).then(res => {
        if (res.length === 1) handleOnSelect(res[0])
      });
    }
  }, [])

  const handleOnEdit = () => {
    setFieldValue("eircode", "");
    setFieldValue("addressLine1", "");
  }

  const handleOnSelect = addressResponse => {
    const keysToSkip = ['eircode', 'address_line1', 'address_line2', 'address_line3', 'town', 'county']
    Object.entries(addressResponse).forEach(([key, value]) => {
      if (!keysToSkip.includes(key))
        setFieldValue(key, value);
    })
    setFieldValue("eircode", addressResponse.eircode);
    setFieldValue("addressEircode", addressResponse.eircode);
    setFieldValue("addressLine1", addressResponse.address_line1);
    setFieldValue("addressLine2", addressResponse.address_line2);
    setFieldValue("addressLine3", addressResponse.address_line3);
    setFieldValue("addressTown", addressResponse.town);
    setFieldValue("addressCounty", addressResponse.county);
  };

  if (values.eircode) {
    return <HomeAddressDetails {...props} onEdit={handleOnEdit} />;
  } else {
    return (
      <AddressLookup
        type={HOME_INSURANCE_TYPE}
        onSelect={handleOnSelect}
        hasError={isFieldError('addressLine1', touched, errors)}
        errorMessage={errors.addressLine1}
      />
    );
  }
};

HomeAddress.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object
};

export default HomeAddress;
