"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _GlobeEuropeIcon = require("./GlobeEuropeIcon");

var _GlobeEuropeIcon2 = _interopRequireDefault(_GlobeEuropeIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _GlobeEuropeIcon2.default;