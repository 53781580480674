/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MtaThankYouSubmitClaimContent.scss';

import { Button, DesktopCard, Title } from '@arachas/core/lib';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { backToWallet } from '../../constants/buttonLabelConstants';
import { routes } from '../../routes';
import UserContext from '../../UserContext';

const className = 'c-MtaThankYouSubmitClaimContent';

const MtaThankYouSubmitClaimContent = () => {
  const { getUserName } = useContext(UserContext);


  const getTitleText = () => {
    const name = getUserName();
    return name ? `Thanks ${name},` : 'Thanks!';
  };

  return (
    <div>
      <DesktopCard>
        <div className={className}>
          <div className={`${className}__cancelPolicyPageTextContainer`}>
            <div className={`${className}__primaryTextContainerOne`}>
              <Title type="h1" weight="500">
                <span id="PaymentsThankYouPage__thankYouText">{getTitleText()}</span>
              </Title>
            </div>
            <div className={`${className}__primaryTextContainerTwo`}>
              Thank you for submitting your claims details. An agent will be in contact with you shortly to advise on next steps.
              Please be aware requests for claims must be processed by us before they can be confirmed and registered.
          </div>
          </div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.Wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToWallet}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </div>
  );
};

export default MtaThankYouSubmitClaimContent;
