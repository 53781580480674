"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var MAX_FILE_SIZE = exports.MAX_FILE_SIZE = 3145728;
var MAX_FILE_SIZE_STRING = "3MB"; //Types allowed backend [JPEG, PNG, PDF];

var fileTypeConvert = exports.fileTypeConvert = {
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'application/pdf': 'PDF'
};
var dropzoneLabel = exports.dropzoneLabel = "Files must be .jpeg .png or .pdf and no bigger than ".concat(MAX_FILE_SIZE_STRING, ".");
var ERROR_MESSAGES = exports.ERROR_MESSAGES = {
  BAD_FILE_TYPE: "The format of the file is invalid.",
  OVER_MAX_FILE_SIZE: "The file is more than the max file size of ".concat(MAX_FILE_SIZE_STRING, "."),
  DOCUMENT_NAME_NOT_SELECTED: "Document type dropdown must be selected",
  GENERIC_ERROR: "There was an error uploading the file",
  SCAN_ERROR_TIMEOUT: "The file is taking too long to scan",
  SCAN_ERROR_FAILURE: "The file scan failed this file may be compromised please use another document",
  SCAN_ERROR_INFECTED: "The file scan failed this file is infected",
  UPLOAD_ERROR_FAILURE: "The file can not be uploaded",
  DELETE_DOCUMENT_FAILED: "There was an error deleting the file"
};