/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import {
  AccordionText,
  AutoAddressAutocomplete,
  ButtonGroup,
} from '@arachas/core/lib';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import { commonFormStylesIdentifier } from '../../../../../constants/styleConstants';
import { isFieldError } from '../../../../../helpers';
import {
  inFormStructure,
  InsuranceTypeContext,
} from '../../../../../pages/GetGenericQuotePage';
import AddressLookup from '../../../../AddressLookup';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';
import AddressInfo from './AddressInfo';
import { Address } from './AddressInterface';

interface Props { }

export const BaseAddressDetails = (props: Props & FormikProps) => {
  const className = 'c-AddressDetails';
  const defaultAddressFieldPrefix = 'address';
  const postalAddressFieldPrefix = 'postalAddress';
  const {
    setFieldValue,
    setFieldTouched,
    setTouched,
    touched,
    values,
    errors,
    handleBlur,
    handleChange,
  } = props.formik;
  const insuranceType = useContext(InsuranceTypeContext);
  const selectedAddress = values.addressEircode
    ? {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressLine3: values.addressLine3,
      county: values.addressCounty,
      town: values.addressTown,
      eircode: values.addressEircode,
    }
    : null;

  const setSelectedAddress = (
    addressFieldNamesPrefix: string,
    address: Address
  ) => {
    const addressWithUpdatedFieldNames = {
      [`${addressFieldNamesPrefix}Line1`]: address.addressLine1,
      [`${addressFieldNamesPrefix}Line2`]: address.addressLine2,
      [`${addressFieldNamesPrefix}Line3`]: address.addressLine3,
      [`${addressFieldNamesPrefix}Town`]: address.town,
      [`${addressFieldNamesPrefix}County`]: address.county,
      [`${addressFieldNamesPrefix}Eircode`]: address.eircode,
    };
    Object.entries(addressWithUpdatedFieldNames).forEach(
      ([k, v]: [string, mixed]) => {
        setFieldValue(k, v || '');
      }
    );
    /* You only need to check if one of the read only
       fields has been touched to know if they all have */
    if (!touched[`${addressFieldNamesPrefix}Line1`]) {
      setReadOnlyAddressFieldsAsTouched(addressFieldNamesPrefix);
    }
  };

  const setReadOnlyAddressFieldsAsTouched = (
    addressFieldNamesPrefix: string
  ) => {
    const newTouched = { ...touched };
    newTouched[`${addressFieldNamesPrefix}Line1`] = true;
    newTouched[`${addressFieldNamesPrefix}Line2`] = true;
    newTouched[`${addressFieldNamesPrefix}Line3`] = true;
    newTouched[`${addressFieldNamesPrefix}Town`] = true;
    newTouched[`${addressFieldNamesPrefix}County`] = true;
    newTouched[`${addressFieldNamesPrefix}Eircode`] = true;
    setTouched(newTouched);
  };

  const getAddressFields = (addressFieldNamesPrefix: string) => {
    return (
      <AddressInfo
        fieldNamePrefix={addressFieldNamesPrefix}
        className={className}
        addressLine1={values[`${addressFieldNamesPrefix}Line1`]}
        addressLine2={values[`${addressFieldNamesPrefix}Line2`]}
        addressLine3={values[`${addressFieldNamesPrefix}Line3`]}
        town={values[`${addressFieldNamesPrefix}Town`]}
        county={values[`${addressFieldNamesPrefix}County`]}
        eircode={values[`${addressFieldNamesPrefix}Eircode`]}
      />
    );
  };

  const clearAddressFields = (addressFieldNamesPrefix: string) => {
    setFieldValue(`${addressFieldNamesPrefix}Line1`, '');
    setFieldValue(`${addressFieldNamesPrefix}Line2`, '');
    setFieldValue(`${addressFieldNamesPrefix}Line3`, '');
    setFieldValue(`${addressFieldNamesPrefix}Town`, '');
    setFieldValue(`${addressFieldNamesPrefix}County`, '');
    setFieldValue(`${addressFieldNamesPrefix}Eircode`, '');
  };

  const getDefaultAddressValue = (addressFieldNamesPrefix: string) => {
    return [
      values[`${addressFieldNamesPrefix}Line1`],
      values[`${addressFieldNamesPrefix}Line2`],
      values[`${addressFieldNamesPrefix}Line3`],
      values[`${addressFieldNamesPrefix}Line4`],
    ]
      .filter((e?: string) => !!e)
      .join(', ');
  };

  const getPostalAddress = () => {
    const postalAddressFieldName = `${postalAddressFieldPrefix}Eircode`;
    return values.isThisYourPostalAddress === false ? (
      <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={postalAddressFieldName}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            <AccordionText
              label="Enter full postal address or Eircode"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                {`An Eircode is a seven character
                  alpha-numeric code. For example A65 F4E2.
                  You can find your Eircode by entering your
                  home address at `}
                <a
                  href={'https://finder.eircode.ie'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'https://finder.eircode.ie'}
                </a>
              </div>
            </AccordionText>
          </label>
          <AutoAddressAutocomplete
            onSelect={(address: Address) =>
              setSelectedAddress(postalAddressFieldPrefix, address)
            }
            value={getDefaultAddressValue(postalAddressFieldPrefix)}
            name={postalAddressFieldName}
            errorMessage={errors[postalAddressFieldName]}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={isFieldError(postalAddressFieldName, touched, errors)}
          />
        </div>
        {values[postalAddressFieldName] !== '' &&
          !errors[postalAddressFieldName]
          ? getAddressFields(postalAddressFieldPrefix)
          : null}
      </>
    ) : null;
  };

  const handleOnSelect = (selectedAddress: any) => {

    const mappingField = {
      address_line1: `${defaultAddressFieldPrefix}Line1`,
      address_line2: `${defaultAddressFieldPrefix}Line2`,
      address_line3: `${defaultAddressFieldPrefix}Line3`,
      eircode: `${defaultAddressFieldPrefix}Eircode`,
      town: `${defaultAddressFieldPrefix}Town`,
      county: `${defaultAddressFieldPrefix}County`,
    };
    if (!selectedAddress) {
      Object.values(mappingField).forEach((fieldName: any) => setFieldValue(fieldName, ""));
    } else {
      Object.entries(selectedAddress).forEach(
        ([key: string, value: string]: any) => {
          setFieldValue(mappingField[key] || key, value);
        }
      );
    }
  };

  const insuranceOptions = [TRAVEL_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, VAN_INSURANCE_TYPE];

  const getCustomAddressLabel = () => {
    return insuranceType === VAN_INSURANCE_TYPE ?
      'Eircode/Address where you park your van over night'
      : 'Eircode';
  };

  const getAddressInputLabel = () => {
    return insuranceOptions.includes(insuranceType) ? (
      <label htmlFor="Eircode"
        className={`${commonFormStylesIdentifier}__fieldLabel`}>
        <AccordionText
          label={getCustomAddressLabel()}
          icon="info"
          iconAlign="right"
          customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
        >
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            {`An Eircode is a seven character
                  alpha-numeric code. For example A65 F4E2.
                  You can find your Eircode by entering your
                  home address at `}
            <a
              href={'https://finder.eircode.ie'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {'https://finder.eircode.ie'}
            </a>
          </div>
        </AccordionText>
      </label>
    ) : (<></>);
  };

  const getAddressInputField = () => {
    const mainAddressFieldName = `${defaultAddressFieldPrefix}Eircode`;
    return insuranceType === CAR_INSURANCE_TYPE ? (
      <AddressLookup
        type="motor"
        onSelect={handleOnSelect}
        selectedAddress={selectedAddress}
        errorMessage={errors[mainAddressFieldName]}
        hasError={isFieldError(mainAddressFieldName, touched, errors)}
      />
    ) : (
        <AutoAddressAutocomplete
          onSelect={(address: Address) =>
            setSelectedAddress(defaultAddressFieldPrefix, address)
          }
          value={getDefaultAddressValue(defaultAddressFieldPrefix)}
          name={mainAddressFieldName}
          errorMessage={errors[mainAddressFieldName]}
          handleBlur={handleBlur}
          handleChange={handleChange}
          hasError={isFieldError(mainAddressFieldName, touched, errors)}
        />
      );
  };

  const getMainAddress = () => {
    const mainAddressFieldName = `${defaultAddressFieldPrefix}Eircode`;
    return (
      <>
        {getAddressInputLabel()}
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          {getAddressInputField()}
        </div>
        {values[mainAddressFieldName] !== '' && !errors[mainAddressFieldName]
          ? getAddressFields(defaultAddressFieldPrefix)
          : null}
      </>
    );
  };

  return (
    <div className={className}>
      {getMainAddress()}
      {inFormStructure(
        'isThisYourPostalAddress',
        insuranceType,
        aboutYouFormIdentifier
      ) ? (
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="isThisYourPostalAddress"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              Is this your postal address
          </label>
            <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
              <ButtonGroup
                name="isThisYourPostalAddress"
                onClick={(value: string) => {
                  if (value === 'true') {
                    clearAddressFields(postalAddressFieldPrefix);
                  }
                  setFieldValue('isThisYourPostalAddress', value === 'true');
                  if (!touched.isThisYourPostalAddress) {
                    setFieldTouched('isThisYourPostalAddress', true);
                  }
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true },
                ]}
                value={values.isThisYourPostalAddress}
                touched={touched.isThisYourPostalAddress}
                selected={values.isThisYourPostalAddress}
                error={isFieldError('isThisYourPostalAddress', touched, errors)}
                errorMessage={errors.isThisYourPostalAddress}
              />
            </div>
          </div>
        ) : null}
      {getPostalAddress()}
    </div>
  );
};

BaseAddressDetails.propTypes = {
  formik: PropTypes.object,
};

const AddressDetails = connect(BaseAddressDetails);

export default AddressDetails;
