"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./Dropdown.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _InputErrorMessage = require("../InputErrorMessage");

var _InputErrorMessage2 = _interopRequireDefault(_InputErrorMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/** CSS class for component (B in BEM) */
var componentClassName = 'c-Dropdown';

var Dropdown = function Dropdown(_ref) {
  var error = _ref.error,
      errorMessage = _ref.errorMessage,
      children = _ref.children,
      selectProps = _objectWithoutProperties(_ref, ["error", "errorMessage", "children"]);

  var activeClassNames = [componentClassName, error ? "".concat(componentClassName, "--error") : ''].join(' ');
  return _react2.default.createElement("div", null, _react2.default.createElement(_InputErrorMessage2.default, {
    id: "".concat(selectProps.name, "ErrorMessage"),
    error: error,
    errorMessage: errorMessage
  }), _react2.default.createElement("select", _extends({
    className: activeClassNames,
    id: selectProps.name
  }, selectProps), children));
};

exports.default = Dropdown;