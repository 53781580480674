/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
export const yourDeviceFormInitialValues = {
  'network': '',
  'serialNumber': '',
  'deviceType': '',
  'deviceMake': '',
  'deviceModel': '',
  'id': '',
  'device_type': '',
  'manufacturer': '',
  'model': '',
  'monthly_price': '',
  'annual_price': '',
  'effective_date': '',
};

export const yourDeviceFormFieldsList = [
  'network',
  'serialNumber',
  'deviceType',
  'deviceMake',
  'deviceModel',
  'id',
  'device_type',
  'manufacturer',
  'model',
  'monthly_price',
  'annual_price',
  'effective_date',
];
