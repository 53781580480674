export const travelInsuranceAboutYouFormFields = [
  'title',
  'firstName',
  'lastName',
  'dob',
  'coverAbroad',
  'provider',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressCounty',
  'addressEircode',
  'phoneNo',
  'email',
  'promoCode'
];

export const travelInsuranceYourTravelFormFields = [
  'tripType',
  'travelFromDate',
  'travelToDate',
  'destinationZone',
  'destinationCountry',
  'insuredPerson',
  'numOfChildren',
  'partnerFirstName',
  'partnerLastName',
  'partnerDOB'
];

export const travelInsuranceYourTravelCoverFormFields = [
  'carHireExcess',
  'winterSportsExcess',
  // 'businessExpenseExcess',
  'dataProtectionConsent',
  'chubbTOBConsent',
  'marketingConsent',
];
