export const getOptions = (onChange, setCardBrand, insuranceType, setInvalidFields) => ({
  environment: process.env.REACT_APP_PAYSAFE_ENV,
  onChange,
  setCardBrand,
  insuranceType,
  setInvalidFields,
  fields: {
    cardNumber: {
      selector: "#card-number",
      placeholder: "Card number"
    },
    expiryDate: {
      selector: "#expiration-date",
      placeholder: "Expiry date"
    },
    cvv: {
      selector: "#cvv",
      placeholder: "CVV"
    }
  },
  style: {
    input: {
      "font-family": "Montserrat,Roboto,Tahoma,Helvetica,Arial,sans-serif",
      "font-size": "14px"
    }
  }
});
