"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Typeahead = require("./Typeahead");

var _Typeahead2 = _interopRequireDefault(_Typeahead);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Typeahead2.default;