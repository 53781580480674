"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _MarginWrapper = require("./MarginWrapper");

var _MarginWrapper2 = _interopRequireDefault(_MarginWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _MarginWrapper2.default;