"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PawIcon = require("./PawIcon");

var _PawIcon2 = _interopRequireDefault(_PawIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PawIcon2.default;