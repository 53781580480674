// @flow
import './NavigationGroup.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { NavigationItem } from '../NavigationItem';

const className = 'c-NavigationGroup';

const getNavigationLink = (displayText: string, link: string, index: number) => (
  <Link className={`${className}__item`} key={`navigation-group-item-${index}`} to={link}>
    {displayText}
  </Link>
);

const getNavigationActionItem = (displayText: string, action: () => mixed, index: number) => (
  <div className={`${className}__item`} onClick={action} key={`navigation-group-item-${index}`}>
    {displayText}
  </div>
);

const getNavigationEmptyItem = (displayText: string, index: number) => (
  <div className={`${className}__item`} key={`navigation-group-item-${index}`}>
    {displayText}
  </div>
);

const getNavigationItemComponent = (item: NavigationItem, index: number) => {
  const { displayText, link, Component } = item;
  if (Component && link)
    return (
      <Component key={`navigation-group-item-${index}`}>
        <Link className={`${className}__item`} key={`navigation-group-item-${index}`} to={link}>
          {displayText}
        </Link>
      </Component>);
  else return <></>;
};

const getNavigationItem = (item: NavigationItem, index: number) => {
  if (item.Component) return getNavigationItemComponent(item, index);
  if (item.link) return getNavigationLink(item.displayText, item.link, index);
  else if (item.action)
    return getNavigationActionItem(item.displayText, item.action, index);
  return getNavigationEmptyItem(item.displayText, index);
};

interface Props {
  menuItems: Array<NavigationItem>,
  id: string;
}

const NavigationGroup = ({ menuItems, id }: Props) => (
  <div className={`${className}`} id={id}>{menuItems.map(getNavigationItem)}</div>
);

export default NavigationGroup;
