import { getItemFromSessionStorage, makeRequest } from '../../helpers';

export const getDocumentLinks = async (cognitoAccessTokenKey, url, props = {}) => {
  // Make request and get links from Chubb here
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return makeRequest({
    method: 'GET',
    url: `${basePath}${url}`,
    authToken: authToken,
    history: props.history
  });
};