// @flow

import '../PremiumBreakdown.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionCard } from '@arachas/core/lib';
import React from 'react';

import { PremiumBreakdownConfigs } from '../../../configs/PremiumBreakdownConfigs';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';

interface Props {
  totalPremium: number;
  premiumName: string;
  serviceFee: string;
  winterSportsCover?: number;
  carExcessCover?: number;
  businessCover?: number;
  discount?: number;
  totalPayable: number;
  promoMessage: string;
}

const className = 'c-PremiumBreakdown';

const levyText =
  'The above includes a 5% government levy and €1 stamp duty where applicable.';

const TravelPremiumBreakdown = ({
  totalPremium,
  totalPayable,
  premiumName,
  serviceFee,
  discount = 0,
  winterSportsCover = 0,
  carExcessCover = 0,
  businessCover = 0,
  promoMessage = '',
}: Props) => {
  const showOptionalCoverBreakdown = () => {
    if (
      winterSportsCover === 0 &&
      carExcessCover === 0 &&
      businessCover === 0
    ) {
      return null;
    }
    const showWinterSports = () =>
      winterSportsCover > 0 ? (
        <PremiumBreakdownRow
          label={`Winter sports`}
          price={winterSportsCover}
        />
      ) : null;
    const showCarExcessCover = () =>
      carExcessCover > 0 ? (
        <PremiumBreakdownRow label={`Car excess hire`} price={carExcessCover} />
      ) : null;
    const showBusinessCover = () =>
      businessCover > 0 ? (
        <PremiumBreakdownRow label={'Business'} price={businessCover} />
      ) : null;

    return (
      <>
        <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />

        <PremiumBreakdownRow label={`Optional extras`} bold={true} />
        {showWinterSports()}
        {showCarExcessCover()}
        {showBusinessCover()}

      </>
    );
  };

  return (
    <AccordionCard
      id={`TravelPremiumBreakdown__viewPremium`}
      label="View premium breakdown"
      status="none"
      labelStyle="black"
      on={true}
      toggleByDefault={!PremiumBreakdownConfigs.accordionCollapse.travel}
      childContainerClass={`${className}__noPaddingAccordion`}
      customLabelClass={`${className}__accordionLabel`}
    >
      <div className={`${className}`} id={'TravelBreakdown'}>
        <div className={`${className}__section`}>
          <PremiumBreakdownRow label={`Main cover`} bold={true} />
          <PremiumBreakdownRow
            label={`Policy type`}
            textValue={premiumName}
            prefix={''}
          />
          <PremiumBreakdownRow label={`Total premium`} price={totalPremium} />
          {discount > 0 && (
            <PremiumBreakdownRow
              label={'Includes Total Bank Discount of'}
              price={discount}
              suffix={'%'}
              prefix={''}
              decimalPlaces={0}
            />
          )}
          {showOptionalCoverBreakdown()}
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />

          <PremiumBreakdownRow
            label={`Broker Service Fee`}
            price={serviceFee}
            bold={true}
          />
          <PremiumBreakdownRow
            label={'Total payable'}
            price={totalPayable}
            bold={true}
          />
          {promoMessage !== '' ?
            <PremiumBreakdownRow label={promoMessage} /> :
            <></>
          }
          <PremiumBreakdownRow label={levyText} />
        </div>
      </div>
    </AccordionCard>
  );
};

export default TravelPremiumBreakdown;
