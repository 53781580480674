/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import type { Node } from 'react';
import React, { createContext,useReducer } from 'react';

import { BOI_WALLET_USER_NAME_STORAGE } from './constants'
import { getItemFromSessionStorage } from './helpers';

type ActionTypes = 'setFirstName' | 'default';

type State = {
  firstName: string
};

type Action = {
  type: ActionTypes,
  payload: {
    firstName: string
  }
};

type ActionsObject = {
  [key: ActionTypes]: () => State
};

type Props = { 
  children: Node,
  initialValue?: {
    firstName: string
  };
};

const emptyFirstName: State = { firstName: '' };

const UserContext = createContext<any>([
  emptyFirstName,
  (a: Action) => {
    reducer(emptyFirstName, a);
  }
]);

const reducer = (state: State, action: Action) => {
  const actions: ActionsObject = {
    setFirstName: () => ({
      ...state,
      firstName: action.payload.firstName
    }),
    default: () => state
  };

  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type]();
  }

  return actions.default();
};

export const UserContextProvider = ({ children, initialValue }: Props) => {
  const initalState = initialValue ? initialValue : { firstName: '' };

  const [user, userDispatch] = useReducer(reducer, initalState);

  const getNameFromStorage = () => {
    return getItemFromSessionStorage(BOI_WALLET_USER_NAME_STORAGE);
  };

  const getUserName = () => {
    if (user.firstName) return user.firstName;
    const storageName = getNameFromStorage();
    if (storageName) {
      userDispatch({
        type: 'setFirstName',
        payload: { firstName: storageName }
      });
      return storageName;
    }
    return '';
  };

  const value = {
    getUserName,
    user,
    userDispatch,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
