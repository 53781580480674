
import { commonFormStylesIdentifier } from '../../../../constants';

export const getFieldActiveClassNames = (isDynamicField, isSubFormComponent) => {
  const classNames = [`${commonFormStylesIdentifier}__fieldContainer`];
  if (isDynamicField) {
    classNames.push(isSubFormComponent ? `${commonFormStylesIdentifier}__dynamicFieldsContainer--with-no-padding` : `${commonFormStylesIdentifier}__dynamicFieldsContainer`);
  } else if (!isSubFormComponent) {
    classNames.push(`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`);
  }
  return classNames.join(' ');
};