// Generic Storage Identifiers
export const GET_QUOTE_RESPONSE_STORAGE = 'GetQuoteResponse';
export const SUBMITTED_GET_QUOTE_STORAGE = 'SubmittedGetQuote';
export const GET_QUOTE_VALUES_STORAGE = 'GetQuoteValues';
export const RECALCULATED_QUOTE_VALUES_STORAGE = 'RecalculatedQuoteValues';
export const DIRECT_DEBIT_STORAGE = 'DirectDebit';
export const EMAIL_TO_REGISTER_STORAGE = 'EmailToRegister';
export const LOCATION_HISTORY_STORAGE = 'locationHistory';
export const SINGLE_SCHEMA_STORAGE = 'SingleSchema';
export const SINGLE_POLICY_STORAGE = 'SinglePolicy';
export const LOGIN_DESTINATION = 'loginDestination';
export const URL_PROMOCODE = 'UrlPromocode';
export const ASSUMPTIONS = 'Assumptions';
export const CONSENT_KEY = 'CONSENT_KEY'
