import * as yup from 'yup';

export default yup.object().shape({
  cardIsValid: yup.boolean().oneOf([true]).required(),
  nameOnCard: yup
    .string()
    .matches(/^[a-z ]+$/i, 'Only letters are allowed')
    .min(2, "Please enter more than 2 characters")
    .max(50, "Only a maximum of 50 characters")
    .required('Please enter name on card.')
});
