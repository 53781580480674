(function() {
  const getUrlPath = () => window.location.hash.replace(/(\/)?#\//, "");

  window.dataLayer.push({
    'event': 'virtualPageview', 'urlPath': `/${getUrlPath()}`
  });

  window.addEventListener("hashchange", () => {
    window.dataLayer.push({
      'event': 'virtualPageview', 'urlPath': `/${getUrlPath()}`
    });
  });
})();
