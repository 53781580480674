"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var settings = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};
/**
 * Creates query string for GET request
 * @param data to be converted to query
 * @returns encoded string for fetch
 */

var generateQueryString = function generateQueryString(data) {
  return Object.entries(data).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return encodeURI("".concat(key, "=").concat(value));
  }).join('&');
};

var fetchData = function fetchData(url) {
  return regeneratorRuntime.async(function fetchData$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          return _context.abrupt("return", fetch(url, settings).then(function (response) {
            if (response.status === 200) {
              return response.json();
            } else if (response.status === 500) {
              //this an error too but sends an error message
              return response.json();
            } else {
              return 'theres been an error';
            }
          }).catch(function (e) {
            return e;
          }));

        case 1:
        case "end":
          return _context.stop();
      }
    }
  });
};
/**
 *
 * @param code County name (Co. Meath) or dublin postal district (Dublin 1)
 * @returns Cleaned county name (Meath/Dublin)
 */


var getCountyName = function getCountyName(code) {
  return code.replace(/^Co\.\s*/gi, '').replace(/\s\d+w?$/i, '').trim();
};

var setFirstLetterCapitalized = function setFirstLetterCapitalized() {
  var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  field = field.toLowerCase();
  if (field) return field[0].toUpperCase() + field.slice(1);else return field;
};
/**
 * @param reformattedAddress array of address details returned from auto address
 * @param eircode
 * @returns formated address object
 */


var getAddressObject = function getAddressObject(reformattedAddress, eircode, vanityAddress, postalAddress) {
  var _reformattedAddress = _slicedToArray(reformattedAddress, 5),
      addressLine1 = _reformattedAddress[0],
      addressLine2 = _reformattedAddress[1],
      addressLine3 = _reformattedAddress[2],
      townOrPostal = _reformattedAddress[3],
      countyName = _reformattedAddress[4];

  var county = countyName ? getCountyName(countyName) : getCountyName(townOrPostal);
  return {
    addressLine1: addressLine1,
    addressLine2: addressLine2 || setFirstLetterCapitalized(postalAddress[1]),
    addressLine3: addressLine3 || setFirstLetterCapitalized(postalAddress[2]),
    county: county,
    eircode: eircode,
    town: townOrPostal,
    displayAddress: vanityAddress.join(', ')
  };
};
/**
 * @param address eircode or address query
 * @returns list of possible addresses
 */


var getAddress = exports.getAddress = function getAddress(address) {
  var queryString, data, options;
  return regeneratorRuntime.async(function getAddress$(_context2) {
    while (1) {
      switch (_context2.prev = _context2.next) {
        case 0:
          queryString = generateQueryString({
            key: process.env.REACT_APP_AUTOADDRESS_KEY || "",
            address: address,
            addressProfileName: 'DemoApi5Line',
            vanityMode: 'true'
          });
          _context2.next = 3;
          return regeneratorRuntime.awrap(fetchData("https://api.autoaddress.ie/2.0/autocomplete?".concat(queryString)));

        case 3:
          data = _context2.sent;

          if (!data.reformattedAddress) {
            _context2.next = 6;
            break;
          }

          return _context2.abrupt("return", getAddressObject(data.reformattedAddress, data.postcode, data.vanityAddress, data.postalAddress));

        case 6:
          options = data.options.map(function (address) {
            return {
              text: address.displayName,
              key: address.links[0].href
            };
          });
          return _context2.abrupt("return", {
            options: options,
            totalOptions: data.totalOptions
          });

        case 8:
        case "end":
          return _context2.stop();
      }
    }
  });
};
/**
 * @param link received from auto address
 * @returns address data or array of addresses or groups
 */


var getAddressesFromLink = exports.getAddressesFromLink = function getAddressesFromLink(link) {
  var data;
  return regeneratorRuntime.async(function getAddressesFromLink$(_context3) {
    while (1) {
      switch (_context3.prev = _context3.next) {
        case 0:
          _context3.next = 2;
          return regeneratorRuntime.awrap(fetchData(link));

        case 2:
          data = _context3.sent;

          if (!(data.options.length > 0)) {
            _context3.next = 7;
            break;
          }

          return _context3.abrupt("return", {
            options: data.options.map(function (address) {
              return {
                text: address.displayName,
                key: address.links[0].href
              };
            }),
            totalOptions: data.totalOptions
          });

        case 7:
          return _context3.abrupt("return", getAddressObject(data.reformattedAddress, data.postcode, data.vanityAddress, data.postalAddress));

        case 8:
        case "end":
          return _context3.stop();
      }
    }
  });
};