import { makeRequest } from '../../helpers';

const basePath = process.env.REACT_APP_API_ENDPOINT || '';

export const getDeviceTypes = async () => {
  const devices = await makeRequest({
    url: `${basePath}/device/lookup/devices`,
  });
  return devices.map(({ device_type }) => ({
    displayName: device_type,
    dropdownValue: device_type,
  }))
};

export const getDeviceMake = async (type) => {
  const devices = await makeRequest({
    url: `${basePath}/device/lookup/devices?deviceType=${type}`,
  });
  return devices.map(({ manufacturer }) => ({
    displayName: manufacturer,
    dropdownValue: manufacturer,
  }))
};

export const getDeviceData = async (type, make) => {
  return await makeRequest({
    url: `${basePath}/device/lookup/devices?deviceType=${type}&manufacturer=${make}`,
  });
};
