import * as yup from "yup";

import { ZONE_IDS } from '../../../constants/travel/TravelConstants';
import { allowedDriverAge } from '../../../helpers/DateHelper';

export const yourTravelCoverFormYupSchema = {
  excess: yup.array().of(yup.number()).required(),
  carHireExcess: yup.boolean().nullable()
    .when(['destinationZone', 'dob'], (destinationZone, dob, schema) => {
      if (destinationZone === ZONE_IDS['Europe'] && allowedDriverAge(dob)) {
        return schema.required('This field is required');
      }
    }),
  winterSportsExcess: yup.boolean().nullable().required('This field is required'),
  // businessExpenseExcess: yup.boolean().nullable().required('This field is required'),
  dataProtectionConsent: yup.boolean()
    .oneOf([true], 'You must accept the Terms and Conditions'),
  chubbTOBConsent: yup.boolean()
    .oneOf([true], 'You must accept the Chubb Terms of Business and Privacy Policy.'),
  marketingConsent: yup.boolean().oneOf([true], '')
    .test('marketing-consent-function-motor',
      'You must select a method of contact', function () {
        return this.parent.emailConsent ||
          this.parent.postConsent ||
          this.parent.phoneConsent ||
          this.parent.smsConsent;
      })
};

export default yourTravelCoverFormYupSchema;
