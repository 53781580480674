"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _HomeIcon = require("./HomeIcon");

var _HomeIcon2 = _interopRequireDefault(_HomeIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _HomeIcon2.default;