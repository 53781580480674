"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _EnvelopeIcon = require("./EnvelopeIcon");

var _EnvelopeIcon2 = _interopRequireDefault(_EnvelopeIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _EnvelopeIcon2.default;