import {
    internationalTravelInsurancePhoneNumber,
    localDeviceInsurancePhoneNumber,
    localTravelInsurancePhoneNumber,
} from '../../constants/phoneNumbers';

export const travel = {
    textOne: 'To make a Claim please phone or write to travel claims within thirty days of the incident, or as soon as possible afterwards and provide Your name, address and policy number. We do not recommend you send financial or personal sensitive details via email as it may not be secure whilst in the public domain.',
    textTwo: 'Please follow the link to access all claims forms',
    addLineOne: 'Address',
    addLineTwo: 'Travel Insurance Claims',
    addLineThree: 'Sedwick',
    addLineFour: 'Merrion Hall',
    addLineFive: 'Strand Road',
    addLineSix: 'Sandymount',
    addLineSeven: 'Dublin 4',
    phoneOne: localTravelInsurancePhoneNumber,
    phoneTwo: internationalTravelInsurancePhoneNumber
};

export const device = {
    textOne: 'To speak to us about a claim please call us on',
    phoneOne: localDeviceInsurancePhoneNumber
};