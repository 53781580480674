"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PersonTwoIcon = require("./PersonTwoIcon");

var _PersonTwoIcon2 = _interopRequireDefault(_PersonTwoIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PersonTwoIcon2.default;