/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './HelpPage.scss';

import { DesktopCard } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { MobileBackButton } from '../../components/MobileBackButton';
import { CAR_INSURANCE_TYPE, commonPageStylesIdentifier, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants';
import HelpDetails from './HelpDetails';
import HelpIcon from './HelpIcon';

const HelpPage = (props) => {
  const { history } = props;
  const className = 'c-HelpPage';

  return (
    <>
      <DesktopCard>
        <div className={className}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          <div className={`${className}__iconContainer`}>
            <div className={`${className}__icon`}>
              <HelpIcon />
            </div>
            <div className={`${className}__title`}>Help</div>
          </div>
          <div className={`${className}__accordions`}>
            <HelpDetails insuranceType={HOME_INSURANCE_TYPE} />
            <HelpDetails insuranceType={CAR_INSURANCE_TYPE} />
            <HelpDetails insuranceType={TRAVEL_INSURANCE_TYPE} />
            <HelpDetails insuranceType={VAN_INSURANCE_TYPE} />
          </div>
        </div>
      </DesktopCard ></>
  );
};

HelpPage.propTypes = {
  insuranceType: PropTypes.object,
  history: PropTypes.object
};

export default HelpPage;