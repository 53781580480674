"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ArrowIcon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ArrowIcon(_ref) {
  var isOpen = _ref.isOpen,
      horizontal = _ref.horizontal;
  var rotate = horizontal ? isOpen ? 'rotate(270)' : 'rotate(90)' : isOpen ? 'rotate(180)' : 'rotate(0)';
  return _react2.default.createElement("svg", {
    viewBox: "0 0 20 20",
    preserveAspectRatio: "none",
    fill: "transparent",
    stroke: "#979797",
    strokeWidth: "3px",
    transform: rotate
  }, _react2.default.createElement("path", {
    d: "M1,6 L10,15 L19,6"
  }));
}