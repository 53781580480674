/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../../../../styles/commonFormStyles.scss';

import { InputField } from "@arachas/core/lib";
import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants/styleConstants';
import { isFieldError } from '../../../../../helpers/FieldErrorHelper';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import ResourceDropdown from '../../../../ResourceDropdown';
import { aboutYouFormIdentifier } from '../../../CommonFiles/commonFormConstants';
import { isOneOfCompanyTypes, isOneOfTradingTypes } from '../../AboutYouForm/aboutYouFormYupSchema';

export const BaseProposerCompanyDetails = (props) => {
  const className = 'c-ContactDetails';
  const {
    errors, handleChange, handleBlur, values, touched, setFieldValue,
    setFieldTouched
  } = props.formik;

  const { resources,
    insuranceType } = props;

  const handleChangeResourceProposerType = (value, name) => {
    if (values.proposerType) {
      setFieldValue('tradingAs', '');
      setFieldValue('companyName', '');
      setFieldTouched('tradingAs', false);
      setFieldTouched('companyName', false);
    }
    setFieldValue(name, value || {});
  };

  return (
    <div className={className}>
      {inFormStructure('proposerType', insuranceType, aboutYouFormIdentifier) &&
        <>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor="proposerType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Proposer Type
            </label>
            <ResourceDropdown
              placeholder="Select here"
              error={isFieldError('proposerType', touched, errors)}
              errorMessage={errors.proposerType}
              name="proposerType"
              value={values.proposerType}
              onChange={handleChangeResourceProposerType}
              onBlur={handleBlur}
              values={resources.proposer_type}
            />
          </div>
          {isOneOfTradingTypes(values.proposerType.alias) ?
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor="tradingAs" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Trading As
              </label>
              <InputField
                placeholder="Type here"
                error={isFieldError('tradingAs', touched, errors)}
                errorMessage={errors.tradingAs}
                name="tradingAs"
                value={values.tradingAs}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text" />
            </div> :
            <></>
          }
          {isOneOfCompanyTypes(values.proposerType.alias) ?
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor="companyName" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Company Name
              </label>
              <InputField
                placeholder="Type here"
                error={isFieldError('companyName', touched, errors)}
                errorMessage={errors.companyName}
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text" />
            </div> :
            <></>
          }
        </>
      }
    </div>
  );
};

BaseProposerCompanyDetails.propTypes = {
  formik: PropTypes.object,
  insuranceType: PropTypes.string,
  resources: PropTypes.object,
  handleChangeResource: PropTypes.func
};

const ProposerCompanyDetails = connect(BaseProposerCompanyDetails);
export default ProposerCompanyDetails;
