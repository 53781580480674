
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ManageInsuranceProductPage.scss';

import { DesktopCard } from '@arachas/core/lib';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import IconAndTitle from '../../components/IconAndTitle/IconAndTitle';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants';
import { dustyGrayColor } from '../../constants/colors';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, getInsurancePageTitle, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';
import { carButtons, deviceButtons, homeButtons, travelButtons, vanButtons } from './PageButtons';

const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}

const ManageInsuranceProductPage = (props: Props) => {
  const className = 'c-ManageInsuranceProductPage';
  const { match, history } = props;
  const insuranceType = match.params.type || '';

  const insuranceTypeButtons = {
    [HOME_INSURANCE_TYPE]: homeButtons,
    [CAR_INSURANCE_TYPE]: carButtons,
    [TRAVEL_INSURANCE_TYPE]: travelButtons,
    [DEVICE_INSURANCE_TYPE]: deviceButtons,
    [VAN_INSURANCE_TYPE]: vanButtons,
    '': ''
  };

  const checkFormStructure = (
    field: any,
    insuranceType: any,
  ) => {
    if (insuranceTypeButtons[insuranceType] !== undefined)
      return insuranceTypeButtons[insuranceType].lastIndexOf(field) !== -1;
    return false;
  };

  const renderDocuments = () => {
    return (
      checkFormStructure('viewDocuments', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.policyLinks.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__viewDocumentsButton'}
            IconRight={RightArrowIcon}
          >
            View documents
          </ButtonWithIcons>
        </Link>
      </div>
    );
  };

  const renderViewDocumentsLandingPage = () => {
    return (
      checkFormStructure('viewDocumentsLandingPage', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.viewDocumentsLandingPage.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__viewDocumentsLandingPagButton'}
            IconRight={RightArrowIcon}
          >
            View documents
          </ButtonWithIcons>
        </Link>
      </div>
    );
  };

  const renderBreakdownAssistanceButton = () => {
    return (
      checkFormStructure('breakdownAssistance', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.breakdownAssistance.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__breakdownAssistanceButton'}
            IconRight={RightArrowIcon}
          >
            Breakdown assistance
        </ButtonWithIcons>
        </Link>
      </div>
    );
  };

  const renderMedicalAssistance = () => {
    return (

      checkFormStructure('medicalAssistance', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.medicalAssistance.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__medicalAssistanceButton'}
            IconRight={RightArrowIcon}
          >
            Medical assistance
          </ButtonWithIcons>
        </Link>
      </div>
    );
  };

  const renderSubmitAClaim = () => {
    return (
      checkFormStructure('submitClaim', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.submitClaimPage.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__submitClaimButton'}
            IconRight={RightArrowIcon}
          >
            Submit a claim
          </ButtonWithIcons>
        </Link>
      </div>

    );
  };

  const renderRenewClaim = () => {
    return (
      checkFormStructure('renewPolciy', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.renewPolicyPage.url}${insuranceType}`}>
          <ButtonWithIcons
            id="ManageInsuranceProductPage__renewButton"
            IconRight={RightArrowIcon}
          >
            Renew
          </ButtonWithIcons>
        </Link>
      </div>
    );
  };

  const renderAmendClaim = () => {
    return (
      checkFormStructure('amendPolicy', insuranceType) &&
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.amendPolicyPage.url}${insuranceType}`}>
          <ButtonWithIcons
            id="ManageInsuranceProductPage__amendMyPolicyButton"
            IconRight={RightArrowIcon}
          >
            Amend my policy
          </ButtonWithIcons>
        </Link>
      </div>
    );
  };

  return (
    <div className={className}>
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {getInsurancePageTitle[insuranceType]}
        </div>
      </div>
      <DesktopCard>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <IconAndTitle insuranceType={insuranceType} />
        <div className={`${className}__buttonContainer`}>
          {renderDocuments()}
          {renderViewDocumentsLandingPage()}
          {renderBreakdownAssistanceButton()}
          {renderMedicalAssistance()}
          {renderSubmitAClaim()}
          {renderRenewClaim()}
          {renderAmendClaim()}
          {/* TODO: @amanda - this part will be needed */}
          {/* <div className={`${className}__buttonSpacing`}>
          <Link to='#'>
            <ButtonWithIcons 
              id="ManageInsuranceProductPage__myInsuranceProfileButton"
              iconRight={RightArrowIcon}
            >
              My insurance profile
            </ButtonWithIcons>
          </Link>
        </div> */}
        </div>
      </DesktopCard>
    </div>
  );
};

export default withRouter(ManageInsuranceProductPage);