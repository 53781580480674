/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import './index.scss';

import type { Node } from 'react';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LOCATION_HISTORY_STORAGE } from '../../constants';
import {
  getItemFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import  { fbqTrackEvent } from '../../scripts/facebookPixelEvents'; 
import ErrorBoundary from '../ErrorBoundary';
import MobileHeader from '../MobileHeader';
interface AppProps {
  children?: Node;
}

const App = ({ children }: AppProps) => {
  const className = 'c-App';
  const history = useHistory() 
  const location = useLocation();


  useEffect(() => {

     // eslint-disable-next-line no-unused-vars
     return history.listen((location: any)  => {
      fbqTrackEvent('PageView')
      onUrlChange();
     })
  },[history]) 

  const onUrlChange = () => {
    const data = JSON.parse(getItemFromSessionStorage(LOCATION_HISTORY_STORAGE));
    // If the pathname hasn't been changed don't add an entry to the location history
    if (data && location.pathname === data[data.length - 1]) return;

    const newData = data ? [...data, location.pathname] : [location.pathname];
    saveInSessionStorage(LOCATION_HISTORY_STORAGE, JSON.stringify(newData));
  };

  return (
    <ErrorBoundary>
      <div className={`${className}`} id="App">
        {/*Leaving here for now as may be needed again - Mobile Header Now being used for all site: desktop + Mobile */}
        {/* {isMobile ?
          <MobileHeader routes={routes} /> :
          <DesktopHeader routes={routes} />
        } */}
        <MobileHeader routes={routes} />
        <div className={`${className}__childrenContainer`}>{children}</div>
      </div>
    </ErrorBoundary>
  );
};

export default App;
