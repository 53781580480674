/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './GetVanRegPage.scss';
import '../../styles/commonPageStyles.scss';

import { Button, Title } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from "prop-types";
import React from 'react';
import * as yup from 'yup';

import CarRegLookup from '../../components/CarRegLookup';
import { IconAndTitle } from '../../components/IconAndTitle';
import { MobileBackButton } from '../../components/MobileBackButton';
import { VAN_INSURANCE_TYPE } from '../../constants';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { GET_QUOTE_VALUES_STORAGE } from '../../constants';
import {
  isFieldError,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';


const GetVanRegPageForm = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    history
  } = props;

  const insuranceType = 'van';

  const goToGenericQuotePage = () => {
    saveInSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    props.history.push({
      pathname: `${routes.getGenericQuotePage.url}${insuranceType}`,
      state: {
        insuranceType: insuranceType,
      }
    });
  };

  const titleText = 'Get a Quote';
  const className = 'c-GetVanRegPage';
  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>

      <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
        <IconAndTitle insuranceType={VAN_INSURANCE_TYPE} title={titleText} />
      </div>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <div className={`${className}__container`}>
          <div className={`${className}__innerContent`}>
            <Title align="left" type="h1" weight="weight500" variant="greyBrown">
              {titleText}
            </Title>
          </div>
        </div>
      </div>

      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <Form className={`${className}__form`}>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <span className={`${className}__input`}>
                <CarRegLookup
                  compressedView
                  values={values}
                  name="vanReg"
                  errors={errors}
                  errorMessage={errors.vanReg}
                  placeholder="Type here"
                  onChange={handleChange}
                  touched={touched}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  isFieldError={isFieldError}
                  insuranceType={insuranceType}
                />
              </span>
            </div>
            <div className={`${className}__button`}>
              <Button
                id="GetVanRegPage__nextButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={() => {
                  goToGenericQuotePage();
                }}
                type="submit"
              >
                NEXT
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div >
  );
};

const GetVanRegPage = withFormik({
  mapPropsToValues(props) {
    let initialValues = {
      vanReg: '',
      vanMake: '',
      vanModel: '',
      vanType: '',
      vanYear: '',
      vanWeight: '',
      vanDescription: ''
    };
    if (props.location && props.location.state && props.location.state.vanReg) {
      initialValues.vanReg = props.location.state.vanReg;
    }
    const valuesObject = {};
    Object.keys(initialValues).forEach((key) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    vanReg: yup
      .string()
      .required('Van registration is required'),
    vanMake: yup
      .string()
      .required('Van make is required'),
    vanModel: yup
      .string()
      .required('Van model is required'),
    vanType: yup
      .string()
      .required('Van body type is required'),
    vanYear: yup
      .string()
      .required('Van year is required'),
    vanWeight: yup
      .string()
      .required('Van payload weight is required'),
    vanDescription: yup
      .string()
      .required('Van description is required')
  }),
  displayName: 'GetVanRegPageForm'
})(GetVanRegPageForm);

GetVanRegPageForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  isValid: PropTypes.func,
  dirty: PropTypes.bool,
  history: PropTypes.object
};

export default GetVanRegPage;
