import { TitleWithUnderLine } from '@arachas/core/lib';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';

import QuoteDisplay from '../../../components/QuoteDisplay';
import ThreeStateToggle from '../../../components/ThreeStateToggle';
import {
  VAN_INSURANCE_TYPE
} from '../../../constants';
import { commonPageStylesIdentifier } from '../../../constants/styleConstants';
import {
  getPayloadObjFromSessionStorage,
} from '../../../helpers';
import { Divider } from '../../../pages/SummaryPage/SummaryComponents';
import { allConditions, vanConditions } from './vanAssumptions';

const VanAssumptionsPage = (props) => {
  const {
    goToThankYouPage, setFieldValue, errors, values, isValid,
    submitForm,
    touched
  } = props;
  const className = 'c-AssumptionsPage';



  const getPriceFromPayload = () => {
    const payload = getPayloadObjFromSessionStorage(VAN_INSURANCE_TYPE);
    return payload.premium.single_payment;
  };

  const onProceedClicked = () => {
    if (!isValid) {
      submitForm();
    } else goToThankYouPage(values);
  };


  const renderToggles = (conditions) => {
    return conditions.map((condition, index) => {
      return (
        <>
          <div key={`tnx${index}`}>
            <ThreeStateToggle label={condition.label}
              error={touched[condition.name] && errors[condition.name]}
              value={values[condition.name]}
              tooltip={condition.tooltip}
              name={condition.name} onChange={(val) => setFieldValue(condition.name, val)}
            />
          </div>
          <Divider />
        </>);
    });
  };

  return (
    <>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Van Declaration</TitleWithUnderLine>
      </div>
      <div className={`${className}__assumptionsCard`}>
        {renderToggles(vanConditions)}

        <div>
          <QuoteDisplay
            price={getPriceFromPayload()}
            sticky
            onClickFunction={onProceedClicked}
            title='Total'
          />
        </div>
      </div>

    </>
  );
};

const errorMessage = 'Please select';
const initialFormValues = allConditions
  .map(condition => condition.name)
  .reduce((acc, element) => {
    acc[element] = null;
    return acc;
  }, {});

const validationSchema = allConditions.reduce((acc, { name }) => {
  acc[name] = yup.boolean(errorMessage).oneOf([true, false], errorMessage).required(errorMessage).nullable();
  return acc;
}, {});

const FormikVanAssumptionsPage = withFormik({
  mapPropsToValues: () => initialFormValues,
  validationSchema: yup.object().shape({
    ...validationSchema,
  }),
  displayName: 'VanAssumptionsPage'
})(VanAssumptionsPage);



VanAssumptionsPage.propTypes = {
  goToThankYouPage: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  submitForm: PropTypes.func,
  submitCount: PropTypes.number,
  className: PropTypes.string
};

export default FormikVanAssumptionsPage;
