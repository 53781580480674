/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import React from 'react';

import { serviceNotAvailablePhoneNumber } from '../../constants/phoneNumbers';

const className = 'c-GenericErrorPage';

const errorText =
  `We're sorry, our system is currently unavailable. Please call us on ${serviceNotAvailablePhoneNumber} to get your quote today.`;

interface Props {
  firstName?: string;
}

const RSAErrorPage = ({ firstName }: Props) => {

  const firstNameMessage = () =>
    firstName ? `Thanks, ${firstName}!` : 'Thanks!';

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      <div className={`${className}__warningMessage`}
        id='RSAErrorPage__warningMessage'
        data-testid='RSAErrorPage__warningMessage'>
        {errorText}
      </div>
    </div>
  );
};

export default RSAErrorPage;
