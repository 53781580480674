
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './AmendProductPage.scss';

import { DesktopCard } from '@arachas/core/lib';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, iconsAndTitles } from '../../constants';
import { dustyGrayColor } from '../../constants/colors';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';
import HomeAmendProductPage from './HomeAmendProductPage';
import MotorAmendProductPage from './MotorAmendProductPage';

const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

const AmendProductPage = (props) => {
  const className = 'c-AmendProductPage';
  const { match, history } = props;
  const insuranceType = match.params.type || '';

  const renderCustomClass = () => {
    return insuranceType === DEVICE_INSURANCE_TYPE ?
      `${className}__deviceIcon` :
      `${className}__icon`;
  };

  const customPageTitle = {
    [HOME_INSURANCE_TYPE]: "Home",
    [CAR_INSURANCE_TYPE]: "Car",
    [TRAVEL_INSURANCE_TYPE]: "Travel",
    [DEVICE_INSURANCE_TYPE]: "Device",
    '': ''
  };

  const insuranceTypeAmendPage = {
    [HOME_INSURANCE_TYPE]: <HomeAmendProductPage insuranceType={insuranceType} />,
    [CAR_INSURANCE_TYPE]: <MotorAmendProductPage insuranceType={insuranceType} />,
    '': ''
  };

  return (
    <>
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {customPageTitle[insuranceType]}
        </div>
      </div>
      <DesktopCard>
        <div className={className}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          {insuranceType && iconsAndTitles[insuranceType] &&
            <div className={`${className}__iconContainer`}>
              <div className={renderCustomClass()}>
                {iconsAndTitles[insuranceType].icon}
              </div>
            </div>
          }
          <div className={`${className}__buttonContainer`}>

            {insuranceTypeAmendPage[insuranceType]}

            <div className={`${className}__buttonSpacing`}>
              <Link to={`${routes.cancelPolicy.url}${insuranceType}`}>
                <ButtonWithIcons
                  id={'AmendProductPage__cancelMyPolicyButton'}
                  IconRight={RightArrowIcon}
                >
                  Cancel my policy
                </ButtonWithIcons>
              </Link>
            </div>

          </div>
        </div>
      </DesktopCard></>
  );
};

AmendProductPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(AmendProductPage);
