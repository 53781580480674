import { toast } from "react-toastify";

import ToastConfig from '../../configs/ToastConfig';

export const UseNotification = () => {

    // eslint-disable-next-line complexity
    const callNotification = (action, msg, updatedConfig?={}) => {
        const config = {
            ...ToastConfig,
            ...updatedConfig
        }
        switch(action) {
            case 'notify':
                toast.notify(msg, config);
                break;
            case 'error':
                toast.error(msg, config);
                break; 
            case 'success':
                toast.success(msg, config);
                break; 
            case 'info':
                toast.info(msg, config);
                break; 
        }
    }
    return {
        callNotification
    }

}