/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

// TODO fix this complexity issue... somehow
// @dogpawhat Ciarán Curley

/* eslint-disable complexity */

import '../../../../../styles/commonFormStyles.scss';

import { DatePicker,InputField } from '@arachas/core/lib';
import { connect, FormikProps } from 'formik';
import React, { useContext } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { getRepeatingFieldName, isFieldError } from '../../../../../helpers';
import {
  inFormStructure,
  InsuranceTypeContext,
} from '../../../../../pages/GetGenericQuotePage';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';
import ResourceDropdown from '../../../../ResourceDropdown';

interface Props {
  fieldNamePrefix?: string;
}

export const BasePersonalDetails = (props: Props & FormikProps) => {
  const insuranceType = useContext(InsuranceTypeContext);

  const className = 'c-PersonalDetails';
  const { errors, handleChange, handleBlur, touched, values } = props.formik;

  const { fieldNamePrefix, handleChangeResource } = props;
  return (
    <div className={className}>
      {inFormStructure('title', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={getRepeatingFieldName('title', fieldNamePrefix)}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Title
          </label>
          <ResourceDropdown
            error={isFieldError('title', touched, errors)}
            errorMessage={errors.title}
            name={getRepeatingFieldName('title', fieldNamePrefix)}
            placeholder="Type here"
            type="text"
            value={values.title}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={props.resources.title}
          />
        </div>
      ) : (
        <></>
      )}
      {inFormStructure('firstName', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={getRepeatingFieldName('firstName', fieldNamePrefix)}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            First name
          </label>
          <InputField
            error={isFieldError('firstName', touched, errors)}
            errorMessage={errors.firstName}
            name={getRepeatingFieldName('firstName', fieldNamePrefix)}
            placeholder="Type here"
            touched={touched.firstName}
            value={values.firstName}
            onChange={handleChange}
            type="text"
            onBlur={handleBlur}
          />
        </div>
      ) : (
        <></>
      )}
      {inFormStructure('lastName', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={getRepeatingFieldName('lastName', fieldNamePrefix)}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Last name
          </label>
          <InputField
            error={isFieldError('lastName', touched, errors)}
            errorMessage={errors.lastName}
            name={getRepeatingFieldName('lastName', fieldNamePrefix)}
            placeholder="Type here"
            touched={touched.lastName}
            type="text"
            onBlur={handleBlur}
            value={values.lastName}
            onChange={handleChange}
          />
        </div>
      ) : (
        <></>
      )}
      {inFormStructure('gender', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={getRepeatingFieldName('gender', fieldNamePrefix)}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Gender
          </label>
          <ResourceDropdown
            error={isFieldError('gender', touched, errors)}
            errorMessage={errors.gender}
            name={getRepeatingFieldName('gender', fieldNamePrefix)}
            placeholder="Type here"
            type="text"
            value={values.gender}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={props.resources.gender}
          />
        </div>
      ) : (
        <></>
      )}
      {inFormStructure('dob', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={getRepeatingFieldName('dob', fieldNamePrefix)}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Date of birth
          </label>
          <DatePicker
            textType
            error={errors.dob}
            name={getRepeatingFieldName('dob', fieldNamePrefix)}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="DD/MM/YYYY"
            touched={touched.dob}
            value={values.dob}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const PersonalDetails = connect(BasePersonalDetails);

export default PersonalDetails;
