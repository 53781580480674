export const HOME_INSURANCE_TYPE = 'home';
export const CAR_INSURANCE_TYPE = 'car';
export const TRAVEL_INSURANCE_TYPE = 'travel';
export const DEVICE_INSURANCE_TYPE = 'device';
export const VAN_INSURANCE_TYPE = 'van';
export const BUSINESS_INSURANCE_TYPE = 'business';
export const LIFE_INSURANCE_TYPE = 'life';
export const PET_INSURANCE_TYPE = 'pet';
export const HEALTH_INSURANCE_TYPE = 'health';
export const PENSIONS_INSURANCE_TYPE = 'pensions';
export const SAVINGS_AND_INVEST_INSURANCE_TYPE = 'savingsAndInvestments';

export const getInsurancePageTitle = {
    [HOME_INSURANCE_TYPE]: 'Home',
    [CAR_INSURANCE_TYPE]: 'Car',
    [TRAVEL_INSURANCE_TYPE]: 'Travel',
    [DEVICE_INSURANCE_TYPE]: 'Device',
    [VAN_INSURANCE_TYPE]: 'Van',

};
