/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './QuoteExtras.scss';

import { AccordionText, SimpleSwitch } from '@arachas/core/lib';
import { noop } from '@arachas/core/lib/utils';
import { FormikProps } from 'formik';
import React, { Component } from 'react';

import { ALLIANZ_HOME_SCHEMA_ID, AVIVA_HOME_SCHEMA_ID, AXA_HOME_SCHEMA_ID, ZURICH_HOME_SCHEMA_ID } from '../../constants/insurers/homeInsurerIDs';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import type { ViewQuoteValues } from '../../pages/ViewQuote';
import SpecifiedItems from '../SpecifiedItems';
import UnspecifiedItems from '../UnspecifiedItems';

interface Props {
  handleChangeWRecalculate: noop;
  setFieldValue: noop;
  isFieldError: noop;
}

class QuoteExtras extends Component<Props & FormikProps<ViewQuoteValues>> {
  render() {
    const className = 'c-QuoteExtras';
    const {
      handleBlur,
      handleChangeWRecalculate,
      values,
      errors,
      setFieldValue,
      setTouched,
      touched,
      isFieldError,
      resources,
      specifiedItemsResources,
      schemeId
    } = this.props;

    const schemeDetails = {
      [ALLIANZ_HOME_SCHEMA_ID]:
      {
        link: "https://www.arachas.ie/allianz-home-policy-book",
        unspecifiedItemsLabel: "Do you wish to add unspecified items cover?",
        unspecifiedItemsReadMore: "This option is to increase cover for loss or damage to items such as jewellery, handbags, and other personal possessions normally carried with you outside of the home. "
      },
      [ZURICH_HOME_SCHEMA_ID]:
      {
        link: "https://www.arachas.ie/home-insurance-policy-document.pdf",
        unspecifiedItemsLabel: "Do you wish to increase your unspecified items cover?",
        unspecifiedItemsReadMore: "We automatically include unspecified items cover up to €1,500 as standard benefit. This option is to increase cover for loss or damage to items such as jewellery, handbags, and other personal possessions normally carried with you outside of the home. Single item limit €1,275. "
      },
      [AXA_HOME_SCHEMA_ID]:
      {
        link: "https://www.arachas.ie/media/tuifbxop/axa-home-policy-document.pdf",
        unspecifiedItemsLabel: 'Do you wish to increase your unspecified items cover by €2,450?',
        unspecifiedItemsReadMore: "This option is to increase cover for loss or damage to items such as jewellery, handbags, and other personal possessions normally carried with you outside of the home. "
      },
      [AVIVA_HOME_SCHEMA_ID]:
      {
        link: "https://www.arachas.ie/aviva-homechoice-policy-booklet",
        unspecifiedItemsLabel: "Do you wish to add unspecified items cover?",
        unspecifiedItemsReadMore: "This option is to increase cover for loss or damage to items such as jewellery, handbags, and other personal possessions normally carried with you outside of the home. "
      },
    };

    const setUnspecifiedItemsForAxa = () =>
      !values['unspecifiedItemsSwitch'] ?
        handleChangeWRecalculate('unspecifiedItems', resources.dynamic_unspecified_items_amount[0])
        : handleChangeWRecalculate('unspecifiedItems', null);


    return (
      <>
        <div key="unspecifiedItemsSwitch">
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__switchContainer`}>
              <label htmlFor="unspecifiedItemsSwitch"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}>
                {schemeDetails[schemeId].unspecifiedItemsLabel}
              </label>
              <SimpleSwitch
                name='unspecifiedItemsSwitch'
                onLabel="on"
                on={values['unspecifiedItemsSwitch']}
                onClick={() => {
                  handleChangeWRecalculate(
                    'unspecifiedItemsSwitch',
                    !values['unspecifiedItemsSwitch']
                  );
                  schemeId === AXA_HOME_SCHEMA_ID &&
                    setUnspecifiedItemsForAxa();

                }
                }
                onBlur={handleBlur}
                isYesNoQuestion={true}
              />
            </div>
            <div className={`${className}__readMore`}>
              <AccordionText
                label="Read more"
                labelColor="inherit"
                openLabel="Read less"
                inverted
              >
                <div className={`${className}__readMoreContent`}>
                  {schemeDetails[schemeId].unspecifiedItemsReadMore}
                  Refer to <a href={schemeDetails[schemeId].link} target="_blank" rel="noopener noreferrer">policy booklet </a>
                  for full details.
                </div>
              </AccordionText>
            </div>
          </div>
          {schemeId === AXA_HOME_SCHEMA_ID ?
            (<></>) :
            values.unspecifiedItemsSwitch &&
            <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}>
              <UnspecifiedItems
                isFieldError={isFieldError}
                handleChangeWRecalculate={handleChangeWRecalculate}
                handleBlur={handleBlur}
                value={values.unspecifiedItems}
                resources={resources}
                errors={errors} />
            </div>
          }
        </div>
        <div key="specifiedItemsSwitch">
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__switchContainer`}>
              <label htmlFor="specifiedItemsSwitch"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}>
                Do you want to add cover for specified items?
              </label>
              <SimpleSwitch
                name='specifiedItemsSwitch'
                onLabel="on"
                on={values['specifiedItemsSwitch']}
                onClick={() =>
                  handleChangeWRecalculate(
                    'specifiedItemsSwitch',
                    !values['specifiedItemsSwitch']
                  )
                }
                onBlur={handleBlur}
                isYesNoQuestion={true}
              />
            </div>
            <div className={`${className}__readMore`}>
              <AccordionText
                label="Read more"
                labelColor="inherit"
                openLabel="Read less"
                inverted
              >
                <div className={`${className}__readMoreContent`}>
                  This is cover for loss or damage to personal items such as jewellery,
                  laptops and sports equipment which you would specifically like to name and insure outside your home.
                  You’ll need to provide a valuation issued in the last 3 years for individual items over €6,000. Refer to <a href={schemeDetails[schemeId].link} rel="noopener noreferrer">policy booklet</a>
                  {' '}for full details
                </div>
              </AccordionText>
            </div>
          </div>
          {values['specifiedItemsSwitch'] &&
            <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}>
              <SpecifiedItems
                errors={errors.specifiedItems}
                handleChangeWRecalculate={handleChangeWRecalculate}
                values={values}
                setFieldValue={setFieldValue}
                setTouched={setTouched}
                touched={touched.specifiedItems}
                resources={specifiedItemsResources}
              />
            </div>}
        </div>
      </>
    );
  }
}

export default QuoteExtras;
