/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './YourTravelForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, ButtonGroup, DatePicker, Label } from '@arachas/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { commonFormStylesIdentifier } from '../../../constants';
import { TRIP_TYPE_CODE, ZONE_CHUBB_LABELS, ZONE_KEYS } from '../../../constants/travel/TravelConstants';
import { getDateOnly } from '../../../helpers/DateHelper';
import { isFieldError } from '../../../helpers/FieldErrorHelper';
import type { AboutYouFormType, YourTripFormType } from '../../../types';
import type {
  DestinationZoneCountries,
  TravelFormPropsType,
  Zone
} from '../../../types/travel/YourTravelTypes';
import DestinationCountry from './DestinationCountry';
import InsuredPerson from './InsuredPerson';

type Values = AboutYouFormType & YourTripFormType;

const YourTravelForm = (props: FormikProps<Values> & TravelFormPropsType) => {
  const className = 'c-YourTravelForm';
  const [destinationZones, setDestinationZones] = useState([]);
  const [destinationCountries, setDestinationCountries] = useState([]);
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    tripTypeOptions,
    zonesForTripType = {},
    destinationCountryList = []
  } = props;

  const definitionPdfLink = `${process.env.REACT_APP_DOCUMENT_ENDPOINT ||
    ''}/documents/chubb/Destination Zone.pdf`;

  useEffect(() => {
    const tripType = values.tripType || TRIP_TYPE_CODE.SINGLE_TRIP;
    if (zonesForTripType[tripType]) {
      const destinationZones = zonesForTripType[tripType].map((zone: Zone) => {
        const newZone = { ...zone };
        newZone.label = ZONE_KEYS[zone.label];
        return newZone;
      });
      setDestinationZones(destinationZones);
      const countries = values.destinationZone
        ? countriesForDestinationZone(values.destinationZone, destinationZones)
        : [];
      setDestinationCountries(countries);
    }
  }, [zonesForTripType]);

  //todo move to helper
  const countriesForDestinationZone = (
    zoneId: string,
    zones: Array<Zone> = destinationZones
  ) => {
    const destinationZone: void | Zone = zones.find(
      (zone: Zone) => zone.value === parseInt(zoneId, 10)
    );
    if (destinationZone) {
      const destinationZoneName = destinationZone.label;
      const zoneObject = destinationCountryList.find(
        (countriesForZone: DestinationZoneCountries) =>
          countriesForZone.name === ZONE_CHUBB_LABELS[destinationZoneName]
      );
      return zoneObject
        ? zoneObject.countries.map((country: string) => ({
          value: country,
          text: country,
          key: country
        }))
        : [];
    }
    return [];
  };

  //todo move to helper
  const handleTripTypeChange = (selectedTripType: string) => {
    if (selectedTripType !== values.tripType) {
      const newZones = zonesForTripType[selectedTripType].map((zone: Zone) => {
        const newZone = { ...zone };
        newZone.label = ZONE_KEYS[zone.label];
        return newZone;
      });
      setDestinationZones(newZones);
      setFieldValue('tripType', selectedTripType);
      if (selectedTripType === TRIP_TYPE_CODE.MULTI_TRIP) {
        setFieldValue('travelToDate', '');
        setFieldValue('destinationCountry', '');
      }
    }
  };

  //todo move to helper
  const destinationZoneChange = (e: string) => {
    setDestinationCountries(countriesForDestinationZone(e));
    setFieldValue('destinationCountry', '');
    setFieldValue('destinationZone', e);
  };

  const getTravelDateValue = (travelDate: ?string) => {
    /* If travel date is defined call getDateOnly else return the value that was
       passed to initial values. Whether it be '' or null or undefined */
    return travelDate ? getDateOnly(travelDate) : travelDate;
  };

  const getTravelDateLabel = (tripType: string) =>
    tripType === TRIP_TYPE_CODE.SINGLE_TRIP
      ? 'Enter travel dates'
      : 'Policy start date';

  return (
    <div className={`${className}__container`}>
      <div className={`${className}__formPaddingContainer`}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <Label
            htmlFor="tripType"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Cover type
          </Label>
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <ButtonGroup
              name="tripType"
              error={isFieldError('tripType', touched, errors)}
              errorMessage={errors.tripType}
              options={tripTypeOptions}
              onClick={handleTripTypeChange}
              spaceBetween={true}
              selected={values.tripType}
              largerButton={true}
            />
          </div>
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <Label
            htmlFor="travelFromDate"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            {getTravelDateLabel(values.tripType)}
          </Label>
          <DatePicker
            name="travelFromDate"
            error={errors.travelFromDate}
            onBlur={handleBlur}
            touched={touched.travelFromDate}
            placeholder="Type here"
            onChange={handleChange}
            value={getTravelDateValue(values.travelFromDate)}
          />
        </div>
        {values.tripType === TRIP_TYPE_CODE.SINGLE_TRIP ? (
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <Label
              htmlFor="travelToDate"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              To
            </Label>
            <DatePicker
              name="travelToDate"
              error={errors.travelToDate}
              placeholder="Type here"
              onBlur={handleBlur}
              touched={touched.travelToDate}
              onChange={handleChange}
              value={getTravelDateValue(values.travelToDate)}
            />
          </div>
        ) : (
            <></>
          )}
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer`}
        >
          <Label
            htmlFor="destinationZone"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            <AccordionText
              label="Destination zone"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                For single trip cover please confirm your main country of destination in the box provided.
                For multi-trip cover please select the area to be covered by this policy.{' '}
                <a href={definitionPdfLink} target={'_blank'} rel='noopener noreferrer'>
                  Full list of countries in our underwriters definition of Europe.
                </a>
              </div>
            </AccordionText>
          </Label>
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <ButtonGroup
              name="destinationZone"
              options={destinationZones}
              onClick={destinationZoneChange}
              spaceBetween={true}
              error={isFieldError('destinationZone', touched, errors)}
              errorMessage={errors.destinationZone}
              handleChange={handleChange}
              disabled={!values.tripType}
              selected={values.destinationZone}
              largerButton={true}
            />
          </div>
        </div>
        <DestinationCountry
          touched={touched}
          errors={errors}
          values={values}
          destinationCountries={destinationCountries}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />
      </div>
      <InsuredPerson {...props} />
    </div>
  );
};

export default YourTravelForm;
