import { ClockIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { fontColorSecondary } from '../../constants/colors';
import { whiteIcons } from '../../constants/insuranceIconsAndTitles';
import { policyTypeToInsuranceTypeLookup } from '../../constants/policyTypesConstants';
import { getDayDifference } from '../../helpers/DateHelper';

const Policy = ({ policy, onClick, className }) => {
  const insuranceType = policyTypeToInsuranceTypeLookup[policy.policy_type];
  const expirationDays = getDayDifference(
    new Date(policy.end_date), new Date()
  );
  return (
    <div className={`${className}__policy`} onClick={() => onClick(policy, insuranceType)}>
      <div className={`${className}__policy__icon ${className}__policy__icon--${insuranceType}`}>
        {whiteIcons[insuranceType]}
      </div>
      <div className={`${className}__policy__reference`}>
        {policy.reference_number}
      </div>
      <div className={`${className}__policy__expiration`}>
        <span className={`${className}__policy__expirationLabel`}>
          <ClockIcon color={fontColorSecondary} />
            Renewal countdown
          </span>
        <span className={`${className}__policy__expirationDays`}>
          {expirationDays} day{expirationDays > 1 ? 's' : ''}
        </span>
      </div>
    </div>
  );
};

Policy.propTypes = {
  policy: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Policy;
