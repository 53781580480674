import { ALLIANZ_SCHEMA_ID, AVIVA_SCHEMA_ID, AXA_SCHEMA_ID, ZURICH_SCHEMA_ID } from '../../../constants/insurers/carInsurerIDs';

const insuredListAllianz = [
  "Damage as a result of fire, theft, accident or vandalism",
  "Your legal liability to other people arising from an accident",
  "Cover while driving your car in Europe",
  "Replacement locks, car keys, key cards and lock transmitters",
  "New car replacement within 12 months of registration or if stolen and not recovered",
  "Step Back Bonus Protection",
  "Personal effects and clothing",
  "Personal accident",
  "Breakdown assistance",
  "Third party cover to tow a trailer",
  "Comprehensive driving of other cars",
  "Emergency treatment following an accident",
  "Fire brigade charges",
  "Policy excess of €250 for full licence drivers",
  "Unlimited Windscreen cover" 
]

const insuredListAviva = [
  "Damage as a result of fire, theft or accident",
  "Your legal liability to other people arising from an accident",
  "Cover while driving your car in Europe for up to 31 days",
  "Replacement locks and alarms up to €1000",
  "New car replacement- if your car is less than 12 months old and you are the first owner. In the event of a total loss, or loss greater than 50%, in one incident we will pay for a brand new replacement car",
  "Step-back no claim discount. In event of a single claim, you retain part of your discount",
  "Personal belongings up to €150 for one event. Damage covers rugs, clothing and personal belongings",
  "Breakdown assistance roadside and in your driveway. You are also covered for the cost of towing your car to the nearest repairer and completing your journey",
  "Driving of other cars included for third party cover only and main driver only. Occupation restrictions may apply",
  "Emergency treatment expenses",
  "Fire brigade charges up to €1,000",
  "Policy Excess standard €300",
  "Unlimited Windscreen Cover if Aviva's aligned repair network are used"
]

const insuredListAxa = [
  "Damage as a result of fire, theft, accident or vandalism",
  "Your legal liability to other people arising from an accident",
  "Cover while driving your car in Europe for up to 31 days",
  "Replacement locks and alarms up to €750 (if keys are stolen from your home by force or violence)",
  "New car replacement- if your car is less than 12 months old and you are the first owner, in the event of a total loss we will pay for a brand-new replacement car",
  "Step Back Bonus Protection",
  "Personal belongings up to €250",
  "Personal Accident up to 30,000 for insured",
  "Breakdown assistance covers roadside or in your driveway",
  "Third party cover whilst towing of a caravan, trailer or broken down car",
  "Driving of other cars included for third party cover only - you must be aged 25-70",
  "Replacement car if your car is repaired by one of AXA’s garages after an accident, fire or theft up to a maximum of 35 days. The replacement car is usually a Class-A or economy class 1 litre car.",
  "Fire brigade charges up to €1270",
  "Policy Excess standard €300",
  "Unlimited windscreen cover with Axa approved repairer - €400 max if approved repairer not used"
]

const insuredListZurich = [
  "Damage as a result of fire, theft, accident or vandalism",
  "Your legal liability to other people arising from an accident",
  "Cover while driving your car in Europe for up to 30days",
  "Replacement locks or car keys if stolen for up to €800",
  "New car replacement within 12 months of registration or if stolen and not recovered",
  "Step Back Bonus Protection",
  "Personal effects and childcare accessories up to €550",
  "Personal Accident for the insured or spouse",
  "Roadside Breakdown assistance",
  "Driving of other cars included for third party cover only",
  "Emergency service for accident, fire or theft. This includes the cost of replacement car, returning home by public transport or overnight accommodation ",
  "Fire brigade charges up to €2,000",
  "Standard policy excess of €250",
  "Unlimited Windscreen cover with an approved repairer - €350 max if approved repairer not used"
]

const ipidAllianz = {
  label: "Allianz IPID Document",
  url: "//www.arachas.ie/allianz-insurance-product-information-ipid"
}

const ipidAviva = {
  label: "Aviva Motor Choice IPID",
  url: "//www.arachas.ie/aviva-MotorChoice-Policy-IPID"
}

const ipidAxa = {
  label: "Axa IPID Document",
  url: "//www.arachas.ie/axa-car-insurance-ipid"
}

const ipidZurich = {
  label: "Zurich IPID Document",
  url: "//www.arachas.ie/media/e54basub/zurich-private-car-ipid.pdf"
}

export const getlistOfInsured = {
  [ALLIANZ_SCHEMA_ID]: insuredListAllianz,
  [AVIVA_SCHEMA_ID]: insuredListAviva,
  [AXA_SCHEMA_ID]: insuredListAxa,
  [ZURICH_SCHEMA_ID]: insuredListZurich
}

export const getListOfIPID = {
  [ALLIANZ_SCHEMA_ID]: ipidAllianz,
  [AVIVA_SCHEMA_ID]: ipidAviva,
  [AXA_SCHEMA_ID]: ipidAxa,
  [ZURICH_SCHEMA_ID]: ipidZurich
}