/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ManageMyInsuranceDashboard.scss';

import { AnimatedSpinner } from '@arachas/core/lib';
import React, { useContext, useEffect, useState } from 'react';
import type { Match, RouterHistory } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { getAccessToken } from '../../helpers';
import { getUserProfile } from '../../services/profile';
import type { UserProfileType } from '../../types/profile';
import ManageMyInsuranceDashboard from './ManageMyInsuranceDashboard';

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}

const ManageMyInsuranceDashboardWrapper = (props: Props) => {
  const className = 'c-ManageMyInsuranceDashboard';

  const [userProfile, setUserProfile] = useState(null);
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    if (!userProfile) {
      getUserProfile(getAccessToken(cognitoUser)).then(
        (res: UserProfileType) => {
          setUserProfile(res);
        }
      );
    }
  });

  return userProfile ?
    <ManageMyInsuranceDashboard
      {...props}
      userProfile={userProfile}
    /> : (
      <div className={`${className}__spinnerContainer`}>
        <AnimatedSpinner />
      </div>
    );
};

export default ManageMyInsuranceDashboardWrapper;
