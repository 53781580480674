"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HandIcon = function HandIcon(props) {
  var color = props.color,
      solid = props.solid;
  var style = solid ? {
    fill: color,
    enableBackground: 'new 0 0 63 63'
  } : {
    fill: 'none',
    stroke: color,
    strokeWidth: '1.5px'
  };
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    style: style,
    viewBox: "0 0 60 52.242"
  }, _react2.default.createElement("g", {
    id: "prefix__hand",
    transform: "translate(-12.687 -59)"
  }, _react2.default.createElement("path", {
    id: "prefix__Path_1574",
    d: "M70.7 203.72a5.655 5.655 0 0 0-7.885.609l-5.843 6a5.635 5.635 0 0 0-3.486-1.207H41.117A15.73 15.73 0 0 0 35.251 208a15.041 15.041 0 0 0-13.334 7.152h-.011a4.5 4.5 0 0 0-3.192 1.331l-5.37 5.381a2.257 2.257 0 0 0 0 3.181L26 237.8a2.247 2.247 0 0 0 3.181.011l5.471-5.392a4.546 4.546 0 0 0 1.252-3.937l.711-.688a2.268 2.268 0 0 1 1.568-.632h14.993a11.226 11.226 0 0 0 7.863-3.192c.135-.135.485-.553 1.9-2.234s3.914-4.682 8.416-10.063a5.646 5.646 0 0 0-.655-7.953zm-43.059 29.3l-9.5-9.577 3.779-3.79c.158.158.677.666 1.85 1.839l7.727 7.727zm40.273-24.254L57.835 220.81a6.767 6.767 0 0 1-4.648 1.839H38.195a6.737 6.737 0 0 0-4.693 1.9l-.158.158-7.434-7.434c1.952-3.08 5.246-4.761 9.352-4.761a11.228 11.228 0 0 1 4.512.936 2.287 2.287 0 0 0 .914.192h12.8a1.128 1.128 0 0 1 0 2.256h-9.201a2.256 2.256 0 0 0 0 4.512h9.205a5.642 5.642 0 0 0 5.64-5.64v-.169l4.174-4.3c2.854-2.933 2.854-2.933 2.9-2.989a1.146 1.146 0 0 1 .869-.395 1.112 1.112 0 0 1 .722.259 1.135 1.135 0 0 1 .112 1.588z",
    "data-name": "Path 1574",
    transform: "translate(0 -127.223)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1575",
    d: "M74.334 364.877l-3.847 d3.79L61 359.09l3.779-3.79c.158.158.677.666 1.85 1.839 1.376 1.399 3.689 3.722 7.705 7.738z",
    "data-name": "Path 1575",
    transform: "translate(-42.862 -262.875)",
    style: {
      opacity: .3
    }
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1576",
    d: "M171.9 244.25l-10.075 12.05a6.767 6.767 0 0 1-4.648 1.839h-14.992a6.737 6.737 0 0 0-4.693 1.9l-.158.158-7.434-7.434c1.952-3.08 5.246-4.761 9.352-4.761a11.228 11.228 0 0 1 4.512.936 2.287 2.287 0 0 0 .914.192h12.8a1.128 1.128 0 1 1 0 2.256h-9.205a2.256 2.256 0 0 0 0 4.512h9.205a5.642 5.642 0 0 0 5.64-5.64v-.169l4.174-4.3c2.854-2.933 2.854-2.933 2.9-2.989a1.146 1.146 0 0 1 .869-.395 1.112 1.112 0 0 1 .722.259 1.135 1.135 0 0 1 .117 1.586z",
    "data-name": "Path 1576",
    transform: "translate(-103.99 -162.711)",
    style: {
      opacity: .3
    }
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1577",
    d: "M155.705 64.731a8.585 8.585 0 1 0 0 5.708H162v5.731h5.731v-5.731h2.869v-5.731h-14.895zm-8.021 5.719a2.865 2.865 0 1 1 2.865-2.865 2.869 2.869 0 0 1-2.864 2.865z",
    "data-name": "Path 1577",
    transform: "translate(-112.152)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1578",
    d: "M195.531 112.665a2.865 2.865 0 1 1-2.865-2.865 2.869 2.869 0 0 1 2.865 2.865z",
    "data-name": "Path 1578",
    transform: "translate(-157.133 -45.069)",
    style: {
      opacity: .3
    }
  })));
};

HandIcon.defaultProps = {
  color: _colors.fontColorSecondary,
  solid: true
};
HandIcon.propTypes = {
  color: _propTypes2.default.string,
  solid: _propTypes2.default.bool
};
exports.default = HandIcon;