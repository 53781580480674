"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DesktopCard = require("./DesktopCard");

var _DesktopCard2 = _interopRequireDefault(_DesktopCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _DesktopCard2.default;