// @flow
import './PremiumBreakdownRow.scss';

import PropTypes from 'prop-types';
import React from 'react';

interface PremiumBreakdownRowProps {
  label: string;
  price?: number;
  prefix?: string;
  suffix?: string;
  decimalPlaces?: number;
  bold?: boolean;
  textValue?: string;
}

const PremiumBreakdownRow = (props: PremiumBreakdownRowProps) => {
  const className = 'c-PremiumBreakdownRow';
  const { 
    decimalPlaces,
    label,
    price,
    prefix = '€',
    suffix,
    bold = false,
    textValue = ''
  } = props;

  const subHeadingClassName = [
    `${className}__label`,
    bold && `${className}__label--bold`
  ].filter(Boolean).join(' ');

  const getPrice = () => {
    const numberOfDecimalPlaces =
      decimalPlaces !== undefined ? decimalPlaces : 2;
    return price !== undefined ? (
      <div
        className={`${className}__price`}
        id={`PremiumBreakdownRow__${label}--price`}
      >
        {prefix}
        {price.toFixed(numberOfDecimalPlaces)}
        {suffix}
      </div>
    ) : null;
  };

  const getText = () => {
    return textValue !== '' ? (
      <div
        className={`${className}__price`}
        id={`PremiumBreakdownRow__${label}--price`}
      >
        {textValue}
      </div>
    ) : null;
  };

  return (
    <div
      className={`${className}__rowContainer`}
      id={`PremiumBreakdownRow__${label}`}
    >
      <div
        className={subHeadingClassName}
        id={`PremiumBreakdownRow__${label}--label`}
      >
        {label}
      </div>
      {getPrice()}
      {getText()}
    </div>
  );
};

PremiumBreakdownRow.propTypes = {
  price: PropTypes.number
};

export default PremiumBreakdownRow;
