/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import LicenceTypeLicenceCountry from '../../../../../components/LicenceTypeLicenceCountry';
import { CAR_INSURANCE_TYPE, commonFormStylesIdentifier, VAN_INSURANCE_TYPE } from '../../../../../constants';
import { getRepeatingFieldName, isFieldError } from '../../../../../helpers';
import { InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';
import ResourceDropdown from '../../../../ResourceDropdown';
import { getFieldActiveClassNames } from '../aboutYouFormHelper';

interface Props {
  insuranceType?: string,
  fieldNamePrefix?: string,
  isSubFormComponent?: boolean,
  hideFieldsList?: Array<string>;
}

// eslint-disable-next-line complexity
export const BaseDrivingDetails = (props: Props & FormikProps) => {
  const insuranceTypeContext = useContext(InsuranceTypeContext);
  const insuranceType = props.insuranceType || insuranceTypeContext;

  const className = 'c-DrivingDetails';
  const {
    errors,
    handleBlur,
    touched,
    values,
  } = props.formik;

  const { isSubFormComponent = false, resources, handleChangeResource } = props;

  const { fieldNamePrefix = [] } = props;


  const renderDrivingLicenceType = () => {
    if (insuranceType === VAN_INSURANCE_TYPE && fieldNamePrefix.length === 0) {
      return (
        <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
          <LicenceTypeLicenceCountry
            formik={props.formik}
            resources={resources}
            getRepeatingFieldName={getRepeatingFieldName}
            fieldNamePrefix={fieldNamePrefix} />
        </div>
      );
    }
    else if (insuranceType === CAR_INSURANCE_TYPE) {
      return (
        <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
          <label htmlFor={getRepeatingFieldName("driversLicenseType", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Drivers Licence Type
          </label>
          <ResourceDropdown
            placeholder="Select here"
            error={isFieldError('driversLicenseType', touched, errors)}
            errorMessage={errors.driversLicenseType}
            name={getRepeatingFieldName("driversLicenseType", fieldNamePrefix)}
            value={values.driversLicenseType}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={resources.licence_type}
          />
        </div>

      );
    }
  };

  return (
    <div className={className}>

      {renderDrivingLicenceType()}

      {inFormStructure('yearsHeld', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
          <label htmlFor={getRepeatingFieldName("yearsHeld", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Years Held
          </label>
          <ResourceDropdown
            placeholder="Select here"
            error={isFieldError('yearsHeld', touched, errors)}
            errorMessage={errors.yearsHeld}
            name={getRepeatingFieldName("yearsHeld", fieldNamePrefix)}
            value={values.yearsHeld}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={resources.licence_held}
          />
        </div>
      }
    </div >
  );
};

BaseDrivingDetails.propTypes = {
  formik: PropTypes.object
};

const DrivingDetails = connect(BaseDrivingDetails);

export default DrivingDetails;
