/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 *
 */

export const NO_PREPOPULATION = 'Home/NO_PREPOPULATION';
export const PREPOPULATION_PREFORMED = 'Home/PREPOPULATION_PREFORMED';

export const HOME_COVER_TYPE = {
  OWN_LIVE: 'Home I own & live in',
  OWN_RENT: 'Home I own & rent out',
  OWN_HOLIDAY: 'Home I own & use as a holiday home',
  RENT: 'Rent from someone'
};

export const PROPERTY_TYPE_APARTMENT_DESCRIPTION = 'Apartment';
export const PROPERTY_TYPE_TERRACED_DESCRIPTION = 'Terraced';

export const isLiving = (coverType) => coverType === HOME_COVER_TYPE.OWN_LIVE || coverType === HOME_COVER_TYPE.RENT;
export const isHoliday = (coverType) => coverType === HOME_COVER_TYPE.OWN_HOLIDAY;
export const isLandlord = (coverType) => coverType === HOME_COVER_TYPE.OWN_RENT;
export const isOwning = (coverType) => coverType.description === HOME_COVER_TYPE.OWN_LIVE || coverType.description === HOME_COVER_TYPE.OWN_RENT;

