/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { addDays, endOfToday, parse, startOfToday } from 'date-fns';
import * as yup from 'yup';

export const YourDeviceCoverFormYupSchema = {
  policyStart: yup
    .date()
    .transform((_, dateString: string) => parse(dateString))
    .min(startOfToday(), 'Please enter a date 30 or less days in the future')
    .max(
      addDays(endOfToday(), 30),
      'Please enter a date 30 or less days in the future'
    )
    .required('Policy start date is required'),

  acceptedAssumptions: yup
    .boolean()
    .oneOf([true], 'You must accept the Disclosure Requriements'),
  acceptedTermsAndConditions: yup
    .boolean()
    .oneOf([true], 'You must accept the Terms and Conditions'),
  marketingConsent: yup
    .boolean()
    .oneOf([true], '')
    .test(
      'marketing-consent-function-motor',
      'You must select a method of contact',
      function (): boolean {
        return (
          this.parent.emailConsent ||
          this.parent.postConsent ||
          this.parent.phoneConsent ||
          this.parent.smsConsent
        );
      }
    ),
};

export default YourDeviceCoverFormYupSchema;
