import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  carInsuranceAboutYouFormFields,
  carInsuranceYourCarCoverFormFields,
  carInsuranceYourCarFormFields,
  deviceInsuranceAboutYouFormFields, deviceInsuranceYourCoverFormFields, deviceInsuranceYourDeviceFormFields,
  homeInsuranceAboutYouFormFields,
  homeInsuranceYourHomeCoverFormFields,
  homeInsuranceYourHomeFormFields,
  travelInsuranceAboutYouFormFields,
  travelInsuranceYourTravelCoverFormFields,
  travelInsuranceYourTravelFormFields,
  vanInsuranceAboutYouFormFields,
  vanInsuranceYourCoverFormFields,
  vanInsuranceYourVanFormFields
} from './displayedFields';

export default {
  [HOME_INSURANCE_TYPE]: [
    ...homeInsuranceAboutYouFormFields,
    ...homeInsuranceYourHomeCoverFormFields,
    ...homeInsuranceYourHomeFormFields,
  ],
  [CAR_INSURANCE_TYPE]: [
    ...carInsuranceAboutYouFormFields,
    ...carInsuranceYourCarFormFields,
    ...carInsuranceYourCarCoverFormFields
  ],
  [TRAVEL_INSURANCE_TYPE]: [
    ...travelInsuranceAboutYouFormFields,
    ...travelInsuranceYourTravelFormFields,
    ...travelInsuranceYourTravelCoverFormFields
  ],
  [DEVICE_INSURANCE_TYPE]: [
    ...deviceInsuranceAboutYouFormFields,
    ...deviceInsuranceYourDeviceFormFields,
    ...deviceInsuranceYourCoverFormFields
  ],
  [VAN_INSURANCE_TYPE]: [
    ...vanInsuranceAboutYouFormFields,
    ...vanInsuranceYourVanFormFields,
    ...vanInsuranceYourCoverFormFields
  ],
  ['']: null,
};
