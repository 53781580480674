import PropTypes from 'prop-types';
import React from 'react';

function Message(props) {
  const { className, subject, message } = props;
  return (
    <>
      <div className={`${className}__subject`}>{subject}</div>
      <div className={`${className}__message`}>{message}</div>
    </>
  );
}

Message.propTypes = {
  className: PropTypes.string,
  subject: PropTypes.string,
  message: PropTypes.string,
};

export default Message;
