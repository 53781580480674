/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './GenericClaimPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  Button,
  DropzoneUploader,
  WideDivider,
  XIcon
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { dividerColor } from '../../constants/colors';
import { DOCUMENT_UPLOAD } from "../../constants/uploadFileTypes";
import { getAccessToken, getObjectFromSessionStorage } from '../../helpers';
import {
  scanDocument, signUploadURL,
} from "../../services/uploadService";

const className = 'c-DocumentsUploadPage';

const MultipleDocUpload = (props) => {
  const {
    setFieldValue,
    values,
    touched,
    baseFieldName = 'uploadDocuments',
    setTouched,
    setFieldTouched,
    documentType,
  } = props;

  const { match } = props;
  const insuranceType = match.params.type || '';
  const { cognitoUser } = useContext(CognitoContext);
  const accessToken = getAccessToken(cognitoUser);
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);

  let itemCount = 0;
  const maxItemAllowed = 6;


  useEffect(() => {
    setFieldValue('docUpload', values.docUpload || [{ ...emptyDocUploadItem }]);
    setTouched({ docUpload: [{ ...untouchedDocUpload }] });

  }, [setFieldValue, setTouched]);

  const emptyDocUploadItem = {
    documentType: documentType,
    applicationFile: []
  };

  const untouchedDocUpload = {
    documentType: false,
    applicationFile: false,
  };

  const addItem = () => {
    const newValues = [...values.docUpload];
    const newTouched = [...touched];
    newValues.push({ ...emptyDocUploadItem });
    newTouched.push({ ...untouchedDocUpload });
    setTouched({ docUpload: newTouched });
    setFieldValue('docUpload', newValues);
  };

  const removeItem = (index) => {
    const newValues = [...values.docUpload];
    const newTouched = [...touched];
    newValues.splice(index, 1);
    newTouched.splice(index, 1);
    setTouched({ docUpload: newTouched });
    setFieldValue('docUpload', newValues);
  };

  const getPolicyNumber = () => policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;


  const hasFilesUploaded = (itemCount) => {
    const index = itemCount - 1;
    for (var i = 0; i <= index; i++) {
      if (!(values.docUpload[i].applicationFile && values.docUpload[i].applicationFile[0])) {
        return false;
      }
    }
    return true;

  };


  const removeFile = (index) => {
    const fieldName = `docUpload[${index}].applicationFile`;
    setFieldValue(fieldName, []);
    setFieldTouched(fieldName, false);

  };

  const renderDocUploadItems = () => {
    const docUploadElements = [];
    const docUpload = values.docUpload || [];
    const docUploadItemsCount = docUpload.length;
    itemCount = docUploadItemsCount;


    docUpload.forEach((docUploadItem, index) => {
      docUploadElements.push(
        <div key={`DocUploadItem_${index}`}>
          <div className={`${className}__itemPromptAndNumberContainer`}>
            <div className={`${className}__itemNumberContainer`}>
              <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
                Item {index + 1}
              </label>
              {docUploadItemsCount > 1 &&
                <div
                  className={`${className}__closeIcon`}
                  onClick={() => removeItem(index)}
                  id={`DocUploadItems__closeIcon${index + 1}`}
                  data-testid={`DocUploadItems__closeIcon${index + 1}`}
                >
                  <XIcon />
                </div>
              }
            </div>
          </div>


          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
              <label htmlFor={baseFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Please select a file
                </label>
              <DropzoneUploader
                id={index}
                insuranceType={insuranceType}
                documentName={documentType}
                policyNumber={getPolicyNumber()}
                accessToken={accessToken}
                files={values.docUpload[index].applicationFile}
                setFiles={files => {
                  if (files[0] && files[0].file.status === "success") {
                    const fieldName = `docUpload[${index}].applicationFile`;
                    setFieldValue(fieldName, files);
                    setFieldTouched(fieldName, true);
                  }
                }}
                category={DOCUMENT_UPLOAD.category}
                allowed_formats={DOCUMENT_UPLOAD.allowed_formats}
                signUploadURL={signUploadURL}
                scanDocument={scanDocument}
                removeFile={removeFile}
              />
            </div>
          </div>

          {index < docUploadItemsCount - 1 &&
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
          }
        </div >
      );
    });
    return docUploadElements;
  };

  return (
    <>
      <div>
        {renderDocUploadItems()}
        {itemCount < maxItemAllowed ?
          <Button

            disabled={!hasFilesUploaded(itemCount)}
            fluid={true}
            onClick={() => {
              addItem();
            }}
            id='DocUploadItems__addAnotherItemButton'
            data-testid='DocUploadItems__addAnotherItemButton'
          >
            ADD ANOTHER DOCUMENT
          </Button>
          :
          ' '}
      </div>
    </>
  );
};

MultipleDocUpload.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.array,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.array,
  handleBlur: PropTypes.func,
  baseFieldName: PropTypes.string,
  fieldFilledBefore: PropTypes.object,
  resources: PropTypes.object,
  isValid: PropTypes.bool,
  documentType: PropTypes.object,
};

export default MultipleDocUpload;