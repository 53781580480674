import { TickIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants';

export const className = 'c-SummaryPage';

export const Divider = () => {
  return (
    <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
      <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
    </div>
  )
}

export const Tick = (props) => {
  return (
    <div className={`${className}__tickContainer`}>
      <div className={`${className}__tick`}>
        <TickIcon />
      </div>
      {props.children}
    </div>
  );
};

export const renderLine = (text, divider = true) => {
  return (
    <>
      <Tick>
        <p>
          {text}
        </p>
      </Tick>
      {divider && <Divider />}
    </>
  )
}

Tick.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
