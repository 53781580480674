/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */


import { AnimatedSpinner } from '@arachas/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';

import { whiteColor } from '../../../constants/colors';
import { TRAVEL_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { getTripTypeOptions, getZonesForTripType } from '../../../helpers/TravelHelper';
import { routes } from '../../../routes';
import type { RouterPropsType } from '../../../types/commonTypes';
import YourTravelForm from './YourTravelForm';

const className = 'c-YourTravelForm';

const YourTravelFormWrapper = (props: RouterPropsType & FormikProps) => {
  const { history, travelResources } = props;

  useEffect(() => {
    if (!travelResources.trip_types) {
      history.push(`${routes.genericErrorPage.url}${TRAVEL_INSURANCE_TYPE}`);
    }
  }, []);

  if (!travelResources) return <div className={`${className}__spinnerContainer`}>
    <AnimatedSpinner backgroundColor={whiteColor} /></div>;



  return (
    <YourTravelForm
      {...props}
      zonesForTripType={getZonesForTripType(travelResources.trip_types)}
      tripTypeOptions={getTripTypeOptions(travelResources.trip_types)}
      destinationCountryList={travelResources.destination_country_list}
    />
  );
};

export default YourTravelFormWrapper;
