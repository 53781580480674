

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import { featureList } from './InfoContstants.js';
const numOfFeatures = 10;

export const getlistOfFeatures = () => {
    return featureList;
};

export const getShowUpToFeatureNumber = () => {
    return numOfFeatures;
};
