"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Slider = require("./Slider");

var _Slider2 = _interopRequireDefault(_Slider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Slider2.default;