"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DeleteIcon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function DeleteIcon() {
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16"
  }, _react2.default.createElement("path", {
    id: "add_circle-24px",
    d: "M15.675,4.309A8.023,8.023,0,1,0,4.315,15.641,8.023,8.023,0,0,0,15.675,4.309Zm-3.44,9.017-2.24-2.234-2.24,2.234-1.12-1.117,2.24-2.234L6.635,7.741l1.12-1.117,2.24,2.234,2.24-2.234,1.12,1.117-2.24,2.234,2.24,2.234Z",
    transform: "translate(-1.975 -1.975)",
    fill: "#600460"
  }));
}