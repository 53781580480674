/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './AgentLatestQuotes.scss';

import { AnimatedSpinner, Button, Text } from '@arachas/core/lib';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { iconsAndTitles } from '../../constants';
import { offWhite } from '../../constants/colors';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getAccessToken } from '../../helpers';
import { routes } from '../../routes';
import {
  getLatestQuotes,
  updateContactedStatus,
} from '../../services/agent/latestQuotesService';
import type { QuoteSummaryType } from '../../types/commonTypes/QuoteSummaryType';
import { QuoteRow } from './';

// const mock =
//   [{
//     "first_name": "Lester", "last_name": "Chester", "email":
//       "vaxupywe@mailinator.com", "contact_number": "+353 872238210",
//     "quote_reference": "CHEL01001", "promotion_code": "",
//     "premium_amount": [
//       {
//         "amount": 50,
//         "insurer": "Zurich",
//         "scheme_code": "A1000"
//       },
//     ],
//     "timestamp": "2021-06-08T12:19:54.417"
//   },
//   {
//     "first_name": "Amanda", "last_name": "Hughes",
//     "email": "amanda.hughes@version1.com", "contact_number":
//       "01200000", "quote_reference": "HUGA6F001", "promotion_code": "Choosy2021",
//     "premium_amount": [
//       {
//         "amount": 2,
//         "insurer": "Zurich",
//         "scheme_code": "A1000"
//       },
//       {
//         "amount": 1,
//         "insurer": "Aviva",
//         "scheme_code": "B1000"
//       },
//     ], "timestamp": "2021-06-08T08:57:15.719"
//   }];

const AgentLatestQuotes = () => {
  const className = 'c-AgentLatestQuotes';
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { cognitoUser } = useContext(CognitoContext);
  const accessToken = getAccessToken(cognitoUser);
  const pageSize = 7;

  let headers = [
    'Contacted Status',
    'Quote Reference',
    'Email',
    'Customer Name',
    'Zurich Amount',
    'Allianz Amount',
    'Aviva Amount',
    'Axa Amount',
    'Customer Contact Number',
    'Timestamp',
  ];

  const getQuotes = async () => {
    setLoading(true);
    const latestQuotes = await getLatestQuotes(
      accessToken,
      pageSize,
      pageNumber
    );
    setLoading(false);
    if (latestQuotes.content) {
      setPageNumber(pageNumber + 1);
      setTotalPages(latestQuotes.total_pages);
      setQuotes([...quotes, ...latestQuotes.content]);
    }
  };

  useEffect(() => {
    async function initQuotesList(): any {
      await getQuotes();
    }
    initQuotesList();
  }, []);

  const handleCustomerContacted = (clickedQuote: QuoteSummaryType) => {
    setQuotes(
      quotes.map((quote: QuoteSummaryType) =>
        quote.quote_reference === clickedQuote.quote_reference
          ? { ...quote, contacted: true }
          : quote
      )
    );
    updateContactedStatus(accessToken, clickedQuote.quote_reference, true).then(
      setTimeout(() => {
        setQuotes(
          quotes.filter(
            (quote: QuoteSummaryType) =>
              quote.quote_reference !== clickedQuote.quote_reference
          )
        );
      }, 3000)
    );
  };

  const renderTableHeader = () => {
    const tableHeaderClassName = 'c-QuoteRows';
    const tableHeaderColumnClassName = `${tableHeaderClassName}__column ${tableHeaderClassName}__column--header`;

    return (
      <>
        {headers.map((label: string, key: number) => {
          return (
            <div
              className={`${tableHeaderColumnClassName}${key === headers.length - 1 ? ` ${tableHeaderColumnClassName}__last` : ''
                }`}
              key={key}
            >
              {label}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={className}>
      <div className={`${className}__titleContainer`}>
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>
            {iconsAndTitles[HOME_INSURANCE_TYPE].icon}
          </div>
        </div>
        <div className={`${className}__titleText`}>
          <Text size="inherit">Live Home Quotes</Text>
        </div>
      </div>
      <div className={`${className}__quotesContainer`}>
        <div className={`c-QuoteRows`}>
          {renderTableHeader()}
          {quotes.map((quote: QuoteSummaryType, key: number) => {
            return (
              <QuoteRow
                key={key}
                quote={quote}
                url={`${routes.getGenericQuotePage.url}${HOME_INSURANCE_TYPE}`}
                customerContacted={handleCustomerContacted}
              />
            );
          })}
        </div>
      </div>
      {loading && <AnimatedSpinner backgroundColor={offWhite} />}
      {pageNumber < totalPages && (
        <div className={`${className}__loadMore`}>
          <Button
            id="LatestQuotes__loadMoreButton"
            fluid
            quaternary
            onClick={() => {
              getQuotes();
            }}
            type="submit"
          >
            LOAD MORE
          </Button>
        </div>
      )}
    </div>
  );
};

export default AgentLatestQuotes;
