"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _XIcon = require("./XIcon");

var _XIcon2 = _interopRequireDefault(_XIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _XIcon2.default;