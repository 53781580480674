import { DatePicker, InputField } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { isFieldError } from '../../helpers';

const PolicyChangeDate = props => {
  const {
    values, touched, errors, handleBlur,
    handleChange, changeType
  } = props;

  return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="dateFrom"
          className={`${commonFormStylesIdentifier}__fieldLabel`}>Change Start Date
        </label>
        <DatePicker
          error={errors.dateFrom}
          name="dateFrom"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="DD/MM/YYYY"
          touched={touched.dateFrom}
          values={values.dateFrom}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="timeFrom"
          className={`${commonFormStylesIdentifier}__fieldLabel`}>Change Start Time
        </label>
        <InputField
          errorMessage={errors.timeFrom}
          error={isFieldError('timeFrom', touched, errors)}
          type="time"
          name="timeFrom"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.timeFrom}
          values={values.timeFrom}
        />
      </div>
      {changeType === "TEMPORARY" && <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="dateTo"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>Change End Date
          </label>
          <DatePicker
            error={errors.dateTo}
            name="dateTo"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="DD/MM/YYYY"
            touched={touched.dateTo}
            values={values.dateTo}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="timeTo"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>Change End Time
          </label>
          <InputField
            errorMessage={errors.timeTo}
            error={isFieldError('timeTo', touched, errors)}
            type="time"
            name="timeTo"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.timeTo}
            values={values.timeTo}
          />
        </div>
      </>
      }
    </div>
  );
};

PolicyChangeDate.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  changeType: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object
};

export default PolicyChangeDate;
