/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import PropTypes from 'prop-types';
import React from 'react';

import CancelPolicy from '../../components/CancelPolicy';
import { SINGLE_POLICY_STORAGE } from '../../constants/sessionStorage';
import { getObjectFromSessionStorage } from '../../helpers';
import { routes } from '../../routes';

const className = 'p-CancelPolicy';


const CancelPolicyPage = props => {
  const { match, history } = props;
  const insuranceType = match.params.type || '';
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);

  if (!policyDetails || !policyDetails.reference_number) history.push(routes.Wallet.url);

  return (
    <div className={className}>
      <CancelPolicy insuranceType={insuranceType} policyNumber={policyDetails.reference_number} history={history} />
    </div>
  );
};

CancelPolicyPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default CancelPolicyPage;
