"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PersonTwoIcon = function PersonTwoIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 71.429 50",
    width: "71.429",
    height: "50",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("g", {
    id: "supervisor_account-24px",
    transform: "translate(-2 -5)"
  }, _react2.default.createElement("circle", {
    id: "Ellipse_20",
    cx: "5.357",
    cy: "5.357",
    r: "5.357",
    style: {
      opacity: .3
    },
    "data-name": "Ellipse 20",
    transform: "translate(21.643 12.143)"
  }), _react2.default.createElement("path", {
    id: "Path_1573",
    d: "M4.8 19.571h15.357A11.64 11.64 0 0 1 21.229 16h-1.072A37.432 37.432 0 0 0 4.8 19.571z",
    style: {
      opacity: .3
    },
    "data-name": "Path 1573",
    transform: "translate(7.2 28.286)"
  }), _react2.default.createElement("path", {
    id: "Path_1574",
    d: "M27 30a12.654 12.654 0 0 0 12.5-12.5A12.654 12.654 0 0 0 27 5a12.654 12.654 0 0 0-12.5 12.5A12.654 12.654 0 0 0 27 30zm0-17.857a5.493 5.493 0 0 1 5.357 5.357 5.357 5.357 0 1 1-10.714 0A5.493 5.493 0 0 1 27 12.143zm.357 35.714H12a37.1 37.1 0 0 1 15-3.571h1.071a16.258 16.258 0 0 1 5.714-6.429A48.8 48.8 0 0 0 27 37.143c-8.214 0-25 4.286-25 12.5V55h25v-5.357a3.22 3.22 0 0 1 .357-1.786zm26.429-8.929c-6.429 0-19.643 3.571-19.643 10.714V55h39.286v-5.357c0-7.143-13.215-10.714-19.643-10.714zm4.285-6.428a8.48 8.48 0 0 0 4.643-7.857A8.929 8.929 0 1 0 49.5 32.5a9.509 9.509 0 0 0 4.286 1.071 8.484 8.484 0 0 0 4.285-1.071z",
    "data-name": "Path 1574"
  })));
};

exports.default = PersonTwoIcon;