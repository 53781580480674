import './GoCardless.scss';

import { InputField, WideDivider } from '@arachas/core/lib';
import { Dropdown } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { dividerColor } from '../../../../constants/colors';
import { commonFormStylesIdentifier } from '../../../../constants/styleConstants';
import { isFieldError } from '../../../../helpers';
import GoCardlessLogo from './GoCardlessLogo';

const className = 'c-GoCardless';

const dateValues = (() => {
  const values = [];
  for (let day = 1; day <= 28; day++) values.push(day);
  return values;
})();

const GoCardless = props => {
  const { values, errors, touched, handleChange, setFieldTouched } = props;

  const handleCustomBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  return (
    <div className={className}>

      <div className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withMarginTop`}>
        <label
          htmlFor="accountHolderName"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Account Holder Name
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('accountHolderName', touched, errors)}
            errorMessage={errors.accountHolderName}
            name="accountHolderName"
            placeholder="Type here"
            touched={touched.accountHolderName}
            value={values.accountHolderName}
            onChange={handleChange}
            type="text"
            onBlur={handleCustomBlur.bind(this, 'accountHolderName')}
          />
        </span>
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withMarginTop`}>
        <label
          htmlFor="bic"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          BIC
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('bic', touched, errors)}
            errorMessage={errors.bic}
            name="bic"
            placeholder="Type here"
            touched={touched.bic}
            value={values.bic}
            onChange={handleChange}
            type="text"
            onBlur={handleCustomBlur.bind(this, 'bic')}
          />
        </span>
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withMarginTop`}>
        <label
          htmlFor="iban"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          IBAN
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('iban', touched, errors)}
            errorMessage={errors.iban}
            name="iban"
            placeholder="Type here"
            touched={touched.iban}
            value={values.iban}
            onChange={handleChange}
            type="text"
            onBlur={handleCustomBlur.bind(this, 'iban')}
          />
        </span>
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withMarginTop`}>
        <label
          htmlFor="collectionDate"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Preferred Debit Day
        </label>
        <span className={`${className}__input`}>
          <Dropdown
            error={isFieldError('collectionDate', touched, errors)}
            errorMessage={errors.collectionDate}
            name="collectionDate"
            placeholder="Type here"
            touched={touched.collectionDate}
            value={values.collectionDate}
            onChange={handleChange}
            type="text"
            onBlur={handleCustomBlur.bind(this, 'collectionDate')}
          >
            <option value="">Select a number</option>
            {dateValues.map((day) => (
              <option key={`collectionDate-${day}`}>{day}</option>
            ))}

          </Dropdown>
        </span>
      </div>
      <div>
        <GoCardlessLogo />
        <div>Payment by GoCardless. Read the <a href='https://gocardless.com/privacy/payers'
          className={`${className}__link`}
          target='_blank'
          rel="noopener noreferrer"
        >GoCardless privacy notice</a></div>

        <div className={`${commonFormStylesIdentifier}__dividerContainer ${className}__divider`}>
          <WideDivider height={1} color={dividerColor} />
        </div>
      </div>
    </div>
  );
};

GoCardless.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default GoCardless;
