/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { URL_PROMOCODE } from '../../../../../constants';
import { getObjectFromSessionStorage } from '../../../../../helpers';
import { InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import PromoCode from '../../../../PromoCode';
import { aboutYouFormIdentifier } from '../../../CommonFiles/commonFormConstants';
import { getFieldActiveClassNames } from '../aboutYouFormHelper';
interface Props {
  insuranceType?: string,
  fieldNamePrefix?: string,
  isSubFormComponent?: boolean,
  hideFieldsList?: Array<string>;
}

export const BasePromoCodeDetails = (props: Props & FormikProps) => {
  const insuranceTypeContext = useContext(InsuranceTypeContext);
  const insuranceType = props.insuranceType || insuranceTypeContext;

  const className = 'c-PromoCodeDetails';
  const {
    errors,
    values,
    setFieldValue,
  } = props.formik;

  const { isSubFormComponent } = props;

  useEffect(() => {
    const promocodeObject = getObjectFromSessionStorage(`${insuranceType}${URL_PROMOCODE}`);
    if (JSON.stringify(promocodeObject) !== '{}') {
      setFieldValue('promoCode', promocodeObject);
      sessionStorage.removeItem(`${insuranceType}${URL_PROMOCODE}`);
    }
  }, []);

  return (
    <div className={className}>

      {inFormStructure('promoCode', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
          <PromoCode values={values} errors={errors} setFieldValue={setFieldValue} insuranceType={insuranceType} />
        </div>
      }
    </div >
  );
};

BasePromoCodeDetails.propTypes = {
  formik: PropTypes.object
};

const PromoCodeDetails = connect(BasePromoCodeDetails);

export default PromoCodeDetails;
