/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../../styles/commonFormStyles.scss';
import './YourCarCoverForm.scss';

import { Button, Checkbox, DatePicker } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  MYSELF_AND_ADDITIONAL_DRIVERS,
  MYSELF_AND_SPOUSE,
  MYSELF_ONLY
} from '../../../constants/motor/whoIsDrivingYourCarConstants';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants/commonClassNames';
import { isFieldError } from '../../../helpers';
import { MarketingConsent } from '../../MarketingConsent';
import MotorClaims from '../../MotorClaims';
import ResourceButtonGroup from '../../ResourceButtonGroup';
import { emptyAdditionalDriver } from '../YourCarCoverForm/YouCarCoverFormSections/AdditionalDrivers/AdditionalDriversInitialValues';
import { AdditionalDrivers, PenaltyPoints } from './YouCarCoverFormSections';

export const yourCarCoverMainDriverPrefix = 'mainDriver';

const className = 'c-YourCarCoverForm';
const additionalDriversFieldName = 'additionalDrivers';
const dutyOfDisclosureURL = 'https://www.arachas.ie/duty-of-disclosure.pdf';
const termsOfBusinessURL = 'https://www.arachas.ie/tob';
const dataPrivacy = '//www.arachas.ie/compliance/privacy-policy/';
const scheduleOfFees = '//www.arachas.ie/schedule-of-fees.pdf';
const remuneration = '//www.arachas.ie/compliance/remuneration/';
const termsOfUse = 'https://www.arachas.ie/terms-of-use.pdf';


const checkboxLabelFontSize = 16;

const YourCarCoverForm = (props) => {
  const {
    errors,
    handleBlur,
    setFieldValue,
    touched,
    values,
    setTouched,
    setFieldTouched,
    formFilledBefore,
    resources
  } = props;

  const handleCustomBlur = (
    fieldName,
    e,
  ) => {
    setFieldValue(fieldName, values[fieldName]);
    handleBlur(e);
  };

  const fillDriverFields = (whoIsDriving) => {
    if (whoIsDriving === MYSELF_ONLY) return;
    const relationshipToProposer = whoIsDriving === MYSELF_AND_SPOUSE ? {
      relationshipToProposer: {
        'id': '541',
        'alias': 'S',
        'description': 'Spouse',
        'display_order': 0,
      }
    } : {};
    setFieldValue(additionalDriversFieldName, [{
      ...emptyAdditionalDriver,
      ...values[additionalDriversFieldName],
      ...relationshipToProposer
    }]);
  };

  const updateDriverValues = (whoIsDrivingObj) => {
    if (whoIsDrivingObj.alias === MYSELF_ONLY) {
      // Clear additional drivers
      setFieldTouched(additionalDriversFieldName, []);
      setFieldValue(additionalDriversFieldName, []);
    } else if (whoIsDrivingObj.alias === MYSELF_AND_SPOUSE) {
      // Limit additional driver to one
      setFieldValue(additionalDriversFieldName, [values[additionalDriversFieldName][0]]);
    }
    setFieldValue('whoIsDrivingYourCar', whoIsDrivingObj);
    fillDriverFields(whoIsDrivingObj.alias);
  };

  useEffect(() => {
    const alreadyPicked = () => values.whoIsDrivingYourCar && values.whoIsDrivingYourCar.alias;
    if (resources.who_driving && !alreadyPicked()) {
      const defaultValue = resources.who_driving.find(who =>
        who.alias === MYSELF_AND_SPOUSE
      );
      setFieldValue('whoIsDrivingYourCar', defaultValue);
      fillDriverFields(MYSELF_AND_SPOUSE);
    }
  }, [resources]);

  const showAdditionalDrivers = () => {
    const checkWhoIsDrivingHasAlias = () => values.whoIsDrivingYourCar !== undefined && values.whoIsDrivingYourCar !== '' && values.whoIsDrivingYourCar.alias;
    const getMaxDrivers = () => values.whoIsDrivingYourCar.alias === MYSELF_AND_SPOUSE ? 1 : 5;
    const shouldHideRelationship = () => values.whoIsDrivingYourCar.alias === MYSELF_AND_SPOUSE;
    return checkWhoIsDrivingHasAlias() && (values.whoIsDrivingYourCar.alias === MYSELF_AND_SPOUSE ||
      values.whoIsDrivingYourCar.alias === MYSELF_AND_ADDITIONAL_DRIVERS) ? (
      <div className={`${className}__additionalDriversContainer`}>
        <AdditionalDrivers
          {...props}
          baseFieldName={additionalDriversFieldName}
          fieldFilledBefore={formFilledBefore}
          maxDrivers={getMaxDrivers()}
          hideRelationship={shouldHideRelationship()}
          onBlur={handleCustomBlur}
        />
      </div>
    ) : <></>;
  };

  const getWhoIsDrivingResources = () => {
    if (resources.who_driving.length > 0) {
      return [
        {
          ...resources.who_driving.find(who => who.alias === 'Myself_only'),
          icon: 'one_person'
        },
        {
          ...resources.who_driving.find(who => who.alias === 'Myself_and_Spouse'),
          icon: 'two_people'
        },
        {
          ...resources.who_driving.find(who => who.alias === 'Myself_and_Additional_Drivers'),
          icon: 'person_children'
        }
      ];
    } else return [];
  };

  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <label htmlFor="coverType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Cover type
        </label>
        <div className={`${className}__compButton`}>
          <Button
            fluid
            value={values.coverType}
            error={isFieldError('coverType', touched, errors)}
            errorMessage={errors.coverType}
          >
            <b>COMPREHENSIVE</b>
          </Button>
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <label htmlFor="policyStart" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Policy start date
        </label>
        <DatePicker
          error={errors.policyStart}
          name="policyStart"
          onBlur={handleBlur}
          onChange={(e) =>
            setFieldValue('policyStart', e.currentTarget.value)
          }
          placeholder="Type here"
          touched={touched.policyStart}
          value={values.policyStart}
        />
      </div>
      <PenaltyPoints
        driverTypePrefix={yourCarCoverMainDriverPrefix}
        showDisqualifiedOrConvictedField={true}
        onBlur={handleCustomBlur}
        {...props}
        mainContainerCustomClass={`${className}__penaltyPointsMainContainer`}
        repeatingFieldsCustomClass={`${className}__penaltyPointsRepeatingContainer`}
        fieldFilledBefore={formFilledBefore}
        resources={resources}
      />
      <div className={`${className}__componentContainer ${commonFormStylesIdentifier}__dividerContainer`}>
        <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <div className={`${className}__motorClaimsLabelContainer`}>
          <label
            className={`${commonFormStylesIdentifier}__fieldLabel`}
            htmlFor="motorClaims"
          >
            How many accidents or claims have you or anyone that may drive the car, had in the last five years?
          </label>
        </div>
        <MotorClaims
          errors={errors}
          fieldName="motorClaims"
          onChange={(e) => setFieldValue('motorClaims', e)}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          touched={touched}
          values={values}
          onBlur={handleCustomBlur}
          resources={resources}
        />
      </div>
      <div className={`${className}__componentContainer ${commonFormStylesIdentifier}__dividerContainer`}>
        <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="whoIsDrivingYourCar" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            {`Who will be driving your car?`}
            <div className={`${className}__infoText`}>
              If you add an additional driver you could save on the cost of your insurance
            </div>
          </label>
          <ResourceButtonGroup
            error={isFieldError('whoIsDrivingYourCar', touched, errors)}
            errorMessage={errors.whoIsDrivingYourCar}
            onBlur={handleBlur}
            name="whoIsDrivingYourCar"
            selected={values.whoIsDrivingYourCar}
            onClick={updateDriverValues}
            options={getWhoIsDrivingResources()}
            largerButton
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          {showAdditionalDrivers()}
        </div>
      </div>
      <div
        className={`${commonFormStylesIdentifier}__termsAndConditionsContainer ${className}__termsAndConditionsContainer--padding`}>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="disclosureRequirements"
            name="disclosureRequirements"
            label={
              <>
                Your quote and cover is based on your
                <a href={dutyOfDisclosureURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Duty of Disclosure
                </a>. Please tick to confirm you have read and accepted these.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.disclosureRequirements}
            onChange={() => setFieldValue('disclosureRequirements', !values.disclosureRequirements)}
            value={values.disclosureRequirements}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            label={
              <>
                Please tick to confirm acceptance of our
                <a href={termsOfBusinessURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Business
                </a>,
                <a href={termsOfUse}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Use,
                </a>
                &nbsp;

                <a href={dataPrivacy}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Data Privacy
                </a>,
                <a href={scheduleOfFees}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Schedule of Fees
                </a> and
                <a href={remuneration}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Remuneration documents
                </a>
                {' '} before proceeding.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedTermsAndConditions}
            onChange={() => setFieldValue('acceptedTermsAndConditions', !values.acceptedTermsAndConditions)}
            value={values.acceptedTermsAndConditions}
          />
        </div>
        <MarketingConsent values={values} checkboxLabelFontSize={checkboxLabelFontSize} setFieldValue={setFieldValue} />
      </div>
    </div>
  );
};

YourCarCoverForm.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  formFilledBefore: PropTypes.bool,
  resources: PropTypes.object,
  latestQuote: PropTypes.object
};

export default YourCarCoverForm;
