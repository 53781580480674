import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../constants/insuranceTypeConstants';

var API_KEY = {
  [CAR_INSURANCE_TYPE]: process.env.REACT_APP_CAR_PAYSAFE_KEY,
  [HOME_INSURANCE_TYPE]: process.env.REACT_APP_HOME_PAYSAFE_KEY,
  [TRAVEL_INSURANCE_TYPE]: process.env.REACT_APP_TRAVEL_PAYSAFE_KEY,
  [DEVICE_INSURANCE_TYPE]: process.env.REACT_APP_DEVICE_PAYSAFE_KEY,
  [VAN_INSURANCE_TYPE]: process.env.REACT_APP_VAN_PAYSAFE_KEY,
};

const ACCOUNT_ID = {
  [CAR_INSURANCE_TYPE]: process.env.REACT_APP_CAR_PAYSAFE_MERCHANT_ID,
  [HOME_INSURANCE_TYPE]: process.env.REACT_APP_HOME_PAYSAFE_MERCHANT_ID,
  [TRAVEL_INSURANCE_TYPE]: process.env.REACT_APP_TRAVEL_PAYSAFE_MERCHANT_ID,
  [DEVICE_INSURANCE_TYPE]: process.env.REACT_APP_DEVICE_PAYSAFE_MERCHANT_ID,
  [VAN_INSURANCE_TYPE]: process.env.REACT_APP_VAN_PAYSAFE_MERCHANT_ID
};

let instance = null;

let isValid = {
  'cvv': undefined, 'cardNumber': undefined, 'expiryDate': undefined,
};

const isCardValid = () => !(Object.values(isValid).includes(false) || Object.values(isValid).includes(undefined));

const getInvalidField = errorMessage => {
  if (errorMessage.indexOf('ccv') >= 0) return 'ccv';
  if (errorMessage.indexOf('card number') >= 0) return 'cardNumber';
  if (errorMessage.indexOf('expiry date') >= 0) return 'expiryDate';
};

const emptyContainerElementExists = selector =>
  document.getElementById(selector) &&
  document.getElementById(selector).children.length === 0;

export const initPaysafe = (options) => {
  if (window && window.paysafe && emptyContainerElementExists(options.fields.cardNumber.selector.replace('#', ''))) {
    window.paysafe.fields.setup(API_KEY[options.insuranceType], options, function (paysafeInstance, error) {
      if (error) {
        // disable next line for console log because it gives us message that something when bad with paysafe
        // eslint-disable-next-line
        console.warn('Error setting up paysafe.');
      } else {
        instance = paysafeInstance;

        paysafeInstance.cardBrandRecognition(function (instance, response) {
          const cardBrand = response.data.cardBrand;
          if (options.setCardBrand) options.setCardBrand(cardBrand);
        });

        ['cvv', 'cardNumber', 'expiryDate'].forEach(field => {
          instance.fields(field).fieldValueChange(function () {
            isValid[field] = false;
            if (options.setInvalidFields) options.setInvalidFields(isValid);
          });
          instance.fields(field).valid(function () {
            isValid[field] = true;
            options.onChange(isCardValid());
            if (options.setInvalidFields) options.setInvalidFields(isValid);
          });
          instance.fields(field).invalid(function () {
            isValid[field] = false;
            options.onChange(isCardValid());
            if (options.setInvalidFields) options.setInvalidFields(isValid);
          });
        });
      }
    });
  }
};

export const pay = (successFn, errorFn, insuranceType, pay3DSObj = false) => {
  if (!instance) {
    // disable next line for console log because it gives us message that something when bad with paysafe
    // eslint-disable-next-line
    console.warn("No paysafe instance");
    return;
  }
  const callback = (paysafeInstance, error, result) => {
    if (error) {
      if (errorFn) errorFn(error.code, error.detailedMessage, getInvalidField(error.message));
    } else {
      if (successFn) successFn(result.token);
    }
  };
  if (pay3DSObj.amount) {
    const tokenizeObj = {
      threeDS: {
        amount: pay3DSObj.amount,
        currency: "EUR",
        accountId: parseInt(ACCOUNT_ID[insuranceType]),
        useThreeDSecureVersion2: true
      },
      vault: {
        holderName: pay3DSObj.cardHolder
      }
    };
    instance.tokenize(tokenizeObj, callback);
  } else {
    instance.tokenize(callback);
  }
};
