import './DocumentsUploadedPage.scss';

import PropTypes from 'prop-types';
import React from 'react';

export const ErrorModal = () => {
  const className = 'c-DocumentsUploadedPage';
  return (
    <div>
      <div className={`${className}__heading`}>Error Message</div>
      <div className={`${className}__text`}>
        We are unable to retrieve your document at this time.
        Please try again later
    </div>
    </div>);
};

export const DeleteModal = (props) => {
  const className = 'c-DocumentsUploadedPage';
  return (
    <div>
      <div className={`${className}__heading`}>Delete Document</div>
      <div className={`${className}__text`}>
        Are you sure you want to delete
        this document?
      <div className={`${className}__redError`}>{props.errorDeleteMsg}</div>
      </div>
    </div>);
};

DeleteModal.propTypes = {
  errorDeleteMsg: PropTypes.string
};



