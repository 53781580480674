/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import GenericClaimPage from '../GenericClaimPage';
import SubmitClaimDefaultPage from '../SubmitClaimDefaultPage/SubmitClaimDefaultPage';
import DefaultClaimPage from './DefaultClaimPage';


interface Props {
  match: Match,
  history: RouterHistory;
}

const SubmitClaimPage = ({ match, history }: Props) => {

  const type = match.params.type || '';

  const claimPages = {
    [TRAVEL_INSURANCE_TYPE]: <SubmitClaimDefaultPage />,
    [HOME_INSURANCE_TYPE]: <GenericClaimPage history={history} match={match} />,
    [DEVICE_INSURANCE_TYPE]: <SubmitClaimDefaultPage />,
    [CAR_INSURANCE_TYPE]: <GenericClaimPage history={history} match={match} />,
    [VAN_INSURANCE_TYPE]: <GenericClaimPage history={history} match={match} />,
    [""]: ""
  };

  return claimPages[type] || <DefaultClaimPage history={history} />;
};

export default withRouter(SubmitClaimPage);
