"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./Card.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../constants/colors");

var _CardWrapper = require("../CardWrapper");

var _CardWrapper2 = _interopRequireDefault(_CardWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * 
 */
var className = 'c-Card';

var Card = function Card(_ref) {
  var _ref$border = _ref.border,
      border = _ref$border === void 0 ? 'none' : _ref$border,
      _ref$boxShadow = _ref.boxShadow,
      boxShadow = _ref$boxShadow === void 0 ? '0 2px 4px 0 rgba(0, 0, 0, 0.08)' : _ref$boxShadow,
      children = _ref.children,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? _colors.whiteColor : _ref$color,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? '100%' : _ref$height,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? '100%' : _ref$width,
      _ref$margin = _ref.margin,
      margin = _ref$margin === void 0 ? '0.5%' : _ref$margin;
  var cardStyle = {
    height: height,
    width: width,
    border: border,
    backgroundColor: color,
    boxShadow: boxShadow,
    margin: margin
  };
  return _react2.default.createElement("div", {
    className: className,
    style: cardStyle
  }, _react2.default.createElement(_CardWrapper2.default, {
    alignItemsCenter: false
  }, children));
};

exports.default = Card;