/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { getItemFromSessionStorage, makeRequest } from '../../helpers';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

export const sendRenewalDate = async (payload, cognitoAccessTokenKey) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/profile/renewal`,
    authToken: authToken,
    payload: payload
  });
};


export const removeRenewalDate = async (payload, cognitoAccessTokenKey) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return await makeRequest({
    method: 'DELETE',
    url: `${basePath}/profile/renewal`,
    authToken: authToken,
    payload: payload
  });
};

export const getRenewalDates = async (cognitoAccessTokenKey) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/profile/renewal`,
    authToken: authToken
  });
};
