/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './ChangePassword.scss';

import { Button, InputField,MobileCard } from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants';
import { routes } from '../../routes';


const ChangePasswordForm = (props) => {
  const className = 'c-ChangePassword';
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    touched,
    values,
    history
  } = props;
  const { cognitoUser } = useContext(CognitoContext);
  const isFieldError = (status) => {
    return dirty && touched[status] && errors[status] !== undefined;
  };

  const completeChangePassword = async () => {
    Auth.changePassword(cognitoUser, values.currentPassword, values.newPassword)
      .then(() => {
        history.push(routes.Wallet.url)
      })
      .catch((error) => {
        setFieldError('confirmNewPassword', error.message);
      });
  };

  return (
    <div className={`${className}`}>
      <Form autoComplete={"off"}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__container`}>
          <div className={`${className}__title`}>Change Password</div>

          <MobileCard>
            <div className={`${className}__fieldLabel`}>
              <label htmlFor="currentPassword" className={`${className}__label`}>
                Current Password
            </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('currentPassword')}
                  errorMessage={errors.currentPassword}
                  name="currentPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Type here"
                  touched={touched.currentPassword}
                  type="password"
                  autoComplete={"off"}
                  values={values.currentPassword}
                />
              </span>
            </div>
            <div className={`${className}__fieldLabel`}>
              <label htmlFor="newPassword" className={`${className}__label`}>
                New Password
            </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('newPassword')}
                  errorMessage={errors.newPassword}
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Type here"
                  touched={touched.newPassword}
                  type="password"
                  autoComplete={"off"}
                  values={values.newPassword}
                />
              </span>
            </div>
            <div className={`${className}__fieldLabel`}>
              <label htmlFor="confirmNewPassword" className={`${className}__label`}>
                Confirm New Password
            </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('confirmNewPassword')}
                  errorMessage={errors.confirmNewPassword}
                  name="confirmNewPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Type here"
                  touched={touched.confirmNewPassword}
                  type="password"
                  autoComplete={"off"}
                  values={values.confirmNewPassword}
                />
              </span>
            </div>
            <div className={`${className}__button`}>
              <Button
                id='CompleteChangePassword__button'
                fluid={true}
                quaternary
                disabled={!isValid}
                onClick={async () => {
                  await completeChangePassword();
                }}
              >
                CONFIRM
            </Button>
            </div>
          </MobileCard>
        </div>
      </Form>
    </div>
  );
};

const ChangePassword = withFormik({
  mapPropsToValues() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    };
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    currentPassword: yup.string()
      .required('Please enter your current password'),
    newPassword: yup.string()
      .required('Please enter a new password')
      .min(8, 'Requires a minimum of 8 characters')
      .test('new-password', 'It should not be the same as the old one', function (
        value
      ) {
        return this.parent.currentPassword !== value
      }),
    confirmNewPassword: yup.string()
      .required('Please confirm new password')
      .min(8, 'Requires a minimum of 8 characters')
      .test('passwords-match', 'Passwords must match', function (
        value
      ) {
        return this.parent.newPassword === value;
      })
  }),
  displayName: 'ChangePasswordForm'
})(ChangePasswordForm);

ChangePasswordForm.propTypes = {
  dirty: PropTypes.bool,
  errors: PropTypes.array,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isValid: PropTypes.bool,
  setFieldError: PropTypes.func,
  touched: PropTypes.array,
  values: PropTypes.array,
  history: PropTypes.object
}

export default ChangePassword;
