/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../styles/commonFormStyles.scss';

import {
DatePicker,
  InputField, Label} from '@arachas/core/lib';
import { FormikProps } from "formik";
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { isFieldError } from '../../../../helpers';
import type { TravelFormPropsType } from '../../../../types/travel/YourTravelTypes';

const InsuredPersonDetails = (props: FormikProps & TravelFormPropsType) => {
  const className = 'c-TravelInsuredPersonDetails';
  const { handleBlur, touched, errors, handleChange, values } = props;
  const firstNameLabel = "Partner's First name", lastNamelabel = "Partner's Last name",
    dobLabel = "Partner's Date of birth";

  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Label htmlFor="partnerFirstName" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          {firstNameLabel}
        </Label>
        <InputField
          name="partnerFirstName"
          placeholder="Type here"
          type="text"
          error={isFieldError('partnerFirstName', touched, errors)}
          errorMessage={errors.partnerFirstName}
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.partnerFirstName}
          value={values.partnerFirstName}
        />
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Label htmlFor="partnerLastName" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          {lastNamelabel}
        </Label>
        <InputField
          name="partnerLastName"
          placeholder="Type here"
          type="text"
          error={isFieldError('partnerLastName', touched, errors)}
          errorMessage={errors.partnerLastName}
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.partnerLastName}
          value={values.partnerLastName}
        />
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Label htmlFor="partnerDOB" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          {dobLabel}
        </Label>
        <DatePicker
          textType
          error={errors.partnerDOB}
          name="partnerDOB"
          placeholder="DD/MM/YYYY"
          onBlur={handleBlur}
          touched={touched.partnerDOB}
          onChange={handleChange}
          value={values.partnerDOB}
        />
      </div>
    </div>
  );
};

export default InsuredPersonDetails;
