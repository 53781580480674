"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./ControllerButton.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/**
    Helper button for inputs, pre styled for easy positioning
 */
var ControllerButton = function ControllerButton(props) {
  return _react2.default.createElement("button", _extends({
    className: "c-ControllerButton"
  }, props), props.children);
};

exports.default = ControllerButton;