/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './ClaimContent.scss';
import '../../styles/commonPageStyles.scss';

import React from 'react';

const className = 'c-ClaimContent';

const MotorClaimHelpContent = () => {

  return (
    <>
      <div className={`${className}__customBullets`}>
        <div className={`${className}__bulletHeading`}>
          Important things to do if involved in an accident:
        </div>
        <ul>
          <li>Exchange insurance details with other drivers(s)</li>
          <li>Exchange contact details with the other drivers(s)</li>
          <li>Report the incident to the local garda station</li>
        </ul>
      </div>
      <div className={`${className}__customBullets`}>
        <div className={`${className}__bulletHeading`}>
          If possible, take photos, including:
      </div>
        <ul>
          <li>Your Car</li>
          <li>Other Car(s)</li>
          <li>Other Drivers Car Insurance disc(s)</li>
          <li>Scene of the accident</li>
          <li>Location</li>
        </ul>
      </div>
    </>
  );
};

export default MotorClaimHelpContent;