export const COMPREHENSIVE = {
    id: 549,
    alias: 'COMP',
    description: 'Comprehensive',
};

export const THIRD_PARTY = {
    id: 550,
    alias: 'TPFT',
    description: 'Third Party Fire And Theft',
};

export const COMPREHENSIVE_DESCRIPTION = 'Comprehensive';
export const THIRD_PARTY_DESCRIPTION = 'Third Party Fire And Theft';

export const MYSELF_ONLY = '1';
export const MYSELF_AND_SPOUSE = '2';
export const MYSELF_AND_NAMED_DRIVERS = '3';
export const OPEN_DRIVING = '4';
export const OPEN_DRIVING_AND_NAMED_DRIVERS = '5';