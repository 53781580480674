"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Toggle = require("../../containers/Toggle");

var _Toggle2 = _interopRequireDefault(_Toggle);

var _utils = require("../../utils");

var _Switch = require("../Switch");

var _Switch2 = _interopRequireDefault(_Switch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ToggleSwitch = function ToggleSwitch(_ref) {
  var on = _ref.on,
      onToggle = _ref.onToggle;
  return _react2.default.createElement(_Toggle2.default, {
    onToggle: onToggle,
    on: on
  }, function (_ref2) {
    var on = _ref2.on,
        toggle = _ref2.toggle;
    return _react2.default.createElement(_Switch2.default, {
      on: on,
      onClick: toggle
    });
  });
};

ToggleSwitch.defaultProps = {
  onToggle: _utils.noop,
  on: false
};
ToggleSwitch.propTypes = {
  onToggle: _propTypes2.default.func,
  on: _propTypes2.default.bool
};
exports.default = ToggleSwitch;