
export const comprehensiveCoverage = [
    "Unlimited cover for injury to other people (pedestrians, passengers and other drivers)",
    "€5 million protection for any damage to other people’s property",
    "Loss or theft of tools if stolen from your van or destroyed in an accident",
    "Third party trailer cover while attached to vehicle",
    "Emergency medical treatment at the scene of an accident",
    "No Claims Discount Stepback – (If you hold a No Claims Bonus of 4 years or more, you won’t lose your full discount if you need to claim)",
    "New replacement van (If your van is less than 1 year old and repairs will cost more than 60% of the vehicle value)",
    "Fire and theft claims will not affect your No Claims Discount",
    "Motor rescue - 24-hour breakdown assistance in Ireland or the UK",
    "Fire Brigade charges up to €1270",
    "Replacement locks up to €750",
    "Personal Belongings up to €250",
    "Legal Expenses Cover (If you are the victim of a miscarriage of justice or an uninsured driver, legal expenses cover will enable you to pursue damages)",
    "Personal Injury cover (Financial benefit in the event of being hospitalised, limb or sight loss, or death)",
];

export const thirdPartyCoverage = [
    "Unlimited cover for injury to other people (pedestrians, passengers and other drivers)",
    '€5 million protection for any damage to other people’s property',
    'Third party trailer cover while attached to vehicle',
    'Emergency medical treatment at the scene of an accident',
    'No Claims Discount Stepback – (If you hold a No Claims Bonus of 4 years or more, you won’t lose your full discount if you need to claim)',
    'New replacement van (If your van is less than 1 year old and repairs will cost more than 60% of the vehicle value)',
    'Fire and theft claims will not affect your No Claims Discount',
    'Motor rescue - 24-hour breakdown assistance in Ireland or the UK',
    'Fire Brigade charges up to €1270',
    'Replacement locks up to €750',
    'Personal Belongings up to €250',
    'Legal Expenses Cover (If you are the victim of a miscarriage of justice or an uninsured driver, legal expenses cover will enable you to pursue damages)',
    'Personal Injury cover (Financial benefit in the event of being hospitalised, limb or sight loss, or death)'
];
