import {
  aboutYouFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';
import { CAR_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getDateOfBirthMin } from '../../helpers';
import { getQuoteFormStructure } from './getQuoteFormStructure';

const employmentStatusValues = {
  'OCC143': 'HOUSEWIFE_HUSBAND',
  'OCC234': 'RETIRED',
  'OCC260': 'STUDENT',
  'SEF': 'SELF_EMPLOYED',
  'UAC': 'UNEMPLOYED'
};

const getEmploymentStatusCode = (occupationCode) => {
  if (['OCC143',
    'OCC234',
    'OCC260',
    'SEF',
    'UAC'].includes(occupationCode)) return employmentStatusValues[occupationCode];
  return occupationCode ? 'EMPLOYED' : "";
};

const getOrEmpty = (fieldValue) => fieldValue || "";

const getAddressFieldValue = (address, fieldName) => address && address[fieldName] ? address[fieldName] : '';

const getTitleOrEmpty = (insuranceType, fieldValue) => {
  if (!fieldValue) {
    return '';
  } else if (fieldValue === 'DR') {
    return insuranceType === CAR_INSURANCE_TYPE ? '' : fieldValue;
  }
  return fieldValue;
};

export const mapAboutYouProfileValues = (values, insuranceType) => ({
  title: getTitleOrEmpty(insuranceType, values.title),
  firstName: getOrEmpty(values.first_name),
  lastName: getOrEmpty(values.last_name),
  email: getOrEmpty(values.email),
  addressLine1: getAddressFieldValue(values.address, 'address_line1'),
  addressLine2: getAddressFieldValue(values.address, 'address_line2'),
  addressLine3: getAddressFieldValue(values.address, 'address_line3'),
  addressTown: getAddressFieldValue(values.address, 'town'),
  addressCounty: getAddressFieldValue(values.address, 'county'),
  addressEircode: getAddressFieldValue(values.address, 'eircode'),
  phoneNo: getOrEmpty(values.phone),
  dob: getOrEmpty(values.date_of_birth),
  employmentStatus: getEmploymentStatusCode(values.occupation),
  mainOccupation: values.occupation
});

export const getFormikObject = (insuranceType, formName, formYupSchema) => {
  if (
    !getQuoteFormStructure[insuranceType] ||
    !getQuoteFormStructure[insuranceType][formName]
  )
    return {};
  const fieldsToValidate = getQuoteFormStructure[insuranceType][formName];
  const yupSchema = {};
  fieldsToValidate.forEach(fieldName => {
    yupSchema[fieldName] = formYupSchema[fieldName];
  });
  const overwriteDob = () => formName === aboutYouFormIdentifier && insuranceType === VAN_INSURANCE_TYPE && formYupSchema.dob
  if (overwriteDob()) {
    yupSchema.dob = getDateOfBirthMin(18)
  }
  return yupSchema;
};