/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Location, Match } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import MtaCancelPolicyThankYou from '../../components/MtaCancelPolicyThankYou/MtaCancelPolicyThankYou';
import MtaChangeCarThankYou from '../../components/MtaChangeCarThankYou/MtaChangeCarThankYou';
import MtaDefaultThankYou from '../../components/MtaDefaultThankYou/MtaDefaultThankYou';
import MtaThankYouSubmitClaimContent from '../../components/MtaThankYouSubmitClaimContent';
import MtaUploadDocumentsThankYou from '../../components/MtaUploadDocumentsThankYou/MtaUploadDocumentsThankYou';

interface Props {
  location: Location;
  match: Match;
  pageType?: string;
}

const MtaThankYouPage = (props: Props) => {
  let location = useLocation();
  const pageType = location.state && location.state.pageType ? location.state.pageType : '';
  const insuranceType = props.match.params.type || '';

  const getThankYou = {
    "": "",
    changeCar: <MtaChangeCarThankYou />,
    cancelCar: <MtaCancelPolicyThankYou insuranceType={insuranceType} />,
    submitClaim: <MtaThankYouSubmitClaimContent />,
    uploadDocuments: <MtaUploadDocumentsThankYou insuranceType={insuranceType} />,
    defaultPage: <MtaDefaultThankYou />
  };


  return getThankYou[pageType];
};

export default MtaThankYouPage;
