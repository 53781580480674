export const vanInsuranceAboutYouFormFields = [
    "proposerType",
    "tradingAs",
    "companyName",
    'title',
    'firstName',
    'lastName',
    'dob',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressCounty',
    'addressEircode',
    'phoneNo',
    'email',
    'traderOccupation',
    'vatRegistered',
    // 'yearsOfResidency',
    'driversLicenseType',
    'licenceCountry',
    'yearsHeld',
    'promoCode'
];

export const vanInsuranceYourCoverFormFields = [
    'vanReg',
    'vanMake',
    'vanModel',
    'vanType',
    'vanYear',
    'vanWeight',
    'vanDescription',
    'vehicleValue',
    'annualKm',
    'signage',
    'areaUse',
    'subAreaUse',
    'ncdYears',
    'ncdInsurer',
    'ncdProctection',
    'privateCar',
];

export const vanInsuranceYourVanFormFields = [
    'coverType',
    'policyStart',
    'penaltyPoints',
    'mainDriverDisqualifiedOrConvicted',
    'claimsCount',
    'motorClaims',
    'acceptedTermsAndConditions',
    'disclosureRequirements',
    'whoIsDrivingYourVan',
    'additionalDrivers',
    'marketingConsent'
];
