import './GoCardlessLegal.scss';

import { Checkbox } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import {
  CONFIRMATION_TEXT, getAuthorisationText, PAYMENT_SCHEDULE_TEXT,
  REFERENCE_NUMBER_TEXT,
  REFUND_TOC_TEXT,
} from './legalTextValues';

const className = 'c-GoCardlessLegal';
const checkboxLabelFontSize = 16;

const GoCardlessLegal = (props) => {
  const { values, setFieldValue } = props;

  return (
    <div className={className}>
      <h3 className={`${className}__title`}>
        Terms and conditions of sale
      </h3>

      <div>Please confirm that you accept the following</div>

      <div className={`${className}__checkbox`}>
        <Checkbox
          id="authorisation"
          name="authorisation"
          label={getAuthorisationText(`${className}__link`)}
          labelFontSize={checkboxLabelFontSize}
          isChecked={values.authorisation}
          onChange={() => setFieldValue('authorisation', !values.authorisation)}
          value={values.authorisation}
        />
      </div>
      <div className={`${className}__checkbox`}>
        <Checkbox
          id="confirmation"
          name="confirmation"
          label={CONFIRMATION_TEXT}
          labelFontSize={checkboxLabelFontSize}
          isChecked={values.confirmation}
          onChange={() => setFieldValue('confirmation', !values.confirmation)}
          value={values.confirmation}
        />
      </div>
      <div className={`${className}__checkbox`}>
        <Checkbox
          id="refundTOC"
          name="refundTOC"
          label={REFUND_TOC_TEXT}
          labelFontSize={checkboxLabelFontSize}
          isChecked={values.refundTOC}
          onChange={() => setFieldValue('refundTOC', !values.refundTOC)}
          value={values.refundTOC}
        />
      </div>
      <div className={`${className}--margin`}>
        {REFERENCE_NUMBER_TEXT}
      </div>
      <div className={`${className}--margin`}>
        {PAYMENT_SCHEDULE_TEXT}
      </div>
    </div>
  );
};

GoCardlessLegal.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default GoCardlessLegal;
