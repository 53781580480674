import './MyPolicies.scss';

import { AccordionCard } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import {
  SINGLE_POLICY_STORAGE
} from '../../constants';
import { saveObjectSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import Policy from './Policy';

const className = "c-MyPolicies";

const MyPolicies = (props) => {
  const {
    userProfile,
    history
  } = props;

  const goToPolicyPage = (policyDetails, insuranceType) => {
    saveObjectSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`, policyDetails);
    history.push(`${routes.manageMyInsuranceProduct.url}${insuranceType}`);
  };

  return (
    <div className={`${className}__container`}>
      <AccordionCard
        label="My Policies"
        status="none"
      >
        {userProfile.policies.map((policy, index) => <Policy policy={policy} onClick={goToPolicyPage} className={className} key={index} />)}
      </AccordionCard>
    </div>
  );
};

MyPolicies.propTypes = {
  userProfile: PropTypes.object,
  history: PropTypes.object
};

export default MyPolicies;
