// @flow
import './PaymentFrequency.scss';

import { ButtonGroup } from "@arachas/core/lib";
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants/styleConstants';

const className = 'c-PaymentsFrequency'; // TODO refactor class name

const PaymentFrequency = (props: FormikProps<{ paymentFrequency: string; }>) => {
  const { setFieldValue, paymentOptions, values } = props;
  return (
    <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__paddedContainer`}>
      <div className={`${className}__buttonGroupLabelMargin`}>
        <label htmlFor="paymentFrequency" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Choose your payment type
        </label>
      </div>
      <span className={`${className}__input`}>
        <ButtonGroup
          name="paymentFrequency"
          onClick={(e: string) =>
            setFieldValue('paymentFrequency', e)
          }
          options={paymentOptions}
          selected={values.paymentFrequency}
        />
      </span>
    </div>
  );
};

export default PaymentFrequency;
