"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DropzoneUploader = require("./DropzoneUploader");

var _DropzoneUploader2 = _interopRequireDefault(_DropzoneUploader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _DropzoneUploader2.default;