/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Signup.scss';
import '../../styles/commonPageStyles.scss';

import { Button, DesktopCard, InputField, TitleWithUnderLine } from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import TagManager from 'react-gtm-module';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { commonFormStylesIdentifier, commonPageStylesIdentifier, EMAIL_TO_REGISTER_STORAGE, SIGN_UP_PAGE_EMAIL_STORAGE } from '../../constants';
import { LOGIN_DESTINATION } from '../../constants/sessionStorage';
import { getItemFromSessionStorage, removeFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';

type Props = {};
type Values = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

const getEmailFromStorage = () => getItemFromSessionStorage(EMAIL_TO_REGISTER_STORAGE);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const SignupForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-Signup';
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    touched,
    values
  } = props;

  const destination = useQuery().get('destination')

  const signup = async (fieldValues: Values) => {
    Auth.signUp({
      username: fieldValues.email,
      password: fieldValues.password,
      attributes: {
        given_name: fieldValues.firstName,
        family_name: fieldValues.lastName,
        email: fieldValues.email
      }
    })
      .then((result: any) => {
        saveInSessionStorage(SIGN_UP_PAGE_EMAIL_STORAGE, fieldValues.email);
        if (destination) {
          saveInSessionStorage(LOGIN_DESTINATION, destination)
        }
        removeFromSessionStorage(EMAIL_TO_REGISTER_STORAGE);
        TagManager.dataLayer({
          dataLayer: DataLayers.afterRegistrationAttempt({
            userId: result.userSub
          }),
          dataLayerName: 'dataLayer'
        })
        props.history.push({
          pathname: routes.verifyEmail.url,
          state: { email: fieldValues.email }
        });
      })
      .catch((e: Error) => {
        setFieldError('confirmPassword', e.message);
        TagManager.dataLayer({
          dataLayer: DataLayers.afterRegistrationAttempt({ errorMessage: e.message }),
          dataLayerName: 'dataLayer'
        })
      });
  };

  return (
    <div className={`${className}`}>
      <DesktopCard>
        <div className={`${className}__container`}>
          <div className={`${className}__innerContent`}>
            <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
              <div className={`${className}__mobileTitle`}>
                <TitleWithUnderLine>Create your account</TitleWithUnderLine>
              </div>
            </div>

            <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
              <div className={`${className}__customDesktopTitle`}>
                Create your account
              </div>
              <div className={`${className}__subTitle`}>
                <p>Register your account now to access your document details and have all your insurance information in one convenient place.</p>
              </div>
            </div>

            <Form>
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label htmlFor="firstName" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                  First Name
                </label>
                <span className={`${className}__input`}>
                  <InputField
                    error={isFieldError('firstName', touched, errors)}
                    errorMessage={errors.firstName}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type Here"
                    touched={touched.firstName}
                    type="text"
                    value={values.firstName}
                  />
                </span>
              </div>
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label htmlFor="lastName" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                  Surname
                </label>
                <span className={`${className}__input`}>
                  <InputField
                    error={isFieldError('lastName', touched, errors)}
                    errorMessage={errors.lastName}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type Here"
                    touched={touched.lastName}
                    type="text"
                    value={values.lastName}
                  />
                </span>
              </div>
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label htmlFor="email" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                  Email address
                </label>
                <span className={`${className}__input`}>
                  <InputField
                    error={isFieldError('email', touched, errors)}
                    errorMessage={errors.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type Here"
                    touched={touched.email}
                    type="email"
                    value={values.email}
                  />
                </span>
              </div>
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label htmlFor="password" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                  Password
                </label>
                <span className={`${className}__input`}>
                  <InputField
                    error={isFieldError('password', touched, errors)}
                    errorMessage={errors.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type Here"
                    touched={touched.password}
                    type="password"
                    value={values.password}
                  />
                </span>
              </div>
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label htmlFor="confirmPassword" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                  Confirm Password
                </label>
                <span className={`${className}__input`}>
                  <InputField
                    error={isFieldError('confirmPassword', touched, errors)}
                    errorMessage={errors.confirmPassword}
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type Here"
                    touched={touched.confirmPassword}
                    type="password"
                    value={values.confirmPassword}
                  />
                </span>
              </div>
              <div className={`${className}__button`}>
                <Button
                  id='SignupPage__signupButton'
                  fluid
                  quaternary
                  disabled={!isValid}
                  onClick={async () => {
                    await signup(values);
                  }}
                  type="submit"
                >
                  CREATE ACCOUNT
                </Button>
              </div>
            </Form>

            <Link
              to={{ pathname: routes.loginPage.url }}
              className={`${className}__link`}
              data-ga
              id="SignupPage__loginLink"
              data-testid="SignupPage__loginLink"
            >
              Already signed up ? Login to MyArachas
            </Link>
          </div>
        </div>
      </DesktopCard>
    </div>
  );
};

const Signup = withFormik < Props, Values> ({
  mapPropsToValues(): {} {
    const email = getEmailFromStorage();
    return {
      firstName: '',
      lastName: '',
      email: email ? email : '',
      password: '',
      confirmPassword: ''
    };
  },
  handleSubmit(
    values: Values,
    { setSubmitting }: FormikProps
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    firstName: yup.string().required('First name is required')
      .matches(/^[a-zA-Z ]+$/, 'Numbers and symbols are not permitted at this time'),
    lastName: yup.string().required('Last name is required')
      .matches(/^[a-zA-Z ]+$/, 'Numbers and symbols are not permitted at this time'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Requires a minimum of 8 characters'),
    confirmPassword: yup.string()
      .required('Please confirm password')
      .min(8, 'Requires a minimum of 8 characters')
      .test('passwords-match', 'Passwords must match', function (
        value: string
      ): boolean {
        return this.parent.password === value;
      })
  }),
  displayName: 'SignupForm'
})(SignupForm);

export default Signup;
