// @flow
import './CardLogo.scss';

import React from 'react';

const className = 'c-CardLogo';

const CardLogo = (props: { cardBrand: string }) => {
  const { cardBrand } = props;
  const altText = `${cardBrand} logo`;
  const logoUrl = `/asset/${cardBrand}.png`;

  return (
    <div className={className}>
      <img className={`${className}__cardLogoImage`} alt={altText} src={logoUrl} />
    </div>
  );
};

export default CardLogo;
