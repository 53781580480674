"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PersonOneIcon = require("./PersonOneIcon");

var _PersonOneIcon2 = _interopRequireDefault(_PersonOneIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PersonOneIcon2.default;