"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var OKIcon = function OKIcon(_ref) {
  var className = _ref.className;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 135.268 135.42",
    className: className
  }, _react2.default.createElement("g", {
    id: "Group_2334",
    "data-name": "Group 2334",
    transform: "translate(-120 -118)"
  }, _react2.default.createElement("g", {
    id: "Group_169",
    "data-name": "Group 169",
    transform: "translate(120 118)"
  }, _react2.default.createElement("path", {
    id: "Path_511",
    "data-name": "Path 511",
    d: "M202.874,185.442l-22.532,22.532-7.82-7.82a3.381,3.381,0,0,0-4.782,4.782l10.211,10.211a3.339,3.339,0,0,0,2.391.985,3.459,3.459,0,0,0,2.391-.985l24.895-24.895a3.382,3.382,0,0,0-4.754-4.81Z",
    transform: "translate(-119.919 -132.583)",
    fill: "#6db841"
  })), _react2.default.createElement("g", {
    id: "Group_227",
    "data-name": "Group 227",
    transform: "translate(120 118)"
  }, _react2.default.createElement("path", {
    id: "Path_512",
    "data-name": "Path 512",
    d: "M25.392,321.488a3.383,3.383,0,1,0-6.217,2.672,3.423,3.423,0,0,0,3.094,2.053,3.324,3.324,0,0,0,1.35-.281A3.4,3.4,0,0,0,25.392,321.488Z",
    transform: "translate(-13.662 -229.584)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_513",
    "data-name": "Path 513",
    d: "M11.69,278.035a3.373,3.373,0,1,0-3.319,4.051A2.775,2.775,0,0,0,9.046,282,3.36,3.36,0,0,0,11.69,278.035Z",
    transform: "translate(-3.673 -197.89)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_514",
    "data-name": "Path 514",
    d: "M111.506,41.222a3.49,3.49,0,0,0,1.8-.506,3.368,3.368,0,1,0-4.641-1.069A3.322,3.322,0,0,0,111.506,41.222Z",
    transform: "translate(-77.807 -24.79)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_515",
    "data-name": "Path 515",
    d: "M74.828,69.544a3.419,3.419,0,0,0,2.335-.928,3.382,3.382,0,1,0-4.67-4.895h0a3.376,3.376,0,0,0,2.335,5.823Z",
    transform: "translate(-51.424 -45.123)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_516",
    "data-name": "Path 516",
    d: "M3.649,235.6a3.386,3.386,0,0,0,3.376-3.376v-.141a3.376,3.376,0,0,0-6.751,0v.169A3.362,3.362,0,0,0,3.649,235.6Z",
    transform: "translate(-0.273 -164.377)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_517",
    "data-name": "Path 517",
    d: "M72.689,396.023a3.383,3.383,0,1,0,4.782-.141A3.406,3.406,0,0,0,72.689,396.023Z",
    transform: "translate(-51.648 -283.866)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_518",
    "data-name": "Path 518",
    d: "M7.52,189.354a2.774,2.774,0,0,0,.675.084,3.341,3.341,0,0,0,3.291-2.7,3.387,3.387,0,0,0-6.639-1.35h0A3.482,3.482,0,0,0,7.52,189.354Z",
    transform: "translate(-3.525 -131.291)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_519",
    "data-name": "Path 519",
    d: "M447.875,164.328h0a3.367,3.367,0,0,0,3.207,2.363,3.186,3.186,0,0,0,1.013-.169,3.36,3.36,0,0,0,2.194-4.219h0a3.363,3.363,0,1,0-6.414,2.025Z",
    transform: "translate(-321.853 -114.957)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_520",
    "data-name": "Path 520",
    d: "M243.971,6.755h.169A3.378,3.378,0,0,0,244.308,0a3.408,3.408,0,0,0-3.544,3.207A3.408,3.408,0,0,0,243.971,6.755Z",
    transform: "translate(-173.112 0)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_521",
    "data-name": "Path 521",
    d: "M289.106,13.553a2.9,2.9,0,0,0,.844.113,3.389,3.389,0,1,0-.844-.113Z",
    transform: "translate(-206.038 -4.969)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_522",
    "data-name": "Path 522",
    d: "M460.134,205.277a3.37,3.37,0,0,0,.338,6.723,1.369,1.369,0,0,0,.338-.028,3.364,3.364,0,1,0-.675-6.695Z",
    transform: "translate(-328.599 -147.523)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_523",
    "data-name": "Path 523",
    d: "M113.71,423.808a3.372,3.372,0,1,0-3.544,5.738,3.468,3.468,0,0,0,1.772.506,3.367,3.367,0,0,0,2.869-1.6A3.4,3.4,0,0,0,113.71,423.808Z",
    transform: "translate(-78.097 -304.225)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_524",
    "data-name": "Path 524",
    d: "M197.749,9.106a3.057,3.057,0,0,0,.506-.028,3.374,3.374,0,1,0-3.882-2.813A3.457,3.457,0,0,0,197.749,9.106Z",
    transform: "translate(-139.745 -1.704)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_525",
    "data-name": "Path 525",
    d: "M20.787,144.832a3.106,3.106,0,0,0,1.322.281A3.349,3.349,0,0,0,25.2,143.06a3.383,3.383,0,1,0-4.416,1.772Z",
    transform: "translate(-13.53 -99.427)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_526",
    "data-name": "Path 526",
    d: "M42.723,104.093a3.494,3.494,0,0,0,1.941.591,3.441,3.441,0,0,0,2.785-1.435,3.375,3.375,0,1,0-4.726.844Z",
    transform: "translate(-29.755 -70.39)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_527",
    "data-name": "Path 527",
    d: "M43.023,360.547a3.375,3.375,0,0,0,1.941,6.132,3.314,3.314,0,0,0,1.941-.619,3.371,3.371,0,1,0-3.882-5.513Z",
    transform: "translate(-29.971 -258.686)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_528",
    "data-name": "Path 528",
    d: "M432.8,124.189a3.364,3.364,0,1,0-2.954-1.716A3.449,3.449,0,0,0,432.8,124.189Z",
    transform: "translate(-308.693 -84.41)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_529",
    "data-name": "Path 529",
    d: "M408.862,378.448a3.371,3.371,0,0,0-4.388,5.12,3.423,3.423,0,0,0,2.194.816,3.294,3.294,0,0,0,2.56-1.181A3.38,3.38,0,0,0,408.862,378.448Z",
    transform: "translate(-289.929 -271.41)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_530",
    "data-name": "Path 530",
    d: "M406.267,86.183A3.375,3.375,0,1,0,403.708,85,3.368,3.368,0,0,0,406.267,86.183Z",
    transform: "translate(-289.641 -57.093)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_531",
    "data-name": "Path 531",
    d: "M460.81,251.677a3.377,3.377,0,0,0-.647,6.723c.112,0,.225.028.338.028a3.384,3.384,0,0,0,.309-6.751Z",
    transform: "translate(-328.6 -180.87)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_532",
    "data-name": "Path 532",
    d: "M153.087,20.631a2.938,2.938,0,0,0,1.181-.225,3.373,3.373,0,1,0-4.332-2A3.32,3.32,0,0,0,153.087,20.631Z",
    transform: "translate(-107.686 -9.966)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_533",
    "data-name": "Path 533",
    d: "M434.683,340.038A3.375,3.375,0,1,0,436,344.623,3.375,3.375,0,0,0,434.683,340.038Z",
    transform: "translate(-308.886 -244.083)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_534",
    "data-name": "Path 534",
    d: "M452.295,297.209a3.372,3.372,0,0,0-2,6.442,3.147,3.147,0,0,0,1.013.141,3.361,3.361,0,0,0,.985-6.582Z",
    transform: "translate(-321.998 -213.491)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_535",
    "data-name": "Path 535",
    d: "M371.043,410.322h0a3.364,3.364,0,0,0-.591,4.726,3.327,3.327,0,0,0,2.672,1.294,3.36,3.36,0,0,0,2.672-5.429A3.427,3.427,0,0,0,371.043,410.322Z",
    transform: "translate(-265.809 -294.396)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_536",
    "data-name": "Path 536",
    d: "M244.471,457.4a3.408,3.408,0,0,0-3.207,3.544,3.372,3.372,0,0,0,3.376,3.207h.169a3.38,3.38,0,1,0-.338-6.751Z",
    transform: "translate(-173.471 -328.735)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_537",
    "data-name": "Path 537",
    d: "M198.9,455.213a3.37,3.37,0,0,0-.985,6.667,3.06,3.06,0,0,0,.506.028,3.365,3.365,0,0,0,.478-6.695Z",
    transform: "translate(-140.248 -327.135)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_538",
    "data-name": "Path 538",
    d: "M154.669,443.877a3.373,3.373,0,1,0-2.335,6.329h0a3.429,3.429,0,0,0,1.153.2,3.368,3.368,0,0,0,1.181-6.526Z",
    transform: "translate(-107.973 -318.865)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_539",
    "data-name": "Path 539",
    d: "M289.506,450.451a3.373,3.373,0,0,0,.844,6.639,2.9,2.9,0,0,0,.844-.113,3.37,3.37,0,0,0-1.688-6.526Z",
    transform: "translate(-206.326 -323.666)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_540",
    "data-name": "Path 540",
    d: "M332.338,434.531a3.378,3.378,0,0,0,1.491,6.414,3.493,3.493,0,0,0,1.491-.338,3.384,3.384,0,1,0-2.982-6.076Z",
    transform: "translate(-237.569 -312.05)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_541",
    "data-name": "Path 541",
    d: "M331.851,29.407a3.493,3.493,0,0,0,1.491.338,3.378,3.378,0,0,0,1.491-6.414h0a3.384,3.384,0,1,0-2.982,6.076Z",
    transform: "translate(-237.222 -16.52)",
    fill: "#6db841"
  }), _react2.default.createElement("path", {
    id: "Path_542",
    "data-name": "Path 542",
    d: "M370.643,53.537a3.379,3.379,0,1,0-.591-4.754A3.361,3.361,0,0,0,370.643,53.537Z",
    transform: "translate(-265.522 -34.123)",
    fill: "#6db841"
  }))));
};

exports.default = OKIcon;