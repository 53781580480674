/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Documents.scss';

import { Button } from '@arachas/core/lib';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../../routes';

interface Props {
  type: string;
}

interface State {}

class Documents extends Component<Props, State> {
  render() {
    const className = 'c-Documents';

    return (
      <div className={className}>
        <div className={`${className}__buttonContainer`}>
          <Link to={`${routes.policyLinks.url}car`}>
            <Button fluid={true}>Car Documents</Button>
          </Link>
          <div className={`${className}__buttonSpacing`}>
            <Link to={`${routes.policyLinks.url}home`}>
              <Button fluid={true}>Home Documents</Button>
            </Link>
          </div>
          <div className={`${className}__buttonSpacing`}>
            <Link to={`${routes.policyLinks.url}travel`}>
              <Button fluid={true}>Travel Documents</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Documents;
