/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import React from 'react';

const mobileLabel = () => <b>Mobile</b>;
const tabletLabel = () => <b>Tablet</b>;

const IPID = () => <>
  See Device IPID for full benefits{' '}
  <a href='https://www.arachas.ie/arachas-zurich-device-IPID-document' target="_blank" rel="noopener noreferrer"><b>here</b></a></>;

export const featureList = [
  { label: () => mobileLabel(), tick: false },
  { label: () => 'Accidental Damage €1500', tick: true },
  { label: () => 'Liquid Damage €1500', tick: true },
  { label: () => 'Theft or Loss €1500', tick: true },
  { label: () => 'Unauthorised Calls €1500', tick: true },

  { label: () => tabletLabel(), tick: false },
  { label: () => 'Accidental Damage €1000', tick: true },
  { label: () => 'Liquid Damage €1000', tick: true },
  { label: () => 'Theft €1000', tick: true },

  { label: () => IPID(), tick: true },
];
