"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _StarSolidIcon = require("./StarSolidIcon");

var _StarSolidIcon2 = _interopRequireDefault(_StarSolidIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _StarSolidIcon2.default;