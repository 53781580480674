/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './DocumentsUploadPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  Button,
  DesktopCard
} from '@arachas/core/lib';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import IconAndTitle from '../../components/IconAndTitle/IconAndTitle';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonFormStylesIdentifier, commonPageStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getAccessToken, getObjectFromSessionStorage } from '../../helpers';
import { isValidResourceValue } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';
import {
  uploadDocument
} from "../../services/uploadService";
import DocUploadAndDropdown from './DocUploadAndDropdown';

const className = 'c-DocumentsUploadPage';

const DocumentsUploadPageForm = (props) => {

  const {
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
    handleBlur,
    resources,
    isValid,
    setTouched,
  } = props;

  const { match, history } = props;
  const insuranceType = match.params.type || '';

  const [buttonContent, setButtonContent] = useState("UPLOAD TO WALLET");
  const { cognitoUser } = useContext(CognitoContext);
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);

  const policyNumber = policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;
  const pageTitle = 'Upload Documents';

  const customPageTitle = {
    [CAR_INSURANCE_TYPE]: "Upload Car Documents",
    [HOME_INSURANCE_TYPE]: "Home Documents",
    '': ''
  };

  const getSubtitle = {
    [HOME_INSURANCE_TYPE]: 'Please upload an up to date valuation for any specified item with a value above €5,000',
  };

  useEffect(() => {
    if (insuranceType === HOME_INSURANCE_TYPE) {
      setFieldValue('docUpload[0].insuranceType', HOME_INSURANCE_TYPE);
    }
  }, []);

  const generateUploadDocumentsPayload = () => {
    const listOfDocIds = values.docUpload.map(doc =>
      doc.applicationFile[0].options.fileID
    );

    return {
      "policy_reference": policyNumber,
      "document_ids": listOfDocIds
    };
  };

  const submitDocuments = async () => {
    setButtonContent(<>UPLOADING <FontAwesomeIcon icon={faSpinner} spin /> </>);
    const payload = generateUploadDocumentsPayload();
    const postAddItemResponse = await uploadDocument(getAccessToken(cognitoUser), insuranceType, payload);

    //successful message
    if (postAddItemResponse.errorMessage === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data" || postAddItemResponse.errorMessage === "Unexpected end of JSON input") {
      history.push({
        pathname: `${routes.mtaThankYouPage.url}${insuranceType}`,
        state: {
          pageType: 'uploadDocuments'
        }
      });
    } else {
      history.push({
        pathname: `${routes.genericErrorPage.url}${insuranceType}`,
        state: {
          errorType: 'mta'
        }
      });
    }
  };

  return (
    <>
      <div className={`${className}`}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__container`}>
          <div className={`${className}__customTitleTextContainer`}>
            <div className={`${className}__customTitleText`}>
              {customPageTitle[insuranceType]}
            </div>
          </div>
          <DesktopCard>
            <IconAndTitle insuranceType={insuranceType} title={pageTitle} />
            <div className={`${className}__subtitleText`}>{getSubtitle[insuranceType]}</div>
            <DocUploadAndDropdown {...props}
              errors={errors.docUpload}
              touched={touched.docUpload}
              setFieldTouched={setFieldTouched}
              handleBlur={handleBlur}
              resources={resources}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
            />
          </DesktopCard>

          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__button`}>
              <Button
                id="DocumentsUploadPage__submitButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={() => {
                  submitDocuments();
                }}
                type="submit"
              >
                {buttonContent}
              </Button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};



const DocumentsUploadPage = withFormik({
  mapPropsToValues() {
    let initialValues = {
      docUpload: [
        {
          insuranceType: '',
          documentType: {},
          applicationFile: []
        }
      ],
    };
    return initialValues;
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({

    docUpload: yup.array().of(
      yup.object().shape({
        insuranceType: yup.string(),
        documentType: yup
          .object()
          .test('documentType',
            'Please select a document type', function (value) {
              const { insuranceType } = this.parent;
              if (insuranceType === HOME_INSURANCE_TYPE) {
                return true;
              } else {
                return isValidResourceValue(value);
              }
            })
          .nullable(true)
          .required('Please select document type'),
        applicationFile:
          yup.array().min(1).required('File upload is required'),
      }))
  }),
  displayName: 'DocumentsUploadPageForm'
})(DocumentsUploadPageForm);

DocumentsUploadPageForm.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,

  fieldFilledBefore: PropTypes.object,
  resources: PropTypes.object,
  isValid: PropTypes.bool,
};


export default DocumentsUploadPage;