/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { getItemFromSessionStorage, makeRequest } from '../../helpers';

export const getLatestQuotes = async (
  cognitoAccessTokenKey,
  pageSize,
  pageNumber,
  props = {}
) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/home/search/latest_quotes?page=${pageNumber}&size=${pageSize}&sort=created_date,DESC`,
    authToken: authToken,
    history: props.history,
  });
};

export const updateContactedStatus = async (
  cognitoAccessTokenKey,
  quoteReference,
  contacted,
  props = {}
) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'PUT',
    url: `${basePath}/home/home_quotes/contacted`,
    authToken: authToken,
    history: props.history,
    payload: {
      quote_reference: quoteReference,
      contacted: contacted,
    },
  });
};

export const getLatestQuoteFromUser = async (
  cognitoAccessTokenKey,
  email,
  props = {}
) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/home/home_quotes/latest`,
    authToken: authToken,
    history: props.history,
    payload: { email: email },
  });
};
