/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import { HOME_COVER_TYPE, PROPERTY_TYPE_APARTMENT_DESCRIPTION } from '../constants/home';

const isHomeTypeValid = (homeType) => {
  return homeType.description !== PROPERTY_TYPE_APARTMENT_DESCRIPTION;
};

const isCoverTypeValid = (coverType) => {
  return coverType.description !== HOME_COVER_TYPE.RENT;
};

export const rebuildPartOfQuote = (homeType, coverType) => {
  return isHomeTypeValid(homeType) && isCoverTypeValid(coverType);
};