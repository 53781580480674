/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const coverTypeComprehensive = {
  id: 549,
  alias: '01',
  description: 'Comprehensive',
};

export const yourCarCoverFormInitialValues = {
  coverType: coverTypeComprehensive,
  policyStart: '',
  motorClaims: [],
  claimsCount: "",
  mainDriverPenaltyPointOffenceCount: '',
  mainDriverPenaltyPointOffences: [],
  mainDriverDisqualifiedOrConvicted: null,
  additionalDrivers: [],
  whoIsDrivingYourCar: { alias: '' },
  disclosureRequirements: false,
  acceptedTermsAndConditions: false,
  marketingConsent: false,
  emailConsent: false,
  postConsent: false,
  phoneConsent: false,
  smsConsent: false,
  selectAll: false,
  acceptedAssumptions: false,
};
