/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 **/

export const motorViewQuoteFormInitialValues = {
  standardExcess: false,
  personalProtectionPlus: false,
  ncdProtection: false,
  additionalBenefitsPlus: false,
  hasPromoCode: false,
  promoCode: '',
};
