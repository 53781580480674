import '../../../styles/commonPageStyles.scss';
import '../../../styles/commonFormStyles.scss';
import '../SummaryPage.scss';

import { CarIcon, TitleWithUnderLine } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import TagManager from 'react-gtm-module';

import { MobileBackButton } from '../../../components/MobileBackButton';
import ProgressBar from '../../../components/ProgressBar';
import QuoteDisplay from '../../../components/QuoteDisplay';
import {
  commonPageStylesIdentifier
} from '../../../constants';
import { primaryPurple } from '../../../constants/colors';
import {
  CAR_INSURANCE_TYPE
} from '../../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_PRODUCT } from '../../../constants/journey';
import {
  getSinglePayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../../helpers';
import { routes } from '../../../routes';
import DataLayers from '../../../scripts/googleAnalyticsDataLayerObjects';
import { Divider, renderLine, Tick } from '../SummaryComponents';
import { getlistOfInsured, getListOfIPID } from './ListOfCarInsured';


const SummaryPage = (props) => {
  const { history, className } = props;

  const payload = getSinglePayloadObjFromSessionStorage(CAR_INSURANCE_TYPE);
  const values = getValuesObjFromSessionStorage(CAR_INSURANCE_TYPE);

  const getPriceFromPayload = () => {
    const motorQuote = payload.premium;
    return motorQuote.total_amount || 0;
  };

  TagManager.dataLayer({
    dataLayer: DataLayers.landsOnSummaryPage({
      insuranceType: CAR_INSURANCE_TYPE,
      cover_premium: getPriceFromPayload()
    }),
    dataLayerName: 'dataLayer'
  })

  const onProceedClicked = () => {
    history.push({ pathname: `${routes.Assumptions.url}${CAR_INSURANCE_TYPE}` });
  };

  const renderDrivers = () => {
    const drivers = values.additionalDrivers.length > 0 ? values.additionalDrivers : [];
    return (
      <>
        Named drivers entitled to drive under this policy <br />
        <div className={`${className}--additionalDrivers`}>
          <b>{`${values.firstName} ${values.lastName}`}</b> <br />
          {drivers.map((driver, index) => {
            if (driver.firstName && driver.lastName) {
              return <div key={index}><b>{`${driver.firstName} ${driver.lastName}`}</b> <br /></div>;
            } else return <></>;
          }
          )}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Product Summary</TitleWithUnderLine>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__titleArea`}>
            <div className={`${className}__titleArea__icon ${className}__titleArea__icon--${CAR_INSURANCE_TYPE}`}>
              <CarIcon color={primaryPurple} />
            </div>
            <h2>{"Car"}</h2>
          </div>
          <div className={`${className}__title`}>Just to remind you</div>
          <div className={`${className}__infoArea`}>
            <p>Your Premium is <b>€{getPriceFromPayload().toFixed(2)}</b></p>
            <Divider />
            <p>Your Cover type is <b>Comprehensive</b></p>
            <Divider />
            <p>Your Vehicle registration is <b>{values.carReg || ''}</b></p>
            <Divider />
            {renderDrivers()}
          </div>
          <div className={`${className}__title`}>{`What's Insured`}</div>
          <div className={`${className}__coverageArea`}>
            {getlistOfInsured[payload.scheme].map((line, index) => <React.Fragment key={index}>{renderLine(line)}</React.Fragment>)}
            <Tick className={className}>
              <p>
                <a href={getListOfIPID[payload.scheme].url} target="_blank" rel="noopener noreferrer">
                  {getListOfIPID[payload.scheme].label}
                </a>
              </p>
            </Tick>
          </div>
        </div>
      </div>
      <QuoteDisplay
        price={getPriceFromPayload()}
        sticky
        onClickFunction={onProceedClicked}
        title='Total'
      />
    </div>
  );
};

SummaryPage.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string
};

export default SummaryPage;
