import { endOfToday, subYears } from 'date-fns';
import * as yup from "yup";

import {
  irishToISOFormat,
  ISODateConvertable
} from './DateHelper';

export const nullableBooleanRequired = (testName, message) => {
  return yup.boolean().test(testName, message,
    function (value) {
      return value !== null;
    }).nullable(true);
};

export const stringRequired = (message) => {
  return yup.string().required(message);
};

export const objectRequired = (message) => {
  return yup.object().required(message);
};

export const dateOfBirthYup = yup.mixed()
  .test('doneFilling', 'Please enter a valid date', (dob) => {
    const dobISO = irishToISOFormat(dob)
    if (!ISODateConvertable(dobISO)) return false
    return true
  })
  .test('minDate', 'Please enter a date after 1900', (dob) => {
    const dobISO = irishToISOFormat(dob)
    if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
    const dobDate = new Date(dobISO)
    const minDate = new Date('1900-01-01')
    return +dobDate >= +minDate
  })
  .test('maxDate', 'You must be over 18 to take out a policy', (dob) => {
    const dobISO = irishToISOFormat(dob)
    if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
    const dobDate = new Date(dobISO)
    const maxDate = subYears(endOfToday(), 18)
    return +dobDate <= +maxDate
  })
  .required('Date of birth is required')

  
export const getDateOfBirthRange = (underAgeLimit, overAgeLimit, pronoun = 'You') => yup.mixed()
.test('doneFilling', 'Please enter a valid date', (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return false
  return true
})
.test('minDate', `${pronoun} must be over the age of ${underAgeLimit} and under the age of ${overAgeLimit} to take out a policy`, (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
  const dobDate = new Date(dobISO)
  const minDate = subYears(endOfToday(), overAgeLimit)
  return +dobDate >= +minDate
})
.test('maxDate', `${pronoun} must be over the age of ${underAgeLimit} and under the age of ${overAgeLimit} to take out a policy`, (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
  const dobDate = new Date(dobISO)
  const maxDate = subYears(endOfToday(), underAgeLimit)
  return +dobDate <= +maxDate
})
.required('Date of birth is required')

export const getDateOfBirthMin = (underAgeLimit, pronoun = 'You') => yup.mixed()
.test('doneFilling', 'Please enter a valid date', (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return false
  return true
})
.test('maxDate', `${pronoun} must be over the age of ${underAgeLimit} to take out a policy`, (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
  const dobDate = new Date(dobISO)
  const maxDate = subYears(endOfToday(), underAgeLimit)
  return +dobDate <= +maxDate
})
.required('Date of birth is required')