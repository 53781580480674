/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './SubmitClaimPage.scss';

import {
  Button,
  CarIcon,
  DesktopCard,
  PhoneButton,
} from '@arachas/core/lib';
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import { backToWallet } from '../../constants/buttonLabelConstants';
import { primaryPurple } from '../../constants/colors';
import { allianzTeam, arachasPhoneNumber } from '../../constants/phoneNumbers';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';

const title = 'Car';
interface Props {
  history: RouterHistory;
}

const DefaultClaimPage = ({ history }: Props) => {
  const className = 'c-SubmitClaimPage';

  return (
    <> <div className={`${className}__customTitleTextContainer`}>
      <div className={`${className}__customTitleText`}>
        {title}

      </div>
    </div>
      <DesktopCard>
        <div className={className}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          <div className={`${className}__iconContainer`}>
            <div className={`${className}__icon`}>
              <CarIcon color={primaryPurple} />
            </div>
          </div>
          <div className={`${className}__customTitle`}>
            <h3>Claims</h3>
          </div>
          <div className={`${className}__content`}>
            <div className={`${className}__customContent`}>
              If you wish to make a claim please call us.
          </div>
            <div className={`${className}__phoneButtonContainer`}>
              <PhoneButton color={primaryPurple}
                phoneNumber={arachasPhoneNumber}
                isWhite
                onClick={() => {
                  fbqTrackEvent('Contact')
                }}>
                Please call our Arachas team
              </PhoneButton><br />
            </div>
          </div>

          <div className={`${className}__customContent`}>
            Or call your insurer directly</div>
          <div className={`${className}__content`}>
            <div className={`${className}__phoneButtonContainer`}>
              <PhoneButton 
                color={primaryPurple}
                phoneNumber={allianzTeam} 
                isWhite
                onClick={() => {
                  fbqTrackEvent('Contact')
                }}>
                Please give the Allianz claims team a call
              </PhoneButton>
            </div>
          </div>

          <div className={`${className}__heading`}>
            Important things to do if involved in an accident:
        </div>

          <div className={`${className}__customList`}>
            <ul>
              <li>Exchange insurance details with the other driver(s)</li>
              <li>Exchange contact details with the other driver(s)</li>
              <li>Report the incident to the local garda station</li>
            </ul>
          </div>

          <div className={`${className}__heading`}>
            If possible, take photos, including:
        </div>
          <div className={`${className}__customList`}>
            <ul>
              <li>Your Car</li>
              <li>Other Car(s)</li>
              <li>Other Driver Car Insurance disc(s)</li>
              <li>Scene of the accident</li>
              <li>Location</li>
            </ul>
          </div>

          <div className={`${className}__buttonContainer`}>
            <Link to={routes.Wallet.url}>
              <Button fluid={true}>{backToWallet}</Button>
            </Link>
          </div>
        </div></DesktopCard></>
  );
};

export default DefaultClaimPage;