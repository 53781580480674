"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _MobileCard = require("./MobileCard");

var _MobileCard2 = _interopRequireDefault(_MobileCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _MobileCard2.default;