import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './SummaryPage.scss';

import PropTypes from 'prop-types';
import React from 'react';

import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import CarSummary from './CarSummary/CarSummary';
import DeviceSummary from './DeviceSummary';
import HomeSummary from './HomeSummary';
import { className } from './SummaryComponents';
import TravelSummary from './TravelSummary';
import VanSummary from './VanSummary/VanSummary';


const SummaryPage = (props) => {
  const { match } = props;
  const insuranceType = match.params.type;

  const getContent = {
    [CAR_INSURANCE_TYPE]: <CarSummary className={className} {...props} />,
    [TRAVEL_INSURANCE_TYPE]: <TravelSummary className={className} {...props} />,
    [DEVICE_INSURANCE_TYPE]: <DeviceSummary className={className} {...props} />,
    [HOME_INSURANCE_TYPE]: <HomeSummary className={className} {...props} />,
    [VAN_INSURANCE_TYPE]: <VanSummary className={className} {...props} />
  };

  return getContent[insuranceType];
};

SummaryPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string
    })
  })
};

export default SummaryPage;
