"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PlaneIcon = function PlaneIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 66.401 56.515",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("g", {
    id: "prefix__flight_takeoff-24px",
    transform: "translate(-1.7 -3.6)"
  }, _react2.default.createElement("path", {
    id: "prefix__Rectangle_1103",
    d: "M0 0H61.711V6.496H0z",
    "data-name": "Rectangle 1103",
    transform: "translate(4.298 53.619)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1563",
    d: "M67.959 23.088a4.842 4.842 0 0 0-5.846-3.573l-17.54 4.872L22.162 3.6l-6.171 1.624 13.317 23.385-16.24 4.222-6.5-4.872-4.868 1.3 8.445 14.616s23.06-6.175 53.916-14.291a5.63 5.63 0 0 0 3.898-6.496z",
    "data-name": "Path 1563"
  })));
};

exports.default = PlaneIcon;