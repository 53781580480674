/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { quantcastDynamicScript } from '../src/scripts/quantcastDynamicElement';
import { CognitoContext } from './CognitoUtils';
import App from './components/App';
import LoadUserRoute from './components/LoadUserRoute';
import PrivateRoute from './components/PrivateRoute';
import { AGENT, PUBLIC } from './constants/agent';
import { isAgent } from './helpers/AgentCheck';
import { routesConfig } from './routes/routesConfig';
import DataLayers from './scripts/googleAnalyticsDataLayerObjects';

// flow is getting removed
// eslint-disable-next-line flowtype/no-weak-types
const getLoadUserRoute = (route, Component, index) => (
  <LoadUserRoute
    key={`loadUserRoute_${index}`}
    exact
    path={route}
    component={Component}
  />
);

// flow is getting removed
// eslint-disable-next-line flowtype/no-weak-types
const getPrivateRoute = (route, Component, index) => (
  <PrivateRoute
    key={`privateRoute_${index}`}
    exact
    path={route}
    component={Component}
  />
);

// flow is getting removed
// eslint-disable-next-line flowtype/no-weak-types
const getRoute = (
  route,
  Component,
  render?,
  index
) =>
  render ? (
    <Route exact path={route} render={render} key={`route_${index}`} />
  ) : (
    <Route exact path={route} component={Component} key={`route_${index}`} />
  );

const { loadUserRoutes, privateRoutes, routes } = routesConfig[
  isAgent() ? AGENT : PUBLIC
];

export const BOIRoutes = () => {
  const location = useLocation()
  const { cognitoUser } = useContext(CognitoContext);
  // On every route change
  useEffect(() => {
    // Add necessary quantcast script
    document.head.prepend(quantcastDynamicScript(location.pathname))
    // Add pageView event to Google Analytics
    TagManager.dataLayer({
      dataLayer: DataLayers.runOnAllPages({
        pathname: location.pathname,
        userId: cognitoUser ? cognitoUser.attributes.sub : null
      }),
      dataLayerName: 'dataLayer'
    })

  }, [location])

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/wallet" />
      </Route>
      <Route>
        {() => (
          <App>
            <Switch>
              <Redirect from="/wallet/address-lookup/" to="/wallet/getgenericquote/home" />
              <Redirect from="/wallet/getquote/carregistration/" to="/wallet/getgenericquote/car" />
              {loadUserRoutes.map(
                (route, index) =>
                  getLoadUserRoute(route.path, route.component, index)
              )}
              {privateRoutes.map(
                (route, index) =>
                  getPrivateRoute(route.path, route.component, index)
              )}
              {routes.map(
                (
                  route,
                  index
                ) => getRoute(route.path, route.component, route.render, index)
              )}
            </Switch>
          </App>
        )}
      </Route>
    </Switch>
  );
}

