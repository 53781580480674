import React from 'react';

const PdfIcon = () => {
  return (

  <svg 
    id="Icon_PDF" 
    data-name="Icon – PDF" 
    xmlns="http://www.w3.org/2000/svg" 
    width="12.903" 
    height="16" 
    viewBox="0 0 12.903 16">

    
  <g id="Group_1869" data-name="Group 1869">
    <g id="Icons">
      <g id="Group_1868" data-name="Group 1868">
        <g id="Group_1867" data-name="Group 1867">
          <g id="PDF">
            <g id="Mask">
              <path id="Path_1317" data-name="Path 1317" d="M9.553,0,13.7,4.486V15a.963.963,0,0,1-.922,1H1.722A.963.963,0,0,1,.8,15V1a.963.963,0,0,1,.922-1ZM4.345,7.145H3.313v3.493H4V9.4h.294a1.324,1.324,0,0,0,.927-.3,1.11,1.11,0,0,0,.326-.862,1.04,1.04,0,0,0-.306-.816,1.3,1.3,0,0,0-.894-.274Zm2.825,0H6.155v3.493H7.07a1.764,1.764,0,0,0,1.312-.459,1.8,1.8,0,0,0,.455-1.321A1.736,1.736,0,0,0,8.4,7.6,1.632,1.632,0,0,0,7.17,7.145Zm4.189,0H9.507v3.493h.675V9.257h1.1v-.6h-1.1v-.9h1.178Zm-4.154.607q.919,0,.919,1.126,0,1.149-.99,1.149H6.841V7.752Zm-2.9,0a.584.584,0,0,1,.412.124.508.508,0,0,1,.131.385.491.491,0,0,1-.157.393.71.71,0,0,1-.473.135H4V7.752Z" transform="translate(-0.8)" fill="#600060" fillRule="evenodd"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
  );
};
export default PdfIcon;