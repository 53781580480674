import { getItemFromSessionStorage, makeRequest } from '../../helpers';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

export async function markMessageAsRead(cognitoAccessTokenKey, id) {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return makeRequest({
    method: 'PUT',
    url: `${basePath}/profile/profile/messages/read?messageId=${id}`,
    authToken: authToken,
  });
}

export async function getMessages(cognitoAccessTokenKey) {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return makeRequest({
    url: `${basePath}/profile/profile/messages?size=1000`,
    authToken: authToken,
  }).then(res => {
    return res.error ? [] : res.content;
  });
}

export async function getReadMessages(cognitoAccessTokenKey) {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return makeRequest({
    url: `${basePath}/profile/profile/messages?size=1000&messageStatus=READ`,
    authToken: authToken,
  }).then(res => {
    return res.error ? [] : res.content;
  });
}
