/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MobileBackButton.scss';

import React from 'react';
import type { RouterHistory } from "react-router-dom";

import { LOCATION_HISTORY_STORAGE } from '../../constants/sessionStorage';
import { getItemFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';

interface Props {
  text?: string;
  history: RouterHistory;
  pageJumpCount?: number;
  callBackPartialPage?: any;
  handlePartialPage?: boolean;
}

const className = 'c-MobileBackButton';
const buttonId = 'MobileBackButton__goBack';

export const MobileBackButton = ({ text = 'BACK', history, pageJumpCount = -1, callBackPartialPage, handlePartialPage = false }: Props) => {

  // eslint-disable-next-line complexity
  const handleChangePage = () => {
    if(handlePartialPage && callBackPartialPage) {
      callBackPartialPage()
    } else {
      const locationHistory = JSON.parse(getItemFromSessionStorage(LOCATION_HISTORY_STORAGE));
      // If the pageJumpCount is less than history length then go to that page else go to the Wallet landing page
      locationHistory && locationHistory.length > Math.abs(pageJumpCount) ? goToPreviousPage(locationHistory) : goToDefaultPage();
    }
  };

  const goToPreviousPage = (locationHistory: Array<string>) => {
    const historyCopy = [...locationHistory];
    historyCopy.splice(pageJumpCount, Math.abs(pageJumpCount));
    saveInSessionStorage(LOCATION_HISTORY_STORAGE, JSON.stringify(historyCopy));
    history.go(pageJumpCount);
  };

  const goToDefaultPage = () => {
    saveInSessionStorage(LOCATION_HISTORY_STORAGE, JSON.stringify([]));
    history.push(routes.Wallet.url);
  };

  return <button type="button" className={`${className}__button`} id={buttonId} data-testid={buttonId} onClick={handleChangePage}>
    <img className={`${className}__arrowLeft`} src="/asset/backArrow.png" alt="Arachas_Logo" />
    <span className={`${className}__text`}>
      {text}
    </span>
  </button >;
};

export default MobileBackButton;
