import {
  aboutYouFormIdentifier,
  yourCarCoverFormIdentifier,
  yourCarFormIdentifier,
  yourDeviceCoverFormIdentifier,
  yourDeviceFormIdentifier,
  yourHomeCoverFormIdentifier,
  yourHomeFormIdentifier,
  yourTravelCoverFormIdentifier,
  yourTravelFormIdentifier,
  yourVanCoverFormIdentifier,
  yourVanFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';
import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  carInsuranceAboutYouFormFields,
  carInsuranceYourCarCoverFormFields,
  carInsuranceYourCarFormFields,
  deviceInsuranceAboutYouFormFields,
  deviceInsuranceYourCoverFormFields,
  deviceInsuranceYourDeviceFormFields,
  homeInsuranceAboutYouFormFields,
  homeInsuranceYourHomeCoverFormFields,
  homeInsuranceYourHomeFormFields,
  travelInsuranceAboutYouFormFields,
  travelInsuranceYourTravelCoverFormFields,
  travelInsuranceYourTravelFormFields,
  vanInsuranceAboutYouFormFields,
  vanInsuranceYourCoverFormFields,
  vanInsuranceYourVanFormFields
} from './displayedFields';

export const getQuoteFormStructure = {
  [HOME_INSURANCE_TYPE]: {
    [aboutYouFormIdentifier]: homeInsuranceAboutYouFormFields,
    [yourHomeFormIdentifier]: homeInsuranceYourHomeFormFields,
    [yourHomeCoverFormIdentifier]: homeInsuranceYourHomeCoverFormFields,
  },
  [CAR_INSURANCE_TYPE]: {
    [aboutYouFormIdentifier]: carInsuranceAboutYouFormFields,
    [yourCarFormIdentifier]: carInsuranceYourCarFormFields,
    [yourCarCoverFormIdentifier]: carInsuranceYourCarCoverFormFields,
  },
  [TRAVEL_INSURANCE_TYPE]: {
    [aboutYouFormIdentifier]: travelInsuranceAboutYouFormFields,
    [yourTravelFormIdentifier]: travelInsuranceYourTravelFormFields,
    [yourTravelCoverFormIdentifier]: travelInsuranceYourTravelCoverFormFields,
  },
  [DEVICE_INSURANCE_TYPE]: {
    [aboutYouFormIdentifier]: deviceInsuranceAboutYouFormFields,
    [yourDeviceFormIdentifier]: deviceInsuranceYourDeviceFormFields,
    [yourDeviceCoverFormIdentifier]: deviceInsuranceYourCoverFormFields,
  },
  [VAN_INSURANCE_TYPE]: {
    [aboutYouFormIdentifier]: vanInsuranceAboutYouFormFields,
    [yourVanFormIdentifier]: vanInsuranceYourCoverFormFields,
    [yourVanCoverFormIdentifier]: vanInsuranceYourVanFormFields
  },
};

export const inFormStructure = (
  field,
  insuranceType,
  formIdentifier,
) => {
  const formStructureArray = getQuoteFormStructure[insuranceType] && getQuoteFormStructure[insuranceType][formIdentifier] ?
    getQuoteFormStructure[insuranceType][formIdentifier] : [];

  return formStructureArray.lastIndexOf(field) !== -1;
};
