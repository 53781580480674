/* eslint-disable no-unused-vars */
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import type { Element } from 'react';
import React from 'react';

import type { DropdownDetailsType } from '../types';

export const mapDropdownOptions = (dropdownOptions: Array<DropdownDetailsType>, optionsKey: string): Array<Element<*>> => {
  return dropdownOptions && dropdownOptions.map((option: DropdownDetailsType, index: number) => {
    return <option key={`${optionsKey}_option_${index}`} value={option.dropdownValue}>
      {option.displayName}
    </option>;
  });
};

export const getValuesFromDropdownDetailsType = (dropdownOptions: Array<DropdownDetailsType>): Array<boolean | string | number> => {
  return dropdownOptions && dropdownOptions.map((option: DropdownDetailsType) => option.dropdownValue);
};
