import { makeRequest } from '../../helpers';

export const UseStatus = () => {

  const checkStatus = async () => {
    const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
    return await makeRequest({
        method: 'GET',
        url: `${basePath}/status`
      });
  };
  return {
    checkStatus
  };
};