/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../components/MobileBackButton/MobileBackButton.scss';

import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-MobileBackButton';
const buttonId = 'MobileBackButton__goBack';

export const CustomMobileBackButton = (props) => {

  return <button type="button" className={`${className}__button`} id={buttonId} data-testid={buttonId} onClick={props.onClick}>
    <img className={`${className}__arrowLeft`} src="/asset/backArrow.png" alt="Arachas_Logo" />
    <span className={`${className}__text`}>
      {'BACK'}
    </span>
  </button >;
};

CustomMobileBackButton.propTypes = {
  onClick: PropTypes.func
}

export default CustomMobileBackButton;
