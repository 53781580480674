
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './AmendProductPage.scss';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { dustyGrayColor } from '../../constants/colors';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';


const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

const HomeAmendProductPage = () => {
  const className = 'c-AmendProductPage';

  return (
    <>
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.updateSpecifiedItems.url}${HOME_INSURANCE_TYPE}`}>
          <ButtonWithIcons
            id={'HomeAmendProductPage__updateSpecifiedItems'}
            IconRight={RightArrowIcon}
          >
            Update specified items
          </ButtonWithIcons>
        </Link>
      </div>
    </>

  );
};


export default HomeAmendProductPage;