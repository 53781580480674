"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterInvalidFile = undefined;

require("filepond/dist/filepond.min.css");

require("./DropzoneUploader.scss");

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactFilepond = require("react-filepond");

var _DropzoneConstants = require("./DropzoneConstants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
var DropzoneUploader = _react2.default.memo(function (_ref) {
  var id = _ref.id,
      files = _ref.files,
      setFiles = _ref.setFiles,
      allowed_formats = _ref.allowed_formats,
      insuranceType = _ref.insuranceType,
      documentName = _ref.documentName,
      policyNumber = _ref.policyNumber,
      accessToken = _ref.accessToken,
      signUploadURL = _ref.signUploadURL,
      scanDocument = _ref.scanDocument,
      removeFile = _ref.removeFile;
  var className = "c-DropzoneUploader";
  var inputEl = (0, _react.useRef)(null);
  var MAX_POLL_TIMES = 10; //10 seconds

  var POLL_INTERVAL_MILISECONDS = 1000;
  var pollTimes = 0;
  var fileID = 0;

  var isDocumentNameNull = function isDocumentNameNull(documentName) {
    return documentName === null || Object.keys(documentName).length === 0;
  };

  var scanStatusTypes = ["CLEAN", "FAILED", "INFECTED"];
  return _react2.default.createElement("div", {
    className: className
  }, _react2.default.createElement(_reactFilepond.FilePond, {
    id: id,
    labelIdle: "Browse or take a photo",
    ref: inputEl,
    files: files,
    allowMultiple: false,
    allowReplace: false,
    labelFileProcessingError: function labelFileProcessingError(error) {
      if (error.code === 0) {
        return error.body;
      }

      return _DropzoneConstants.ERROR_MESSAGES.GENERIC_ERROR;
    },
    server: {
      process: function process(fieldName, file, metadata, load, error, progress, _abort) {
        if (!allowed_formats.includes(file.type)) {
          error(_DropzoneConstants.ERROR_MESSAGES.BAD_FILE_TYPE);
          return;
        }

        if (file.size > _DropzoneConstants.MAX_FILE_SIZE) {
          error(_DropzoneConstants.ERROR_MESSAGES.OVER_MAX_FILE_SIZE);
          return;
        }

        var request = new XMLHttpRequest();

        if (isDocumentNameNull(documentName)) {
          error(_DropzoneConstants.ERROR_MESSAGES.DOCUMENT_NAME_NOT_SELECTED);
          return;
        }

        var payload = {
          "policy_reference": policyNumber,
          "document_name": documentName,
          "file_type": _DropzoneConstants.fileTypeConvert[file.type]
        };
        signUploadURL(accessToken, insuranceType, payload).then(function (signedUrlResponse) {
          if (!signedUrlResponse || signedUrlResponse.error || !signedUrlResponse.url) {
            error(_DropzoneConstants.ERROR_MESSAGES.GENERIC_ERROR);
            return;
          } // Storing the s3_key into the file object so we have a way to retrieve
          // the information when trying to delete the file. We need this when trying to delete
          // after moving back and forth in the app flow


          fileID = signedUrlResponse.id;
          fetch(signedUrlResponse.url, {
            method: 'PUT',
            body: file
          }).then(function (res) {
            if (res.status !== 200) {
              error(_DropzoneConstants.ERROR_MESSAGES.UPLOAD_ERROR_FAILURE);
              return;
            }
          });

          var checkScanStatus = function checkScanStatus(scanFileResponse) {
            if (scanFileResponse.scan_status === "CLEAN") {
              load();
            } else if (scanFileResponse.scan_status === "FAILURE") {
              fileID = 0;
              error(_DropzoneConstants.ERROR_MESSAGES.SCAN_ERROR_FAILURE);
              return;
            } else if (scanFileResponse.scan_status === "INFECTED") {
              fileID = 0;
              error(_DropzoneConstants.ERROR_MESSAGES.SCAN_ERROR_INFECTED);
              return;
            }
          };

          var pollScanFile = function pollScanFile() {
            var scanFileResponse;
            return regeneratorRuntime.async(function pollScanFile$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    _context.next = 2;
                    return regeneratorRuntime.awrap(scanDocument(accessToken, insuranceType, signedUrlResponse.id));

                  case 2:
                    scanFileResponse = _context.sent;

                    if (!(scanFileResponse.error || pollTimes >= MAX_POLL_TIMES)) {
                      _context.next = 9;
                      break;
                    }

                    fileID = 0;
                    error(_DropzoneConstants.ERROR_MESSAGES.SCAN_ERROR_TIMEOUT);
                    return _context.abrupt("return");

                  case 9:
                    if (scanStatusTypes.includes(scanFileResponse.scan_status)) {
                      checkScanStatus(scanFileResponse);
                    } else {
                      pollTimes++;
                      setTimeout(pollScanFile, POLL_INTERVAL_MILISECONDS);
                    }

                  case 10:
                  case "end":
                    return _context.stop();
                }
              }
            });
          };

          pollScanFile();

          request.upload.onprogress = function (e) {
            progress(e.lengthComputable, e.loaded, e.total);
          };
        }).catch(function (err) {
          error(err, " ", _DropzoneConstants.ERROR_MESSAGES.GENERIC_ERROR);
        });
        return {
          abort: function abort() {
            request.abort();

            _abort();
          }
        };
      },
      revert: function revert(uniqueFileId, load, error) {
        var object_name;
        return regeneratorRuntime.async(function revert$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                object_name = typeof uniqueFileId === "string" ? uniqueFileId : uniqueFileId.s3_key;

                if (object_name) {
                  _context2.next = 4;
                  break;
                }

                error(_DropzoneConstants.ERROR_MESSAGES.DELETE_DOCUMENT_FAILED);
                return _context2.abrupt("return");

              case 4:
                load();

              case 5:
              case "end":
                return _context2.stop();
            }
          }
        });
      }
    },
    onprocessfile: function onprocessfile(error, fileItem) {
      fileItem.options = {
        type: "limbo",
        fileID: fileID
      };
      fileItem.file.status = error ? "error" : "success";
      setFiles([fileItem]);
    },
    onremovefile: function onremovefile() {
      removeFile(id);
    }
  }), _react2.default.createElement("div", {
    className: "".concat(className, "__smallLabel")
  }, _DropzoneConstants.dropzoneLabel));
});

var filterInvalidFile = exports.filterInvalidFile = function filterInvalidFile(item) {
  if (!item || !item.file) return false;
  return item.file.status && item.file.status !== "error";
};

DropzoneUploader.propTypes = {
  id: _propTypes2.default.number,
  files: _propTypes2.default.array,
  allowed_formats: _propTypes2.default.array,
  insuranceType: _propTypes2.default.string,
  documentName: _propTypes2.default.object,
  policyNumber: _propTypes2.default.string,
  accessToken: _propTypes2.default.string,
  setFiles: _propTypes2.default.func,
  signUploadURL: _propTypes2.default.func,
  scanDocument: _propTypes2.default.func,
  removeFile: _propTypes2.default.func
};
DropzoneUploader.displayName = 'DropzoneUploader';
exports.default = DropzoneUploader;