/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *

 */
import { TRIP_TYPES, TRIP_TYPES_ICONS, ZONE_KEYS_ICONS } from '../constants/travel/TravelConstants';

export const getZonesForTripType = (tripTypes) => {
  return tripTypes.reduce((map, tripType) => {
    map[tripType.id.toString()] = tripType.destination_zones.map((zone) => ({
      value: zone.id,
      label: zone.value,
      icon: ZONE_KEYS_ICONS[zone.value],
    }));
    return map;
  }, {});
};

export const getTripTypeOptions = (tripTypes) => {
  return tripTypes.map((type) => ({
    value: type.id,
    label: TRIP_TYPES[type.name],
    icon: TRIP_TYPES_ICONS[type.name]
  }));
};

export const getInsuranceProviders = (tripTypes) => {
  return tripTypes.reduce((map, tripType) => {
    map[tripType.id.toString()] = tripType.pmi_providers.map((insuranceProviders) => ({
      dropdownValue: insuranceProviders.id,
      displayName: insuranceProviders.name,
    }));
    return map;
  }, {});
};
