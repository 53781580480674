/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { ButtonGroup, InputField } from '@arachas/core/lib';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { isFieldError } from '../../../../../helpers';
import { InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import { aboutYouFormIdentifier } from '../../../CommonFiles/commonFormConstants';
import { getFieldActiveClassNames } from '../aboutYouFormHelper';

interface Props {
  fieldNamePrefix?: string,
  isSubFormComponent?: boolean,
  hideFieldsList?: Array<string>;
}

/* eslint-disable */
export const BaseBOICustomerAndPromoCode = (props: Props & FormikProps) => {
  const insuranceType = useContext(InsuranceTypeContext);

  const className = 'c-BOICustomerAndPromoCode';
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    values
  } = props.formik;
  const { isSubFormComponent = false } = props;

  const showMortgageApplicationNumberField = () => {
    if (!values.hasMortgage) return <></>;
    return (
      <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
        <label
          htmlFor="mortgageApplicationNumber"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Mortgage application number
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <span className={`${className}__input`}>
            <InputField
              error={errors.mortgageApplicationNumber}
              errorMessage={errors.mortgageApplicationNumber}
              name="mortgageApplicationNumber"
              placeholder="Type here"
              touched={touched.mortgageApplicationNumber}
              value={values.mortgageApplicationNumber}
              onChange={handleChange}
              type="text"
              maxLength={8}
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={className}>

      {/* {inFormStructure('promoCode', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false,isSubFormComponent)}>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='promoCode' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              If you have a promotional code please enter it below.
          </label>
            <InputField
              error={isFieldError('promoCode', touched, errors)}
              errorMessage={errors.promoCode}
              name='promoCode'
              placeholder="Type here"
              value={values.promoCode}
              onChange={handleChange}
              type="text"
              onBlur={handleBlur}
            />
          </div>
        </div>
      } */}
      {inFormStructure('hasMortgage', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="hasMortgage"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              Do you have a Arachas mortgage on the property?
          </label>
            <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
              <ButtonGroup
                key="hasMortgage"
                name="hasMortgage"
                onClick={(value: boolean) => {
                  setFieldValue('hasMortgage', value === 'true');
                  setFieldTouched('hasMortgage', value);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]}
                error={isFieldError('hasMortgage', touched, errors)}
                errorMessage={errors.hasMortgage}
                selected={values.hasMortgage}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
      }
      {inFormStructure('hasMortgage', insuranceType, aboutYouFormIdentifier) &&
        showMortgageApplicationNumberField()
      }
    </div>
  );
};

BaseBOICustomerAndPromoCode.propTypes = {
  formik: PropTypes.object
};

const BOICustomerAndPromoCode = connect(BaseBOICustomerAndPromoCode);

export default BOICustomerAndPromoCode;
