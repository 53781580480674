/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { noop } from '@arachas/core/lib/utils';
import { addDays, endOfToday, parse, startOfToday } from 'date-fns';
import * as yup from 'yup';
import { Schema } from 'yup';

import {
  MYSELF_AND_ADDITIONAL_DRIVERS,
  MYSELF_AND_SPOUSE,
} from '../../../constants/motor/whoIsDrivingYourCarConstants';
import { occupationEmployed, occupationPartTime, occupationSelfEmployed, occupationUnemployed } from '../../../constants/OccupationTypeConstants';
import { dateOfBirthYup, nullableBooleanRequired } from '../../../helpers/CommonYupValidation';
import { isValidResourceValue } from '../../../helpers/FieldErrorHelper';
import { getOccupationOptions } from '../../../mocks/MainOccupationValues';

const moreThanTwo = 'Please enter more than two characters';
const lessThanFifty = 'Please enter in less than fifty characters';
const mainOccupationOptions = getOccupationOptions();

const shouldHaveOccupation = (status: string) => {
  const occupationStatuses: Array<string> = [
    occupationEmployed,
    occupationSelfEmployed,
    occupationUnemployed,
    occupationPartTime,

  ];
  return occupationStatuses.includes(status);
};

const checkIfYearsHeldWithinRange = (
  value: number,
  path: string,
  createError: noop,
) => {
  if (value < 0) {
    return createError({ path, message: 'Please enter a larger number' });
  } else if (value > 100) {
    return createError({ path, message: 'Please enter a smaller number' });
  }
};

const additionalDriverYupSchema = yup.object().shape({
  title: yup
    .object()
    .test('title', 'Please select title', isValidResourceValue)
    .nullable(true)
    .required('Please select Title'),
  //First Name and Last Name: can't be empty, less than 50 chars
  firstName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('Last name is required'),
  dob: dateOfBirthYup,
  employmentStatus: yup
    .object()
    .test('employmentStatus', 'Please select employment status', isValidResourceValue)
    .nullable()
    .required('Please select employment status'),
  mainOccupation: yup
    .string()
    .when(
      'employmentStatus',
      (employmentStatus: string, schema: Schema<string>) =>
        shouldHaveOccupation(employmentStatus)
          ? schema
            .oneOf(
              mainOccupationOptions.map(
                (occupation: { value: string; }) => occupation.value
              ),
              'Occupation is required'
            )
            .required('Occupation is required')
          : schema
    ),
  relationshipToProposer: yup
    .object()
    .test('relationshipToProposer', 'Relationship to proposer', isValidResourceValue)
    .nullable()
    .required('Relationship to proposer'),
  driversLicenseType: yup
    .object()
    .test('driversLicenseType', 'Drivers licence type is required', isValidResourceValue)
    .nullable()
    .required('Drivers licence type is required'),

  yearsHeld: yup
    .string()
    .test('yearsHeldDrivingLicense', 'Years Held is required', function (
      value: string
    ): boolean {
      if (!value) return false;
      const { path, createError } = this;
      const strippedValue = value.replace(/,/g, ``);
      const integerValue = parseInt(strippedValue, 10);
      const error = checkIfYearsHeldWithinRange(
        integerValue,
        path,
        createError,
      );
      if (error) return error;
      return true;
    })
    .nullable(true),
  additionalDriverPenaltyPointOffences: yup
    .array()
    .when('additionalDriverPenaltyPointOffenceCount', {
      is: (penaltyOffenceCount: number) => penaltyOffenceCount > 0,
      then: yup
        .array()
        .of(
          yup.object().shape({
            penaltyPoints: yup
              .object()
              .test('penaltyPoints', 'Penalty points is required', isValidResourceValue)
              .required('Penalty points is required')
              .nullable(),
            offence: yup
              .object()
              .test('offence', 'Offence is required', isValidResourceValue)
              .required('Offence is required')
              .nullable(),
            date: yup
              .date()
              .nullable()
              .transform((_, dateString: string) => {
                return dateString ? parse(dateString) : null;
              })
              .max(
                startOfToday(),
                'Please enter a date of today or in the past',
              )
              .required('Date is required')
              .nullable(),
          }),
        )
        .required('Penalty points and offence required'),
      otherwise: yup.array().notRequired(),
    }),
  additionalDriverPenaltyPointOffenceCount: yup.object()
    .test('additionalDriverPenaltyPointOffenceCount', 'Number of penalty points is required', isValidResourceValue)
    .required('Number of penalty points is required')
    .nullable(),
  useOfOtherCar: yup
    .object()
    .test('useOfOtherCar', 'Use of car is required', isValidResourceValue)
    .nullable()
    .required('Use of car is required'),
});

export const yourCarCoverFormYupSchema = {
  policyStart: yup
    .date()
    .transform((_, dateString: string) => parse(dateString))
    .min(startOfToday(), 'Please enter a date 28 or less days in the future')
    .max(
      addDays(endOfToday(), 28),
      'Please enter a date 28 or less days in the future',
    )
    .required('Policy start date is required'),
  coverType: yup.object()
    .test('coverType', 'Cover type is required', isValidResourceValue)
    .required('Cover type is required'),
    motorClaims: yup.array().of(
      yup.object().shape({
        claim_date: yup.date()
        .transform((_, dateString: string)=> {
          return dateString ? parse(dateString) : null
        })
        .max(
          startOfToday(),
            'Please enter a date of today or in the past',
        )
        .required('Date is required'), 
        claim_type: yup.object().required('Motor claim type is required'),
        claim_status: yup.boolean().required('Motor claim status is required'),
        claim_amount: yup.string().required('Motor claim amount is required'),
      }),
  ),
  claimsCount: yup
    .object()
    .test('claimsCount', 'Number of claims is required', isValidResourceValue)
    .nullable()
    .required('Number of claims is required'),
  mainDriverPenaltyPointOffences: yup
    .array()
    .when('mainDriverPenaltyPointOffenceCount', {
      is: (penaltyOffenceCount: number) => penaltyOffenceCount > 0,
      then: yup
        .array()
        .of(
          yup.object().shape({
            penaltyPoints: yup
              .object()
              .test('penaltyPoints', 'Penalty points is required', isValidResourceValue)
              .required('Penalty points is required')
              .nullable(),
            offence: yup
              .object()
              .test('offence', 'Offence is required', isValidResourceValue)
              .required('Offence is required')
              .nullable(),
            date: yup
              .date()
              .nullable()
              .transform((_, dateString: string) => {
                return dateString ? parse(dateString) : null;
              })
              .max(
                startOfToday(),
                'Please enter a date of today or in the past',
              )
              .required('Date is required')
              .nullable(),
          })
        )
        .required('Penalty points and offence required'),
      otherwise: yup.array().notRequired()
    }),
  mainDriverPenaltyPointOffenceCount: yup
    .object()
    .test('mainDriverPenaltyPointOffenceCount', 'Number of penalty points is required', isValidResourceValue)
    .required('Number of penalty points is required')
    .nullable(),
  mainDriverDisqualifiedOrConvicted: nullableBooleanRequired(
    'isDriverDisqualifiedOrConvicted',
    'You must select an answer to this field',
  ),
  additionalDrivers: yup.array().when('whoIsDrivingYourCar', {
    //TODO @amanda fix this addtionaldrivers validation
    is: (whoIsDrivingYourCar: any) =>
      MYSELF_AND_SPOUSE === whoIsDrivingYourCar.alias ||
      MYSELF_AND_ADDITIONAL_DRIVERS === whoIsDrivingYourCar.alias,
    then: yup
      .array()
      .of(additionalDriverYupSchema)
      .required('Additional driver is required'),
    otherwise: yup.array().notRequired(),
  }),
  disclosureRequirements: yup
    .boolean()
    .oneOf([true], 'You must accept the Disclosure Requriements'),
  acceptedTermsAndConditions: yup
    .boolean()
    .oneOf([true], 'You must accept the Terms and Conditions'),
  whoIsDrivingYourCar: yup.object().test('whoIsDrivingYourCar', 'Please select', (value: any) => !!value.alias && !!value.id).required('Please select'),
  marketingConsent: yup
    .boolean()
    .oneOf([true], '')
    .test(
      'marketing-consent-function-motor',
      'You must select a method of contact',
      function (): boolean {
        return (
          this.parent.emailConsent ||
          this.parent.postConsent ||
          this.parent.phoneConsent ||
          this.parent.smsConsent
        );
      },
    ),
};

export default yourCarCoverFormYupSchema;
