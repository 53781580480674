/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './ViewMultiplePoliciesPage.scss';

import { DesktopCard } from '@arachas/core/lib';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import IconAndTitle from '../../components/IconAndTitle/IconAndTitle';
import { MobileBackButton } from '../../components/MobileBackButton';
import {
  commonPageStylesIdentifier,
  iconsAndTitles,
  policyTypeIdentifierLookup,
  SINGLE_POLICY_STORAGE
} from '../../constants';
import { dustyGrayColor } from '../../constants/colors';
import { saveObjectSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import type { UserProfilePolicyType, UserProfileType } from '../../types/profile';

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
  insuranceType: string,
  userProfile: UserProfileType;
}

const RightArrowIcon = () => <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />;

const ViewMultiplePoliciesPage = (props: Props) => {
  const className = 'c-ViewMultiplePoliciesPage';
  const { insuranceType = '', userProfile = {}, history } = props;

  const getPolicies = () => {
    const noPoliciesMessage = <div className={`${className}__noPoliciesMessage`}>You have no policies for this insurance</div>;
    if (!userProfile.policies || userProfile.policies.length === 0) {
      return noPoliciesMessage;
    }
    const insuranceTypePolicies = userProfile.policies.filter((policyDetails: UserProfilePolicyType) => {
      return policyDetails.policy_type === policyTypeIdentifierLookup[insuranceType];
    });
    return insuranceTypePolicies.length > 0 ? getPoliciesLinks(insuranceTypePolicies) : noPoliciesMessage;
  };

  const goToPolicyPage = (policyDetails: UserProfilePolicyType) => {
    saveObjectSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`, policyDetails);
    history.push(`${routes.manageMyInsuranceProduct.url}${insuranceType}`);
  };

  const getPoliciesLinks = (insuranceTypePolicies: Array<UserProfilePolicyType>) => {
    return insuranceTypePolicies.map((policyDetails: UserProfilePolicyType) => {
      return <div key={policyDetails.reference_number} className={`${className}__buttonSpacing`}>
        <ButtonWithIcons
          id={'ViewMultiplePoliciesPage__openPolicyButton'}
          onClick={() => { goToPolicyPage(policyDetails); }}
          IconRight={RightArrowIcon}
        >
          Policy Number: {policyDetails.reference_number}
        </ButtonWithIcons>
      </div>;
    });
  };

  return (
    <>
      <div className={className}><div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {iconsAndTitles[insuranceType].title}
        </div>
      </div>
        <DesktopCard>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          <IconAndTitle insuranceType={insuranceType} />
          <div className={`${className}__buttonContainer`}>
            {getPolicies()}
          </div> </DesktopCard></div></>

  );
};

export default ViewMultiplePoliciesPage;