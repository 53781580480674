/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MtaDefaultThankYou.scss';

import { Button, DesktopCard, Title } from '@arachas/core/lib';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { backToWallet } from '../../constants/buttonLabelConstants';
import { routes } from '../../routes';
import UserContext from '../../UserContext';

const className = 'c-MtaDefaultThankYou';

const MtaDefaultThankYou = () => {
    const { getUserName } = useContext(UserContext);


    const getTitleText = () => {
        const name = getUserName();
        return name ? `Thanks, ${name}.` : 'Thanks!';
    };

    return (
        <div>
            <DesktopCard>
                <div className={className}>
                    <div className={`${className}__cancelPolicyPageTextContainer`}>
                        <div className={`${className}__primaryTextContainerOne`}>
                            <Title type="h1" weight="500">
                                <span id="PaymentsThankYouPage__thankYouText">{getTitleText()}</span>
                            </Title>
                        </div>
                        <div className={`${className}__primaryTextContainerTwo`}>
                            You have submitted a request to make a change to your policy.
                            We will take care of this but please note requests for changes must be confirmed by us before cover is amended.
          </div>

                        <div className={`${className}__primaryTextContainerThree`}>
                            We will be in touch shortly to confirm your updated cover and outline any change in premium if applicable.
            </div>

                    </div>
                    <div className={`${className}__buttonContainer`}>
                        <div className={`${className}__buttonSpacing`}>
                            <Link to={routes.Wallet.url}>
                                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                                    {backToWallet}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </DesktopCard>
        </div>
    );
};

export default MtaDefaultThankYou;
