"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function XIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#979797' : _ref$color,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? 12 : _ref$width,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === void 0 ? '1.1px' : _ref$strokeWidth;
  return _react2.default.createElement("svg", {
    viewBox: "0 0 20 20",
    preserveAspectRatio: "none",
    width: width,
    fill: "transparent",
    stroke: color,
    strokeWidth: strokeWidth
  }, _react2.default.createElement("path", {
    d: "M1,1 L19,19"
  }), _react2.default.createElement("path", {
    d: "M19,1 L1,19"
  }));
}

XIcon.propTypes = {
  color: _propTypes2.default.string,
  width: _propTypes2.default.number,
  strokeWidth: _propTypes2.default.string
};
exports.default = XIcon;