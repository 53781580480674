/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import React from 'react';

import ContactUs from '../../components/ContactUs/ContactUs';
const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
  redTextMessage?: string;
  quoteReferenceLine?: string;
  isCardError?: boolean;
}

const HomeErrorPage = (props: Props) => {
  const { firstName, redTextMessage, quoteReferenceLine, isCardError } = props;

  const firstNameMessage = () =>
    firstName ? `We're sorry ${firstName}` : 'We\'re sorry';

  
  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      {
        !isCardError &&
        (<div className={`${className}__warningMessage`}
              id='HomeErrorPage__warningMessage'
              data-testid='HomeErrorPage__warningMessage'>{redTextMessage}
        </div>)
      }
      {isCardError ?
        (<div>We have been unable to connect to our payment service. You have not been charged. Please try
          again.</div>) : (
          <div className={`${className}__referralNotice`}>
            <div>
              If you can provide us with some additional information we may be able to provide you with a quote over the
              phone.
            </div>
            <div>You can contact us on {<ContactUs Number={'01-5920898'}/>}. Our lines are open Monday to Friday 9am to 5pm.
            </div>
          </div>)}

      <div className={`${className}__referenceTextContainer`}>
        {quoteReferenceLine}
      </div>
    </div>
  );
};

// Set default props
HomeErrorPage.defaultProps = {
  firstName: "",
  redTextMessage: '',
  quoteReferenceLine: ''
};

export default HomeErrorPage;
