
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { AnimatedSpinner } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import {
  getAccessToken, getItemFromSessionStorage,
  makeRequest
} from '../../helpers';
import DocumentsUploadPage from './DocumentsUploadPage';


const DocumentsUploadPageWrapper = props => {
  const [resources, setResources] = useState(null);

  const { match } = props;
  const insuranceType = match.params.type || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const { cognitoUser } = useContext(CognitoContext);
  const resource = 'document_name';

  useEffect(() => {
    if (!resources) {
      let authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      makeRequest({
        url: insuranceType === 'car' ? `${basePath}/motor/resources/${resource}` : `${basePath}/${insuranceType}/resources/${resource}`,
        authToken: authToken,
      })
        .then(response => {
          response.length > 4 ?
            setResources({ [resource]: response.slice(0, 4) })
            :
            setResources({ [resource]: response });
        });
    }
  }, [resources]);

  if (!resources)
    return (
      <div>
        <AnimatedSpinner />
      </div>
    );

  return <DocumentsUploadPage {...props} resources={resources} />;
};

DocumentsUploadPageWrapper.propTypes = {
  match: PropTypes.object,
};

export default DocumentsUploadPageWrapper;
