import { Button, DatePicker, InputField } from '@arachas/core/lib';
import { noop } from '@arachas/core/lib/utils';
import { Form, withFormik } from 'formik';
import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
// eslint-disable-next-line flowtype/no-types-missing-file-annotation
import type { Match, RouterHistory } from 'react-router-dom';
import * as yup from 'yup';

import {
    commonFormStylesIdentifier
  } from '../../constants';
import { RETRIEVE_QUOTE } from '../../constants/commonConstants';
import { dateOfBirthYup, getRepeatingFieldName, isFieldError } from '../../helpers';
import { irishToRawFormat } from '../../helpers/DateHelper';
import { getRetreiveQuoteFunctionForInsuranceType } from '../../helpers/LatestQuoteHelper';
import { UseNotification } from '../../hooks/UseNotification/UseNotification';
import { routes } from '../../routes';


interface RouterProps {
    history: RouterHistory;
    match: Match;
}

interface Props {
    handleBlur: noop;
    setTouched: noop;
    isValid: Boolean;
    errors: any;
    touched: any;
    values: any;
    RouterProps: RouterProps;
}

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const RetreiveQuoteForm = (props: FormikProps & Props) => {
const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    values,
    RouterProps
    } = props;
    const className = 'c-Signup';
    const { history, match } = RouterProps;
    const { callNotification } = UseNotification()

    const insuranceType = match?.params.type ? match.params.type : '';

    const onRetrieve = useCallback(async () => {
        if(values.email) {
          const payloadData = {...values}
          payloadData.date_of_birth = irishToRawFormat(payloadData.date_of_birth)
          const getQuote = getRetreiveQuoteFunctionForInsuranceType(insuranceType);
          await getQuote(payloadData).then((response) => {
            if(response && Object.keys(response).length > 0) {
              history.push({
                pathname: `${routes.getGenericQuotePage.url}${insuranceType}`,
                state: {
                  retrievedQuotes: response
                }
              });
            } else {
              callNotification('error', 'User credentials invalid')
            }
          }).catch(() => {
            callNotification('error', 'User credentials invalid')
          })
        }
          //one api where it exist or not
    }, [values])

  return (
    <Form>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="first_name" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          First Name
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('first_name', touched, errors)}
            errorMessage={errors.first_name}
            name="first_name"
            testId='first_name'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Type Here"
            touched={touched.first_name}
            type="text"
            value={values.first_name}
          />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="last_name" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Surname
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('last_name', touched, errors)}
            errorMessage={errors.last_name}
            name="last_name"
            testId='last_name'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Type Here"
            touched={touched.last_name}
            type="text"
            value={values.last_name}
          />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="email" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Email address
        </label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('email', touched, errors)}
            errorMessage={errors.email}
            name="email"
            testId='email'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Type Here"
            touched={touched.email}
            type="email"
            value={values.email}
          />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={getRepeatingFieldName('date_of_birth')}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Date of birth
          </label>
          <DatePicker
            textType
            error={errors.date_of_birth}
            name={getRepeatingFieldName('date_of_birth')}
            onBlur={handleBlur}
            testId='date_of_birth'
            onChange={handleChange}
            placeholder="DD/MM/YYYY"
            touched={touched.date_of_birth}
            value={values.date_of_birth}
          />
        </div>
      <div className={`${commonFormStylesIdentifier}__button`}>
          <Button
            id="ReteiveQuote__submitButton"
            fluid={true}
            quaternary
            onClick={onRetrieve}
            testid='retrieve_btn'
            disabled={!isValid}
            type="button"
          >
            {RETRIEVE_QUOTE}
          </Button>
      </div>
    </Form>
  );
}

const schema = {
  first_name:yup.string()
              .min(2, 'First name should be more than 2 characters')
              .required('First name is required'),
    last_name:yup.string()
              .min(2, 'Last name should be more than 2 characters')
              .required('Last name is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .max(50, 'Email must be less then 50 chars')
      .required('Valid Email is required'), 
    date_of_birth: dateOfBirthYup
  }
  const RetreiveQuote = withFormik({
    mapPropsToValues() {
      return {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        email: ''
      };
    },
    validationSchema: yup.object().shape(schema),
    displayName: 'RetreiveQuoteForm',
  })(RetreiveQuoteForm);

  RetreiveQuote.propTypes = {
    errors: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    isValid: PropTypes.bool,
    touched: PropTypes.object,
    values: PropTypes.object,
    RouterProps: PropTypes.object
  };
  
export default RetreiveQuote;
