/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Loading.scss';
import '../../styles/commonPageStyles.scss';

import {
  AnimatedSpinner,
  DesktopCard,
  Title,
  TitleWithUnderLine
} from '@arachas/core/lib';
import React from 'react';

import { commonPageStylesIdentifier } from '../../constants';
import { whiteColor } from '../../constants/colors';

const className = 'c-Loading';

type Props = {
  text: string;
  spinnerBackgroundColor?: string
};

const Loading = (props: Props) => {

  const {spinnerBackgroundColor = whiteColor} = props;

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Get a Quote</TitleWithUnderLine>
        </div>
      </div>
      <DesktopCard>
        <div className={`${className}__icon`}>
          <AnimatedSpinner backgroundColor={spinnerBackgroundColor} />
        </div>

        <Title type="h1" weight="light" align="center">
          {props.text}
        </Title>
      </DesktopCard>
    </div>
  );
};

export default Loading;
