"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AccordionCard = require("./AccordionCard");

var _AccordionCard2 = _interopRequireDefault(_AccordionCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _AccordionCard2.default;