"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _this = undefined;

require("./AutoAddressAutocomplete.scss");

var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _phoneNumbers = require("../../../../arachas/src/constants/phoneNumbers");

var _utils = require("../../utils");

var _InputField = require("../Inputs/InputField");

var _InputField2 = _interopRequireDefault(_InputField);

var _autoAddressServices = require("./autoAddressServices");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var isFetching = false; // Just a control flag to enable debounce

var AutoAddressAutocomplete = function AutoAddressAutocomplete(props) {
  var hasError = props.hasError,
      errorMessage = props.errorMessage,
      handleBlur = props.handleBlur,
      handleChange = props.handleChange,
      name = props.name;
  var upperValue = props.value;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = _slicedToArray(_useState3, 2),
      options = _useState4[0],
      setOptions = _useState4[1];

  var _useState5 = (0, _react.useState)(''),
      _useState6 = _slicedToArray(_useState5, 2),
      value = _useState6[0],
      setValue = _useState6[1];

  var _useState7 = (0, _react.useState)(false),
      _useState8 = _slicedToArray(_useState7, 2),
      eircodeNotFound = _useState8[0],
      setEircodeNotFound = _useState8[1];

  (0, _react.useEffect)(function () {
    setValue(upperValue);
  }, [upperValue]);

  var handleAutoAddressResponse = function handleAutoAddressResponse(data, inputValue) {
    if (data.options) {
      setOptions(data.options);
      inputValue ? setEircodeNotFound(data.totalOptions === 0) :
      /* If there is no value then display the form error message instead of the
         eircode can't be found error which is shown when eircodeNotFound is set to true */
      setEircodeNotFound(false);
    } else if (data.eircode) {
      setOptions([]);
      setEircodeNotFound(false);
      setIsOpen(false);
      setValue(data.displayAddress);
      props.onSelect(data);
    } else {
      setEircodeNotFound(true);
    }
  };

  var getNoEircodeErrorMessage = function getNoEircodeErrorMessage() {
    return _react2.default.createElement("div", null, "We are unable to identify your Address or Eircode, please visit  ", _react2.default.createElement("a", {
      href: 'http://eircode.ie'
    }, 'www.eircode.ie'), " to get your Eircode. Alternatively call us on ", '', _phoneNumbers.addressHelpNumber);
  };

  var setSearchQuery = function setSearchQuery(ev) {
    var target = ev.target;
    setIsOpen(true);
    setValue(target.value);

    if (!isFetching) {
      isFetching = true;
      setTimeout(function () {
        isFetching = false;
        (0, _autoAddressServices.getAddress)(target.value).then(function (data) {
          handleAutoAddressResponse(data, target.value);
        });
      }, 500);
    }
  };

  var selectOption = function selectOption(optionKey) {
    (0, _autoAddressServices.getAddressesFromLink)(optionKey).then(function (data) {
      handleAutoAddressResponse(data, optionKey);
    });
  };

  var getOptions = function getOptions(options) {
    var optionElements = options.map(function (option, index) {
      return _react2.default.createElement("li", {
        onClick: selectOption.bind(_this, option.key),
        key: "autocomplete-option-".concat(index)
      }, option.text);
    });
    return optionElements.length > 0 ? _react2.default.createElement("ul", {
      id: "AutoAddressOptionsList",
      "data-testid": "AutoAddressOptionsList"
    }, optionElements) : _react2.default.createElement(_react2.default.Fragment, null);
  };

  return _react2.default.createElement("div", {
    className: "c-AutoAddressAutocomplete",
    id: "AutoAddressComponent",
    "data-testid": "AutoAddressComponent"
  }, _react2.default.createElement(_InputField2.default, {
    placeholder: "Enter full address or Eircode",
    name: name,
    value: value,
    autoComplete: "off",
    error: eircodeNotFound || hasError,
    errorMessage: eircodeNotFound ? getNoEircodeErrorMessage() : errorMessage,
    type: "text",
    onBlur: handleBlur,
    suffix: _freeSolidSvgIcons.faMapMarkerAlt,
    onChange: function onChange(event) {
      setSearchQuery(event);

      if (handleChange) {
        handleChange(event);
      }
    }
  }), isOpen ? getOptions(options) : null);
};

exports.default = AutoAddressAutocomplete;