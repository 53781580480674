"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PeopleChildrenIcon = require("./PeopleChildrenIcon");

var _PeopleChildrenIcon2 = _interopRequireDefault(_PeopleChildrenIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PeopleChildrenIcon2.default;