/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const yourTravelCoverFormInitialValues = {
  excess: [],
  carHireExcess: null,
  winterSportsExcess: null,
  // businessExpenseExcess: null,
  dataProtectionConsent: false,
  chubbTOBConsent: false,
  marketingConsent: false,
  emailConsent: false,
  postConsent: false,
  phoneConsent: false,
  smsConsent: false,
  selectAll: false
};
