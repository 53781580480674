const personalDetailsFields = {
  title: '',
  firstName: '',
  lastName: '',
  gender: '',
  dob: ''
};

const addressDetailsFields = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressTown: '',
  addressCounty: '',
  addressEircode: '',
  isThisYourPostalAddress: null,
  postalAddressLine1: '',
  postalAddressLine2: '',
  postalAddressLine3: '',
  postalAddressTown: '',
  postalAddressCounty: '',
  postalAddressEircode: ''
};

const contactDetailsFields = {
  phoneNo: '',
  email: ''
};

const employmentDetailsFields = {
  employmentStatus: '',
  mainOccupation: ''
};

const drivingDetailsFields = {
  driversLicenseType: '',
  licenceCountry: '',
  yearsHeld: '',
};

const promoCodeDetails = {
  promoCode: ''
};

const proposerCompanyDetailsFields = {
  proposerType: '',
  tradingAs: '',
  companyName: ''
};

const traderDetailsFields = {
  traderOccupation: '',
  vatRegistered: '',
  // yearsOfResidency: ''
};

// const BOICustomerAndPromoCodeFields = {
//   promoCode: ''
// };

const insuranceFormDetailsFields = {
  coverAbroad: '',
  provider: ''
};

export const aboutYouFormInitialValues = {
  ...personalDetailsFields,
  ...addressDetailsFields,
  ...contactDetailsFields,
  ...employmentDetailsFields,
  ...drivingDetailsFields,
  ...promoCodeDetails,
  ...proposerCompanyDetailsFields,
  ...traderDetailsFields,
  ...insuranceFormDetailsFields,
  // ...BOICustomerAndPromoCodeFields
};
