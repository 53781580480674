import Auth from '@aws-amplify/auth';

const config = {
  region: process.env.REACT_APP_COGNITO_REGION,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  storage: window.sessionStorage
};

Auth.configure(config);
