import { Button, ButtonGroup } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { getAccessToken, isFieldError } from '../../helpers';
import { isValidResourceValue } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';
import AddAdditionalDriver from './AddAdditionalDriver';
import { mapAddAdditionalDriver } from './AddAdditionalDriverHelpers';
import { addAdditionalDriver } from './addAdditionalDriverService';
import { additionalDriverYupSchema } from './addAdditionalDriverYupSchema';

const POLICY_CHANGE_TYPE = {
  TEMPORARY: 'TEMPORARY',
  PERMANENT: 'PERMANENT',
};

const AddAdditionalDriverForm = props => {
  const {
    values, setFieldValue, history,
    setFieldTouched, touched, errors, isValid
  } = props;

  const { cognitoUser } = useContext(CognitoContext);
  const accessToken = getAccessToken(cognitoUser);

  const handleSubmitButtonClick = async () => {
    addAdditionalDriver(mapAddAdditionalDriver({
      ...values,
      policyNumber: props.policyNumber,
    }), accessToken).then(() => {
      history.push({
        pathname: `${routes.mtaThankYouPage.url}car`,
        state: { pageType: 'defaultPage' },
      });
    });
  };

  return (
    <Form>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="changeType"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Is this change temporary or permanent?
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            key="changeType"
            name="changeType"
            onClick={(value) => {
              setFieldValue('changeType', value);
              setFieldTouched('changeType', true);
            }}
            options={[
              { label: 'Temporary', value: POLICY_CHANGE_TYPE.TEMPORARY },
              { label: 'Permanent', value: POLICY_CHANGE_TYPE.PERMANENT },
            ]}
            error={isFieldError('changeType', touched, errors)}
            errorMessage={errors.changeType}
            selected={values.changeType}
          />
        </div>
      </div>

      <AddAdditionalDriver includeClaims includeDateChange {...props} />

      <div>
        <Button
          id="CancelQuotePage__submitButton"
          fluid={true}
          quaternary
          disabled={!isValid}
          onClick={handleSubmitButtonClick}
          type="button"
        >
          CONFIRM
        </Button>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues() {
    return {
      additionalDrivers: [],
      motorClaims: [],
    };
  },
  validationSchema: yup.object().shape({
    additionalDrivers: yup
      .array()
      .of(additionalDriverYupSchema)
      .required('Additional driver is required'),
    changeType: yup.string().required('Change type is required'),
    timeFrom: yup.string().required('Start time is required'),
    dateFrom: yup.date().required('Start date is required'),
    timeTo: yup.string().when('changeType', {
      is: 'TEMPORARY',
      then: yup.string().required('End time is required'),
      otherwise: yup.string().notRequired(),
    }),
    dateTo: yup.date().when('changeType', {
      is: 'TEMPORARY',
      then: yup.date().required('End date is required'),
      otherwise: yup.date().notRequired(),
    }),
    motorClaims: yup.array().of(
      yup.object().shape({
        claim_type: yup.object().required('Motor claim type is required'),
        claim_status: yup.boolean().required('Motor claim status is required'),
        claim_amount: yup.string().required('Motor claim amount is required'),
        claim_date: yup.string().required('Motor claim date is required'),
      }),
    ),
    claimsCount: yup
      .object()
      .test('claimsCount', 'Number of claims is required', isValidResourceValue)
      .nullable()
      .required('Number of claims is required'),
  }),
  displayName: 'AddAdditionalDriverForm',
})(AddAdditionalDriverForm);

AddAdditionalDriverForm.propTypes = {
  history: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  policyNumber: PropTypes.string,
  isValid: PropTypes.bool,
  setTouched: PropTypes.func,
  resources: PropTypes.object
};
