/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Logout.scss';

import { Button } from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import BrandLogo from '../../components/BrandLogo';
import { commonPageStylesIdentifier } from '../../constants';
import { backToWallet } from '../../constants/buttonLabelConstants';
import { removeAllSessionStorage } from '../../helpers';
import { routes } from '../../routes';

const Logout = () => {
  const className = 'c-Logout';
  const { setCognitoUser } = useContext(CognitoContext);
  const [message, setMessage] = useState('Should not see this');

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        setCognitoUser(null);
        removeAllSessionStorage();
        setMessage('You have logged out successfully');
      })
      .catch(() => setMessage('An error occurred while logging out'));
  }, [setCognitoUser]);

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo`}>
            <BrandLogo theme="dark" />
          </div>
          <div className={`${className}__text`} id='Logout__text'>{message}</div>
          <div className={`${className}__buttonContainer`}>
            <Link to={{ pathname: routes.Wallet.url }}>
              <Button fluid id='Logout__button'>{backToWallet}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
