"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PlanesIcon = function PlanesIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 63.057 56.16",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("g", {
    id: "prefix__flight_takeoff-24px_2",
    "data-name": "flight_takeoff-24px 2",
    transform: "translate(0 -7.002)"
  }, _react2.default.createElement("path", {
    id: "prefix__Rectangle_1104",
    d: "M0 0H62.401V6.568H0z",
    "data-name": "Rectangle 1104",
    transform: "translate(.657 56.594)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1564",
    d: "M48.279 22.265a3.6 3.6 0 0 0-3.941-2.627l-12.151 3.284L16.422 8.8l-4.27.985 9.2 16.093-11.17 2.956-4.6-3.284-3.282.985 5.912 10.181s16.093-4.27 37.44-9.853a4.44 4.44 0 0 0 2.627-4.598z",
    "data-name": "Path 1564",
    transform: "translate(-2.3 14.294)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1565",
    d: "M48.354 15.066A2.432 2.432 0 0 0 45.07 13.1l-10.181 2.952L21.752 3.9l-3.613.985 7.882 13.794-9.853 1.971-3.941-2.956-2.627.985 4.926 8.539s13.465-3.613 31.529-8.211a3.567 3.567 0 0 0 2.299-3.941z",
    "data-name": "Path 1565",
    transform: "translate(14.375 3.102)"
  })));
};

exports.default = PlanesIcon;