import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import PropTypes from 'prop-types';
import React from 'react';

import ContactUs from '../../components/ContactUs/ContactUs';
import { arachasHomeAgent } from '../../constants/phoneNumbers';

const className = 'c-GenericErrorPage';
const VanErrorPage = (props) => {
  const {
    firstName,
  } = props;

  const firstNameMessage = () =>
    firstName ? `We're sorry ${firstName}.` : 'We\'re sorry.';

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      <div className={`${className}__referenceTextContainer`}>
        We have not been able to get an online quote from our insurance partner.
        If you can provide us with some additional information we may be able to
        provide you with a quote over the phone.
        You can contact us on {<ContactUs Number={arachasHomeAgent}/>}{}.
        Our lines are open Monday- to Friday 09:00 - 17:00
        </div>
    </div>
  );
};

VanErrorPage.propTypes = {
  firstName: PropTypes.string,
};

export default VanErrorPage;
