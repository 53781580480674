/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './RenewalDates.scss';

import { Button,DesktopCard, PersonOneIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants';
import { primaryPurple } from '../../constants/colors';
import { whiteIcons } from '../../constants/insuranceIconsAndTitles';
import { getAccessToken } from '../../helpers';
import { routes } from '../../routes';
import { getRenewalDates, removeRenewalDate } from '../../services/common/profileService';

const RenewalDates = (props) => {
  const className = 'c-RenewalDates';
  const {
    history
  } = props;
  const { cognitoUser } = useContext(CognitoContext);

  const [renewalDates, setRenewalDates] = useState([])
  const [errorMessage, setErrorMessage] = useState("")

  const fetchDates = () => {
    getRenewalDates(getAccessToken(cognitoUser)).then(res => {
      if (res.error === "No policy renewals found") {
        setRenewalDates([])
      } else if (res.error) {
        setErrorMessage("Error: " + res.error)
      } else {
        setRenewalDates(res)
      }
    })
  }

  useEffect(() => {
    fetchDates()
  }, [])

  const deleteDate = async dateObj => {
    await removeRenewalDate(dateObj, getAccessToken(cognitoUser))
    fetchDates()
  }

  const printCustomDate = date => {
    const year = date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);
    return `${day} / ${month} / ${year}`
  }

  const renderRow = dateObj => {
    const chosenProduct = dateObj.product.alias === 'motor' ? 'car' : dateObj.product.alias
    const iconObj = whiteIcons[chosenProduct];
    return (
      <div className={`${className}__dateRow`}>
        <div className={`${className}__productIcon ${className}__productIcon--${chosenProduct}`}>
          {iconObj}
        </div>
        <div className={`${className}__info`}>
          <div className={`${className}__date`}>{printCustomDate(dateObj.renewal_date)}</div>
          {dateObj.insurer.description}
        </div>
        <div className={`${className}__close`} onClick={() => deleteDate(dateObj)}>
          X
          </div>
      </div>
    )
  }

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${className}__container`}>
        <DesktopCard>
          <div className={`${className}__iconContainer`}>
            <div className={`${className}__icon`}>
              <PersonOneIcon color={primaryPurple} />
            </div>
          </div>
          <div className={`${className}__titleContainer`}>
            <h2 className={`${className}__title`}>Other Renewal Dates</h2>
          </div>
          {renewalDates.length > 0 && renewalDates.map(renderRow)}
          <div className={`${className}__error`}>{errorMessage}</div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.addRenewalDate.url}>
                <Button
                  id={'AddRenewalDate__button'}
                  fluid={true}
                  quaternary
                >
                  ADD RENEWAL DATES
                </Button>
              </Link>
            </div>
          </div>
        </DesktopCard>
      </div>
    </div>
  );
};

RenewalDates.propTypes = {
  history: PropTypes.object
}

export default RenewalDates;
