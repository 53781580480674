/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */


import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { HOME_INSURANCE_TYPE, SINGLE_POLICY_STORAGE } from '../../constants';
import {
  getObjectFromSessionStorage,
} from '../../helpers';
import { getAccessToken } from '../../helpers';
import { getInsuranceType } from '../../services/home/homeInsuranceTypeService';
import PolicyLinksPage from './PolicyLinksPage';

const PolicyLinksPageWrapper = (props) => {
  const { cognitoUser } = useContext(CognitoContext);
  const { match } = props;
  const insuranceType = match.params.type || '';
  const [coverType, setCoverType] = useState('');


  const getInsuranceCoverType = async () => {
    const documentsResponse = await getInsuranceType(getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`).reference_number, getAccessToken(cognitoUser));
    setCoverType(documentsResponse);
  };

  useEffect(() => {
    if (coverType === '' && insuranceType === HOME_INSURANCE_TYPE) {
      getInsuranceCoverType();
    }
  }, [coverType]);

  return (
    <PolicyLinksPage
      coverType={coverType}
      {...props}
    />
  );
};

PolicyLinksPageWrapper.propTypes = {
  match: PropTypes.object,
};

export default PolicyLinksPageWrapper;
