"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TickIcon = require("./TickIcon");

var _TickIcon2 = _interopRequireDefault(_TickIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TickIcon2.default;