/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { AnimatedSpinner } from '@arachas/core/lib';
import React, { useEffect, useState } from 'react';

import {
  makeRequest,
} from '../../helpers';
import HomeUpdateSpecifiedItems from './HomeUpdateSpecifiedItems';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

const className = 'c-HomeUpdateSpecifiedItemsWrapper';

const HomeUpdateSpecifiedItemsWrapper = props => {
  const [resources, setResources] = useState(null);

  useEffect(() => {
    if (!resources) {
      let authToken = undefined;
      let singlePromise = makeRequest({
        url: `${basePath}/home/resources/specified_item_type`,
        authToken: authToken,
      });

      singlePromise.then(response => {
        setResources([{ 'specified_item_type': [...response] }]);
      });

    }
  }, [resources]);

  if (!resources)
    return (
      <div className={`${className}__spinnerContainer`}>
        <AnimatedSpinner />
      </div>
    );

  return <HomeUpdateSpecifiedItems {...props} resources={resources} />;
};

export default HomeUpdateSpecifiedItemsWrapper;
