/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './PrivateRoute.scss';

import { AnimatedSpinner } from '@arachas/core/lib';
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { LoginPage } from '../../pages/LoginPage';
import type { RouterPropsType } from '../../types/commonTypes';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const className = 'c-PrivateRoute';
  const { cognitoUser, loadingUser } = useContext(CognitoContext);
  const isLoggedIn = !!cognitoUser;

  return (
    <Route
      {...rest}
      render={(props: RouterPropsType) => {
        if (loadingUser) {
          return <div className={`${className}__spinnerContainer`}>
            <AnimatedSpinner/>
          </div>;
        }
        if (isLoggedIn) {
          return <Component {...props} />;
        }
        return <LoginPage {...props} />;
      }}
    />
  );
};
export default PrivateRoute;
