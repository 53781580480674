/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { getItemFromSessionStorage, makeRequest } from '../helpers';

//TODO put it back in later
// const sendAnalyticsEvent = (quotePromise) => {
//   quotePromise.then(response => {
//     if (!response.error) {
//       window.dataLayer.push({
//         'event': 'insuranceEvent',
//         'eventLabel': 'Home Insurance quote',
//         'quoteNum': `${response.response.quote_reference}`
//       });
//     }
//   });
// };

export const postHomeQuote = async (homeQuoteData, cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const quoteRequest = makeRequest({
    method: 'POST',
    url: `${basePath}/home/home_quotes`,
    authToken: authToken,
    payload: homeQuoteData,
    history: props.history
  });


  //TODO put it back in later
  // sendAnalyticsEvent(quoteRequest);

  return await quoteRequest;
};
