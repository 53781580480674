"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function EnvelopeIcon(_ref) {
  var _ref$height = _ref.height,
      height = _ref$height === void 0 ? 25 : _ref$height,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? 32 : _ref$width;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: width,
    height: height,
    viewBox: "0 0 32.007 25"
  }, _react2.default.createElement("path", {
    fill: "#600460",
    d: "M29.605 9.125L16.8 16.937 4 9.125V24.75h25.605zm0-3.125H4l12.8 7.8z",
    opacity: "0.3",
    transform: "translate(-.8 -2.875)"
  }), _react2.default.createElement("path", {
    fill: "#600460",
    d: "M5.2 29h25.606a3.173 3.173 0 0 0 3.2-3.125V7.125A3.173 3.173 0 0 0 30.806 4H5.2A3.173 3.173 0 0 0 2 7.125v18.75A3.173 3.173 0 0 0 5.2 29zM30.806 7.125L18 14.922 5.2 7.125zM5.2 10.25L18 18.062l12.8-7.812v15.625H5.2z",
    transform: "translate(-2 -4)"
  }));
}

EnvelopeIcon.propTypes = {
  height: _propTypes2.default.number,
  width: _propTypes2.default.number
};
exports.default = EnvelopeIcon;