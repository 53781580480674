import React, { useEffect,useState } from 'react';

import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { GET_QUOTE_RESPONSE_STORAGE, SINGLE_SCHEMA_STORAGE } from '../../constants/sessionStorage/genericStorageIdentifiers';
import { getObjectFromSessionStorage, removeFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import { MotorViewQuote } from '../MotorViewQuote';
import { ViewQuote } from '../ViewQuote';
import ViewMultipleQuotes from './ViewMultipleQuotes';

const ViewQuotesWrapper = (props) => {
	const {
		history,
		match
	} = props;

	const insuranceType = match.params.type;

	const payload = getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`);
	const [selected, setSelected] = useState(getObjectFromSessionStorage(`${insuranceType}${SINGLE_SCHEMA_STORAGE}`));

	const oneSchema = () => payload && payload.length === 1;
	const multipleSchema = () => payload && payload.length > 1;
	const selectedObject = () => selected && Object.keys(selected).length > 0;
	const schemeId = () => oneSchema() ? payload[0].scheme_code : null;

	const selectSchema = (quote) => {
		const quoteString = JSON.stringify(quote);
		saveInSessionStorage(`${insuranceType}${SINGLE_SCHEMA_STORAGE}`, quoteString);
		setSelected(quote);
	};

	const unSelectSchema = () => {
		removeFromSessionStorage(`${insuranceType}${SINGLE_SCHEMA_STORAGE}`);
		setSelected(null);
	};

	const receivedOneSchema = oneSchema();
	const receivedMultipleSchema = multipleSchema();
	const receivedMultipleSchemaAndSelected = multipleSchema() && selectedObject();

	const viewQuoteFunctions = {
		[CAR_INSURANCE_TYPE]: <MotorViewQuote unSelectSchema={unSelectSchema} {...props} />,
		[HOME_INSURANCE_TYPE]: <ViewQuote unSelectSchema={unSelectSchema} schemeId={schemeId()} {...props} />
	};


	const render = () => {
		if (receivedOneSchema || receivedMultipleSchemaAndSelected) {
			return viewQuoteFunctions[insuranceType];
		} else if (receivedMultipleSchema) {
			return <ViewMultipleQuotes selectSchema={selectSchema} {...props} />;
		} else {
			history.push(`${routes.genericErrorPage.url}${insuranceType}`);
			return <></>;
		}
	};

	useEffect(() => {
		fbqTrackEvent('Lead');
		fbqTrackEvent('Contact');
	}, [])
	return render();
};

export default ViewQuotesWrapper;