/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './GetQuoteThankYouGenericPage.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { CognitoContext } from '../../CognitoUtils';
import { GET_QUOTE_RESPONSE_STORAGE, GET_QUOTE_VALUES_STORAGE } from '../../constants';
import { offWhite, whiteColor } from '../../constants/colors';
import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { SINGLE_SCHEMA_STORAGE, SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import {
  getAccessToken,
  getItemFromSessionStorage,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { postDeviceQuote } from '../../services/device/deviceServices';
import { postHomeQuote } from '../../services/homeServices';
import { postMotorQuote } from '../../services/motor/motorServices';
import { postTravelQuote } from '../../services/travel/travelServices';
import { postVanQuote } from '../../services/van/vanServices';
import { GenericErrorPage } from '../GenericErrorPage';
import Loading from '../Loading/Loading';
import Success from './Success';

const REQUEST_LOADING = 'GetQuoteThankYou/LOADING';
const REQUEST_SUCCESS = 'GetQuoteThankYou/SUCCESS';
const REQUEST_FAILURE = 'GetQuoteThankYou/FAILURE';

let mappingsData = {};
let insuranceType = '';
let values = {};

let payload = {
  response: {
    error_message: '',
    quote_reference: '',
    quote_timestamp: '',
    cover_premium: '',
    premium_details: [
      {
        id: null,
        updated_date: null,
        item_name: '',
        sequence: 0,
        amount: 0,
        percentage: 0,
        product_variant: ''
      }
    ],
    acceptance_notifications: [
      {
        id: null,
        updated_date: null,
        description: '',
        notification_type: '',
        overidden: ''
      }
    ]
  }
};

const GetQuoteThankYouGenericPage = (props) => {
  const spinnerBackgroundColor = isMobile ? offWhite : whiteColor;
  const [errorType, setErrorType] = useState(props.match.params.type || '');
  const [requestStatus, setRequestStatus] = useState(
    REQUEST_LOADING
  );
  const { cognitoUser } = useContext(CognitoContext);

  // running only once to post the payload
  useEffect(() => {
    if (props.location.state) {
      mappingsData = props.location.state.mappingsData;
      insuranceType = props.location.state.insuranceType;
      values = props.location.state.values || {};
      saveInSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    }
    getQuote();
  }, [props.location.state]);

  const getQuote = () => {
    if (insuranceType === CAR_INSURANCE_TYPE)
      removeFromSessionStorage(`${CAR_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
    // Used to ensure a call to the backend is only made going forward through the journey
    const submittedGetQuote = getItemFromSessionStorage(`${insuranceType}${SUBMITTED_GET_QUOTE_STORAGE}`);
    removeFromSessionStorage(`${insuranceType}${SUBMITTED_GET_QUOTE_STORAGE}`);
    if (insuranceType && submittedGetQuote) {
      callApiService(mappingsData, insuranceType);
    } else {
      setErrorType(insuranceType);
      setRequestStatus(REQUEST_FAILURE);
    }
  };
  const isPayloadInvalid = (payload) => {
    if (payload.message === "Endpoint request timed out") {
      return true;
    }
    else if (payload.acceptance_notifications && payload.acceptance_notifications.length > 0) {
      return true;
    }
    return false;
  };

  const checkResponse = (payload) => {
    if (!payload.error) {
      if (isPayloadInvalid(payload)) {
        setRequestStatus(REQUEST_FAILURE);
        return;
      }
      saveInSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`, JSON.stringify(payload));
      setRequestStatus(REQUEST_SUCCESS);
      return;
    }
    setRequestStatus(REQUEST_FAILURE);
  };

  const isRSADown = (payload) =>
    payload.error &&
    payload.error_code &&
    payload.error_code === "EXTERNAL_SERVICE_UNAVAILABLE";

  const callApiService = async (mappingsData, insuranceType) => {
    const postServiceOnInsuranceType = {
      [CAR_INSURANCE_TYPE]: postMotorQuote,
      [HOME_INSURANCE_TYPE]: postHomeQuote,
      [TRAVEL_INSURANCE_TYPE]: postTravelQuote,
      [DEVICE_INSURANCE_TYPE]: postDeviceQuote,
      [VAN_INSURANCE_TYPE]: postVanQuote,
    };

    payload = await postServiceOnInsuranceType[insuranceType](mappingsData, getAccessToken(cognitoUser), props);

    if (payload) {
      checkResponse(payload);
    }
    // check for RSA errors
    else if (isRSADown(payload)) {
      setRequestStatus(REQUEST_FAILURE);
      setErrorType('rsa');
    } else {
      setErrorType(insuranceType);
      setRequestStatus(REQUEST_FAILURE);
    }
  };

  const cProps = {
    values,
    payload,
    insuranceType,
  };

  const getLoadingComponent = () => <Loading {...cProps}
    text='Please wait, we are processing your quote for you'
    spinnerBackgroundColor={spinnerBackgroundColor}
    errorType={errorType} />;

  const getSuccessComponent = () => <Success {...cProps} />;

  const getErrorComponent = () => <GenericErrorPage {...cProps} errorType={errorType} />;

  const paths = {
    [REQUEST_LOADING]: getLoadingComponent,
    [REQUEST_SUCCESS]: getSuccessComponent,
    [REQUEST_FAILURE]: getErrorComponent
  };

  const Result = paths[requestStatus];
  return <Result />;
};

GetQuoteThankYouGenericPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  values: PropTypes.object,
};

export default GetQuoteThankYouGenericPage;
