/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const yourHomeCoverFormInitialValues = {
  policyStart: '',
  rebuildCost: '',
  contentsCoverOf: '',
  acceptedAssumptions: false,
  acceptedTermsAndConditions: false,

  //Marketing Consent
  marketingConsent: false,
  emailConsent: false,
  postConsent: false,
  phoneConsent: false,
  smsConsent: false,
  selectAll: false
};
