"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PhoneIcon = function PhoneIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? _colors.fontColorSecondary : _ref$color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18"
  }, _react2.default.createElement("g", {
    id: "prefix__call-24px",
    transform: "translate(-3 -3)"
  }, _react2.default.createElement("path", {
    id: "prefix__Path_1581",
    d: "M19 17.5a10.891 10.891 0 0 1-2.6-.5l-1.2 1.2a16.058 16.058 0 0 0 3.8.8v-1.5zM6.5 5H5a12.277 12.277 0 0 0 .8 3.8L7 7.6A19.969 19.969 0 0 1 6.5 5z",
    "data-name": "Path 1581",
    style: {
      opacity: .3,
      fill: color
    }
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1582",
    d: "M20 21a1.075 1.075 0 0 0 1-1v-3.5a1 1 0 0 0-1-1 10.88 10.88 0 0 1-3.6-.6c-.1 0-.2-.1-.3-.1a.908.908 0 0 0-.7.3l-2.2 2.2a15.568 15.568 0 0 1-6.6-6.6l2.2-2.2a.8.8 0 0 0 .2-1A12.3 12.3 0 0 1 8.5 4a1 1 0 0 0-1-1H4a1.075 1.075 0 0 0-1 1 16.986 16.986 0 0 0 17 17zm-3.6-4a23.812 23.812 0 0 0 2.6.5V19a16.058 16.058 0 0 1-3.8-.8zM5 5h1.5A19.969 19.969 0 0 0 7 7.6L5.8 8.8A16.058 16.058 0 0 1 5 5z",
    "data-name": "Path 1582",
    style: {
      fill: color
    }
  })));
};

exports.default = PhoneIcon;