"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PeopleChildrenIcon = function PeopleChildrenIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 123.445 50",
    width: "123.445",
    height: "50",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("g", {
    id: "prefix__children",
    transform: "translate(-1 -4)"
  }, _react2.default.createElement("g", {
    id: "prefix__Group_2695",
    "data-name": "Group 2695",
    transform: "translate(1 4)"
  }, _react2.default.createElement("g", {
    id: "prefix__Group_2694",
    "data-name": "Group 2694"
  }, _react2.default.createElement("ellipse", {
    id: "prefix__Ellipse_26",
    cx: "6",
    cy: "6.5",
    style: {
      opacity: .3
    },
    "data-name": "Ellipse 26",
    rx: "6",
    ry: "6.5",
    transform: "translate(19.586 6)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1589",
    d: "M21.75 15C13.313 15 3.625 19.063 3 21.25v3.125h37.5V21.25C39.875 19.063 30.188 15 21.75 15z",
    style: {
      opacity: .3
    },
    "data-name": "Path 1589",
    transform: "translate(3.25 19.375)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1590",
    d: "M16.7 13.1c4.063 3.125 7.188 6.875 7.188 12.188v9.375h12.5v-9.375c0-6.875-11.25-10.938-19.688-12.188z",
    "data-name": "Path 1590",
    transform: "translate(32.363 15.338)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1591",
    d: "M17.763 29a12.5 12.5 0 1 0 0-25 15.833 15.833 0 0 0-4.063.625A20.174 20.174 0 0 1 17.763 16.5 20.174 20.174 0 0 1 13.7 28.375a15.833 15.833 0 0 0 4.063.625z",
    "data-name": "Path 1591",
    transform: "translate(25.988 -4)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1592",
    d: "M17.5 29A12.5 12.5 0 1 0 5 16.5 12.537 12.537 0 0 0 17.5 29zm0-18.75a6.25 6.25 0 1 1-6.25 6.25 6.268 6.268 0 0 1 6.25-6.25z",
    "data-name": "Path 1592",
    transform: "translate(7.5 -4)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1593",
    d: "M26 13c-8.437 0-25 4.063-25 12.5v9.375h50V25.5C51 17.063 34.438 13 26 13zm18.75 15.625H7.25V25.5c.625-2.188 10.313-6.25 18.75-6.25s18.125 4.063 18.75 6.25z",
    "data-name": "Path 1593",
    transform: "translate(-1 15.125)"
  }))), _react2.default.createElement("path", {
    id: "prefix__Path_1594",
    d: "M38.788 25.5a7.6 7.6 0 0 0 7.5-7.5 7.5 7.5 0 0 0-15 0 7.6 7.6 0 0 0 7.5 7.5zm0 3.75c-5 0-14.688 2.5-14.688 7.5v3.438h29.375v-3.75c0-4.688-9.687-7.188-14.687-7.188z",
    "data-name": "Path 1594",
    transform: "translate(49.088 13.813)"
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1595",
    d: "M45.788 25.5a7.6 7.6 0 0 0 7.5-7.5 7.5 7.5 0 0 0-15 0 7.6 7.6 0 0 0 7.5 7.5zm0 3.75c-5 0-14.688 2.5-14.688 7.5v3.438h29.375v-3.75c.313-4.688-9.687-7.188-14.687-7.188z",
    "data-name": "Path 1595",
    transform: "translate(63.963 13.813)"
  })));
};

exports.default = PeopleChildrenIcon;