import { ALLIANZ_SCHEMA_ID, AVIVA_SCHEMA_ID, AXA_SCHEMA_ID, ZURICH_SCHEMA_ID } from '../../constants/insurers/carInsurerIDs';

export const carFeatureList = {
  [ALLIANZ_SCHEMA_ID]: [
    { icon: 'bullet-event', label: 'Step Back Bonus Protection' },
    { icon: 'bullet-car', label: 'Breakdown assistance covers roadside or in your driveway' },
    { icon: 'bullet-transport', label: 'Driving of other cars included as standard' }
  ],
  [AVIVA_SCHEMA_ID]: [
    { icon: 'bullet-event', label: 'Step Back Bonus Protection' },
    { icon: 'bullet-car', label: 'Breakdown assistance covers roadside or in your driveway' },
    { icon: 'bullet-transport', label: 'Driving of other cars included as standard' }
  ],
  [ZURICH_SCHEMA_ID]: [
    { icon: 'bullet-event', label: 'Step Back Bonus Protection' },
    { icon: 'bullet-car', label: 'Roadside Breakdown assistance' },
    { icon: 'bullet-transport', label: 'Driving of other cars included for third party cover only' }
  ],
  [AXA_SCHEMA_ID]: [
    { icon: 'bullet-event', label: 'Step Back Bonus Protection' },
    { icon: 'bullet-car', label: 'Roadside Breakdown assistance' },
    { icon: 'bullet-transport', label: 'Driving of other cars included for third party cover only' }
  ]
};
