/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

export const yourHomeFormInitialValues = {
  coverType: '',

  // ownRent fields
  isFamilyUnit: '',
  numTenants: '',


  // ownHolidayFields
  isInUse: '',

  homeType: '',
  yearOfConstruction: '',
  numBedrooms: '',
  numBathrooms: '',
  heatingType: '',
  houseAlarm: '',
  twoSmokeDetectors: '',
  firstTimeBuyer: '',
  claims: [],
  propertyJointlyOwned: '',
  homeNoClaimsBonus: '',

  // Jointly Owned
  jointlyOwnedDetailsTitle: '',
  jointlyOwnedDetailsFirstName: '',
  jointlyOwnedDetailsLastName: '',
  jointlyOwnedDetailsDateOfBirth: undefined,
};
