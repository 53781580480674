"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//@amanda to fix
// type BOILogoNoSpaceProps = {
//   color?: string
// };
var BOILogoNoSpace = function BOILogoNoSpace() // { color }: BOILogoNoSpaceProps
{
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/1999/xlink",
    width: "205.7px",
    height: "18.7px",
    viewBox: "0 0 225.244 18.229",
    style: {
      fill: "#fff"
    }
  }, _react2.default.createElement("g", {
    id: "Group_2441",
    "data-name": "Group 2441",
    transform: "translate(-46.717 -54.863)"
  }, _react2.default.createElement("g", {
    id: "Group_2403",
    "data-name": "Group 2403",
    transform: "translate(240.561 54.863)"
  }, _react2.default.createElement("path", {
    id: "Path_1397",
    "data-name": "Path 1397",
    d: "M266.174,71.216c7.465,0,13.521-3.47,13.521-7.75s-6.055-7.744-13.521-7.744-13.521,3.464-13.521,7.744,6.053,7.75,13.521,7.75m-15.7-7.745c0-4.756,7.029-8.608,15.7-8.608s15.7,3.852,15.7,8.608-7.026,8.6-15.7,8.6-15.7-3.85-15.7-8.6",
    transform: "translate(-250.473 -54.863)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1398",
    "data-name": "Path 1398",
    d: "M266.634,61.655c.254-1.453,6.587-1.652,6.587-3.144a.073.073,0,0,1,.07-.072.071.071,0,0,1,.068.072v1.577c0,1.87-6.366,1.635-6.667,3.433-.011.039-.019.072-.058.072s-.05-.033-.056-.072c-.306-1.8-6.676-1.563-6.676-3.433V58.51a.074.074,0,0,1,.074-.072.076.076,0,0,1,.07.072c0,1.492,6.329,1.692,6.587,3.144",
    transform: "translate(-250.932 -55.037)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1399",
    "data-name": "Path 1399",
    d: "M266.634,65.022c.254-1.455,6.587-1.653,6.587-3.142a.067.067,0,0,1,.07-.068.065.065,0,0,1,.068.068v1.572c0,1.877-6.366,1.634-6.667,3.432-.011.042-.019.073-.058.073s-.05-.031-.056-.073c-.306-1.8-6.676-1.555-6.676-3.432V61.88a.068.068,0,0,1,.074-.068.069.069,0,0,1,.07.068c0,1.489,6.329,1.688,6.587,3.142",
    transform: "translate(-250.932 -55.201)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1400",
    "data-name": "Path 1400",
    d: "M266.634,68.475c.254-1.457,6.587-1.655,6.587-3.147a.071.071,0,0,1,.07-.07.068.068,0,0,1,.068.07V66.9c0,1.876-6.366,1.631-6.667,3.435-.011.037-.019.069-.058.069s-.05-.032-.056-.069c-.306-1.8-6.676-1.559-6.676-3.435V65.328a.072.072,0,0,1,.074-.07.073.073,0,0,1,.07.07c0,1.492,6.329,1.691,6.587,3.147",
    transform: "translate(-250.932 -55.369)",
    fill: "#fff"
  })), _react2.default.createElement("g", {
    id: "Group_2406",
    "data-name": "Group 2406",
    transform: "translate(46.717 56.372)"
  }, _react2.default.createElement("g", {
    id: "Group_2405",
    "data-name": "Group 2405"
  }, _react2.default.createElement("g", {
    id: "Group_2404",
    "data-name": "Group 2404"
  }, _react2.default.createElement("path", {
    id: "Path_1401",
    "data-name": "Path 1401",
    d: "M201.01,63.716h-3.252a7.56,7.56,0,0,1,.237,2.082v2.765a4.522,4.522,0,0,1-2.522.628c-2.5,0-3.55-2.16-3.55-5.622,0-3.37,1.1-5.494,3.27-5.526,1.8-.025,2.442,1.181,2.721,2.714h3.14c-.531-2.43-2.257-4.307-5.92-4.308-3.523,0-6.473,2.555-6.473,7.12,0,5.572,3.065,7.22,6.473,7.231a12.158,12.158,0,0,0,5.877-1.462Z",
    transform: "translate(-53.622 -56.449)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1402",
    "data-name": "Path 1402",
    d: "M206.381,61.035V59.621h-3.075a7.214,7.214,0,0,1,.28,2.1c0,.8,0,8.9,0,8.9h2.8v-6.3c-.009-1.87,1.161-2.823,3.159-2.813l0-2.181a3.465,3.465,0,0,0-3.16,1.7",
    transform: "translate(-54.335 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1403",
    "data-name": "Path 1403",
    d: "M226.178,70.946a5.254,5.254,0,0,0,3.1-1.133v.823h2.8v-11h-2.8v8.455a3.456,3.456,0,0,1-2.068.946c-1.367,0-1.674-.812-1.673-2.414V59.634h-3.062a8.2,8.2,0,0,1,.274,2.108v5.016c0,1.772.3,4.187,3.433,4.187",
    transform: "translate(-55.267 -56.604)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1404",
    "data-name": "Path 1404",
    d: "M234.257,59.629a5.708,5.708,0,0,1,.295,2.037V73.31h2.784V69.928a3.775,3.775,0,0,0,2.793,1.021c2.008,0,4.49-1.131,4.484-5.862-.007-4.641-2.356-5.761-4.2-5.759a4.7,4.7,0,0,0-3.078,1.155v-.853Zm3.079,2.683a2.694,2.694,0,0,1,2.072-1.256c1.144,0,2.269.745,2.242,4.025-.026,3.363-1.039,4.139-2.272,4.139a2.52,2.52,0,0,1-2.043-1.205Z",
    transform: "translate(-55.84 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1405",
    "data-name": "Path 1405",
    d: "M215.476,59.321c-2.734.017-5.154,1.613-5.143,5.847s2.493,5.774,5.143,5.774,5.125-1.557,5.106-5.774c-.02-4.24-2.248-5.865-5.106-5.847m-.017,9.991c-1.572,0-2.179-1.29-2.179-4.144,0-2.984.6-4.217,2.179-4.23,1.505-.013,2.147,1.341,2.176,4.23.028,2.854-.605,4.144-2.176,4.144",
    transform: "translate(-54.676 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1406",
    "data-name": "Path 1406",
    d: "M109.56,60.371V70.489h2.8V62.812h1.972V61.193h-1.972V59.922c0-1.048.567-1.827,1.544-1.825a3.7,3.7,0,0,1,2.048.645V56.949a5.744,5.744,0,0,0-2.43-.462c-2.806,0-3.959,1.71-3.959,3.884",
    transform: "translate(-49.774 -56.451)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1407",
    "data-name": "Path 1407",
    d: "M102.312,59.328c-2.733.017-5.153,1.612-5.141,5.847s2.492,5.774,5.141,5.774,5.127-1.558,5.107-5.774c-.02-4.241-2.249-5.866-5.107-5.847m-.017,9.99c-1.571,0-2.178-1.289-2.178-4.143,0-2.985.6-4.218,2.178-4.231,1.505-.012,2.149,1.342,2.178,4.231.029,2.854-.608,4.143-2.178,4.143",
    transform: "translate(-49.171 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1408",
    "data-name": "Path 1408",
    d: "M83.276,70.5s0-10.833,0-11.629a5.571,5.571,0,0,0-.3-2.06h3.091V70.5Z",
    transform: "translate(-48.481 -56.467)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1409",
    "data-name": "Path 1409",
    d: "M89.78,59.63h2.947l-3.525,4.3,4.12,6.708H89.876l-3.534-6.179,2.533-3.079a4.3,4.3,0,0,0,.906-1.753",
    transform: "translate(-48.645 -56.604)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1410",
    "data-name": "Path 1410",
    d: "M52.978,70.5H46.989V58.965a6.872,6.872,0,0,0-.272-2.15h6.861c1.906,0,3.612.884,3.612,3.04C57.19,61.93,55.7,62.68,54.27,63A3.648,3.648,0,0,1,57.9,66.542c0,2.694-2.25,3.972-4.918,3.963M50.014,58.346v4.038h1.768c1.456,0,2.368-.62,2.371-2.116,0-1.519-1.073-1.926-2.362-1.923Zm0,5.581v5.039h2.017a2.324,2.324,0,0,0,2.65-2.626c-.014-1.69-1.111-2.418-2.65-2.414Z",
    transform: "translate(-46.717 -56.467)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1411",
    "data-name": "Path 1411",
    d: "M64.684,59.32c2.088,0,4.469.3,4.469,3.525v5.48a8.782,8.782,0,0,0,.248,2.3H66.77c-.048-.16-.134-.516-.2-.774a5.761,5.761,0,0,1-3.421,1.094c-2.1,0-3.5-1.1-3.513-3.178-.012-2.122,1.4-3.139,3.437-3.636.89-.217,2.857-.673,3.29-.8v-.692c0-1.417-.787-1.809-1.649-1.809a1.871,1.871,0,0,0-2.005,1.9l-2.556.01c0-1.472.968-3.427,4.527-3.425m.27,5.9c-1.226.354-2.448.993-2.443,2.277a1.458,1.458,0,0,0,1.64,1.6,3.6,3.6,0,0,0,2.217-.813V64.846c-.365.092-.911.226-1.414.371",
    transform: "translate(-47.346 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1412",
    "data-name": "Path 1412",
    d: "M77.491,59.333a5.983,5.983,0,0,0-3.146,1.106v-.82H71.29a7.646,7.646,0,0,1,.28,2.1c0,.8,0,8.911,0,8.911h2.775V62.163a3.605,3.605,0,0,1,2.118-.946c1.365,0,1.69.812,1.689,2.414v7h2.773v-7.14c0-1.774-.3-4.161-3.433-4.161",
    transform: "translate(-47.912 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1413",
    "data-name": "Path 1413",
    d: "M128.526,61.033V59.619h-3.075a7.214,7.214,0,0,1,.282,2.1c0,.8,0,8.9,0,8.9h2.793v-6.3c-.009-1.87,1.161-2.823,3.159-2.813v-2.18a3.461,3.461,0,0,0-3.159,1.7",
    transform: "translate(-50.547 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1414",
    "data-name": "Path 1414",
    d: "M179.658,56.815a6,6,0,0,1,.283,2.056v1.352a4,4,0,0,0-2.786-1.016c-1.681,0-4.483.968-4.491,5.757-.006,4.568,2.221,5.871,4.323,5.854a4.778,4.778,0,0,0,2.954-1.15v.825h2.8V56.815Zm.283,11.075a2.786,2.786,0,0,1-2.026,1.2c-1.236,0-2.293-.7-2.288-4.137,0-3.287,1.12-4.027,2.266-4.027a2.591,2.591,0,0,1,2.048,1.174Z",
    transform: "translate(-52.844 -56.467)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1415",
    "data-name": "Path 1415",
    d: "M120.381,70.5s0-10.818,0-11.613a7.587,7.587,0,0,0-.254-2.076H123.4V70.5Z",
    transform: "translate(-50.288 -56.467)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1416",
    "data-name": "Path 1416",
    d: "M144.775,70.5s0-10.833,0-11.629a5.572,5.572,0,0,0-.3-2.06h3.09V70.5Z",
    transform: "translate(-51.473 -56.467)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1417",
    "data-name": "Path 1417",
    d: "M142.653,64.986c0,.16,0,.318-.014.479H135.4c0,2.381.484,3.857,2.169,3.862,1.346,0,1.865-.98,2.041-2.416l2.9,0a4.622,4.622,0,0,1-4.944,4.039c-2.8,0-5.132-1.483-5.126-5.953.006-3.859,2.29-5.665,5.126-5.669,2.747,0,5.094,1.8,5.08,5.654M135.435,64h4.248c-.071-1.347-.495-3.048-2.11-3.047s-2,1.744-2.138,3.047",
    transform: "translate(-50.887 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1418",
    "data-name": "Path 1418",
    d: "M154.364,59.32c2.087,0,4.469.3,4.469,3.525v5.48a8.833,8.833,0,0,0,.247,2.3h-2.631c-.047-.16-.134-.516-.194-.774a5.765,5.765,0,0,1-3.422,1.094c-2.1,0-3.5-1.1-3.513-3.178-.011-2.122,1.4-3.139,3.438-3.636.889-.217,2.856-.673,3.289-.8v-.692c0-1.417-.786-1.809-1.649-1.809a1.871,1.871,0,0,0-2.005,1.9l-2.556.01c0-1.472.968-3.427,4.527-3.425m.27,5.9c-1.227.354-2.449.993-2.444,2.277a1.459,1.459,0,0,0,1.641,1.6,3.6,3.6,0,0,0,2.216-.813V64.846c-.365.092-.91.226-1.413.371",
    transform: "translate(-51.708 -56.589)",
    fill: "#fff"
  }), _react2.default.createElement("path", {
    id: "Path_1419",
    "data-name": "Path 1419",
    d: "M167.171,59.333a5.987,5.987,0,0,0-3.147,1.106v-.82H160.97a7.647,7.647,0,0,1,.279,2.1c0,.8,0,8.911,0,8.911h2.775V62.163a3.608,3.608,0,0,1,2.119-.946c1.365,0,1.689.812,1.688,2.414v7H170.6v-7.14c0-1.774-.3-4.161-3.432-4.161",
    transform: "translate(-52.275 -56.589)",
    fill: "#fff"
  }))))));
};

exports.default = BOILogoNoSpace;