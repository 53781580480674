export const carInsuranceAboutYouFormFields = [
  'title',
  'firstName',
  'lastName',
  'dob',
  'autoAddressValue',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressTown',
  'addressCounty',
  'addressEircode',
  'phoneNo',
  'email',
  'employmentStatus',
  'mainOccupation',
  'driversLicenseType',
  'yearsHeld',
  'promoCode'
];

export const carInsuranceYourCarFormFields = [
  'carReg',
  'carModel',
  'carMake',
  'carPurchased',
  'vehicleValue',
  'carUsedForWork',
  'noClaimsBonus',
  'drivingExpType',
  'ncbCountry',
  'isManualSearch'
];

export const carInsuranceYourCarCoverFormFields = [
  'policyStart',
  'coverType',
  'motorClaims',
  'claimsCount',
  'mainDriverPenaltyPointOffenceCount',
  'mainDriverPenaltyPointOffences',
  'mainDriverDisqualifiedOrConvicted',
  'hasAdditionalDrivers',
  'whoIsDrivingYourCar',
  'additionalDrivers',
  'acceptedTermsAndConditions',
  'disclosureRequirements',
  'marketingConsent',
];
