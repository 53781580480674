/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import './PaymentThankYouLoggedInContent.scss';

import { Button } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';

const className = 'c-PaymentThankYouLoggedInContent';

const PaymentThankYouLoggedInContent = (props) => {
  const { insuranceType } = props;

  const loggedInContent = {
    [HOME_INSURANCE_TYPE]: 'If you have specified any individual items over the value of €5,000 as part of your policy you will need to upload valuation documents to complete your policy within the next 14 days.',
    [CAR_INSURANCE_TYPE]: 'To complete your policy we need you to upload a photograph of your No Claims Bonus and your Driver’s Licence within the next 14 days.',
    [TRAVEL_INSURANCE_TYPE]: '',
    [DEVICE_INSURANCE_TYPE]: ''
  };

  return (
    <div>
      <div className={`${className}__primaryTextContainerTwo`}>
        {loggedInContent[insuranceType]}
      </div>
      <div className={`${className}__buttonContainer`}>
        <div className={`${className}__buttonSpacing`}>
          <Link to={`${routes.uploadDocumentsPage.url}${insuranceType}`}>
            <Button fluid data-ga id="PaymentsThankYouPage__uploadDocumentsButton">
              UPLOAD DOCUMENTS NOW
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

PaymentThankYouLoggedInContent.propTypes = {
  insuranceType: PropTypes.string,
};

export default PaymentThankYouLoggedInContent;
