"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HamburguerMenu = function HamburguerMenu(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    style: {
      fill: color
    },
    viewBox: "0 0 20 19.08"
  }, _react2.default.createElement("g", {
    fillRule: "evenodd"
  }, _react2.default.createElement("path", {
    d: "M1.06,19.08a1.06,1.06,0,1,1,0-2.12H18.94a1.06,1.06,0,0,1,0,2.12Zm0-8.48a1.06,1.06,0,0,1,0-2.12H18.94a1.06,1.06,0,0,1,0,2.12Zm0-8.48A1.06,1.06,0,0,1,1.06,0H18.94a1.06,1.06,0,0,1,0,2.12Z"
  })));
};

exports.default = HamburguerMenu;