import uniqid from 'uniqid';

export const fbqTrackEvent = (eventName, optionalParameterObj = {}) => {
  if (window.allowConsent) {
    window.fbq('consent', 'grant');
  } else {
    window.fbq('consent', 'revoke');
  }
  window.fbq('track', eventName, optionalParameterObj, {
    eventID: `${eventName}.${uniqid()}`,
  });
};

export const fbqCustomEvent = (eventName, optionalParameterObj = {}) => {
  if (window.allowConsent) {
    window.fbq('consent', 'grant');
  } else {
    window.fbq('consent', 'revoke');
  }
  window.fbq('trackCustom', eventName, optionalParameterObj, {
    eventID: `${eventName}.${uniqid()}`,
  });
};

// eslint-disable-next-line complexity
export const facebookTrigger = event => {
  const acceptedCookieConditions = [
    'accept-recommended-btn-handler',
    'onetrust-accept-btn-handler',
  ];
  if (
    acceptedCookieConditions.includes(event.target.id) ||
    [...event.target.classList].includes('save-preference-btn-handler')
  ) {
    window.allowConsent = false;
    var values = window.OnetrustActiveGroups.split(',');
    const allowValues = ['C0001', 'C0002', 'C0004'];
    const allow =
      values
        .filter(item => item)
        .sort()
        .join(',') === allowValues.sort().join(',');
    const checkIfBlocked = () => {
      let flag = false;
      let blockValues = ['C0001'];
      let data = values.filter(item => item);
      // eslint-disable-next-line flowtype/no-types-missing-file-annotation
      data.forEach((item: any) => {
        if (blockValues.includes(item)) {
          flag = true;
        } else {
          flag = false;
        }
      });
      return flag;
    };
    if (allow) {
      window.allowConsent = true;
    }
    if (checkIfBlocked(values)) {
      window.allowConsent = false;
    }
    sessionStorage.setItem('CONSENT_KEY', window.allowConsent);
  }
};
