import { ALLIANZ_HOME_SCHEMA_ID, AVIVA_HOME_SCHEMA_ID, AXA_HOME_SCHEMA_ID, ZURICH_HOME_SCHEMA_ID } from '../../../constants/insurers/homeInsurerIDs';

const insuredListAllianzLiving = [
  "Loss or damage to your buildings and contents as a result of events such as fire , storm or flood",
  "24 Hour Emergency Helpline",
  "Accidental Damage on building and contents included",
  "Automatic 10% Increase in contents cover for Christmas period",
  "10% increase in contents cover when notified of a wedding",
  "Alternative Accommodation up to 15% of combined Building & Contents Sums Insured",
  "Contents covered in the open e.g., garden furniture up to €100",
  "Fire Brigade charges covered up to €2000",
  "Replacement locks",
  "Cover up to €650 for finding a water or oil leak",
  "Liability cover for up to €3m for third party & domestic employee liability",
];

const insuredListAllianzNotLiving = [
  "Loss or damage to your buildings and contents as a result of events such as fire, storm or flood",
  "24 Hour Emergency Helpline",
  "Accidental Damage on building and contents included",
  "Alternative Accommodation or Loss of Rent is covered up to 15% of the Sum Insured once the property is deemed uninhabitable",
  "Fire Brigade charges covered up to €2000",
  "Replacement locks",
  "Cover up to €650 for finding a water or oil leak",
  "Your Liability cover for up to €3m as property owner or occupier"
];

const insuredListZurichLiving = [
  "Damage to your buildings and contents as a result of events such as fire, storm or flood",
  "24 Hour Emergency Helpline",
  "Accidental Damage on building and contents included as standard",
  "€1500 unspecified items all risk cover included as standard",
  "Cover for Business Equipment up to €4000 automatically included",
  "Automatic 10% Increase in contents cover for Christmas period",
  "10% increase in contents cover when notified of a wedding",
  "Public Liability Cover to provide childcare for up to 2 children",
  "Alternative Accommodation up to 20% of Building Sums Insured",
  "Fire Brigade charges covered up to 2500",
  "Replacement locks up to 700",
  "Cover up to €750 for finding a water or oil leak",
  "Liability cover for up to €3m for third party & domestic employee liability"
];

const insuredListZurichNotLiving = [
  "Damage to your buildings and contents as a result of events such as fire , storm or flood",
  "24 Hour Emergency Helpline",
  "Accidental Damage on building and contents included as standard",
  "Alternative Accommodation up to 20% of Building Sums Insured",
  "Fire Brigade charges covered up to 2500",
  "Replacement locks up to 500",
  "Cover up to €750 for finding a water or oil leak",
  "Liability cover for up to €3m for third party & domestic employee liability"
];

const insuredListAxaLiving = [
  "Loss or damage to your buildings and contents as a result of events such as fire, storm or flood",
  "24 Hour Emergency Helpline",
  "Accidental Damage on building and contents included",
  "Automatic 10% Increase in contents cover for Christmas period",
  "10% increase in contents cover when notified of a wedding",
  "Alternative Accommodation up to 20% of Building Sums Insured",
  "Contents covered in the open e.g., garden furniture up to €750",
  "Fire Brigade charges covered up to 2600",
  "Replacement locks up to 750",
  "Cover up to €750 for finding a water or oil leak",
  "Liability cover for up to €3m for third party & domestic employee liability"
];

const insuredListAxaNotLiving = [
  "Loss or damage to your buildings and contents as a result of events such as  fire , storm or flood",
  "24 Hour Emergency Helpline",
  "Accidental Damage on building and contents included",
  "Alternative Accommodation up to 20% of Building  Sums Insured",
  "Fire Brigade charges covered up to 2600",
  "Replacement locks up to 750",
  "Cover up to €750 for finding a water or oil leak",
  "Your Legal Liability to the Public as the property owner and/or as occupier  up to €3m"
];

const insuredListAvivaLiving = [
  "Loss or damage to your buildings and contents as a result of events such as fire, storm or flood",
  "24 Hour Emergency Helpline with up to €250 cover per incident",
  "Accidental Damage on building and contents included",
  "Automatic 10% increase in contents cover for Christmas period",
  "10% increase in contents cover when notified of a wedding",
  "Alternative Accommodation up to 15% of combined Building & Contents Sums Insured",
  "Jury Service - €40 per day up to a max of €650 with no loss of No Claims Discount",
  "Fire Brigade charges covered up to €1500",
  "Replacement locks up to €400",
  "Cover up to €750 for finding a water or oil leak",
  "Liability cover for third party & domestic employee liability"
];

const ipidAllianzLiving = {
  label: "Allianz Home Insurance IPID",
  url: "//www.arachas.ie/allianz-home-insurance-product-IPID"
};

const ipidAllianzNotLiving = {
  label: "Allianz Home Insurance IPID",
  url: "//www.arachas.ie/allianz-home-insurance-product-IPID"
};

const ipidZurichLiving = {
  label: "Zurich Home Insurance IPID",
  url: "//www.arachas.ie/media/r4gnmkim/zurich-arachas-home-ipid-document.pdf"
};

const ipidZurichNotLiving = {
  label: "Zurich Home Insurance IPID",
  url: "//www.arachas.ie/media/r4gnmkim/zurich-arachas-home-ipid-document.pdf "
};

const ipidAxaLiving = {
  label: "Axa Home Insurance IPID",
  url: "//www.arachas.ie/AXA-home-IPID"
};

const ipidAxaNotLiving = {
  label: "Axa Home Insurance IPID",
  url: "//www.arachas.ie/AXA-holiday-home-IPID"
};

const ipidAxaLandord = {
  label: "Axa Home Insurance IPID",
  url: "https://www.arachas.ie/AXA-landlord-IPID"
};

const ipidAvivaLiving = {
  label: "Aviva Home Insurance IPID",
  url: "//www.arachas.ie/aviva-homechoice-insurance-product-IPID"
};

export const getlistOfInsured = {
  [ALLIANZ_HOME_SCHEMA_ID]: {
    living: insuredListAllianzLiving,
    notLiving: insuredListAllianzNotLiving
  },
  [ZURICH_HOME_SCHEMA_ID]: {
    living: insuredListZurichLiving,
    notLiving: insuredListZurichNotLiving
  },
  [AXA_HOME_SCHEMA_ID]: {
    living: insuredListAxaLiving,
    notLiving: insuredListAxaNotLiving
  },
  [AVIVA_HOME_SCHEMA_ID]: {
    living: insuredListAvivaLiving,
  },
};

export const getListOfIPID = {
  [ALLIANZ_HOME_SCHEMA_ID]: {
    living: ipidAllianzLiving,
    notLiving: ipidAllianzNotLiving
  },
  [ZURICH_HOME_SCHEMA_ID]: {
    living: ipidZurichLiving,
    notLiving: ipidZurichNotLiving
  },
  [AXA_HOME_SCHEMA_ID]: {
    living: ipidAxaLiving,
    notLiving: ipidAxaNotLiving,
    rentOut: ipidAxaLandord
  },
  [AVIVA_HOME_SCHEMA_ID]: {
    living: ipidAvivaLiving,
  }
};