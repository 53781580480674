"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SunIcon = require("./SunIcon");

var _SunIcon2 = _interopRequireDefault(_SunIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SunIcon2.default;