/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../styles/commonFormStyles.scss';
import './HomeUpdateSpecifiedItems.scss';

import { Button, Title } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useContext } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import SpecifiedItems from '../../components/SpecifiedItems';
import { commonFormStylesIdentifier, commonPageStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getAccessToken, getItemFromSessionStorage, makeRequest } from '../../helpers';
import { getObjectFromSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import { homeUpdateSpecifiedItemsYupSchema } from './HomeUpdateSpecifiedItemsYupSchema';

const className = 'c-HomeUpdateSpecifiedItems';


const HomeUpdateSpecifiedItemsForm = (props) => {
  const { resources, setFieldValue, errors,
    isValid,
    touched,
    values,
    setTouched,
    history,
  } = props;

  const handleChangeWRecalculate = (
    fieldName,
    value,
  ) => {

    setFieldValue(fieldName, value);
  };

  const { cognitoUser } = useContext(CognitoContext);
  const policyDetails = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${SINGLE_POLICY_STORAGE}`);


  const getAddSpeciifedItemPayload = () => {
    return {
      "policy_reference": policyDetails.reference_number,
      "items": values.specifiedItems.map(specifiedItems => {
        if (specifiedItems.value !== null) {
          const newValue = parseFloat(specifiedItems.value.replace(/,/gi, '')).toFixed(2);
          specifiedItems.value = newValue;
        }
        return specifiedItems;
      }),
    };
  };


  const postAddSpecifiedItem = async (getAddSpeciifedItemPayload, cognitoAccessTokenKey, props = {}) => {
    const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
    const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
    return await makeRequest({
      method: 'POST',
      url: `${basePath}/home/mta/specified_items`,
      authToken: authToken,
      payload: getAddSpeciifedItemPayload,
      history: props.history
    });
  };

  const submitAddSpeciifedItem = async () => {
    if (getAddSpeciifedItemPayload() !== {}) {
      const postAddItemResponse = await postAddSpecifiedItem(getAddSpeciifedItemPayload(), getAccessToken(cognitoUser), props);
      if (postAddItemResponse.errorMessage === "Unexpected end of JSON input" || postAddItemResponse.errorMessage === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data") {
        history.push({
          pathname: `${routes.mtaThankYouPage.url}${HOME_INSURANCE_TYPE}`,
          state: {
            pageType: 'defaultPage'
          }
        });
      } else {
        history.push({
          pathname: `${routes.genericErrorPage.url}${HOME_INSURANCE_TYPE}`,
          state: {
            errorType: 'mta'
          }
        });
      }
    }
  };

  const titleText = 'Add Specified Items';

  return (
    <div className={`${className}`}>

      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${className}__container`}>

        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <div className={`${className}__mobileTitle`}>
            <Title align="left" type="h1" weight="weight500" variant="greyBrown">
              {titleText}
            </Title>
          </div>
        </div>

        <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
          <div className={`${commonPageStylesIdentifier}__desktopTitle ${className}__fontColor`}>
            {titleText}
          </div>
        </div>
        <div className={`${className}__subHeading`}> Please fill out the form below.</div>
        <div className={`${className}__card`}>
          <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
            <Form className={`${className}__form`}>
              <SpecifiedItems
                errors={errors.specifiedItems}
                handleChangeWRecalculate={handleChangeWRecalculate}
                values={values}
                setFieldValue={setFieldValue}
                setTouched={setTouched}
                touched={touched.specifiedItems}
                resources={resources}
              />
            </Form>
          </div>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>

          <div className={`${className}__button`}>
            <Button
              id="HomeUpdateSpecifiedItems__nextButton"
              fluid
              quaternary
              disabled={!isValid}
              onClick={() => {
                submitAddSpeciifedItem();
              }}
              type="submit"
            >
              Update Items
          </Button>
          </div>
        </div>
      </div>
    </div>

  );
};
const HomeUpdateSpecifiedItems = withFormik({
  mapPropsToValues() { },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: () => {
    return homeUpdateSpecifiedItemsYupSchema;
  },
  displayName: 'HomeUpdateSpecifiedItemsForm'
})(HomeUpdateSpecifiedItemsForm);

HomeUpdateSpecifiedItemsForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setTouched: PropTypes.func,
  isValid: PropTypes.bool,
  dirty: PropTypes.bool,
  history: PropTypes.object,
  resources: PropTypes.object,
};

export default HomeUpdateSpecifiedItems;
