"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var colors = exports.colors = {
  primaryColor: '#600060',
  secondaryColor: '#26cad3',
  tertiaryColor: '#f9b835',
  successColor: '#81bc29',
  errorColor: '#f14643',
  warningColor: '#ff6600',
  lightPrimaryColor: '#600460',
  bgPrimaryColor: '#eaebed',
  bgSecondaryColor: '#f4f5f6',
  bgTertiaryColor: '#f9fafa',
  bgLightestColor: '#fff',
  primaryFontColor: '#2e3d49',
  secondaryFontColor: '#969ea4',
  lightestFontColor: '#fff'
};
var coBrandingDefaults = exports.coBrandingDefaults = {
  text: colors.lightestFontColor,
  textDark: colors.primaryFontColor,
  // Needs removal with refactor
  primary: colors.primaryColor,
  secondary: colors.secondaryColor
};
var screenSize = exports.screenSize = {
  minDesktopWidth: 720
};