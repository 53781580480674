"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var noop = exports.noop = function noop() {};

var handleEnterEvent = exports.handleEnterEvent = function handleEnterEvent(event, callback) {
  if (event.key === "Enter") {
    event.preventDefault();
    callback();
  }
};