"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./TitleWithUnderLine.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * 
 */
var rootClass = 'c-TitleWithUnderLine';

var TitleWithUnderLine = function TitleWithUnderLine(_ref) {
  var children = _ref.children;
  return _react2.default.createElement("div", {
    className: "".concat(rootClass)
  }, _react2.default.createElement("h1", {
    className: "".concat(rootClass, "__text")
  }, children));
};

exports.default = TitleWithUnderLine;