/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

export const yourVanCoverFormInitialValues = {
  coverType: '',
  policyStart: '',
  mainDriverDisqualifiedOrConvicted: null,
  penaltyPoints: '',
  claimsCount: '',
  motorClaims: [],
  whoIsDrivingYourVan: '',
  additionalDrivers: [],
  disclosureRequirements: false,
  acceptedTermsAndConditions: false,
  marketingConsent: false
};