

export const oneTrustEmptyScript = () => {
  const element = document.createElement('script')
  element.setAttribute('type', 'text/javascript');
  const optionWrapper = function OptanonWrapper() {}
  element.innerHTML = optionWrapper
  return element
}

export const oneTrustCallScript = () => {
  const element = document.createElement('script');
  element.src = "//cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  element.setAttribute('type', 'text/javascript');
  element.setAttribute('charset', 'UTF-8');
  element.setAttribute('data-domain-script', '219d76b9-5d96-4f89-82cf-a5e5192ae005');
  return element
}
