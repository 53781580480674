/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './ComingSoon.scss';

import { Text } from '@arachas/core/lib';
import React from 'react';


const ComingSoon = () => {

  const className = 'c-ComingSoon';
  return (
    <div className={className}>
      <div className={`${className}__welcomeContainer`}>
        <div className={`${className}__welcomeText`}>
          <Text size="inherit">Content Coming Soon</Text>
        </div>
      </div>
    </div>

  );
}


export default ComingSoon;
