import { irishToRawFormat } from '../../helpers/DateHelper';

export const mapAddAdditionalDriver = values => {
  const additionalDriver = values.additionalDrivers[0];
  return {
    additional_driver: {
      title: additionalDriver.title,
      first_name: additionalDriver.firstName,
      last_name: additionalDriver.lastName,
      date_of_birth: irishToRawFormat(additionalDriver.dob),
      employment_status: additionalDriver.employmentStatus,
      occupation: additionalDriver.mainOccupation === '' ? null : additionalDriver.mainOccupation,
      licence: {
        type: additionalDriver.driversLicenseType,
        years: additionalDriver.yearsHeld,
      },
      relationship_to_proposer: additionalDriver.relationshipToProposer,
      penalty_details: (additionalDriver.additionalDriverPenaltyPointOffences || []).map(penalty => ({
        penalty_points: penalty.penaltyPoints,
        offence: penalty.offence,
        date: penalty.date,
      })),
      full_time_use_of_other_car: additionalDriver.useOfOtherCar,
      claims: (values.motorClaims || []).map(claim => {
        return {
          'type': claim.claim_type,
          'settled': claim.claim_status,
          'amount': typeof claim.claim_amount === 'string' ? parseFloat(claim.claim_amount.replace(/,/gi, '')).toFixed(2) : claim.claim_amount,
          'date': claim.claim_date,
        };
      }),
    },
    policy_reference: values.policyNumber,
    date_from: values.dateFrom,
    time_from: values.timeFrom,
    date_to: values.dateTo,
    time_to: values.timeTo,
    change_type: values.changeType,
  };
};
