const MainOccupationValues = [
  { value: "MAM", label: "Market Trader" },
  { value: "MAK", label: "Market Gardener" },
  { value: "CAY", label: "Certified Accountant" },
  { value: "AAC", label: "Accountant" },
  { value: "AAD", label: "Actor" },
  { value: "AAE", label: "Actress" },
  { value: "AAF", label: "Actuary" },
  { value: "AAG", label: "Acupuncturist" },
  { value: "AAM", label: "Air Traffic Controller" },
  { value: "AAN", label: "Aircraft Buyer" },
  { value: "AAP", label: "Airline Check-in Staff" },
  { value: "AAU", label: "Alarm Installer" },
  { value: "DBM", label: "Double Glazing Fitter" },
  { value: "AAW", label: "Ambulance Crew" },
  { value: "AAX", label: "Ambulance Driver" },
  { value: "ABD", label: "Antique Dealer" },
  { value: "ABF", label: "Arbitrator" },
  { value: "ABG", label: "Archaeologist" },
  { value: "ABH", label: "Architect" },
  { value: "NAG", label: "Non Commissioned Officer" },
  { value: "ABN", label: "Artist" },
  { value: "CCL", label: "Commercial Artist" },
  { value: "ABL", label: "Art Dealer" },
  { value: "ABT", label: "Auctioneer" },
  { value: "ABV", label: "Auditor" },
  { value: "ABW", label: "Author" },
  { value: "BAC", label: "Bailiff" },
  { value: "MBZ", label: "Musician" },
  { value: "BAE", label: "Bank Staff" },
  { value: "BAI", label: "Barman" },
  { value: "BAH", label: "Barmaid" },
  { value: "BAJ", label: "Barrister" },
  { value: "BAK", label: "Beautician" },
  { value: "BAO", label: "Biologist" },
  { value: "BAP", label: "Blacksmith" },
  { value: "BAS", label: "Boiler Maker" },
  { value: "BAX", label: "Botanist" },
  { value: "BAZ", label: "Brewer" },
  { value: "BBB", label: "Bricklayer" },
  { value: "BBF", label: "Builders Labourer" },
  { value: "BBH", label: "Building Foreman" },
  { value: "BBI", label: "Building Inspector" },
  { value: "BBG", label: "Building Contractor" },
  { value: "BBM", label: "Bus Driver" },
  { value: "CCH", label: "Coach Driver" },
  { value: "TAD", label: "Tax Consultant" },
  { value: "BBN", label: "Butcher" },
  { value: "BAA", label: "Bacon Curer" },
  { value: "BBP", label: "Buyer" },
  { value: "WAP", label: "Wood Worker" },
  { value: "JAD", label: "Joiner" },
  { value: "CAB", label: "Cabinet Maker" },
  { value: "CAL", label: "Care Assistant" },
  { value: "VAH", label: "Voluntary Worker" },
  { value: "CBI", label: "Chemist" },
  { value: "BAN", label: "Biochemist" },
  { value: "CBG", label: "Chef" },
  { value: "CDG", label: "Cook" },
  { value: "CBN", label: "Chiropodist" },
  { value: "CBO", label: "Chiropractor" },
  { value: "CBX", label: "Claims Assessor" },
  { value: "CBZ", label: "Cleaner" },
  { value: "CCC", label: "Clerical Officer" },
  { value: "WAA", label: "Wages Clerk" },
  { value: "VAC", label: "VDU Operator" },
  { value: "AAB", label: "Accommodation Officer" },
  { value: "CDT", label: "Credit Controller" },
  { value: "CCZ", label: "Computer Operator" },
  { value: "CCX", label: "Computer Analyst" },
  { value: "CDB", label: "Confectioner" },
  { value: "CCT", label: "Company Secretary" },
  { value: "CCI", label: "Coal Merchant" },
  { value: "CCK", label: "Cobbler" },
  { value: "CDA", label: "Computer Programmer" },
  { value: "ABE", label: "Applications Programmer" },
  { value: "AAK", label: "Agricultural Contractor" },
  { value: "AAI", label: "Advertising Contractor" },
  { value: "CDE", label: "Contractor" },
  { value: "CDN", label: "County Councillor" },
  { value: "CDL", label: "Council Worker" },
  { value: "CDO", label: "Courier" },
  { value: "DAH", label: "Delivery Courier" },
  { value: "CDY", label: "Customs & Excise Officer" },
  { value: "DAC", label: "Dancer" },
  { value: "EAT", label: "Exotic Dancer" },
  { value: "PAC", label: "Painter And Decorator" },
  { value: "DAL", label: "Dental Assistant" },
  { value: "DAN", label: "Dental Nurse" },
  { value: "DAO", label: "Dental Surgeon" },
  { value: "DAQ", label: "Dentist" },
  { value: "DAM", label: "Dental Hygienist" },
  { value: "AAO", label: "Aircraft Designer" },
  { value: "DBF", label: "Diver" },
  { value: "ABA", label: "Anaesthetist" },
  { value: "FAK", label: "Fast Food Delivery Driver" },
  { value: "CDR", label: "Crane Driver" },
  { value: "DAS", label: "Despatch Driver" },
  { value: "HAM", label: "HGV Driver" },
  { value: "HAN", label: "Hire Car Driver" },
  { value: "FBJ", label: "Fork Lift Truck Driver" },
  { value: "TBJ", label: "Train Driver" },
  { value: "TBF", label: "Tractor Driver" },
  { value: "CAH", label: "Car Delivery Driver" },
  { value: "PBH", label: "Plant Driver" },
  { value: "CBF", label: "Chauffeur" },
  { value: "DBT", label: "Dry Cleaner" },
  { value: "EAB", label: "Editor" },
  { value: "EAE", label: "Electrician" },
  { value: "EAD", label: "Electrical Contractor" },
  { value: "ABX", label: "Auto Electrician" },
  { value: "CBL", label: "Childrens Entertainer" },
  { value: "FAC", label: "Factory Worker" },
  { value: "DAU", label: "Despatch Worker" },
  { value: "LAW", label: "Line Worker" },
  { value: "ABP", label: "Assembly Worker" },
  { value: "FAP", label: "Financial Advisor" },
  { value: "FAE", label: "Farm Worker" },
  { value: "FAF", label: "Farmer" },
  { value: "FAG", label: "Farrier" },
  { value: "DBQ", label: "Dressmaker" },
  { value: "CAD", label: "Cameraman" },
  { value: "MBG", label: "Microfilm Operator" },
  { value: "FAZ", label: "Fisherman" },
  { value: "FAW", label: "Fish Farmer" },
  { value: "FBY", label: "Fitness Instructor" },
  { value: "FBO", label: "Fuel Merchant" },
  { value: "FBR", label: "Funeral Furnisher" },
  { value: "GAG", label: "Gardener" },
  { value: "GAF", label: "Garda" },
  { value: "CAK", label: "Car Valet" },
  { value: "GAI", label: "Genealogist" },
  { value: "GAP", label: "Goldsmith" },
  { value: "GAQ", label: "Graphic Designer" },
  { value: "GAW", label: "Groundsman" },
  { value: "GAZ", label: "Gynaecologist" },
  { value: "HAC", label: "Haulage Contractor" },
  { value: "BBO", label: "Butler" },
  { value: "CAZ", label: "Chambermaid" },
  { value: "HBD", label: "House Parent" },
  { value: "HBE", label: "Househusband" },
  { value: "IAE", label: "Independent Means" },
  { value: "UAB", label: "Underwriter" },
  { value: "IAN", label: "Interior Decorator" },
  { value: "IAO", label: "Interior Designer" },
  { value: "JAC", label: "Jockey" },
  { value: "JAE", label: "Journalist" },
  { value: "JAF", label: "Judge" },
  { value: "LAC", label: "Labourer" },
  { value: "LAA", label: "Laboratory Assistant" },
  { value: "LAE", label: "Land Agent" },
  { value: "LAF", label: "Landlord" },
  { value: "LAK", label: "Lawyer" },
  { value: "TAB", label: "Tanner" },
  { value: "LAN", label: "Legal Assistant" },
  { value: "LAO", label: "Legal Executive" },
  { value: "LAP", label: "Legal Secretary" },
  { value: "LAS", label: "Librarian" },
  { value: "LBB", label: "Locksmith" },
  { value: "LBF", label: "Loss Assessor" },
  { value: "MAB", label: "Machinist" },
  { value: "CDS", label: "Crane Operator" },
  { value: "PBI", label: "Plant Operator" },
  { value: "BAF", label: "Bar Manager" },
  { value: "MAF", label: "Manager" },
  { value: "MAG", label: "Manicurist" },
  { value: "CAE", label: "Car Body Repairer" },
  { value: "CAF", label: "Car Builder" },
  { value: "MBU", label: "Motor Mechanic" },
  { value: "BBC", label: "Broadcaster" },
  { value: "CAS", label: "Cartoonist" },
  { value: "MAW", label: "Medical Secretary" },
  { value: "MBB", label: "Metal Worker" },
  { value: "MBE", label: "Meter Reader" },
  { value: "MBH", label: "Midwife" },
  { value: "WAK", label: "Wholesaler" },
  { value: "MBI", label: "Milkman" },
  { value: "MBL", label: "Milliner" },
  { value: "MBQ", label: "Model" },
  { value: "MBT", label: "Mortician" },
  { value: "MBY", label: "Music Teacher" },
  { value: "ABY", label: "Auxiliary Nurse" },
  { value: "DBE", label: "District Nurse" },
  { value: "PBT", label: "Press Operator" },
  { value: "TAQ", label: "Telex Operator" },
  { value: "LAH", label: "Lathe Operator" },
  { value: "BBJ", label: "Building Surveyor" },
  { value: "OAH", label: "Osteopath" },
  { value: "PAE", label: "Panel Beater" },
  { value: "CBQ", label: "Church Officer" },
  { value: "CCB", label: "Cleric" },
  { value: "PAM", label: "Pawnbroker" },
  { value: "PAS", label: "Pharmacist" },
  { value: "PAU", label: "Photographer" },
  { value: "PAZ", label: "Physiotherapist" },
  { value: "PBJ", label: "Plasterer" },
  { value: "PBL", label: "Playgroup Leader" },
  { value: "PBP", label: "Porter" },
  { value: "PBQ", label: "Postman" },
  { value: "PBR", label: "Postwoman" },
  { value: "PBV", label: "Priest" },
  { value: "PBW", label: "Print Finisher" },
  { value: "PBX", label: "Printer" },
  { value: "PBY", label: "Prison Officer" },
  { value: "PCI", label: "Property Buyer" },
  { value: "PCJ", label: "Property Dealer" },
  { value: "PCF", label: "Professor" },
  { value: "PCP", label: "Psychologist" },
  { value: "PCS", label: "Publican" },
  { value: "EAN", label: "Environmental Health Officer" },
  { value: "CCP", label: "Commissioned Officer" },
  { value: "CDP", label: "Court Officer" },
  { value: "IAC", label: "Immigration Officer" },
  { value: "IAI", label: "Inland Revenue Officer" },
  { value: "RAP", label: "Revenue Officer" },
  { value: "PCT", label: "Publisher" },
  { value: "QAB", label: "Quantity Surveyor" },
  { value: "RAE", label: "Radiologist" },
  { value: "RAD", label: "Radiographer" },
  { value: "RAF", label: "Receptionist" },
  { value: "RAH", label: "Reflexologist" },
  { value: "SAB", label: "Safety Officer" },
  { value: "HAF", label: "Health and Safety Officer" },
  { value: "SAD", label: "Sailor" },
  { value: "SAI", label: "Salesman" },
  { value: "CAJ", label: "Car Salesman" },
  { value: "SAM", label: "Scaffolder" },
  { value: "SAQ", label: "Screen Printer" },
  { value: "SBA", label: "Security Guard" },
  { value: "SAY", label: "Secretary" },
  { value: "SAZ", label: "Secretary and PA" },
  { value: "JAG", label: "Junk Shop Proprietor" },
  { value: "SBG", label: "Sheet Metal Worker" },
  { value: "FBA", label: "Fishmonger" },
  { value: "BAV", label: "Book Seller" },
  { value: "SBU", label: "Silversmith" },
  { value: "CBC", label: "Charity Worker" },
  { value: "CCS", label: "Community Worker" },
  { value: "SBX", label: "Social Worker" },
  { value: "SBY", label: "Solicitor" },
  { value: "SCB", label: "Speech Therapist" },
  { value: "HBH", label: "Housing Officer" },
  { value: "SCM", label: "Steel Worker" },
  { value: "SCR", label: "Stockbroker" },
  { value: "SCU", label: "Storeman" },
  { value: "SCY", label: "Stud Hand" },
  { value: "SDE", label: "Supervisor" },
  { value: "PCD", label: "Produce Supervisor" },
  { value: "SDI", label: "Surveyor - Chartered" },
  { value: "CBD", label: "Chartered Surveyor" },
  { value: "TAA", label: "Tailor" },
  { value: "TAG", label: "Taxidermist" },
  { value: "TAL", label: "Teachers Assistant" },
  { value: "CBY", label: "Classroom Aide" },
  { value: "SDF", label: "Supply Teacher" },
  { value: "SDC", label: "Student Teacher" },
  { value: "HAE", label: "Headteacher" },
  { value: "DAB", label: "Dance Teacher" },
  { value: "DAD", label: "Dark Room Technician" },
  { value: "TAN", label: "Telephonist" },
  { value: "TAO", label: "Telesales Person" },
  { value: "TAS", label: "Textile Worker" },
  { value: "TAX", label: "Tiler" },
  { value: "TBG", label: "Trade Union Official" },
  { value: "BAQ", label: "Blinds Installer" },
  { value: "CAQ", label: "Carpetfitter" },
  { value: "TBI", label: "Traffic Warden" },
  { value: "TBQ", label: "Travel Consultant" },
  { value: "TBR", label: "Travel Courier" },
  { value: "UAA", label: "Undertaker" },
  { value: "LAT", label: "Licensee" },
  { value: "LAU", label: "Lifeguard" },
  { value: "LAV", label: "Lift Attendant" },
  { value: "LAY", label: "Linguist" },
  { value: "LAZ", label: "Literary Agent" },
  { value: "LBA", label: "Lithographer" },
  { value: "LBC", label: "Log Merchant" },
  { value: "MAC", label: "Magistrate" },
  { value: "OAG", label: "Organist" },
  { value: "HAO", label: "Hod Carrier" },
  { value: "OAA", label: "Occupational Therapist" },
  { value: "OAB", label: "Oculist" },
  { value: "NAL", label: "Nursery Nurse" },
  { value: "NAM", label: "Nurseryman" },
  { value: "MBR", label: "Moneylender" },
  { value: "MBS", label: "Mortgage Broker" },
  { value: "NAB", label: "Navigator" },
  { value: "NAC", label: "Negotiator" },
  { value: "NAD", label: "Neurologist" },
  { value: "NAH", label: "Not In Employment" },
  { value: "NAI", label: "Nun" },
  { value: "NAA", label: "Nanny" },
  { value: "MBV", label: "Motor Racing Organiser" },
  { value: "MBW", label: "Motor Trader" },
  { value: "MBX", label: "Museum Attendant" },
  { value: "BBK", label: "Bursar" },
  { value: "BBL", label: "Bus Conductor" },
  { value: "NAF", label: "Night Watchman" },
  { value: "LAR", label: "Lens Grinder & Polisher" },
  { value: "LAL", label: "Leather Worker" },
  { value: "LAI", label: "Laundry Worker" },
  { value: "LAJ", label: "Lavatory Attendant" },
  { value: "BAR", label: "Boat Builder" },
  { value: "MBJ", label: "Mill Worker" },
  { value: "MBK", label: "Miller" },
  { value: "MBA", label: "Metal Polisher" },
  { value: "MBC", label: "Metallurgist" },
  { value: "MBD", label: "Meteorologist" },
  { value: "MBF", label: "Microbiologist" },
  { value: "MBM", label: "Miner" },
  { value: "MBN", label: "Mineralologist" },
  { value: "MBO", label: "Minister of Religion" },
  { value: "MBP", label: "Mobile Disco Owner" },
  { value: "MAY", label: "Messenger" },
  { value: "MAZ", label: "Metal Dealer" },
  { value: "MAV", label: "Medical Practitioner" },
  { value: "PAA", label: "Packer" },
  { value: "OAI", label: "Outfitter" },
  { value: "PAF", label: "Paramedic" },
  { value: "PAG", label: "Park Attendant" },
  { value: "PAH", label: "Park Keeper" },
  { value: "PAD", label: "Pallet Maker" },
  { value: "PAJ", label: "Pathologist" },
  { value: "PAK", label: "Pattern Maker" },
  { value: "PAI", label: "Party Planner" },
  { value: "BAM", label: "Bill Poster" },
  { value: "CAR", label: "Cartographer" },
  { value: "CAI", label: "Car Park Attendant" },
  { value: "CAN", label: "Careers Officer" },
  { value: "ABK", label: "Art Critic" },
  { value: "CBK", label: "Child Minder" },
  { value: "ABQ", label: "Astrologer" },
  { value: "ABR", label: "Astronomer" },
  { value: "ABS", label: "Au Pair" },
  { value: "ABO", label: "Asphalter" },
  { value: "BAB", label: "Baggage Handler" },
  { value: "ABU", label: "Audiologist" },
  { value: "CBE", label: "Charterer" },
  { value: "CBA", label: "Chandler" },
  { value: "CBB", label: "Charge Hand" },
  { value: "CBJ", label: "Chicken Sexer" },
  { value: "ABB", label: "Animal Breeder" },
  { value: "AAZ", label: "Amusement Arcade Worker" },
  { value: "AAV", label: "Almoner" },
  { value: "CAU", label: "Casual Worker" },
  { value: "CAX", label: "Caulker" },
  { value: "ABI", label: "Archivist" },
  { value: "CCE", label: "Clock Maker" },
  { value: "CCG", label: "Coach Builder" },
  { value: "CCJ", label: "Coastguard" },
  { value: "CBS", label: "Cinema Assistant" },
  { value: "CBT", label: "Circus Proprietor" },
  { value: "CBU", label: "Circus Worker" },
  { value: "TCB", label: "Typist" },
  { value: "UAD", label: "Upholsterer" },
  { value: "UAE", label: "Usher" },
  { value: "VAA", label: "Valuer" },
  { value: "VAE", label: "Verger" },
  { value: "VAD", label: "Vehicle Assessor" },
  { value: "VAG", label: "Vicar" },
  { value: "WAB", label: "Warehouseman" },
  { value: "WAC", label: "Waste Dealer" },
  { value: "WAE", label: "Watchmaker" },
  { value: "WAF", label: "Weaver" },
  { value: "WAH", label: "Welfare Officer" },
  { value: "WAI", label: "Welfare Rights Officer" },
  { value: "WAJ", label: "Wheel Clamper" },
  { value: "WAL", label: "Window Cleaner" },
  { value: "WAM", label: "Window Dresser" },
  { value: "WAO", label: "Wine Merchant" },
  { value: "TAI", label: "Tea Blender" },
  { value: "TAJ", label: "Tea Taster" },
  { value: "TBS", label: "Treasurer" },
  { value: "TBT", label: "Tree Feller" },
  { value: "TBU", label: "Tree Surgeon" },
  { value: "TBV", label: "Trinity House Pilot" },
  { value: "TBW", label: "Tunneller" },
  { value: "TBX", label: "Turf Accountant" },
  { value: "TBY", label: "Turner" },
  { value: "TBZ", label: "Tutor" },
  { value: "TCA", label: "Typesetter" },
  { value: "SBH", label: "Shelf Filler" },
  { value: "SBI", label: "Sheriff" },
  { value: "TBM", label: "Translator" },
  { value: "TBN", label: "Transport Controller" },
  { value: "TBK", label: "Trainer" },
  { value: "TBO", label: "Transport Officer" },
  { value: "TBH", label: "Trading Standards Officer" },
  { value: "TBC", label: "Tour Guide" },
  { value: "TBD", label: "Town Planner" },
  { value: "TBE", label: "Toy Maker" },
  { value: "TAT", label: "Thatcher" },
  { value: "TAP", label: "Television Presenter" },
  { value: "TAU", label: "Theatrical Agent" },
  { value: "TAV", label: "Therapist" },
  { value: "TAW", label: "Ticket Inspector" },
  { value: "TAY", label: "Tobacconist" },
  { value: "TAZ", label: "Tool Maker" },
  { value: "TBA", label: "Tool Setter" },
  { value: "YAA", label: "Youth Hostel Warden" },
  { value: "ZAA", label: "Zoo Keeper" },
  { value: "ZAB", label: "Zoologist" },
  { value: "SBC", label: "Seedsman" },
  { value: "SBE", label: "Servant" },
  { value: "SBJ", label: "Ship Builder" },
  { value: "SBK", label: "Shipwright" },
  { value: "SBZ", label: "Song Writer" },
  { value: "SCA", label: "Sorter" },
  { value: "SBP", label: "Shot Blaster" },
  { value: "SBQ", label: "Showman" },
  { value: "SBR", label: "Shunter" },
  { value: "SBS", label: "Signalman" },
  { value: "SBT", label: "Signwriter" },
  { value: "SBV", label: "Smallholder" },
  { value: "SDB", label: "Student Nurse" },
  { value: "SCS", label: "Stockman" },
  { value: "SCV", label: "Street Entertainer" },
  { value: "SCW", label: "Street Trader" },
  { value: "SBN", label: "Shop Fitter" },
  { value: "SCJ", label: "Stage Mover" },
  { value: "SCI", label: "Stable Hand" },
  { value: "SCD", label: "Sports Centre Attendant" },
  { value: "SCK", label: "Statistician" },
  { value: "SCL", label: "Steel Erector" },
  { value: "SCN", label: "Steeplejack" },
  { value: "SCO", label: "Stenographer" },
  { value: "SCP", label: "Stevedore" },
  { value: "PCQ", label: "Psychotherapist" },
  { value: "PCR", label: "Public Relations Officer" },
  { value: "PCH", label: "Proof Reader" },
  { value: "PCG", label: "Projectionist" },
  { value: "PCL", label: "Property Valuer" },
  { value: "PCM", label: "Proprietor" },
  { value: "RAG", label: "Rector" },
  { value: "RAB", label: "Radio Presenter" },
  { value: "RAC", label: "Radio Producer" },
  { value: "QAC", label: "Quarry Worker" },
  { value: "RAA", label: "Rabbi" },
  { value: "PCV", label: "Purser" },
  { value: "DBK", label: "Door To Door Collector" },
  { value: "DBL", label: "Doorman" },
  { value: "DBN", label: "Double Glazing Salesman" },
  { value: "DBR", label: "Driving Examiner" },
  { value: "DBU", label: "Dustman" },
  { value: "DBV", label: "Dyer" },
  { value: "EAA", label: "Economist" },
  { value: "EAL", label: "Engraver" },
  { value: "EAF", label: "Embalmer" },
  { value: "EAJ", label: "Energy Analyst" },
  { value: "EAP", label: "Estimator" },
  { value: "EAQ", label: "Evangelist" },
  { value: "EAR", label: "Exhaust Fitter" },
  { value: "EAS", label: "Exhibition Organiser" },
  { value: "FAM", label: "Fibre Glass Moulder" },
  { value: "FAD", label: "Fairground Worker" },
  { value: "EAU", label: "Exporter" },
  { value: "FAA", label: "Fabricator" },
  { value: "CCN", label: "Commission Agent" },
  { value: "CCQ", label: "Commodity Broker" },
  { value: "CCU", label: "Complementary Therapist" },
  { value: "CCV", label: "Composer" },
  { value: "CCW", label: "Compositor" },
  { value: "CDF", label: "Conveyancer" },
  { value: "CDH", label: "Cooper" },
  { value: "CDI", label: "Coppersmith" },
  { value: "CDJ", label: "Copywriter" },
  { value: "CDK", label: "Coroner" },
  { value: "CDU", label: "Credit Draper" },
  { value: "CDM", label: "Counsellor" },
  { value: "CBP", label: "Choreographer" },
  { value: "CDV", label: "Croupier" },
  { value: "CDW", label: "Curator" },
  { value: "CDX", label: "Customer Liaison Officer" },
  { value: "FAR", label: "Financier" },
  { value: "DAA", label: "Dairy Worker" },
  { value: "CDZ", label: "Cutler" },
  { value: "CEA", label: "Cutter" },
  { value: "DAE", label: "Dealer - General" },
  { value: "DAF", label: "Debt Collector" },
  { value: "AAA", label: "Abattoir Worker" },
  { value: "DAR", label: "Dermatologist" },
  { value: "AAJ", label: "Aerial Erector" },
  { value: "DAG", label: "Decorator" },
  { value: "DAI", label: "Delivery Roundsman" },
  { value: "DAJ", label: "Demolition Worker" },
  { value: "DAK", label: "Demonstrator" },
  { value: "HBC", label: "Hostess" },
  { value: "HAY", label: "Hospital Consultant" },
  { value: "HAZ", label: "Hospital Doctor" },
  { value: "HBF", label: "Housekeeper" },
  { value: "HAT", label: "Horse Breeder" },
  { value: "HAU", label: "Horse Dealer" },
  { value: "HAV", label: "Horse Riding Instructor" },
  { value: "HAW", label: "Horse Trader" },
  { value: "HAX", label: "Horticulturalist" },
  { value: "IAA", label: "Ice Cream Vendor" },
  { value: "IAB", label: "Illustrator" },
  { value: "MAH", label: "Manufacturing Agent" },
  { value: "MAI", label: "Marine Pilot" },
  { value: "IAL", label: "Insurance Assessor" },
  { value: "IAK", label: "Instrument Maker" },
  { value: "IAP", label: "Interpreter" },
  { value: "IAQ", label: "Interviewer" },
  { value: "FBD", label: "Floor Layer" },
  { value: "IAF", label: "Industrial Chemist" },
  { value: "IAG", label: "Industrial Designer" },
  { value: "FBE", label: "Florist" },
  { value: "FBZ", label: "Flower Arranger" },
  { value: "FBF", label: "Flying Instructor" },
  { value: "FBG", label: "Food Processor" },
  { value: "MAR", label: "Mathematician" },
  { value: "MAS", label: "Matron" },
  { value: "MAP", label: "Masseur" },
  { value: "MAQ", label: "Masseuse" },
  { value: "FAT", label: "Firefighter" },
  { value: "FAV", label: "Firewood Merchant" },
  { value: "FAX", label: "Fish Filleter" },
  { value: "FAY", label: "Fish Fryer" },
  { value: "GAK", label: "General Practitioner" },
  { value: "GAL", label: "Geologist" },
  { value: "GAM", label: "Geophysicist" },
  { value: "GAO", label: "Glazier" },
  { value: "GAH", label: "Gas Fitter" },
  { value: "GAY", label: "Gun Smith" },
  { value: "GAV", label: "Groom" },
  { value: "GAR", label: "Grave Digger" },
  { value: "GAS", label: "Gravel Merchant" },
  { value: "HAD", label: "Hawker" },
  { value: "HAH", label: "Health Planner" },
  { value: "HAI", label: "Health Visitor" },
  { value: "HAJ", label: "Hearing Therapist" },
  { value: "HAL", label: "Herbalist" },
  { value: "DBG", label: "Docker" },
  { value: "DBH", label: "Dockyard Worker" },
  { value: "DBJ", label: "Dog Groomer" },
  { value: "JAH", label: "Justice Of The Peace" },
  { value: "KAA", label: "Keep Fit Instructor" },
  { value: "KAB", label: "Kennel Hand" },
  { value: "KAC", label: "Kissagram Person" },
  { value: "KAD", label: "Kitchen Worker" },
  { value: "KAE", label: "Knitter" },
  { value: "IAS", label: "Ironmonger" },
  { value: "LAD", label: "Laminator" },
  { value: "JAA", label: "Janitor" },
  { value: "FBK", label: "Forwarding Agent" },
  { value: "FBL", label: "Foundry Worker" },
  { value: "FBM", label: "French Polisher" },
  { value: "DAW", label: "Dinner Lady" },
  { value: "DBD", label: "Disc Jockey" },
  { value: "FBP", label: "Fund Raiser" },
  { value: "FBS", label: "Furnace Man" },
  { value: "FBT", label: "Furniture Dealer" },
  { value: "FBV", label: "Furniture Remover" },
  { value: "FBX", label: "Furrier" },
  { value: "GAD", label: "Gamekeeper" },
  { value: "PBA", label: "Piano Tuner" },
  { value: "PBB", label: "Picker" },
  { value: "PBC", label: "Picture Framer" },
  { value: "PBE", label: "Pipe Fitter" },
  { value: "PBF", label: "Pipe Layer" },
  { value: "PBG", label: "Planning Officer" },
  { value: "PBN", label: "Pool Attendant" },
  { value: "PBO", label: "Pools Collector" },
  { value: "SAP", label: "Scrap Dealer" },
  { value: "SAR", label: "Screen Writer" },
  { value: "SAS", label: "Script Writer" },
  { value: "SAT", label: "Sculptor" },
  { value: "SAV", label: "Seaman" },
  { value: "SAW", label: "Seamstress" },
  { value: "SAX", label: "Second Hand Dealer" },
  { value: "SAN", label: "School Crossing Warden" },
  { value: "SAH", label: "Sales Support" },
  { value: "SAJ", label: "Sand Blaster" },
  { value: "SAL", label: "Saw Miller" },
  { value: "SAC", label: "Sail Maker" },
  { value: "SAE", label: "Sales Administrator" },
  { value: "SAA", label: "Saddler" },
  { value: "PBK", label: "Plater" },
  { value: "PAN", label: "Personal Assistant" },
  { value: "RAT", label: "Road Sweeper" },
  { value: "RAU", label: "Road Worker" },
  { value: "TAE", label: "Tax Inspector" },
  { value: "PAX", label: "Physicist" },
  { value: "PAY", label: "Physiologist" },
  { value: "PAO", label: "Personnel Officer" },
  { value: "PAP", label: "Pest Controller" },
  { value: "PAT", label: "Photo Engraver" },
  { value: "RAN", label: "Restorer" },
  { value: "RAQ", label: "Rig Worker" },
  { value: "RAR", label: "Rigger" },
  { value: "RAS", label: "Riveter" },
  { value: "RAI", label: "Refuse Collector" },
  { value: "RAJ", label: "Registrar" },
  { value: "RAK", label: "Reporter" },
  { value: "RAL", label: "Researcher" },
  { value: "PCE", label: "Producer" },
  { value: "PBZ", label: "Private Investigator" },
  { value: "PCA", label: "Probation Officer" },
  { value: "PCB", label: "Process Worker" },
  { value: "PCC", label: "Procurator Fiscal" },
  { value: "PBU", label: "Presser" },
  { value: "PBS", label: "Potter" },
  { value: "PAL", label: "Pattern Weaver" },
  { value: "DAT", label: "Despatch Rider" },
  { value: "WAG", label: "Welder" },
  { value: "WAR", label: "Writer" },
  { value: "MAL", label: "Market Researcher" },
  { value: "BAD", label: "Baker" },
  { value: "BAG", label: "Barber" },
  { value: "MAE", label: "Make Up Artist" },
  { value: "BAT", label: "Boiler Man" },
  { value: "BAW", label: "Book-Keeper" },
  { value: "SBL", label: "Shoe Repairer" },
  { value: "BAU", label: "Book Binder" },
  { value: "BBA", label: "Brewery Worker" },
  { value: "BBD", label: "Builder" },
  { value: "CAP", label: "Carpenter" },
  { value: "CAT", label: "Cashier" },
  { value: "CAO", label: "Caretaker" },
  { value: "CBM", label: "Chimney Sweep" },
  { value: "CBV", label: "Civil Servant" },
  { value: "CCA", label: "Clergyman" },
  { value: "CCD", label: "Clerk" },
  { value: "AAH", label: "Administration Staff" },
  { value: "CCY", label: "Computer Consultant" },
  { value: "IAM", label: "Insurance Representative" },
  { value: "CCM", label: "Commercial Traveller" },
  { value: "CDC", label: "Construction Worker" },
  { value: "CDD", label: "Consultant" },
  { value: "DAP", label: "Dental Technician" },
  { value: "DAV", label: "Dietician" },
  { value: "DBI", label: "Doctor" },
  { value: "DBP", label: "Draughtsman" },
  { value: "VAB", label: "Van Driver" },
  { value: "LBD", label: "Lorry Driver" },
  { value: "DBS", label: "Driving Instructor" },
  { value: "EAK", label: "Engineer" },
  { value: "EAM", label: "Entertainer" },
  { value: "EAO", label: "Estate Agent" },
  { value: "OAD", label: "Operations Supervisor" },
  { value: "FAB", label: "Factory Inspector" },
  { value: "FAJ", label: "Fashion Designer" },
  { value: "FAN", label: "Film Producer" },
  { value: "FAQ", label: "Financial Analyst" },
  { value: "FBC", label: "Fitter - Tyre/Exhaust" },
  { value: "FBB", label: "Fitter" },
  { value: "FBI", label: "Forester" },
  { value: "FBH", label: "Foreman" },
  { value: "FBN", label: "Fruiterer" },
  { value: "FBQ", label: "Funeral Director" },
  { value: "LAG", label: "Landscape Gardener" },
  { value: "GAE", label: "Garage Foreman" },
  { value: "CAG", label: "Car Dealer" },
  { value: "GAN", label: "Glass Worker" },
  { value: "GAT", label: "Green Keeper" },
  { value: "GAU", label: "Greengrocer" },
  { value: "GAX", label: "Guest House Proprietor" },
  { value: "HAB", label: "Hairdresser" },
  { value: "HAR", label: "Homeopath" },
  { value: "HBG", label: "Housewife" },
  { value: "JAB", label: "Jeweller" },
  { value: "LAB", label: "Laboratory Technician" },
  { value: "LAM", label: "Lecturer" },
  { value: "LAQ", label: "Leisure Centre Attendant" },
  { value: "LBE", label: "Loss Adjustor" },
  { value: "MAA", label: "Machine Operator" },
  { value: "MAD", label: "Maintenance Staff" },
  { value: "MAT", label: "Mechanic" },
  { value: "MAX", label: "Merchant Seaman" },
  { value: "NAE", label: "Newsagent" },
  { value: "NAJ", label: "Nurse" },
  { value: "OAF", label: "Optician" },
  { value: "PAB", label: "Painter" },
  { value: "CBR", label: "Church Warden" },
  { value: "PAQ", label: "Petrol Station Attendant" },
  { value: "PBM", label: "Plumber" },
  { value: "PCK", label: "Property Developer" },
  { value: "PCO", label: "Psychiatrist" },
  { value: "QAA", label: "Quality Controller" },
  { value: "RAM", label: "Restaurateur" },
  { value: "RAV", label: "Roofer" },
  { value: "SAG", label: "Sales Representative" },
  { value: "SAF", label: "Sales Executive" },
  { value: "SAO", label: "Scientist" },
  { value: "SBB", label: "Security Officer" },
  { value: "SBM", label: "Shop Assistant" },
  { value: "SBO", label: "Shop Keeper" },
  { value: "YAB", label: "Youth Worker" },
  { value: "SDK", label: "Software Developer" },
  { value: "SCG", label: "Sportsman" },
  { value: "SDH", label: "Surveyor" },
  { value: "SDJ", label: "Systems Analyst" },
  { value: "TAF", label: "Taxi Driver" },
  { value: "TAK", label: "Teacher" },
  { value: "TBP", label: "Travel Agent" },
  { value: "LBG", label: "Lumberjack" },
  { value: "HAP", label: "Home Help" },
  { value: "NAK", label: "Nursery Assistant" },
  { value: "MAU", label: "Medical Consultant" },
  { value: "WAN", label: "Windscreen Fitter" },
  { value: "TBL", label: "Training Officer" },
  { value: "SCT", label: "Stonemason" },
  { value: "SCF", label: "Sports Coach" },
  { value: "SCQ", label: "Stock Controller" },
  { value: "SDG", label: "Surgeon" },
  { value: "CCO", label: "Commissionaire" },
  { value: "CCR", label: "Commodity Dealer" },
  { value: "FAS", label: "Fire Officer" },
  { value: "OAE", label: "Optical Assistant" },
  { value: "IAJ", label: "Inspector - Insurance" },
  { value: "IAD", label: "Importer" },
  { value: "FBW", label: "Furniture Restorer" },
  { value: "VAF", label: "Veterinary Surgeon" }
];

export const getOccupationOptions = () => {
  return MainOccupationValues.map((occupation) => {
    return {
      key: occupation.value,
      value: occupation.value,
      text: occupation.label
    };
  });
};

export default MainOccupationValues;
