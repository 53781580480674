/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { CAR_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../constants/insuranceTypeConstants';
import { getItemFromSessionStorage, makeRequest } from '../helpers';

// const typeNames = {
//   [`${CAR_INSURANCE_TYPE}`]: "Car",
//   [`${HOME_INSURANCE_TYPE}`]: "Home"
// };

//TODO put it back in later
// const sendAnalyticsEvent = (bindPromise, insuranceType, paymentDetails) => {
//   bindPromise.then(response => {
//     if (!response.error) {
//       window.dataLayer.push({
//         'event': 'checkout',
//         'transactionId': paymentDetails.payment_details.quote_reference,
//         'transactionAffiliation': 'Bank of Ireland',
//         'transactionTotal': paymentDetails.payment_details.price,
//         'transactionProducts': [{
//           'sku': `${typeNames[insuranceType]} Insurance`,
//           'name': `${typeNames[insuranceType]} Insurance GWS`,
//           'category': 'Consumer - Insurance',
//           'price': paymentDetails.payment_details.price,
//           'quantity': 1
//         }],
//         'catchAll': paymentDetails.payment_details.product_selected || ""
//       });
//     }
//   });
// };

const getBindUrl = (basePath, insuranceType) => {
  if (insuranceType === CAR_INSURANCE_TYPE) {
    return `${basePath}/motor/motor_quotes/bind`;
  }
  if (insuranceType === TRAVEL_INSURANCE_TYPE) {
    return `${basePath}/travel/travel_quotes/bind`;
  }
  return `${basePath}/${insuranceType}/${insuranceType}_quotes/bind`;
};

export const buyNowBind = async (paymentDetails, cognitoAccessTokenKey, insuranceType = 'home', props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const paymentRequest = makeRequest({
    method: 'POST',
    url: getBindUrl(basePath, insuranceType),
    authToken: authToken,
    payload: paymentDetails,
    history: props.history
  });
  // if (insuranceType !== TRAVEL_INSURANCE_TYPE) sendAnalyticsEvent(paymentRequest, insuranceType, paymentDetails);
  return await paymentRequest;
};
