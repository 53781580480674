const daysOfTheMonthValues = [];

for (let day = 1; day <= 31; ++day) {
  daysOfTheMonthValues.push({
    displayName: `${day}`,
    dropdownValue: day,
  });
}

export default daysOfTheMonthValues;
