/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import './SubmitClaimDefaultPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  PhoneButton
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { primaryPurple } from '../../constants/colors';
import { DEVICE_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import { device, travel } from './ContentTextAndNumbers.js';
import TravelText from './TravelText';

const className = 'c-SubmitClaimDefaultPage';

const DefaultClaimContent = (props) => {

  const { match } = props;
  const insuranceType = match.params.type || '';

  const getPhoneNumber = {
    [TRAVEL_INSURANCE_TYPE]: travel.phoneOne,
    [DEVICE_INSURANCE_TYPE]: device.phoneOne
  };

  const getText = {
    [DEVICE_INSURANCE_TYPE]: <>{device.textOne}</>,
    [TRAVEL_INSURANCE_TYPE]: <TravelText />
  };

  return (
    <>
      <div className={`${className}__content`}>
        <div className={`${className}__textContent`}>
          {getText[insuranceType]}
        </div>
        <div className={`${className}__phoneButtonContainer`}>
          <PhoneButton color={primaryPurple} label='Please give us a call.'
            phoneNumber={getPhoneNumber[insuranceType]}
            isWhite
            onClick={() => {
              fbqTrackEvent('Contact')
            }}>
            {getPhoneNumber[insuranceType]}
          </PhoneButton>
          {insuranceType === TRAVEL_INSURANCE_TYPE ? <><div className={`${className}__content`}>
            <div className={`${className}__outOfIrelandContentText`}>
              If calling from outside of Ireland
              </div>
            <PhoneButton 
              color={primaryPurple} 
              label='Please give us a call.'
              phoneNumber={travel.phoneTwo} 
              isWhite
              onClick={() => {
                fbqTrackEvent('Contact')
              }}>
              {travel.phoneTwo}
            </PhoneButton>
          </div>
          </>
            :
            ''}
        </div>
      </div>
    </>
  );
};

DefaultClaimContent.propTypes = {
  match: PropTypes.object,
};

export default withRouter(DefaultClaimContent);