import './Messages.scss';

import { DesktopCard } from '@arachas/core/lib';
import { AnimatedSpinner } from '@arachas/core/lib';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { getAccessToken } from '../../helpers';
import Message from './Message';
import { getMessages, getReadMessages } from './messagesService';

const className = 'c-Messages';


function Messages() {
  const [messages, setMessages] = useState(null);
  const [readMessages, setReadMessages] = useState(null);
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    const accessToken = getAccessToken(cognitoUser);
    getMessages(accessToken).then(setMessages);
    getReadMessages(accessToken).then(setReadMessages);
  }, []);

  if (!messages) return <AnimatedSpinner />;
  if (!readMessages) return <AnimatedSpinner />;

  const messagesArray = messages.map((message, index) => (
    <div key={`message-${index}`} className={`${className}__messageContainer`}>
      <Message {...message} />
    </div>
  ));

  const readMessagesArray = readMessages.map((message, index) => (
    <div key={`message-${index}`} className={`${className}__messageContainer`}>
      <Message {...message} />
    </div>
  ));

  const renderReadMessages = () => {
    return (
      readMessagesArray.length > 0 &&
      <>
        <div className={`${className}__title`}>
          Read Messages
          </div>
        <DesktopCard>
          {readMessagesArray}
        </DesktopCard>
      </>
    );
  };

  return (
    <div className={className}>
      {messagesArray.length > 0 &&
        <>
          <div className={` ${className}__title`}>New Messages</div>
          <DesktopCard>
            {messagesArray}
          </DesktopCard>
        </>
      }
      { renderReadMessages()}
    </div >
  );
}

export default Messages;
