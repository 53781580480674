/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './CallUsPage.scss';

import {
  Button,
  DesktopCard,
  PhoneButton,
} from '@arachas/core/lib';
import { noop } from '@arachas/core/lib/utils';
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { backToWallet } from '../../constants/buttonLabelConstants';
import { primaryPurple } from '../../constants/colors';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import { MobileBackButton } from '../MobileBackButton';

type Props = {
  icon: noop,
  contentText?: string,
  phoneNumber: string,
  internationalPhoneNumber?: string,
  title: string,
  history: RouterHistory,
  cardTitle: string,
  afterContent?: string;
};

const CallUsPage = ({ icon, title, contentText, phoneNumber, history, cardTitle, internationalPhoneNumber, afterContent }: Props) => {
  const className = 'c-CallUsPage';


  return (
    <div className={className}>
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {cardTitle}
        </div>

      </div>
      <DesktopCard>
        <div className={`${className}__container`}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          {icon ? (
            <div className={`${className}__iconContainer`}>
              <div className={`${className}__icon`}>{icon}</div>
            </div>)
            : <></>}
          <div className={`${className}__customTitleContainer`}>
            <div className={`${className}__customTitle`}>
              <div className={`${className}__titleText`}>
                {title}
              </div>
            </div>
          </div>
          <div className={`${className}__contentContainer`}>
            <div className={`${className}__customContent`}>
              {contentText}
            </div>
            <div className={`${className}__phoneButtonContainer`}>
              <PhoneButton 
                color={primaryPurple} 
                label='Please give us a call.' 
                phoneNumber={phoneNumber}
                onClick={() => {
                  fbqTrackEvent('Contact')
                }}  
                isWhite>
                {phoneNumber}
              </PhoneButton>
              {internationalPhoneNumber &&
                (<>
                  <br />
                  <PhoneButton color={primaryPurple} label='When calling us from outside of Ireland'
                    phoneNumber={internationalPhoneNumber}
                    isWhite
                    onClick={() => {
                      fbqTrackEvent('Contact')
                    }}>
                    {internationalPhoneNumber}
                  </PhoneButton>
                </>)
              }
              {afterContent ? <div className={`${className}__afterContent`}>{afterContent}</div> : <></>}
            </div>
            <div className={`${className}__buttonContainer`}>
              <Link to={routes.Wallet.url}>
                <Button fluid={true}>
                  {backToWallet}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>

    </div >
  );
};

export default CallUsPage;
