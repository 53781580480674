/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './YourTravelForm.scss';
import '../../../styles/commonFormStyles.scss';

import { Label, Typeahead } from '@arachas/core/lib';
import type { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../constants';
import { TRIP_TYPE_CODE } from '../../../constants/travel/TravelConstants';
import { isFieldError } from '../../../helpers/FieldErrorHelper';

type Values = {
  destinationCountry: string,
  tripType: string;
};

const DestinationCountry = ({
  values,
  errors,
  touched,
  setFieldTouched,
  destinationCountries,
  setFieldValue,
  handleBlur
}: FormikProps<Values> & { destinationCountries: mixed[]; }) => {
  const destinationCountryTouched = touched.destinationCountry;
  const destinationCountryError = errors.destinationCountry;
  const destinationCountryValue = values.destinationCountry;

  if (values.tripType !== TRIP_TYPE_CODE.SINGLE_TRIP) return <></>;

  return (
    <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
      <Label
        htmlFor="destinationCountry"
        className={`${commonFormStylesIdentifier}__fieldLabel`}
      >
        Destination country
      </Label>
      <span className={`${commonFormStylesIdentifier}__input`}>
        <Typeahead
          data={destinationCountries}
          id="destinationCountry"
          name="destinationCountry"
          placeholder="Type here"
          onClick={(e: string) => setFieldValue('destinationCountry', e)}
          onChange={(option: { key: string, value: string, text: string; }) => {
            if (!destinationCountryTouched) {
              setFieldTouched('destinationCountry', true);
            }
            setFieldValue('destinationCountry', option ? option.value : '');
          }}
          error={isFieldError('destinationCountry', touched, errors)}
          errorMessage={destinationCountryError}
          value={destinationCountryValue}
          onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
            if (!destinationCountryTouched) {
              setFieldTouched('destinationCountry', true);
            }
            handleBlur(e);
          }}
        />
      </span>
    </div>
  );
};

export default DestinationCountry;
