import './HomeAddressDetails.scss';

import { AccordionText, InputField } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants/styleConstants';

const className = 'c-HomeDetails';

const HomeAddressDetails = ({ values, onEdit }) => {
  return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Eircode:
          <div className={`${className}__editLabel`} onClick={onEdit}>EDIT</div>
        </label>
        <InputField
          readOnly={true}
          value={values.addressEircode}
        />
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Address line 1:
        </label>
        <InputField
          readOnly="true"
          value={values.addressLine1}
        />
      </div>

      <AccordionText
        label="SEE MORE"
        openLabel="SEE LESS"
        inverted
      >

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Address line 2:
          </label>
          <InputField
            readOnly="true"
            value={values.addressLine2}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Address line 3:
          </label>
          <InputField
            readOnly="true"
            value={values.addressLine3}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Town:
          </label>
          <InputField
            readOnly="true"
            value={values.addressTown}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
            County:
          </label>
          <InputField
            readOnly="true"
            value={values.addressCounty}
          />
        </div>
      </AccordionText>
    </div>
  );
};

HomeAddressDetails.propTypes = {
  values: PropTypes.object,
  onEdit: PropTypes.func
};

export default HomeAddressDetails;

