/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const YourDeviceCoverFormInitialValues = {
  acceptedTermsAndConditions: false,
  acceptedAssumptions: false,
  marketingConsent: false,
  emailConsent: false,
  postConsent: false,
  phoneConsent: false,
  smsConsent: false,
  selectAll: false
};
