"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ClockIcon = function ClockIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#600060' : _ref$color;
  var style = {
    fill: color
  };
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 20 20"
  }, _react2.default.createElement("path", {
    fill: "",
    d: "M12 2a10 10 0 1 0 10 10A10.029 10.029 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.024 8.024 0 0 1-8 8zm.5-13H11v6l5.2 3.1.8-1.2-4.5-2.7z",
    transform: "translate(-2 -2)",
    style: style
  }));
};

ClockIcon.propTypes = {
  color: _propTypes2.default.string
};
exports.default = ClockIcon;