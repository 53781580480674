import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './SummaryPage.scss';

import { TitleWithUnderLine } from '@arachas/core/lib';
import { MapIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import TagManager from 'react-gtm-module';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import {
  commonPageStylesIdentifier
} from '../../constants';
import { primaryPurple } from '../../constants/colors';
import {
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_PRODUCT } from '../../constants/journey';
import { TRIP_TYPE_CODES, TRIP_TYPES } from '../../constants/travel/TravelConstants';
import {
  getPayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { Divider, renderLine, Tick } from './SummaryComponents';


const SummaryPage = (props) => {
  const { history, className } = props;

  const payload = getPayloadObjFromSessionStorage(TRAVEL_INSURANCE_TYPE);
  const values = getValuesObjFromSessionStorage(TRAVEL_INSURANCE_TYPE);

  const getPriceFromPayload = () => {
    const quote = payload.response.quotes.find((quote) => quote.name === TRIP_TYPE_CODES[values.tripType]);
    return quote.breakdown.total_payable;
  };

  TagManager.dataLayer({
    dataLayer: DataLayers.landsOnSummaryPage({
      insuranceType: TRAVEL_INSURANCE_TYPE,
      cover_premium: getPriceFromPayload()
    }),
    dataLayerName: 'dataLayer'
  })

  const onProceedClicked = () => {
    history.push({ pathname: `${routes.getDeclarationPage.url}${TRAVEL_INSURANCE_TYPE}` });
  };

  const getTripType = () => {
    const key = TRIP_TYPE_CODES[values.tripType];
    return TRIP_TYPES[key];
  };

  const getDestination = () => values.destinationZone === "1" ? 'Europe' : 'Worldwide';

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Product Summary</TitleWithUnderLine>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__titleArea`}>
            <div className={`${className}__titleArea__icon ${className}__titleArea__icon--${TRAVEL_INSURANCE_TYPE}`}>
              <MapIcon color={primaryPurple} />
            </div>
            <h2>{"Travel"}</h2>
          </div>
          <div className={`${className}__title`}>Just to remind you</div>
          <div className={`${className}__infoArea`}>
            <p>Your Premium is <b>€{getPriceFromPayload().toFixed(2)}</b></p>
            <Divider />
            <p>Your insurance is <b>{getTripType()}, {getDestination()}, {values.destinationCountry}</b></p>
          </div>
          <div className={`${className}__title`}>{`What's Insured`}</div>
          <div className={`${className}__coverageArea`}>
            {renderLine("Medical expenses to a maximum of €5,000,000")}
            {renderLine("Cancellations up to €4,000")}
            {renderLine("Personal property cover up to €2000")}
            {renderLine("Personal Accident up to €38,000")}
            {renderLine("Lost/Stolen Money: €500 per person")}
            <Tick>
              <p><a href="//www.arachas.ie/media/jgjd10cu/insurance-product-information.pdf" target="_blank" rel="noopener noreferrer">Travel IPID</a></p>
            </Tick>
          </div>
        </div>
      </div>
      <QuoteDisplay
        price={getPriceFromPayload()}
        sticky
        onClickFunction={onProceedClicked}
        title='Total'
      />
    </div>
  );
};

SummaryPage.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string
};

export default SummaryPage;
