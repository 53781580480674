/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './VerifyEmail.scss';
import '../../styles/commonPageStyles.scss';

import {
  Button,
  DesktopCard,
  InputField,
  TitleWithUnderLine} from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import type { FormikBag } from 'formik';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import type { RouterHistory } from 'react-router-dom';
import * as yup from 'yup';

import {
  CODE_MISMATCH_EXCEPTION,
  commonFormStylesIdentifier,
  commonPageStylesIdentifier,
  SIGN_UP_PAGE_EMAIL_STORAGE,
  USER_NOT_FOUND_EXCEPTION
} from '../../constants';
import { getItemFromSessionStorage } from '../../helpers';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';

type Props = {
  history: RouterHistory;
};
type Values = {
  email: string,
  verificationCode: string;
};


type StatusType =
  | { statusType: 'Init'; }
  | { statusType: 'AWSError', error: any; };

const verify = async (
  { email, verificationCode }: Values,
  { setFieldError, setSubmitting, setStatus, props }: FormikBag<Props, Values>
) => {
  try {
    await Auth.confirmSignUp(email, verificationCode);

    props.history.push({
      pathname: routes.loginPage.url,
      state: { email: email }
    });
  } catch (e) {
    setFieldError('verificationCode', e.message);
    // Store error in Formik status
    setStatus({
      statusType: 'AWSError',
      error: e
    });
  } finally {
    setSubmitting(false);
  }
};

const EMAIL_FAILURE = 'VerifyEmail/EMAIL_FAILURE';

const VerifyEmailForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-VerifyEmail';
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    submitCount,
    // Using Formik status to track AWS errors between submits
    // Status is just arbitary state
    isSubmitting
  } = props;
  const status: StatusType = props.status;

  const [isSendingCode, setIsSendingCode] = useState(false);
  const [lastSendSuccessEmail, setLastSendSuccessEmail] = useState(
    EMAIL_FAILURE
  );
  const showResendButton = useMemo(() => {
    // If AWS told us the verification code was incorrect,
    // show the button to resend verifcation codes
    return (
      status &&
      status.statusType === 'AWSError' &&
      status.error.code === CODE_MISMATCH_EXCEPTION
    );
  }, [status]);

  const getEmail = () =>
    props.location.state
      ? props.location.state.email
      : getItemFromSessionStorage(SIGN_UP_PAGE_EMAIL_STORAGE);
  useEffect(() => {
    setFieldValue('email', getEmail() || '');
  }, []);

  const resendCode = async () => {
    setFieldTouched('email', true);
    setIsSendingCode(true);
    if (errors.email) {
      setIsSendingCode(false);
      return;
    }

    try {
      await Auth.resendSignUp(values.email);

      setLastSendSuccessEmail(values.email);
    } catch (e) {
      const msg =
        e.code === USER_NOT_FOUND_EXCEPTION
          ? 'This email is not registered with the system, please sign up for an account first'
          : e.message;

      setFieldError('email', msg);
      setLastSendSuccessEmail(EMAIL_FAILURE);
    } finally {
      setIsSendingCode(false);
    }
  };

  const resendDisabled = () => {
    if (submitCount === 0) return true;
    return isSendingCode || errors.email;
  };

  const confirmDisabled = () => {
    if (submitCount === 0) return false;
    return isSubmitting || !isValid;
  };

  const showEmailPrompt = () => {
    // False positive on this lint rule
    // eslint-disable-next-line react/prop-types
    if (errors.email) return <></>;
    // eslint-disable-next-line react/prop-types
    if (lastSendSuccessEmail === values.email) {
      return (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          Check your email for the new code
        </div>
      );
    }
    return <></>;
  };

  const showResendCodeButton = () => {
    if (showResendButton)
      return (
        <div className={`${className}__link`}>
          Did not get the code?
          <a
            id="VerifyEmailPage__resendButton"
            fluid
            quaternary
            onClick={() => {
              resendCode();
            }}
            disabled={resendDisabled()}
          >
          Resend Code
          </a>
        </div>
      );
    return <></>;
  };

  return (
    <div className={`${className}`}>
      <DesktopCard>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <TitleWithUnderLine>Verification code</TitleWithUnderLine>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
              Verification code
            </div>
          </div>

          <Form>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="email"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Email address
              </label>
              <InputField
                error={isFieldError('email', touched, errors)}
                errorMessage={errors.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter email address"
                touched={touched.email}
                type="email"
                value={values.email}
              />
            </div>
           <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            Enter the 6 digit code we sent you via email to continue
              <label
                htmlFor="verificationCode"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Please enter your verification code
              </label>
              <InputField
                error={isFieldError('verificationCode', touched, errors)}
                errorMessage={errors.verificationCode}
                name="verificationCode"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter verification code"
                touched={touched.verificationCode}
                type="text"
                value={values.verificationCode}
              />
            </div>
            {showEmailPrompt()}
            <div className={`${className}__button`}>
              <Button
                id="VerifyEmailPage__confirmButton"
                fluid
                quaternary
                disabled={confirmDisabled()}
                type="submit"
              >
                CREATE ACCOUNT
              </Button>
            </div>
            {showResendCodeButton()}
          </Form>
        </div>
      </div>
      </DesktopCard>
    </div>
  );
};

const VerifyEmail = withFormik<Props, Values, StatusType>({
  mapPropsToValues(): {} {
    return {
      verificationCode: '',
      email: ''
    };
  },
  mapPropsToStatus(): StatusType {
    // set inital state
    return {
      statusType: 'Init'
    };
  },
  handleSubmit(values: Values, bag: FormikBag<Props, Values>) {
    verify(values, bag);
  },
  validationSchema: yup.object().shape({
    verificationCode: yup.string().required('Verification code is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required')
  }),
  displayName: 'VerifyEmailForm'
})(VerifyEmailForm);

export default VerifyEmail;
