import { Typeahead } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ResourceTypeahead = (props) => {
  const [data, setData] = useState([]);
  const [keyValueHelper, setKeyValueHelper] = useState({});
  useEffect(() => {
    setData(props.data.map(option => ({
      key: option.id,
      value: option.id,
      text: option.description
    })));
    setKeyValueHelper(props.data.reduce((reducer, value) => {
      reducer[value.id] = value;
      return reducer;
    }, {}));
  }, [props.data]);

  const newProps = {
    ...props,
    data,
    onChange: (value) => {
      if (value) {
        props.onChange(keyValueHelper[value.key]);
      } else props.onChange(undefined);
    },
    value: props.value ? props.value.id : undefined
  };

  return (
    <Typeahead {...newProps} />
  );
};

ResourceTypeahead.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  values: PropTypes.array,
  data: PropTypes.array
};

export default ResourceTypeahead;
