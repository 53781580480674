export const carButtons = [
    "viewDocumentsLandingPage",
    "breakdownAssistance",
    "submitClaim",
    "renewPolciy",
    "amendPolicy"
];

export const homeButtons = [
    "viewDocumentsLandingPage",
    "submitClaim",
    "renewPolciy",
    "amendPolicy"
];

export const travelButtons = [
    "viewDocuments",
    'medicalAssistance',
    "submitClaim",
    "renewPolciy",
    "amendPolicy"
];

export const deviceButtons = [
    "viewDocuments",
    "submitClaim",
    "amendPolicy"
];

export const vanButtons = [
    'viewDocuments',
    'breakdownAssistance',
    'submitClaim',
    'renewPolciy'
];