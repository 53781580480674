/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import dateHelper from 'date-helper-util';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import QuoteDisplay from '../../../components/QuoteDisplay';
import { DIRECT_DEBIT_STORAGE } from '../../../constants';
import { DEVICE_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { TOTAL } from '../../../constants/paymentTypes';
import { getObjectFromSessionStorage, isAgent } from '../../../helpers';
import { routes } from '../../../routes';
import { fbqTrackEvent } from '../../../scripts/facebookPixelEvents';
import { pay } from '../../../services/paysafeService';
import CardPayment from '../CardPayment';
import { get3DSObject } from '../ThreeDS';
import devicePaymentSchema from './devicePaymentSchema';

const DevicePaymentForm = (props) => {
  const {
    isValid,
    history,
    setFieldValue,
    insuranceType,
    values
  } = props;

  const [quoteReference, setQuoteReference] = useState('');
  const [price, setPrice] = useState(0);
  const [cardBrand, setCardBrand] = useState('');
  const redirectToHome = () => {
    history.push({ pathname: routes.Wallet.url });
  };
  const [paysafeError, setPaysafeError] = useState('');

  const readQuoteData = () => {
    let paymentData = getObjectFromSessionStorage(`${DEVICE_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`);
    if (!paymentData.quoteReference) {
      redirectToHome();
    } else {
      setQuoteReference(paymentData.quoteReference);
      setPrice(paymentData.price);
    }

  };

  useEffect(() => {
    readQuoteData();
  }, []);

  useEffect(() => {
    setPaysafeError('')
  }, [values])

  const getPaymentDetails = (paymentToken) => {
    return {
      'payment_details': {
        'payment_id': paymentToken,
        'total_premium': price,
        'payment_type': 'CARD_PAYMENT',
        'card_type': cardBrand,
        'quote_reference': quoteReference,
        'purchaser': values.nameOnCard,
        'payment_date': dateHelper(new Date()).getFormated("YYYY-MM-DD")
      },
    };
  };

  const goToPaymentsThankYouPage = (paymentDetails) => {
    props.history.push({
      pathname: `${routes.paymentThankYou.url}${DEVICE_INSURANCE_TYPE}`,
      state: {
        paymentDetails: paymentDetails,
        insuranceType: insuranceType,
      },
    });
  };

  const submitPayment = () => {
    fbqTrackEvent('InitiateCheckout');
    const successFn = (paymentToken) => {
      const paymentDetails = getPaymentDetails(paymentToken);
      goToPaymentsThankYouPage(paymentDetails);
    }
    const errorFn = (code, message) => setPaysafeError(`${message} (Code: ${code})`)
    const pay3DSObj = !isAgent() ? get3DSObject(price, values.nameOnCard) : false
    pay(successFn, errorFn, DEVICE_INSURANCE_TYPE, pay3DSObj)
  };

  const setCardValidity = (isValidCard) => {
    setFieldValue('cardIsValid', isValidCard);
  };

  return (
    <Form>
      <CardPayment
        setCardBrand={setCardBrand}
        setIsValid={setCardValidity} {...props} insuranceType={DEVICE_INSURANCE_TYPE}
        paysafeError={paysafeError}
      />
      <QuoteDisplay
        sticky
        price={price}
        paymentType={TOTAL}
        title={'Total'}
        buttonType="Buy"
        disabled={!isValid}
        onClickFunction={submitPayment}
        quoteReference={quoteReference}
      />
    </Form>
  );
};



const DevicePayments = withFormik({
  mapPropsToValues() {
    return {
      paymentFrequency: '',
      bankRequireOneSignature: '',
      bankAccountYoursWillAcceptDirectDebit: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      bic: '',
      iban: '',
      directDebitPaymentDate: '',
      directDebitLegalText: false,
      nameOnCard: '',
    };
  },
  handleSubmit(
    values,
    { setSubmitting },
  ) {
    setSubmitting(false);
  },
  validationSchema: devicePaymentSchema,
  displayName: 'DevicePaymentForm',
})(DevicePaymentForm);


DevicePaymentForm.propTypes = {
  isValid: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  insuranceType: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
};

export default DevicePayments;
