export const legalTextHomeContent = `Home Insurance is arranged by Arachas Corporate Brokers Limited. Arachas Corporate Brokers Limited trading as Arachas, Capital Insurance Markets, Capital IM, Covercentre is regulated by the Central Bank of Ireland. Registered in Ireland No. 379157. Home Insurance is underwritten by a panel of insurers as follows : Allianz plc is regulated by the Central Bank of Ireland. Registered in Ireland No. 143108.  Zurich Insurance plc is regulated by the Central Bank of Ireland. Registered in Ireland No. 13460.`;
//Hidden due to story AR-3773s
// Allianz plc is regulated by the Central Bank of Ireland. Registered in Ireland No. 143108. 
// AXA Insurance dac, regulated by the Central Bank of Ireland. 
// Registered in Ireland number 136155. Aviva Insurance Ireland Designated Activity Company, trading as Aviva, is regulated by the Central Bank of Ireland. 
// Registered in Ireland No. 605769.

export const legalTextHomeContentTwo = "Standard acceptance criteria, terms & conditions apply.";
export const legalTextMotorContent = "Motor Insurance is arranged by Arachas Corporate Brokers Limited. Arachas Corporate Brokers Limited trading as Arachas, Capital Insurance Markets, Capital IM, Covercentre is regulated by the Central Bank of Ireland. Registered in Ireland No. 379157. Motor Insurance is underwritten by a panel of insurers as follows : Allianz plc is regulated by the Central Bank of Ireland. Registered in Ireland No. 143108.  Zurich Insurance plc is regulated by the Central Bank of Ireland. Registered in Ireland No. 13460. Aviva Insurance Ireland Designated Activity Company, trading as Aviva, is regulated by the Central Bank of Ireland. Registered in Ireland No. 605769. AXA Insurance dac. Registered in Ireland number 136155. AXA Insurance dac is regulated by the Central Bank of Ireland.";
export const legalTextMotorContentTwo = "Standard acceptance criteria, terms & conditions apply.";
export const legalTextTravelContent = "Travel Insurance is arranged by Arachas Corporate Brokers Limited and underwritten by Chubb European Group SE. Arachas Corporate Brokers Limited trading as Arachas, Capital Insurance Markets, Capital IM, Covercentre is regulated by the Central Bank of Ireland. Registered in Ireland No. 379157. Chubb European Group SE trading as Chubb, Chubb Bermuda International and Combined Insurance, is supervised by the Autorité de contrôle prudentiel et de résolution (ACPR) in France and is regulated by the Central Bank of Ireland for conduct of business rules. Registered in Ireland No. 904967 at 5 George's Dock, Dublin 1. Chubb European Group SE is an undertaking governed by the provisions of the French insurance code with registration number 450 327 374 RCS Nanterre. Registered office: La Tour Carpe Diem, 31 Place des Corolles, Esplanade Nord, 92400 Courbevoie, France. Chubb European Group SE has fully paid share capital of €896,176,662.";
export const legalTextDeviceContent = "Mobile Device Insurance is arranged by Arachas Corporate Brokers Ltd. Arachas Corporate Brokers Limited trading as Arachas, Capital Insurance Markets, Capital IM, Covercentre is regulated by the Central Bank of Ireland. Registered in Ireland No. 379157. Mobile Device Insurance is underwritten by Zurich Insurance plc. Zurich Insurance plc is regulated by the Central Bank of Ireland. Registered in Ireland No. 13460.";
export const legalTextVanContent = "Van Insurance is arranged by Arachas Corporate Brokers Limited. Arachas Corporate Brokers Limited trading as Arachas, Capital Insurance Markets, Capital IM, Covercentre is regulated by the Central Bank of Ireland. Registered in Ireland No. 379157. Van Insurance is underwritten by AXA Insurance dac, Axa Insurance is registered in Ireland number 136155. Registered Office Wolfe Tone House, Wolfe Tone Street, Dublin 1. AXA Insurance dac is regulated by the Central Bank of Ireland.";