/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import { irishToRawFormat } from '../../helpers/DateHelper';
import { formatClaims, getStartDate } from '../mappingHelper';

const mapAdditionalDrivers = (
  additionalDrivers = []
) => {
  return additionalDrivers.map((driver) => {
    return {
      title: driver.title,
      first_name: driver.firstName,
      last_name: driver.lastName,
      date_of_birth: irishToRawFormat(driver.dob),
      relationship_to_proposer: driver.relationshipToProposer,
      employment_status: driver.employmentStatus,
      occupation: driver.traderOccupation,
      licence: {
        type: driver.driversLicenseType,
        years: driver.yearsHeld,
        country: driver.licenceCountry,
      },
      penalty_details: {
        penalty_points: driver.penaltyPoints
      }
    };
  });
};

export const mapVanQuote = values => {
  return {
    product: "product",
    version: "1.0",
    van_quote: {
      carrier_id: null,
      quote_mode: null,
      main_driver: {
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        date_of_birth: irishToRawFormat(values.dob),
        occupation: values.traderOccupation,
        licence: {
          type: values.driversLicenseType,
          country: values.licenceCountry,
          years: values.yearsHeld,
        },
        data_protection_consent: values.acceptedTermsAndConditions,
        assumption_confirmation: values.disclosureRequirements,
        proposer_type: values.proposerType,
        trading_as: values.tradingAs,
        vat_registered: values.vatRegistered,
        company_name: values.companyName,
        ncd: {
          insurer: values.ncdInsurer,
          years: values.ncdYears,
          full_ncd_required: values.ncdProctection,
          other_vehicle_with_ncd: values.privateCar,
        },
        // years_resident: values.yearsOfResidency,
        penalty_details: {
          penalty_points: values.penaltyPoints
        },
        disqualified: values.mainDriverDisqualifiedOrConvicted,
        claims: formatClaims(values.motorClaims),
        email: values.email,
        phone: values.phoneNo,
        address: {
          address_line1: values.addressLine1,
          address_line2: values.addressLine2,
          address_line3: values.addressLine3,
          address_line4: values.addressCounty,
          county: values.addressCounty,
          town: values.addressTown,
          post_code: values.addressEircode
        }
      },
      promotion_code: values.promoCode,
      marketing: {
        mailing_consent: values.postConsent,
        email_consent: values.emailConsent,
        mobile_sms_consent: values.smsConsent,
        telephone_consent: values.phoneConsent,
      },
      channel: "Web",
      cover_details: {
        who_driving: values.whoIsDrivingYourVan,
        type: values.coverType,
        start_date: getStartDate(values.policyStart),
        vehicle_details: {
          body_type: values.vanType,
          registration: values.vanReg,
          model: values.vanModel,
          make: values.vanMake,
          year: values.vanYear,
          description: values.vanDescription,
          payload_weight: values.vanWeight,
          value: values.vehicleValue
            ? values.vehicleValue.replace(/,/gi, '')
            : 0,
          km_per_year: values.annualKm,
          has_signage: values.signage,
          area_use: values.areaUse,
          sub_area_use: values.subAreaUse
        }
      },
      additional_drivers: mapAdditionalDrivers(values.additionalDrivers),
    }
  };
};
