export const deviceInsuranceAboutYouFormFields = [
  'title',
  'firstName',
  'lastName',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressCounty',
  'addressEircode',
  'phoneNo',
  'email',
  'promoCode'
];

export const deviceInsuranceYourDeviceFormFields = [
  'network',
  'serialNumber',
  'deviceType',
  'deviceMake',
  'deviceModel',
  'id',
  'device_type',
  'manufacturer',
  'model',
  'monthly_price',
  'annual_price',
  'effective_date'];

export const deviceInsuranceYourCoverFormFields = [
  'policyStart',
  'acceptedAssumptions',
  'acceptedTermsAndConditions',
  'marketingConsent',
];
