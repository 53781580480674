"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AccordionText = require("./AccordionText");

var _AccordionText2 = _interopRequireDefault(_AccordionText);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _AccordionText2.default;