/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { CAR_INSURANCE_TYPE } from '../constants/insuranceTypeConstants';
import { getItemFromSessionStorage, makeRequest } from '../helpers';

const getService = (insuranceType) => insuranceType === CAR_INSURANCE_TYPE ? 'motor' : insuranceType;

export const postCancelClaim = async (accessToken, insuranceType, payload) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/claims/claim`;

  return makeRequest({
    method: 'POST',
    url: URL,
    authToken: authToken,
    payload: payload
  });
};
