"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _variables = require("./variables");

Object.defineProperty(exports, "colors", {
  enumerable: true,
  get: function get() {
    return _variables.colors;
  }
});
Object.defineProperty(exports, "screenSize", {
  enumerable: true,
  get: function get() {
    return _variables.screenSize;
  }
});