import React from 'react';

export const getAuthorisationText = className => (
  <span>I authorise Arachas to send instructions to my bank to debit my account in accordance with the instructions from Arachas.
    I accept the <a href="https://www.arachas.ie/media/hm2p4swk/payment-terms-and-conditions.pdf"
      className={className}
      target='_blank'
      rel="noopener noreferrer">terms and conditions</a> of the Arachas instalment plan.
  </span>
);

export const CONFIRMATION_TEXT = 'I confirm that I am the account holder and am authorised to set up Direct Debit payments on this account.';

export const REFUND_TOC_TEXT = 'As part of your rights you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Full details are available through your bank.';

export const REFERENCE_NUMBER_TEXT = 'Your bank will be debited by Arachas using the following reference number : Creditor Identifier Number DE5635400002197951. We will notify you at least 3 working days in advance of any changes to your payment date, frequency, or amount.';

export const PAYMENT_SCHEDULE_TEXT = 'Please note your full payment schedule will be available to view in your MyArachas documents and your Direct Debit Mandate will be confirmed by email.';
