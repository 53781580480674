// @flow
import { AccordionText, InputField } from "@arachas/core/lib";
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants';
const className = "c-CarRegLookup";

const fields = ["vanType",
  "vanMake",
  "vanModel"];

const compressedFields = ["vanType", "vanMake"];

const fieldsShown = ["vanModel", "vanYear", "vanWeight", "vanDescription"];

const fieldLabels = {
  vanType: "Your van type",
  vanMake: "Your van make",
  vanModel: "Your van model",
  vanYear: "Your van year",
  vanWeight: "Payload weight",
  vanDescription: "Description"
};

const VanDetailsInfo = (props: {
  carMake: string,
  carModel: string,
  carYear: string,
  carFuelType: string,
  carEngineSize: string,
  carVersion: string,
  compressedView: any;
  readonly: boolean;
}) => {
  const mappingFields = props.compressedView ? compressedFields : fields;

  const getVanFields = () => {
    return mappingFields.map((field: string, index: number) => {
      return (
        <div key={index}>
          <div htmlFor={`carDetailField-${field}`}
            className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={field} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              {fieldLabels[field]}
            </label>
            <InputField
              type="text"
              name={field}
              value={props[field]}
              readOnly={true}
            />
          </div>
        </div>);
    });
  };


  const showAccordion = () => {
    return (
      <>
        <div className={`${className}__brokenLine ${commonFormStylesIdentifier}__fieldContainer`} >
          <div className={`${className}__readMore ${commonFormStylesIdentifier}__fieldLabel`}>
            <AccordionText
              label="SEE MORE"
              openLabel="SEE LESS"
              inverted
            >
              <div className={`${className}__readMoreContent`}>
                {fieldsShown.map((field: string) => {
                  return (
                    <div key={`carDetailField-${field}`}
                      className={`${commonFormStylesIdentifier}__fieldContainer`}>
                      <label htmlFor={field} className={`${commonFormStylesIdentifier}__fieldLabel`}>
                        {fieldLabels[field]}
                      </label>
                      <InputField
                        type="text"
                        name={field}
                        value={props[field]}
                        readOnly={true}
                      />
                    </div>
                  );
                })}
              </div>
            </AccordionText>
          </div>
        </div>
      </>);
  };

  return (
    <div className={className}>
      {getVanFields()}
      {(props.readonly) ?
        showAccordion() : <></>}
    </div>
  );
};

export default VanDetailsInfo;
