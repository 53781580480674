export const homeInsuranceAboutYouFormFields = [
  'title',
  'firstName',
  'lastName',
  'dob',
  'phoneNo',
  'email',
  'promoCode'
];

export const homeInsuranceYourHomeFormFields = [
  'coverType',
  'isFamilyUnit',
  'numTenants',
  'isInUse',
  'homeType',
  'yearOfConstruction',
  'numBedrooms',
  'numBathrooms',
  'heatingType',
  'houseAlarm',
  'claimsNumber',
  'twoSmokeDetectors',
  'firstTimeBuyer',
  'homeNoClaimsBonus',
  'claims',
  'propertyJointlyOwned',
  'jointlyOwnedDetailsTitle',
  'jointlyOwnedDetailsFirstName',
  'jointlyOwnedDetailsLastName',
  'jointlyOwnedDetailsDateOfBirth',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressTown',
  'addressCounty',
  'addressEircode',
  'geo_code',
  'match_level',
  'match_type',
  'ecad_identifier',
  'ecad_match_level',
  'ecad_match_result',
  'street_name',
  'post_code',
  'reference'
];

export const homeInsuranceYourHomeCoverFormFields = [
  'policyStart',
  'rebuildCost',
  'contentsCoverOf',
  'acceptedAssumptions',
  'acceptedTermsAndConditions',
  'marketingConsent'
];
