/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './SubmitClaimDefaultPage.scss';

import React from 'react';

import { travel } from './ContentTextAndNumbers';


const TravelText = () => {

	const className = 'c-SubmitClaimDefaultPage';
	return (
		<>
			<div className={`${className}__textAreaOne`}>{travel.textOne}</div>
			{travel.textTwo}
			<b>
				<a href="//ie.chubbinsured.com/travel-claim" rel="noopener noreferrer" target="_blank" >here
      </a>
			</b>
			<br />
			<div className={`${className}__address`}>
				<div>{travel.addLineOne}</div>
				<div>{travel.addLineTwo},</div>
				<div>{travel.addLineThree},</div>
				<div>{travel.addLineFour},</div>
				<div>{travel.addLineFive},</div>
				<div>{travel.addLineSix},</div>
				<div>{travel.addLineSeven}</div>
			</div></>
	);
};


export default TravelText;
