"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./MobileCard.scss");

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
var MobileCard = function MobileCard(_ref) {
  var children = _ref.children;
  var className = 'c-MobileCard';
  return _react2.default.createElement("div", {
    className: className
  }, children);
};

MobileCard.propTypes = {
  children: _propTypes2.default.object
};
exports.default = MobileCard;