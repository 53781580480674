import React from 'react';

const GoCardlessLogo = () => {
  return (
    <svg width="155px" height="32px" viewBox="0 0 259 54" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <title>gocardless-blue@1x</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#0854B3">
          <g transform="translate(0.000000, 0.000000)">
            <path d="M12.2782446,24 C4.99533147,24 0,18.9400387 0,12.0696325 L0,12.0232108 C0,5.43133462 5.18207283,-3.55271368e-15 12.2315593,-3.55271368e-15 C16.43324,-3.55271368e-15 18.9542484,1.11411992 21.3818861,3.20309478 L18.1605976,7.10251451 C16.3398693,5.61702128 14.7525677,4.73500967 12.0448179,4.73500967 C8.30999066,4.73500967 5.32212885,8.03094778 5.32212885,11.9767892 L5.32212885,12.0232108 C5.36881419,16.2475822 8.30999066,19.3578337 12.4183007,19.3578337 C14.2857143,19.3578337 15.9663866,18.893617 17.2735761,17.9651838 L17.2735761,14.6692456 L12.0915033,14.6692456 L12.0915033,10.2591876 L22.2222222,10.2591876 L22.2222222,20.3326886 C19.8412698,22.3288201 16.5266106,24 12.2782446,24"></path>
            <path d="M36.86629,24 C29.905838,24 24.8888889,18.6615087 24.8888889,12.0696325 L24.8888889,12.0232108 C24.8888889,5.43133462 29.9510358,-3.55271368e-15 36.9114878,-3.55271368e-15 C43.8719397,-3.55271368e-15 48.8888889,5.3384913 48.8888889,11.9303675 L48.8888889,11.9767892 C48.8888889,18.615087 43.826742,24 36.86629,24 L36.86629,24 Z M43.6911488,11.9767892 C43.6911488,7.98452611 40.8436911,4.68858801 36.86629,4.68858801 C32.8436911,4.68858801 30.086629,7.93810445 30.086629,11.9303675 L30.086629,11.9767892 C30.086629,15.9690522 32.9340866,19.2649903 36.9114878,19.2649903 C40.9340866,19.2649903 43.6911488,16.0154739 43.6911488,12.0232108 L43.6911488,11.9767892 L43.6911488,11.9767892 Z"></path>
            <path d="M64.7367901,24 C58.1945679,24 53.3333333,18.7079304 53.3333333,12.0232108 C53.3333333,5.38491296 58.1491358,-3.55271368e-15 64.8276543,-3.55271368e-15 C68.9619753,-3.55271368e-15 71.4153086,1.48549323 73.6869136,3.66731141 L71.9150617,5.57059961 C70.0069136,3.71373308 67.8716049,2.36750484 64.7822222,2.36750484 C59.7392593,2.36750484 55.9683951,6.49903288 55.9683951,11.9303675 C55.9683951,17.3152805 59.7846914,21.5396518 64.7822222,21.5396518 C67.917037,21.5396518 69.9614815,20.3326886 72.0967901,18.1972921 L73.7777778,19.868472 C71.4607407,22.3752418 68.8711111,24 64.7367901,24"></path>
            <path d="M98.6666667,23.6111111 L95.8969657,23.6111111 L93.2180747,17.5582011 L80.8679328,17.5582011 L78.1890417,23.6111111 L75.5555556,23.6111111 L85.9078804,0.5 L88.3143418,0.5 L98.6666667,23.6111111 L98.6666667,23.6111111 Z M87.0884086,3.52645503 L81.9122462,15.2195767 L92.2645711,15.2195767 L87.0884086,3.52645503 L87.0884086,3.52645503 Z"></path>
            <path d="M118.621578,23.6111111 L112.083736,14.644 L105.636071,14.644 L105.636071,23.6111111 L103.111111,23.6111111 L103.111111,0.5 L112.805153,0.5 C117.764895,0.5 120.966184,3.22711111 120.966184,7.38711111 C120.966184,11.2697778 118.396135,13.4884444 114.834138,14.1355556 L121.777778,23.5648889 L118.621578,23.5648889 L118.621578,23.6111111 Z M112.624799,2.90355556 L105.681159,2.90355556 L105.681159,12.2866667 L112.624799,12.2866667 C116.006441,12.2866667 118.441224,10.484 118.441224,7.47955556 C118.396135,4.61377778 116.231884,2.90355556 112.624799,2.90355556 L112.624799,2.90355556 Z"></path>
            <path d="M134.307071,23.6111111 L126.222222,23.6111111 L126.222222,0.5 L134.307071,0.5 C141.60202,0.5 146.666667,5.492 146.666667,12.0093333 C146.620202,18.5266667 141.60202,23.6111111 134.307071,23.6111111 M134.307071,2.90355556 L128.870707,2.90355556 L128.870707,21.2075556 L134.307071,21.2075556 C140.161616,21.2075556 143.925253,17.2324444 143.925253,12.1017778 C143.925253,6.92488889 140.161616,2.90355556 134.307071,2.90355556"></path>
            <polygon points="152 0.5 154.650888 0.5 154.650888 21.1662231 168 21.1662231 168 23.6111111 152 23.6111111"></polygon>
            <polygon points="186.53516 2.89875804 173.165297 2.89875804 173.165297 10.7869816 185.088584 10.7869816 185.088584 13.1396097 173.165297 13.1396097 173.165297 21.2123531 186.666667 21.2123531 186.666667 23.6111111 170.666667 23.6111111 170.666667 0.5 186.53516 0.5"></polygon>
            <path d="M200.658963,10.7392996 C205.658074,11.8599222 208,13.7743191 208,17.3696498 C208,21.385214 204.80237,24 200.298667,24 C196.740741,24 193.813333,22.7859922 191.111111,20.2645914 L192.687407,18.3501946 C195.029333,20.5447471 197.281185,21.6653696 200.388741,21.6653696 C203.406222,21.6653696 205.387852,20.0311284 205.387852,17.6498054 C205.387852,15.4552529 204.261926,14.1945525 199.533037,13.1673152 C194.353778,12 191.966815,10.2723735 191.966815,6.39688716 C191.966815,2.6614786 195.07437,-3.55271368e-15 199.352889,-3.55271368e-15 C202.640593,-3.55271368e-15 204.982519,0.980544747 207.279407,2.84824903 L205.793185,4.90272374 C203.721481,3.12840467 201.604741,2.38132296 199.307852,2.38132296 C196.380444,2.38132296 194.533926,4.06225681 194.533926,6.16342412 C194.533926,8.40466926 195.659852,9.66536965 200.658963,10.7392996"></path>
            <path d="M221.103407,10.7392996 C226.102519,11.8599222 228.444444,13.7743191 228.444444,17.3696498 C228.444444,21.385214 225.246815,24 220.743111,24 C217.185185,24 214.257778,22.7859922 211.555556,20.2645914 L213.131852,18.3501946 C215.473778,20.5447471 217.72563,21.6653696 220.833185,21.6653696 C223.850667,21.6653696 225.832296,20.0311284 225.832296,17.6498054 C225.832296,15.4552529 224.70637,14.1945525 219.977481,13.1673152 C214.798222,12 212.411259,10.2723735 212.411259,6.39688716 C212.411259,2.6614786 215.518815,-3.55271368e-15 219.797333,-3.55271368e-15 C223.085037,-3.55271368e-15 225.426963,0.980544747 227.723852,2.84824903 L226.23763,4.90272374 C224.165926,3.12840467 222.049185,2.38132296 219.752296,2.38132296 C216.824889,2.38132296 214.97837,4.06225681 214.97837,6.16342412 C214.97837,8.40466926 216.149333,9.66536965 221.103407,10.7392996"></path>
          </g>
        </g>
      </g>
    </svg>


  );
};

export default GoCardlessLogo;
