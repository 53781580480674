"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AnimatedSpinner = require("./AnimatedSpinner");

var _AnimatedSpinner2 = _interopRequireDefault(_AnimatedSpinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _AnimatedSpinner2.default;