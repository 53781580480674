/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './TravelDeclaration.scss';

import { Button, DesktopCard, TitleWithUnderLine } from '@arachas/core/lib';
import { sanitize } from 'dompurify';
import marked from 'marked';
import React, { useState } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_PRODUCT } from '../../constants/journey';
import { localTravelInsurancePhoneNumber } from '../../constants/phoneNumbers';
import { DECLARATION_DETAILS_STORAGE, DIRECT_DEBIT_STORAGE } from '../../constants/sessionStorage';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { getObjectFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';

const noAgreeInfo =
  `Unfortunately if you cannot confirm the above statements we will not be able to offer cover, therefore you will ` +
  `unable to proceed any further, please call ${localTravelInsurancePhoneNumber} for assistance.`;

const className = 'c-TravelDeclaration';
const firstPClassName = `${className}__firstParagraph`;
const secondPClassName = `${className}__secondParagraph`;

const getResponseData = () => {
  const travelGetQuoteResponse = getObjectFromSessionStorage(`${TRAVEL_INSURANCE_TYPE}${DECLARATION_DETAILS_STORAGE}`);
  return travelGetQuoteResponse ? travelGetQuoteResponse : { declaration: '' };
};

const sanatizer = (unsafeString: string) => sanitize(
  unsafeString,
  {
    ALLOWED_TAGS: ['ol', 'ul', 'li']
  }
);

const getSafeHTMLFromAPI = (markdownString: string) => {
  const htmlString = marked(markdownString);
  return {
    __html: sanatizer(htmlString)
  };
};

interface Props {
  history: RouterHistory;
  location: Location;
}

const TravelDeclaration = ({ location, history }: Props) => {
  const [noAgreeTouched, setNoAgreeTouched] = useState(false);

  const { declaration } = getResponseData();
  const linkAddress = `${routes.MakePayment.url}${TRAVEL_INSURANCE_TYPE}`;
  const stateValues = location.state || getObjectFromSessionStorage(`${TRAVEL_INSURANCE_TYPE}${DECLARATION_DETAILS_STORAGE}`);

  if (Object.keys(stateValues).length === 0) {
    history.push({ pathname: routes.Wallet.url });
    return <></>;
  }

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
        <TitleWithUnderLine>Travel Declaration</TitleWithUnderLine>
      </div>
      <DesktopCard>
        <p id="Travel-Declaration-Paragraph-1" className={firstPClassName}>
        You are responsible for answering honestly and with reasonable care all questions posed by the insurer. 
        Failure to do so may (i) affect the policy terms and/or claims under the policy; and/or (ii) entitle the insurer to terminate the policy or treat the policy as if it never existed.
        </p>
        <p id="Travel-Declaration-Paragraph-2" className={secondPClassName}>
          Before we provide you with a policy, please make sure you can confirm
          the following statements that apply to every person to be insured:
        </p>
        <div
          className={`${className}__declarationContent`}
          dangerouslySetInnerHTML={getSafeHTMLFromAPI(declaration)}
        />
        <div className={`${className}__confirmButtonContainer`}>
          <Button
            id="TravelDeclarationPage__confirmButton"
            fluid
            secondary
            onClick={() => {
              saveInSessionStorage(`${TRAVEL_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`, JSON.stringify(stateValues));
              history.push({
                pathname: linkAddress,
                state: stateValues
              });
            }}
          >
            {`I confirm`}
          </Button>
        </div>
        <div className={`${className}__denyButtonContainer`}>
          <Button
            id="TravelDeclarationPage__denyButton"
            fluid
            secondary
            onClick={() => {
              setNoAgreeTouched(true);
            }}
          >
            {`I do not confirm`}
          </Button>
          {noAgreeTouched && (
            <p className={`${className}__noAgreeInfo`}>{noAgreeInfo}</p>
          )}
        </div>
      </DesktopCard>
    </div>
  );
};

export default TravelDeclaration;
