/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import '../../styles/commonFormStyles.scss';
import './CarRegLookup.scss';

import {
  AnimatedSpinner,
  InputField
} from '@arachas/core/lib';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from "prop-types";
import React, { useContext, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { CAR_INSURANCE_TYPE,commonFormStylesIdentifier, VAN_INSURANCE_TYPE } from '../../constants';
import { offWhite, whiteColor } from '../../constants/colors';
import { regHelpPhoneNumber } from '../../constants/phoneNumbers';
import { getAccessToken } from '../../helpers';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import { getCarRegLookup } from '../../services/motor/carRegLookupService';
import { getVanRegLookup } from '../../services/van/vanServices';
import CarDetailsInfo from './CarDetailsInfo';
import VanDetailsInfo from './VanDetailsInfo';

const REQUEST_LOADING = 'CarRegLookup/LOADING';
const REQUEST_SUCCESS = 'CarRegLookup/SUCCESS';
const REQUEST_FAILURE = 'CarRegLookup/FAILURE';


const CarRegLookup = (props) => {
  const className = 'c-CarRegLookup';
  const {
    disabled,
    errors,
    name,
    onBlur,
    onChange,
    placeholder,
    values,
    setFieldValue,
    isFieldError,
    touched,
    compressedView,
    insuranceType = CAR_INSURANCE_TYPE,
    readonly = false,
  } = props;
  const [requestStatus, setRequestStatus] = useState();
  const { cognitoUser } = useContext(CognitoContext);
  const [editLabel, setEditLabel] = useState("EDIT");
  const [isReadOnly, setIsReadOnly] = useState(readonly);
  // TODO: @kpajtasev manual search disabled for now
  // const { isManualSearch } = values;
  const setIsManualSearch = (status) => setFieldValue("isManualSearch", status);

  let response = {};

  const functions = {
    [CAR_INSURANCE_TYPE]: {
      fetch: (reg) => getCarRegLookup(reg, getAccessToken(cognitoUser), props),
      save: (r) => saveCarDetails(r),
      clear: () => clearCarRegFields()
    },
    [VAN_INSURANCE_TYPE]: {
      fetch: (reg) => getVanRegLookup(reg, getAccessToken(cognitoUser), props),
      save: (r) => saveVanDetails(r),
      clear: () => clearVanRegFields()
    }
  }

  const clearCarRegFields = () => {
    setFieldValue('carMake', '');
    setFieldValue('carModel', '');
    setFieldValue('carYear', '');
    setFieldValue('carFuelType', '');
    setFieldValue('carEngineSize', '');
    setFieldValue('carVersion', '');
    setFieldValue('carABICode', '');
    setFieldValue('doors', '');
    setFieldValue('body_type', '');
    setFieldValue('number_seats', '');
  };

  const clearVanRegFields = () => {
    setFieldValue('vanMake', '');
    setFieldValue('vanType', '');
    setFieldValue('vanModel', '');
    setFieldValue('vanYear', '');
    setFieldValue('vanWeight', '');
    setFieldValue('vanDescription', '');
  };

  const saveCarDetails = (response) => {
    setFieldValue('carMake', response.make.toString());
    setFieldValue('carModel', response.model.toString());
    setFieldValue('carYear', response.year_manufacture.toString());
    setFieldValue('carFuelType', response.fuel_type.toString());
    setFieldValue('carEngineSize', response.engine_cc.toString());
    setFieldValue('carVersion', response.version.toString());
    setFieldValue('doors', response.doors.toString());
    setFieldValue('body_type', response.body_type.toString());
    if (response.hasOwnProperty('number_seats')) {
      setFieldValue('number_seats', response.number_seats.toString());
    }
  }

  const saveVanDetails = (response) => {
    setFieldValue('vanReg', response.registration.toString());
    setFieldValue('vanMake', response.make.toString());
    setFieldValue('vanType', response.body_type.toString());
    setFieldValue('vanModel', response.model.toString());
    setFieldValue('vanYear', response.year.toString());
    setFieldValue('vanWeight', response.payload_weight.toString());
    setFieldValue('vanDescription', response.description.toString());
  }

  const fetchDetails = async (reg) => {
    setIsManualSearch(false);
    setRequestStatus(REQUEST_LOADING);

    if (reg !== '') {
      response = await functions[insuranceType].fetch(reg)
      setRequestStatus(REQUEST_SUCCESS);
      if (response.hasOwnProperty('model') && response.model) {
        functions[insuranceType].save(response)
        setRequestStatus(REQUEST_SUCCESS);
      } else {
        response = {};
        setRequestStatus(REQUEST_FAILURE);
        functions[insuranceType].clear()
      }
    } else {
      response = {};
      setRequestStatus(REQUEST_FAILURE);
      functions[insuranceType].clear()
    }
  };

  const switchToInput = () => {
    setEditLabel("");
    setIsReadOnly(false);
  };

  const getCarDetailsInfo = () => {
    const notInLoadingOrErrorStatus = requestStatus !== REQUEST_LOADING && requestStatus !== REQUEST_FAILURE;
    const hasCarRegAndRegMake = () => !!values.carReg && !!values.carMake;
    return hasCarRegAndRegMake() && notInLoadingOrErrorStatus ?
      <CarDetailsInfo {...values} compressedView={compressedView} readonly={readonly} />
      : <></>;
  };

  const getVanDetailsInfo = () => {
    const notInLoadingOrErrorStatus = requestStatus !== REQUEST_LOADING && requestStatus !== REQUEST_FAILURE;
    const hasVanRegandVanMake = () => !!values.vanReg && !!values.vanMake;
    return hasVanRegandVanMake() && notInLoadingOrErrorStatus ?
      <VanDetailsInfo {...values} compressedView={compressedView} readonly={readonly} />
      : <></>;
  };

  const callFbContactAnalytics = () => {
    fbqTrackEvent('Contact');
    return true;
  }
  const showErrorAfterApiCall = () => {
    const errorMessage =
      `Sorry, we can't find your ${insuranceType}. Please check the Registration Number you entered is correct using the format: 10D45001. Alternatively you can call us on `;

    return values[name] && requestStatus === REQUEST_FAILURE ?
      <div className={`${className}__errorMessage ${commonFormStylesIdentifier}__fieldContainer`}>
        {errorMessage} <a href={`tel:${regHelpPhoneNumber}`} onClick={callFbContactAnalytics}>{regHelpPhoneNumber}</a>
      </div>
      : <></>;
  };

  // TODO: @kpajtasev manual search disabled for now
  // const resetCarValues = () => {
  //   ["carReg",
  //     "carMake",
  //     "carModel",
  //     "carYear",
  //     "carFuelType",
  //     "carEngineSize",
  //     "carVersion"].forEach((field: string) => {
  //     setFieldValue(field, "");
  //     setFieldTouched(field, false);
  //   });
  // };
  //
  // const openManualSearch = () => {
  //   resetCarValues();
  //   setIsManualSearch(true);
  // };

  const hasCarRegFieldError = () => {
    if (touched[name] && values[name] && !values[`${insuranceType}Make`])
      return true;
    return isFieldError(name, touched, errors);
  };

  const getCarRegFieldErrorMessage = () => {
    if (touched[name] && values[name] && !values[`${insuranceType}Make`])
      return <span id={`${insuranceType}Make`}>{`Press the enter key or search icon to get the ${insuranceType} details`}</span>;
    return errors[name];
  };

  const inputError = () => compressedView && !readonly && insuranceType !== CAR_INSURANCE_TYPE ? errors[name] : hasCarRegFieldError();

  const inputErrorMessage = () => compressedView && !readonly && insuranceType !== CAR_INSURANCE_TYPE ? errors[name] : getCarRegFieldErrorMessage();

  const getLoadingAnimatedSpinner = () => {
    return (requestStatus === REQUEST_LOADING) ?
      <AnimatedSpinner padding={'10%'} backgroundColor={!readonly ? offWhite : whiteColor} /> :
      null;
  };

  return (
    <div className={`${className}`} >
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={name} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <div className={`${className}__carRegLabel`}>
            <div>Your {insuranceType} registration</div>
            {readonly ?
              <div className={`${className}__editLabel`} onClick={() => { switchToInput(); }}>{editLabel}</div> :
              <></>
            }
          </div>
          <div className={`${className}__infoText`}>
            Type your registration and tap the search icon
          </div>
        </label>
        <InputField
          readOnly={isReadOnly}
          touched={touched[name]}
          disabled={disabled}
          type="string"
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            setRequestStatus(null);
            functions[insuranceType].clear();
          }}
          value={values[name]}
          error={inputError()}
          errorMessage={inputErrorMessage()}
          onClickIcon={() => {
            fetchDetails(values[name])
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !isReadOnly) {
              // Prevents the form from submitting when you hit the enter key
              e.preventDefault();
              fetchDetails(values[name])
            }
          }}
          suffix={!isReadOnly ? faSearch : null}
        />
      </div>

      {/* TODO: @kpajtasev manual search disabled for now*/}
      {/*<button type="button"*/}
      {/*        className={`${className}__manualSearchButton`}*/}
      {/*        id="OpenCarManualSearchButton"*/}
      {/*        onClick={openManualSearch}>{'Don\'t know your registration number?'}</button>*/}

      {getLoadingAnimatedSpinner()}

      {/* TODO: @kpajtasev manual search disabled for now*/}
      {/*<div className={`${commonFormStylesIdentifier}__fieldContainer`}>*/}
      {/*  {isManualSearch ? <ManualSearch {...props} /> : <></>}*/}
      {/*</div>*/}
      {insuranceType === CAR_INSURANCE_TYPE ? getCarDetailsInfo() : getVanDetailsInfo()}
      {showErrorAfterApiCall()}
    </div>

  );
};

CarRegLookup.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  isFieldError: PropTypes.func,
  touched: PropTypes.object,
  compressedView: PropTypes.bool,
  insuranceType: PropTypes.string,
  readonly: PropTypes.bool
};

export default CarRegLookup;
