import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';


export default {
  [HOME_INSURANCE_TYPE]: [
    { service: 'home', group: 'title' },
    { service: 'home', group: 'type' },
    { service: 'home', group: 'no_claims_bonus' },
    { service: 'home', group: 'claim_type' },
    { service: 'home', group: 'dwelling_type' },
    { service: 'home', group: 'heating_type' },
    { service: 'home', group: 'alarm_type' }
  ],
  [CAR_INSURANCE_TYPE]: [
    { service: 'motor', group: 'title' },
    { service: 'motor', group: 'employment_status' },
    { service: 'motor', group: 'occupation' },

    { service: 'motor', group: 'licence_type' },
    { service: 'motor', group: 'licence_held' },
    { service: 'motor', group: 'years_owned' },
    { service: 'motor', group: 'car_use' },
    { service: 'motor', group: 'driving_experience' },
    { service: 'motor', group: 'ncb_values' },
    { service: 'motor', group: 'ncb_countries' },
    { service: 'motor', group: 'previous_insurer' },
    { service: 'motor', group: 'claim_type' },

    { service: 'motor', group: 'penalty_points' },
    { service: 'motor', group: 'offences' },
    { service: 'motor', group: 'who_driving' },
    { service: 'motor', group: 'relationship' },
    { service: 'motor', group: 'no_of_accident_values' },
    { service: 'motor', group: 'no_of_penalty_point_offences' },
    { service: 'motor', group: 'full_time_use_of_other_car' }
  ],
  [TRAVEL_INSURANCE_TYPE]: [
    { service: 'travel', group: 'title' }
  ],
  [DEVICE_INSURANCE_TYPE]: [
    { service: 'device', group: 'title' },
    { service: 'device', group: 'network' },
  ],
  [VAN_INSURANCE_TYPE]: [
    { service: 'van', group: 'proposer_type' },
    { service: 'van', group: 'title' },
    { service: 'van', group: 'licence_type' },
    { service: 'van', group: 'licence_held' },
    { service: 'van', group: 'driver_occupations' },
    { service: 'van', group: 'years_resident' },

    { service: 'van', group: 'carrying_capacity' },
    { service: 'van', group: 'area_use' },
    { service: 'van', group: 'km_per_year' },
    { service: 'van', group: 'vehicle_use' },
    { service: 'van', group: 'ncd_years' },
    { service: 'van', group: 'ncd_insurer' },

    { service: 'van', group: 'penalty_points' },
    { service: 'van', group: 'claim_type' },
    { service: 'van', group: 'who_driving' },
    { service: 'van', group: 'proposer_relationship' },
    { service: 'van', group: 'no_of_accident_values' }
  ],
  ['']: null
};