/* eslint-disable react/prop-types */
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';
import './AdditionalDrivers.scss';

import { Button, WideDivider, XIcon } from '@arachas/core/lib';
import { type ArrayHelpers, FieldArray, FormikProps } from 'formik';
import React, { useEffect } from 'react';

import LicenceTypeLicenceCountryAdditionalDrivers from '../../../../../components/LicenceTypeLicenceCountryAdditionalDrivers';
import ResourceButtonGroup from '../../../../../components/ResourceButtonGroup';
import ResourceDropdown from '../../../../../components/ResourceDropdown';
import { commonFormStylesIdentifier } from '../../../../../constants';
import { dividerColor } from '../../../../../constants/colors';
import { CAR_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import { checkRepeatingFieldError, getRepeatingFieldErrorMessage } from '../../../../../helpers';
import { getOccupationOptions } from '../../../../../mocks/MainOccupationValues';
import type { AdditionalDriverType } from '../../../../../types/motor';
import { BaseDrivingDetails } from '../../../AboutYouForm/AboutYouFormSections/DrivingDetails/DrivingDetails';
import { BaseEmploymentDetails } from '../../../AboutYouForm/AboutYouFormSections/EmploymentDetails/EmploymentDetails';
import { BasePersonalDetails } from '../../../AboutYouForm/AboutYouFormSections/PersonalDetails/PersonalDetails';
import { PenaltyPoints } from '../index';
import { emptyAdditionalDriver, emptyVanAdditionalDriver, untouchedAdditionalDriver, untouchedVanAdditionalDriver } from './AdditionalDriversInitialValues';


interface Props {
  fieldName?: string;
}

const AdditionalDrivers = (props: Props & FormikProps) => {

  const className = 'c-AdditionalDrivers';

  const {
    setFieldValue,
    values,
    errors,
    setFieldTouched,
    touched,
    handleBlur,
    baseFieldName = 'additionalDrivers',
    fieldFilledBefore,
    resources,
    maxDrivers,
    hideRelationship,
    isVanDriver,
    insuranceType,
    onBlur
  } = props;
  const mainOccupationOptions = getOccupationOptions();

  const chosenDriverObject = isVanDriver ? emptyVanAdditionalDriver : emptyAdditionalDriver;
  const chosenTouchedObject = isVanDriver ? untouchedVanAdditionalDriver : untouchedAdditionalDriver;

  useEffect(() => {
    let initialValues = values[baseFieldName] && values[baseFieldName].length > 0 ? values[baseFieldName] : [{ ...chosenDriverObject }];
    setFieldValue(baseFieldName, initialValues);
    setFieldTouched(baseFieldName, [{ ...chosenTouchedObject }]);
  }, [setFieldValue, setFieldTouched]);

  const addAdditionalDriver = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ ...chosenDriverObject });
  };

  const removeAdditionalDriver = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const getEntryValue = (allEntries: Array<AdditionalDriverType> = [], index: number = 0, fieldName: string = '') => {
    return allEntries.length > index && fieldName !== '' ? allEntries[index][fieldName] : '';
  };

  const getAdditionalDriverFormAttribute = (attribute: { [string]: Array<AdditionalDriverType | { [string]: boolean | string; }>; }, index: number) => {
    return attribute && attribute[baseFieldName] && attribute[baseFieldName][index] ? attribute[baseFieldName][index] : {};
  };

  const getAdditionalDriverProps = (index: number) => {
    return {
      ...props,
      values: getAdditionalDriverFormAttribute(values, index),
      touched: getAdditionalDriverFormAttribute(touched, index),
      errors: getAdditionalDriverFormAttribute(errors, index)
    };
  };

  const handleChangeResource = (value: any, name: string) => {
    setFieldValue(name, value);
  };

  const getName = () => {
    return isVanDriver ? 'Relationship to Proposer' : 'Relationship to you';
  };

  const getRelationshipToProposerField = (index: number) => {
    const relationshipFieldName = `${baseFieldName}[${index}].relationshipToProposer`;
    return hideRelationship && !isVanDriver ?
      <>
      </> :
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={relationshipFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          {getName()}
        </label>
        <span className={`${className}__input`}>
          <ResourceDropdown
            key={`relationshipToProposer_${index}`}
            name={relationshipFieldName}
            placeholder="Select here"
            error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'relationshipToProposer', index)}
            errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'relationshipToProposer', index)}
            value={getEntryValue(values[baseFieldName], index, 'relationshipToProposer')}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={isVanDriver ? props.resources.proposer_relationship : props.resources.relationship}
          />
        </span>
      </div>
      ;
  };

  const getTraderOccupationField = (index: number) => {
    return (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="traderOccupation" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Business / Trade
        </label>
        <ResourceDropdown
          key={`traderOccupation_${index}`}
          placeholder="Select here"
          error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'traderOccupation', index)}
          errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'traderOccupation', index)}
          name={`${baseFieldName}[${index}].traderOccupation`}
          value={getEntryValue(values[baseFieldName], index, 'traderOccupation')}
          onChange={handleChangeResource}
          onBlur={handleBlur}
          values={resources.driver_occupations}
        >
          <option value="">Select here</option>
        </ResourceDropdown>
      </div>
    );
  };

  const getPenaltyPointsField = (index: number) => {
    return isVanDriver ?
      <div className={`${commonFormStylesIdentifier}__fieldContainer  ${className}__componentContainer`}>
        <label htmlFor="penaltyPoints" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Number of penalty points
        </label>
        <ResourceDropdown
          key={`penaltyPoints_${index}`}
          placeholder="Select here"
          name={`${baseFieldName}[${index}].penaltyPoints`}
          error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'penaltyPoints', index)}
          errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'penaltyPoints', index)}
          value={getEntryValue(values[baseFieldName], index, 'penaltyPoints')}
          onChange={handleChangeResource}
          onBlur={handleBlur}
          values={resources.penalty_points}
        />
      </div>
      :
      <PenaltyPoints
        {...getAdditionalDriverProps(index)}
        onBlur={onBlur}
        fieldNamePrefix={`${baseFieldName}[${index}].`}
        driverTypePrefix={`additionalDriver`}
        repeatingFieldsCustomClass={`${className}__penaltyPointsRepeatingContainer`}
        fieldFilledBefore={fieldFilledBefore}
      />;
  };

  const traderOccupationByJourney = (index: number) => {
    return isVanDriver ? getTraderOccupationField(index) : (
      <BaseEmploymentDetails
        formik={getAdditionalDriverProps(index)}
        occupationOptions={mainOccupationOptions}
        insuranceType={CAR_INSURANCE_TYPE}
        fieldNamePrefix={`${baseFieldName}[${index}].`}
        isSubFormComponent={true}
        resources={resources}
        handleChangeResource={handleChangeResource}
      />
    );
  };

  const renderLicenceAndCountry = (index: any) => {
    if (insuranceType === VAN_INSURANCE_TYPE)
      return (
        <LicenceTypeLicenceCountryAdditionalDrivers
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          resources={resources}
          errors={errors}
          index={index}
          baseFieldName={baseFieldName}
          fieldNamePrefix={`${baseFieldName}[${index}].`
          } />
      );
  };

  const getUseOfOtherCarResources = () => {
    if (resources.who_driving.length > 0) {
      return [
        {
          ...resources.full_time_use_of_other_car.find((who: any) => who.alias === 'false'),
        },
        {
          ...resources.full_time_use_of_other_car.find((who: any) => who.alias === 'true'),
        },
      ];
    } else return [];
  };

  const renderUseOfOtherCar = (index: any) => {
    if (insuranceType === CAR_INSURANCE_TYPE)
      return (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="useOfOtherCar" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Does this driver have full time use of any other car?
          </label>
          <ResourceButtonGroup
            error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'useOfOtherCar', index)}
            errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'useOfOtherCar', index)}
            onBlur={handleBlur}
            name="useOfOtherCar"
            selected={values.additionalDrivers[index].useOfOtherCar}
            onClick={(obj: any) => {
              setFieldValue(`${baseFieldName}[${index}].useOfOtherCar`, obj);
              setFieldTouched(`${baseFieldName}[${index}].useOfOtherCar`, true);
            }}
            options={getUseOfOtherCarResources()}
          />
        </div>
      );
  };

  const getVanDriverFields = (index: number) => {
    return isVanDriver ? getRelationshipToProposerField(index) : '';
  };

  const getAdditionalDriverComponent = (arrayHelpers: ArrayHelpers, index: number, additionalDriversCount: number) => {
    return (
      <div key={`component_${index}`}>
        <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
          <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
            Additional Driver {index + 1}
          </label>
          {additionalDriversCount > 1 &&
            <div
              className={`${className}__closeIcon`}
              onClick={() => removeAdditionalDriver(arrayHelpers, index)}
            >
              <XIcon />
            </div>
          }
        </div>
        <BasePersonalDetails
          formik={getAdditionalDriverProps(index)}
          fieldNamePrefix={`${baseFieldName}[${index}].`}
          resources={resources}
          handleChangeResource={handleChangeResource}
        />
        {getVanDriverFields(index)}
        {traderOccupationByJourney(index)}
        {renderLicenceAndCountry(index)}
        <BaseDrivingDetails
          formik={getAdditionalDriverProps(index)}
          fieldNamePrefix={`${baseFieldName}[${index}].`}
          isSubFormComponent={true}
          resources={resources}
          handleChangeResource={handleChangeResource}
        />
        {!isVanDriver ? getRelationshipToProposerField(index) : ''}
        {getPenaltyPointsField(index)}
        {renderUseOfOtherCar(index)}
        {index < additionalDriversCount - 1 &&
          <div className={`${className}__dividerContainer`}>
            <WideDivider height={1} color={dividerColor} />
          </div>
        }
      </div>);
  };

  return (
    <FieldArray
      name="additionalDrivers"
      render={(arrayHelpers: ArrayHelpers) => (
        <div className={className}>
          <div>
            {values[baseFieldName] && values[baseFieldName].length > 0 ? (
              values[baseFieldName].map((additionalDriver: AdditionalDriverType, index: number) => getAdditionalDriverComponent(arrayHelpers, index, values[baseFieldName].length))
            ) : null}
          </div>
          {maxDrivers >= (values[baseFieldName].length + 1) ?
            <div className={`${className}__addComponentButtonContainer`}>
              <Button
                fluid={true}
                onClick={() => {
                  addAdditionalDriver(arrayHelpers);
                }}
              >
                Add additional drivers
              </Button>
            </div>
            : <></>
          }
        </div>
      )}
    />
  );
};

export default AdditionalDrivers;
