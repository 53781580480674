/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../../styles/commonFormStyles.scss';

import { AnimatedSpinnerWhite, InputField } from '@arachas/core/lib';
import { noop } from '@arachas/core/lib/utils';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { EMAIL_TO_REGISTER_STORAGE } from '../../constants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import {
  getAccessToken,
  removeFromSessionStorage,
  saveInSessionStorage,
} from '../../helpers';
import { isAgent } from '../../helpers';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';
import { checkExistingEmail } from '../../services/checkExistingEmail';
import { getLatestUnpurchasedHomeQuote } from '../../services/latestHomeQuoteService';
import { getLatestUnpurchasedMotorQuote } from '../../services/motor/latestMotorQuoteService';
import { getLatestUnpurchasedVanQuote } from '../../services/van/latestVanQuoteService';

const REQUEST_LOADING = 'AgentEmail/LOADING';
const REQUEST_SUCCESS = 'AgentEmail/SUCCESS';
const REQUEST_FAILURE = 'AgentEmail/FAILURE';

type RequestState =
  | typeof REQUEST_LOADING
  | typeof REQUEST_SUCCESS
  | typeof REQUEST_FAILURE;

interface Props {
  insuranceType: string;
  handleCustomBlur?: noop;
  height: string;
  icon?: string;
  heading: noop;
  text: string;
}

const EmailInput = (props: FormikProps & Props) => {
  const className = 'c-EmailInput';

  const {
    touched,
    errors,
    handleChange,
    values,
    setValues,
    setFieldError,
    handleBlur,
  } = props.formik;

  const latestQuoteFunction = {
    [HOME_INSURANCE_TYPE]: getLatestUnpurchasedHomeQuote,
    [CAR_INSURANCE_TYPE]: getLatestUnpurchasedMotorQuote,
    [VAN_INSURANCE_TYPE]: getLatestUnpurchasedVanQuote,
    ['']: null,
  };

  const { insuranceType } = props;

  const [requestStatus, setRequestStatus] = useState <?RequestState>();
  const [emailExisting, setEmailExisting] = useState(false);
  const { cognitoUser } = useContext(CognitoContext);
  const isLoggedIn = !!cognitoUser;

  const getUserProfileForAgent = async (values: any) => {
    const getLatestQuoteForJourney = latestQuoteFunction[insuranceType];
    if (!getLatestQuoteForJourney) return;

    const accessToken = getAccessToken(cognitoUser);
    setRequestStatus(REQUEST_LOADING);
    // undefined not to break other uses of this function
    const profileResponse = await getLatestQuoteForJourney(
      accessToken,
      undefined,
      values.email
    );

    if (profileResponse.quote_reference) {
      const newValues = {
        ...values,
        ...profileResponse.values,
        ...profileResponse.quote,
      };
      setValues(newValues);
      setRequestStatus(REQUEST_SUCCESS);
    } else {
      setFieldError('email', `Email address has not been found`);
      setRequestStatus(REQUEST_FAILURE);
    }
    setRequestStatus(REQUEST_FAILURE);
  };

  const callSetExistingEmail = (e: SyntheticEvent<HTMLInputElement>) => {
    const { target } = e;
    if (!(target instanceof window.HTMLInputElement)) return;
    if (!isLoggedIn) {
      const email = target.value;
      checkExistingEmail(email).then((res: boolean) => {
        if (res) removeFromSessionStorage(EMAIL_TO_REGISTER_STORAGE);
        else saveInSessionStorage(EMAIL_TO_REGISTER_STORAGE, email);
        setEmailExisting(res);
      });
    }
  };

  const handleEmailCustomBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!isAgent()) {
      callSetExistingEmail(e);
    }
    handleBlur(e);
  };

  const renderEmail = () => {
    return emailExisting && !isAgent() ? (
      <div className={`${commonFormStylesIdentifier}__infoText`}>
        That email address is already registered with us.
        <Link
          to={{ pathname: routes.loginPage.url }}
          className={`${className}__link`}
        >
          {' Please click here to login.'}
        </Link>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      {renderEmail()}
      {isAgent() ? (
        //agent input field
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('email', touched, errors)}
            //@amanda to fix
            // eslint-disable-next-line
            errorMessage={errors.email}
            name="email"
            placeholder="Type here"
            type="email"
            onBlur={handleEmailCustomBlur}
            //@amanda to fix
            // eslint-disable-next-line
            value={values.email}
            onChange={handleChange}
            suffix={faSearch}
            onClickIcon={() => {
              getUserProfileForAgent(values);
            }}
          />
        </span>
      ) : (
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('email', touched, errors)}
            //@amanda to fix
            // eslint-disable-next-line
            errorMessage={errors.email}
            name="email"
            placeholder="Type here"
            type="email"
            onBlur={handleEmailCustomBlur}
            //@amanda to fix
            value={values.email}
            onChange={handleChange}
          />
        </span>
      )}
      {requestStatus === REQUEST_LOADING ? (
        <AnimatedSpinnerWhite padding={'20%'} />
      ) : null}
    </>
  );
};

export default EmailInput;
