"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PersonChildrenIcon = require("./PersonChildrenIcon");

var _PersonChildrenIcon2 = _interopRequireDefault(_PersonChildrenIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PersonChildrenIcon2.default;