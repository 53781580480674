/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { iconsAndTitles } from '../../constants';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  travelMedicalAssistanceInternationalPhoneNumber,
} from '../../constants/phoneNumbers';

const content =
  `For medical emergencies whilst abroad please ` +
  `contact Chubb Assistance  24 hours a day, 7 days a week.`;

const MedicalAssistance = ({ match, history }: { match: Match, history: RouterHistory; }) => {
  const type = match.params.type || '';

  return (
    <CallUsPage
      history={history}
      icon={iconsAndTitles[TRAVEL_INSURANCE_TYPE].icon}
      cardTitle={type && iconsAndTitles[type].title}
      title="Medical Assistance"
      contentText={content}
      phoneNumber={travelMedicalAssistanceInternationalPhoneNumber}
    />
  );
};

export default MedicalAssistance;
