/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './MarketingConsent.scss';

import { Checkbox, Text } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants/commonClassNames';


const consentMsg = 'Let’s keep in touch. We’re here to help you always and would love to let you know about any exciting offers or new developments on our products or services. We will only contact you if we believe the information can make your life easier or offer you value for money. By selecting the checkbox, you’re giving consent to Arachas Corporate Brokers Ltd. to use your data to send you information about relevant offers and products. Don’t worry you can change your mind and opt out at any time, we just don’t want you to miss out on anything that could benefit you and add value to your policies with us.';
const consentDetails = 'You can change your mind at any time by emailing';
const consentAddress = `marketing@arachas.ie`;

export const MarketingConsent = (props) => {
  const { values, checkboxLabelFontSize, setFieldValue, } = props;
  const className = 'c-MarketingConsent';

  const getMarketingContactOptions = () => {
    const options = [
      { name: 'emailConsent', label: 'Email' },
      { name: 'smsConsent', label: 'Text/Digital' },
      { name: 'phoneConsent', label: 'Phone' },
      { name: 'postConsent', label: 'Post' }
    ];
    return (
      <>
        <Text className={`${commonFormStylesIdentifier}__subFieldLabel`}>
          <div className={`${className}__consentSubtitle`}>
            How should we get in touch?
          </div>
        </Text>
        {options.map((o, i) => {
          return (
            <div key={i} className={`${commonFormStylesIdentifier}__checkboxContainerList`}
            >
              <Checkbox
                name={o.name}
                label={o.label}
                labelFontSize={checkboxLabelFontSize}
                isChecked={values[o.name]}
                onChange={() => setFieldValue(o.name, !values[o.name])}
                value={values[o.name]}
              />
            </div>
          );
        })}
        <br />
        <div className={`${commonFormStylesIdentifier}__checkboxContainerList`}>
          <Checkbox
            id="selectAll"
            name="selectAll"
            label={"Select All"}
            labelFontSize={checkboxLabelFontSize}
            isChecked={(values.emailConsent && values.postConsent && values.smsConsent && values.phoneConsent)}
            onChange={() => {
              ['emailConsent', 'postConsent', 'smsConsent', 'phoneConsent', 'selectAll'].forEach((field) => setFieldValue(field, !values.selectAll));
            }}
            value={values.selectAll}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
        <Checkbox
          id="marketingConsent"
          name="marketingConsent"
          label={values.marketingConsent ?
            <>
              {consentMsg}<br /><br />
              {consentDetails} <a href="mailto:marketing@arachas.ie"><b>{consentAddress}</b></a>
            </>
            :
            <>
              {consentMsg}
            </>
          }
          labelFontSize={checkboxLabelFontSize}
          isChecked={values.marketingConsent}
          onChange={() => {
            if (!values.marketingConsent) {
              setFieldValue('emailConsent', false);
              setFieldValue('smsConsent', false);
              setFieldValue('phoneConsent', false);
              setFieldValue('postConsent', false);
            }
            setFieldValue('marketingConsent', !values.marketingConsent);
          }}
          value={values.marketingConsent}
        />
      </div>
      {values.marketingConsent ? getMarketingContactOptions() : null}
    </>
  );
};

MarketingConsent.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  checkboxLabelFontSize: PropTypes.number
};

export default MarketingConsent;
