import './ThreeStateToggle.scss';

import { AccordionText } from '@arachas/core/lib';
import { faCheck, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants';

const ThreeStateToggle = ({ onChange, value, name, label, error, tooltip }) => {
  const className = 'c-ThreeStateToggle';
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const getStatusClass = (currentValue) => {
    if (currentValue === true) return `${className}__statusYes`;
    if (currentValue === false) return `${className}__statusNo`;
    return "";
  };

  const getStatusNoClass = (currentValue) => currentValue === false ? `${className}__statusSelected` : "";
  const getStatusYesClass = (currentValue) => currentValue === true ? `${className}__statusSelected` : "";
  const getStatusText = currentValue => {
    if (currentValue === true) return <FontAwesomeIcon icon={faCheck} color={'white'} />;
    if (currentValue === false) return <FontAwesomeIcon icon={faTimes} color={'white'} />;
    return <FontAwesomeIcon icon={faMinus} color={'white'} />;
  };

  const setValue = newValue => {
    setSelectedValue(newValue);
    if (onChange) onChange(newValue);
  };

  const getLabel = (tooltip) => {
    return tooltip ?
      (
        <AccordionText
          label={label}
          icon="info"
          iconAlign="right"
          customLabelClass={`${commonFormStylesIdentifier}__accordionText`}
        >
          <div className={`${className}__InfoText`}>
            {tooltip}
          </div>
        </AccordionText>
      ) : label;
  };

  return (
    <div className={className}>
      <div className={`${className}__toggleContainer`}>

        <label className={`${className}__label`} htmlFor={`${className}__${name}`}>
          {getLabel(tooltip)}
        </label>
        <div className={`${className}__container`} id={`${className}__${name}`}>
          <div className={`${className}__statusLabel ${getStatusYesClass(selectedValue)}`} onClick={() => setValue(true)}
            id={`${className}__${name}Yes`}>Yes
          </div>
          <div className={`${className}__status ${getStatusClass(selectedValue)}`}>{getStatusText(selectedValue)}</div>
          <div className={`${className}__statusLabel ${getStatusNoClass(selectedValue)}`} onClick={() => setValue(false)}
            id={`${className}__${name}No`}>No
          </div>
        </div>
      </div>
      {error && (<div className={`${className}__error`}>{error}</div>)}
    </div>
  );
};

ThreeStateToggle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  tooltip: PropTypes.string
};

export default ThreeStateToggle;
