/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { COMPREHENSIVE_DESCRIPTION, THIRD_PARTY_DESCRIPTION } from '../../constants';
export const featureListComprehensive = [
  { label: () => 'No Claims Discount Stepback', tick: true },
  { label: () => 'Third party trailer cover', tick: true },
  { label: () => 'Emergency Medical Treatment', tick: true },
];

export const featureListThirdParty = [
  { label: () => 'No Claims Discount Stepback', tick: true },
  { label: () => 'Emergency Medical Treatment', tick: true },
  { label: () => 'Third party trailer cover', tick: true },
];

export const getlistOfFeatures = {
  [COMPREHENSIVE_DESCRIPTION]: featureListComprehensive,
  [THIRD_PARTY_DESCRIPTION]: featureListThirdParty,
};

export const getShowUpToFeatureNumber = 3;