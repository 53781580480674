/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import React from 'react';

const className = 'c-GenericErrorPage';

const errorText =
  `We're sorry, our system is currently unavailable.`;

const MTAErrorPage = () => {

  const message = () =>
    'Thanks!';

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {message()}
        </Title>
      </div>
      <div className={`${className}__warningMessage`}
        id='MTAErrorPage__warningMessage'
        data-testid='MTAErrorPage__warningMessage'>
        {errorText}
      </div>
    </div>
  );
};

export default MTAErrorPage;
