"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var KeyIcon = function KeyIcon(props) {
  var color = props.color,
      solid = props.solid;
  var style = solid ? {
    fill: color,
    enableBackground: 'new 0 0 63 63'
  } : {
    fill: 'none',
    stroke: color,
    strokeWidth: '1.5px'
  };
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 58.915 34.367",
    style: style
  }, _react2.default.createElement("g", {
    id: "prefix__vpn_key-24px_1_",
    "data-name": "vpn_key-24px(1)",
    transform: "translate(0 -5)"
  }, _react2.default.createElement("path", {
    id: "prefix__Path_1565",
    d: "M25.812 15.1A12.178 12.178 0 0 0 14.274 7 12.155 12.155 0 0 0 2 19.274a12.155 12.155 0 0 0 12.274 12.274 12.178 12.178 0 0 0 11.538-8.1l.491-1.718h14.974v9.819h4.91v-9.82H51.1v-4.91H26.3zM14.274 26.638a7.364 7.364 0 1 1 7.364-7.364 7.551 7.551 0 0 1-7.364 7.364z",
    "data-name": "Path 1565",
    transform: "translate(2.91 2.91)",
    style: {
      opacity: .3
    }
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1566",
    d: "M17.184 5a17.184 17.184 0 1 0 15.465 24.548h6.628v9.819h14.729v-9.819h4.91V14.819H32.649A17.129 17.129 0 0 0 17.184 5zm36.822 19.638H49.1v9.819h-4.91v-9.819H29.212l-.491 1.718a12.178 12.178 0 0 1-11.538 8.1A12.155 12.155 0 0 1 4.91 22.184 12.155 12.155 0 0 1 17.184 9.91a12.178 12.178 0 0 1 11.538 8.1l.491 1.718h24.793zm-36.822-9.819a7.364 7.364 0 1 0 7.364 7.364 7.551 7.551 0 0 0-7.364-7.364zm0 9.819a2.319 2.319 0 0 1-2.455-2.455 2.319 2.319 0 0 1 2.455-2.455 2.319 2.319 0 0 1 2.455 2.455 2.319 2.319 0 0 1-2.455 2.455z",
    "data-name": "Path 1566"
  })));
};

KeyIcon.defaultProps = {
  color: _colors.fontColorSecondary,
  solid: true
};
KeyIcon.propTypes = {
  color: _propTypes2.default.string,
  solid: _propTypes2.default.bool
};
exports.default = KeyIcon;