
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { AnimatedSpinner } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { SINGLE_POLICY_STORAGE } from '../../constants';
import { getAccessToken, getObjectFromSessionStorage } from '../../helpers';
import { getUploadedDocumentsList } from "../../services/uploadService";
import DocumentsLandingPage from './DocumentsLandingPage';

const DocumentsLandingPageWrapper = props => {
  const [uploadedDocuments, setUploadedDocuments] = useState(null);

  const { match } = props;
  const insuranceType = match.params.type || '';
  const { cognitoUser } = useContext(CognitoContext);
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);


  useEffect(() => {
    if (!uploadedDocuments) {
      const policyNumber = policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;

      getUploadedDocumentsList(getAccessToken(cognitoUser), insuranceType, policyNumber)
        .then(response => {
          setUploadedDocuments(response);
        });
    }
  }, [uploadedDocuments]);

  if (!uploadedDocuments)
    return (
      <div>
        <AnimatedSpinner />
      </div>
    );

  return <DocumentsLandingPage {...props} uploadedDocuments={uploadedDocuments} />;
};

DocumentsLandingPageWrapper.propTypes = {
  match: PropTypes.object,
};

export default DocumentsLandingPageWrapper;
