import './MessagesNavigationItem.scss';

import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { CognitoContext } from '../../../CognitoUtils';
import { getAccessToken } from '../../../helpers';
import { getMessages } from '../../Messages/messagesService';

const className = 'c-MessagesNavigationItem';

function MessagesNavigationItem(props) {
  const [hasMessages, setHasMessages] = useState(false);
  const { cognitoUser } = useContext(CognitoContext);

  const checkForMessages = () => {
    const accessToken = getAccessToken(cognitoUser);
    getMessages(accessToken).then(messages => setHasMessages(messages.length > 0));
    return hasMessages;
  };

  return (
    <div className={className}>
      {checkForMessages() && <span className={`${className}__messagesIndicator`} />}
      {props.children}
    </div>
  );
}

MessagesNavigationItem.propTypes = {
  children: PropTypes.node,
};

export default MessagesNavigationItem;
