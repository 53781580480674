import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, getInsurancePageTitle,HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../constants/insuranceTypeConstants';
import { routes } from '../routes';

const journeyIds = {
  [CAR_INSURANCE_TYPE]: 'P1C',
  [HOME_INSURANCE_TYPE]: 'P2H',
  [TRAVEL_INSURANCE_TYPE]: 'P3T',
  [VAN_INSURANCE_TYPE]: 'P4V',
  [DEVICE_INSURANCE_TYPE]: 'P5D'
}

const getPageInfo = (path) => {
  let longestMatch = { url: '' }
  // Find the route object for this URL
  Object.keys(routes).forEach(routeKey => {
    // Looking for the closest similarity (by identical character match)
    if (path.includes(routes[routeKey].url) && routes[routeKey].url.length > longestMatch.url.length) {
      longestMatch = routes[routeKey]
    }
  })
  // Not found, return empty
  if (!longestMatch.pageType) return { pageType: '', pageCategory: '' }
  // Check if insuranceType is in the URL
  let insuranceTypePrefix = ''
  Object.keys(journeyIds).forEach(type => {
    if (path.includes(`/${type}`)) {
      // Make a prefix out of it for pageCategory
      insuranceTypePrefix = `${getInsurancePageTitle[type]} `
    }
  })
  // Return results
  return {
    pageType: longestMatch.pageType,
    pageCategory: insuranceTypePrefix + longestMatch.pageCategory
  }
}

export default {
  landsOnProductPage: (args) => ({
    event: "add_to_cart",
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [{
          name: `${args.insuranceType} insurance`,
          id: journeyIds[args.insuranceType],
          brand: "Arachas Digital",
          category: "personal insurance",
          quantity: 1
        }]
      }
    }
  }),
  landsOnGetQuotePage: (args) => ({
    event: "checkout_step_1",
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: { step: 1, option: "Get Quote" },
        products: [{
          name: `${args.insuranceType} insurance`,
          id: journeyIds[args.insuranceType],
          brand: "Arachas Digital",
          category: "personal insurance",
          quantity: 1
        }]
      }
    }
  }),
  landsOnViewQuotePage: (args) => ({
    event: "checkout_step_2",
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: { step: 2, option: "Your Quote" },
        products: [{
          name: `${args.insuranceType} insurance`,
          id: journeyIds[args.insuranceType],
          price: args.cover_premium,
          brand: "Arachas Digital",
          category: "personal insurance",
          quantity: 1
        }]
      }
    }
  }),
  landsOnSummaryPage: (args) => ({
    event: "add_payment_info",
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: { step: 3, option: "Your Product" },
        products: [{
          name: `${args.insuranceType} insurance`,
          id: journeyIds[args.insuranceType],
          price: args.cover_premium,
          brand: "Arachas Digital",
          category: "personal insurance",
          quantity: 1
        }]
      }
    }
  }),
  landsOnThankYouPage: (args) => ({
    event: "purchase",
    ecommerce: {
      currencyCode: 'EUR',
      purchase: {
        actionField: {
          id: args.quote_reference,
          affiliation: "Online Store",
          revenue: args.cover_premium
        },
        products: [{
          name: `${args.insuranceType} insurance`,
          id: journeyIds[args.insuranceType],
          price: args.cover_premium,
          brand: "Arachas Digital",
          category: "personal insurance",
          quantity: 1
        }]
      }
    }
  }),
  runOnAllPages: (args) => ({
    event: 'page_view',
    pageName: 'Arachas Insurance Wallet',
    pageType: getPageInfo(args.pathname).pageType, 
    pageCategory: getPageInfo(args.pathname).pageCategory,  
    userId: args.userId,
    userCategory: args.userId ? 'Logged-In' : 'Logged out'
  }),
  afterLoginAttempt: (args) => ({
    event: 'login',
    login: args.errorMessage ? args.errorMessage : 'success',
    userCategory: args.errorMessage ? 'Logged out' : 'Logged-In',
    ...(args.userId ? { userId: args.userId } : {}),
  }),
  afterRegistrationAttempt: (args) => ({
    event: 'create_acount',
    registration: args.errorMessage ? args.errorMessage : 'success',
    userCategory: args.errorMessage ? 'Logged out' : 'Logged-In',
    ...(args.userId ? { userId: args.userId } : {}),
  })
}