/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import './AddressLookup.scss';

import {
  AccordionText,
  AnimatedSpinner,
  AutoAddressAutocomplete,
  InputRadioButton,
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { getAccessToken, isAgent } from '../../helpers';
import { getAppliedAddress } from './addressLookupService';

const dataForType = {
  home: data => ({
    eircode: data.eircode,
  }),
  motor: data => ({
    address_line1: data.addressLine1,
    address_line2: data.addressLine2,
    address_line3: data.addressLine3,
    county: data.county,
    eircode: data.eircode,
    town: data.town,
  }),
};

const className = 'c-AddressLookup';

const defaultEmptyFunction = () => {};

// eslint-disable-next-line complexity
const AddressLookup = props => {
  const { type } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [hasAppliedError, setHasAppliedError] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { cognitoUser } = useContext(CognitoContext);

  const getDisplayAddress = address => {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      town,
      county,
      eircode,
    } = address;
    return [addressLine1, addressLine2, addressLine3, town, county, eircode]
      .filter(element => !!element)
      .join(', ');
  };

  const getDisplayAppliedAddress = address => {
    const {
      address_line1,
      address_line2,
      address_line3,
      town,
      county,
      eircode,
    } = address;
    return getDisplayAddress({
      addressLine1: address_line1,
      addressLine2: address_line2,
      addressLine3: address_line3,
      town,
      county,
      eircode,
    });
  };

  const handleAppliedResponse = address => {
    if (address.length === 1 && props.onSelect) props.onSelect(address[0]);
    else if (address.length > 1) setAddressOptions(address);
    else {
      props.onSelect(null)
      setSelectedAddress(null)
      setHasAppliedError(true);
    }
    setIsLoading(false);
  };

  const onSelect = address => {
    setSelectedAddress(getDisplayAddress(address));
    setIsLoading(true);
    setHasAppliedError(false);
    const accessToken = isAgent() ? getAccessToken(cognitoUser) : null;
    getAppliedAddress(dataForType[type](address), type, accessToken).then(
      handleAppliedResponse
    );
  };

  const handleOnAppliedAddressChange = event => {
    props.onSelect(JSON.parse(event.target.value));
  };

  const appliedAddressRadioButtonValues = addressOptions.map(addressOption => {
    return {
      value: JSON.stringify(addressOption),
      label: getDisplayAppliedAddress(addressOption),
    };
  });

  const handleAddressAutocompleteChange = () => {
    setAddressOptions([]);
    props.onSelect({});
  };

  useEffect(() => {
    if (props.selectedAddress && !selectedAddress && type !== HOME_INSURANCE_TYPE) {
      onSelect(props.selectedAddress);
    }
  }, [props.selectedAddress]);

  return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="autoAddressValue"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          <AccordionText
            label={
              type === 'motor'
                ? 'Eircode or address where you park your car overnight'
                : 'Enter full address or Eircode'
            }
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${commonFormStylesIdentifier}__infoText`}>
              {`An Eircode is a seven character
                  alpha-numeric code. For example A65 F4E2.
                  You can find your Eircode by entering your
                  home address at `}
              <a
                href={'https://finder.eircode.ie'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {'https://finder.eircode.ie'}
              </a>
            </div>
          </AccordionText>
        </label>
        <AutoAddressAutocomplete
          onSelect={address => onSelect(address)}
          value={selectedAddress}
          name="autoAddressValue"
          errorMessage={hasAppliedError ? "We are unable to provide a quote for this address, please call 015920898 for assistance." : props.errorMessage}
          handleBlur={props.handleBlur || defaultEmptyFunction()}
          handleChange={handleAddressAutocompleteChange}
          hasError={props.hasError || hasAppliedError}
        />
      </div>

      {isLoading && <AnimatedSpinner />}

      {addressOptions.length > 1 && (
        <div
          className={`${className}__appliedOptions`}
          id="appliedAddressOptions"
        >
          <div>Please Confirm Your Address Below</div>
          <InputRadioButton
            groupName="homeAddressLookup"
            values={appliedAddressRadioButtonValues}
            onChange={handleOnAppliedAddressChange}
          />
        </div>
      )}
    </div>
  );
};

AddressLookup.propTypes = {
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  onSelect: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  selectedAddress: PropTypes.object,
};

export default AddressLookup;
