import {
  ASSUMPTIONS,
  GET_QUOTE_RESPONSE_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  HOME_QUOTE_RECALCULATED_VALUES_STORAGE,
  RECALCULATED_QUOTE_RESPONSE_STORAGE,
  RECALCULATED_QUOTE_VALUES_STORAGE,
  SINGLE_SCHEMA_STORAGE,
  URL_PROMOCODE
} from '../constants';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../constants/insuranceTypeConstants';
import * as storageIdentifiers from '../constants/sessionStorage';

const removeHomeStorageAfterGetQuote = () => {
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`);
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_RESPONSE_STORAGE}`);
  sessionStorage.removeItem(storageIdentifiers.HOME_QUOTE_RECALCULATED_VALUES_STORAGE);
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`);
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${ASSUMPTIONS}`);
};

const removeMotorStorageAfterGetQuote = () => {
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_RESPONSE_STORAGE}`);
  sessionStorage.removeItem(storageIdentifiers.SELECTED_MOTOR_QUOTE_STORAGE);
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${storageIdentifiers.RECALCULATED_QUOTE_VALUES_STORAGE}`);
  sessionStorage.removeItem(storageIdentifiers.RECALCULATED_QUOTE_RESPONSE_STORAGE);
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`);
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${ASSUMPTIONS}`);
};

const removeTravelStorageAfterGetQuote = () => {
  sessionStorage.removeItem(`${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_RESPONSE_STORAGE}`);
  sessionStorage.removeItem(`${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.RECALCULATED_QUOTE_VALUES_STORAGE}`);
  sessionStorage.removeItem(`${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.DECLARATION_DETAILS_STORAGE}`);
  sessionStorage.removeItem(`${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`);
};

const removeVanStorageAfterGetQuote = () => {
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_RESPONSE_STORAGE}`);
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${storageIdentifiers.RECALCULATED_QUOTE_VALUES_STORAGE}`);
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${storageIdentifiers.DIRECT_DEBIT_STORAGE}`);
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${ASSUMPTIONS}`);
};

const removeDeviceStorageAfterGetQuote = () => {
  sessionStorage.removeItem(`${storageIdentifiers.DEVICE_DIRECT_DEBIT}`);
  sessionStorage.removeItem(`${storageIdentifiers.DEVICE_SINGLE_POLICY}`);
  sessionStorage.removeItem(`${storageIdentifiers.DEVICE_GET_QUOTES}`);
  sessionStorage.removeItem(`${storageIdentifiers.DEVICE_ASSUMPTIONS}`);  
}

const removeStorageAfterGetQuoteLookup = {
  [HOME_INSURANCE_TYPE]: removeHomeStorageAfterGetQuote,
  [CAR_INSURANCE_TYPE]: removeMotorStorageAfterGetQuote,
  [TRAVEL_INSURANCE_TYPE]: removeTravelStorageAfterGetQuote,
  [VAN_INSURANCE_TYPE]: removeVanStorageAfterGetQuote,
  [DEVICE_INSURANCE_TYPE]: removeDeviceStorageAfterGetQuote,
};

export const removeSessionStorageAfterGetQuote = (insuranceType) => {
  if (removeStorageAfterGetQuoteLookup[insuranceType]) {
    removeStorageAfterGetQuoteLookup[insuranceType]();
  }
};

export const removeHomeJourneyStorage = () => {
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`);
  removeHomeStorageAfterGetQuote();
};

export const removeCarJourneyStorage = () => {
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`);
  removeMotorStorageAfterGetQuote();
};

export const removeTravelJourneyStorage = () => {
  sessionStorage.removeItem(`${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`);
  removeTravelStorageAfterGetQuote();
};

export const removeVanJourneyStorage = () => {
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${storageIdentifiers.GET_QUOTE_VALUES_STORAGE}`);
  removeVanStorageAfterGetQuote();
};

export const removeDeviceJourneyStorage = () => {
  sessionStorage.removeItem(`${storageIdentifiers.DEVICE_GET_QUOTE_VALUES}`);
  removeDeviceStorageAfterGetQuote()
}

export const removeAllSessionStorage = () => {
  sessionStorage.removeItem(storageIdentifiers.BOI_WALLET_USER_NAME_STORAGE);
  sessionStorage.removeItem(storageIdentifiers.BOI_WALLET_USER_STORAGE);
  removeAllStoredPolicyNumbers();
  removeAllJourneysSessionStorage();
};


export const removeAllJourneysSessionStorage = () => {
  removeHomeJourneyStorage();
  removeCarJourneyStorage();
  removeTravelJourneyStorage();
  removeVanJourneyStorage();
  removeURLPromoCode();
};

export const getObjectFromSessionStorage = (storageKey) => {
  const data = sessionStorage.getItem(storageKey);
  if (data) return JSON.parse(data);
  return {};
};

export const getItemFromSessionStorage = (storageKey) => {
  return sessionStorage.getItem(storageKey);
};

export const getPayloadObjFromSessionStorage = (insuranceType) => {
  const payload = getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`);
  const recalculated = getObjectFromSessionStorage(RECALCULATED_QUOTE_RESPONSE_STORAGE);
  return { ...payload, ...recalculated };
};

export const getSinglePayloadObjFromSessionStorage = (insuranceType) => {
  const selectedSchema = getObjectFromSessionStorage(`${insuranceType}${SINGLE_SCHEMA_STORAGE}`);
  const payload = getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`)[0];
  const recalculated = getObjectFromSessionStorage(RECALCULATED_QUOTE_RESPONSE_STORAGE);
  return Object.keys(selectedSchema).length > 0 ? { ...selectedSchema, ...recalculated } : { ...payload, ...recalculated };
};

export const getValuesObjFromSessionStorage = (insuranceType) => insuranceType === HOME_INSURANCE_TYPE ?
  ({ ...getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`), ...getObjectFromSessionStorage(`${HOME_QUOTE_RECALCULATED_VALUES_STORAGE}`) })
  :
  ({ ...getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`), ...getObjectFromSessionStorage(`${insuranceType}${RECALCULATED_QUOTE_VALUES_STORAGE}`) });

export const saveInSessionStorage = (storageKey, values) => {
  return sessionStorage.setItem(storageKey, values);
};

export const saveObjectSessionStorage = (storageKey, values) => {
  return sessionStorage.setItem(storageKey, JSON.stringify(values));
};

export const removeAllStoredPolicyNumbers = () => {
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${storageIdentifiers.SINGLE_POLICY_STORAGE}`);
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${storageIdentifiers.SINGLE_POLICY_STORAGE}`);
  sessionStorage.removeItem(`${TRAVEL_INSURANCE_TYPE}${storageIdentifiers.SINGLE_POLICY_STORAGE}`);
  sessionStorage.removeItem(`${DEVICE_INSURANCE_TYPE}${storageIdentifiers.SINGLE_POLICY_STORAGE}`);
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${storageIdentifiers.SINGLE_POLICY_STORAGE}`);
};

export const removeURLPromoCode = () => {
  sessionStorage.removeItem(`${CAR_INSURANCE_TYPE}${URL_PROMOCODE}`);
  sessionStorage.removeItem(`${HOME_INSURANCE_TYPE}${URL_PROMOCODE}`);
  sessionStorage.removeItem(`${DEVICE_INSURANCE_TYPE}${URL_PROMOCODE}`);
  sessionStorage.removeItem(`${VAN_INSURANCE_TYPE}${URL_PROMOCODE}`);
};

export const removeFromSessionStorage = (storageKey) => {
  return sessionStorage.removeItem(storageKey);
};

export default {
  getObjectFromSessionStorage,
  getItemFromSessionStorage,
  getPayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage,
  removeAllSessionStorage,
  removeCarJourneyStorage,
  removeHomeJourneyStorage,
  saveInSessionStorage,
  removeFromSessionStorage,
  saveObjectSessionStorage,
  removeAllJourneysSessionStorage,
  removeSessionStorageAfterGetQuote,
  removeAllStoredPolicyNumbers
};
