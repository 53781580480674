"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GlobeAmericasIcon = function GlobeAmericasIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 75 75",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("path", {
    id: "prefix__globe-americas-solid",
    d: "M37.5 8A37.5 37.5 0 1 0 75 45.5 37.5 37.5 0 0 0 37.5 8zm12.443 54.073c-.59.587-1.208 1.2-1.71 1.706A3.613 3.613 0 0 0 47.3 65.4c-.228.856-.413 1.721-.721 2.551l-2.63 7.084a30.219 30.219 0 0 1-6.449.709V71.6c.256-1.908-1.155-5.483-3.422-7.749a4.839 4.839 0 0 1-1.417-3.422V55.59a4.823 4.823 0 0 0-2.489-4.229c-2.173-1.2-5.264-2.882-7.381-3.948a21.8 21.8 0 0 1-4.786-3.289l-.121-.109a17.358 17.358 0 0 1-2.731-3.136c-1.418-2.082-3.729-5.507-5.23-7.733a30.406 30.406 0 0 1 15.6-15.407l3.631 1.816a2.419 2.419 0 0 0 3.5-2.164v-1.708a30.22 30.22 0 0 1 3.688-.366l4.287 4.283a2.419 2.419 0 0 1 0 3.422l-.709.708-1.564 1.564a1.21 1.21 0 0 0 0 1.71l.709.709a1.21 1.21 0 0 1 0 1.71l-1.21 1.21a1.211 1.211 0 0 1-.856.354H35.64a1.209 1.209 0 0 0-.844.343l-1.5 1.459a1.211 1.211 0 0 0-.239 1.408l2.357 4.716a1.21 1.21 0 0 1-1.081 1.751h-.853a1.208 1.208 0 0 1-.792-.3l-1.4-1.219a2.422 2.422 0 0 0-2.351-.469l-4.713 1.571a1.807 1.807 0 0 0-.236 3.331l1.675.838a10.24 10.24 0 0 0 4.58 1.084c1.591 0 3.416 4.127 4.839 4.839h10.093a4.841 4.841 0 0 1 3.425 1.415l2.07 2.07a4.612 4.612 0 0 1 1.35 3.262 7.037 7.037 0 0 1-2.075 4.987zM63.054 48.26a3.629 3.629 0 0 1-2.14-1.508L58.2 42.674a3.624 3.624 0 0 1 0-4.025l2.962-4.443a3.625 3.625 0 0 1 1.4-1.232l1.963-.981a29.3 29.3 0 0 1 2.949 17.369z",
    transform: "translate(0 -8)"
  }));
};

exports.default = GlobeAmericasIcon;