import { parse, startOfToday } from 'date-fns';
import * as yup from "yup";

import {
  occupationEmployed, occupationPartTime,
  occupationSelfEmployed,
  occupationUnemployed,
} from '../../constants/OccupationTypeConstants';
import { dateOfBirthYup } from '../../helpers';
import { isValidResourceValue } from '../../helpers/FieldErrorHelper';
import { getOccupationOptions } from '../../mocks/MainOccupationValues';

const moreThanTwo = 'Please enter more than two characters';
const lessThanFifty = 'Please enter in less than fifty characters';
const mainOccupationOptions = getOccupationOptions();

const shouldHaveOccupation = (status) => {
  const occupationStatuses = [
    occupationEmployed,
    occupationSelfEmployed,
    occupationUnemployed,
    occupationPartTime,

  ];
  return occupationStatuses.includes(status);
};

const checkIfYearsHeldWithinRange = (
  value,
  path,
  createError,
) => {
  if (value < 0) {
    return createError({ path, message: 'Please enter a larger number' });
  } else if (value > 100) {
    return createError({ path, message: 'Please enter a smaller number' });
  }
};

export const additionalDriverYupSchema = yup.object().shape({
  title: yup
    .object()
    .test('title', 'Please select title', isValidResourceValue)
    .nullable(true)
    .required('Please select Title'),
  //First Name and Last Name: can't be empty, less than 50 chars
  firstName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('Last name is required'),
  dob: dateOfBirthYup,
  employmentStatus: yup
    .object()
    .test('employmentStatus', 'Please select employment status', isValidResourceValue)
    .nullable()
    .required('Please select employment status'),
  mainOccupation: yup
    .string()
    .when(
      'employmentStatus',
      (employmentStatus, schema) =>
        shouldHaveOccupation(employmentStatus)
          ? schema
            .oneOf(
              mainOccupationOptions.map(
                (occupation) => occupation.value,
              ),
              'Occupation is required',
            )
            .required('Occupation is required')
          : schema,
    ),
  relationshipToProposer: yup
    .object()
    .test('relationshipToProposer', 'Relationship to proposer', isValidResourceValue)
    .nullable()
    .required('Relationship to proposer'),
  driversLicenseType: yup
    .object()
    .test('driversLicenseType', 'Drivers licence type is required', isValidResourceValue)
    .nullable()
    .required('Drivers licence type is required'),
  yearsHeld: yup
    .string()
    .test('yearsHeldDrivingLicense', 'Years Held is required', function (
      value,
    ) {
      if (!value) return false;
      const { path, createError } = this;
      const strippedValue = value.replace(/,/g, ``);
      const integerValue = parseInt(strippedValue, 10);
      const error = checkIfYearsHeldWithinRange(
        integerValue,
        path,
        createError,
      );
      if (error) return error;
      return true;
    })
    .nullable(true),
  additionalDriverPenaltyPointOffences: yup
    .array()
    .when('additionalDriverPenaltyPointOffenceCount', {
      is: (penaltyOffenceCount) => penaltyOffenceCount > 0,
      then: yup
        .array()
        .of(
          yup.object().shape({
            penaltyPoints: yup
              .object()
              .test('penaltyPoints', 'Penalty points is required', isValidResourceValue)
              .required('Penalty points is required')
              .nullable(),
            offence: yup
              .object()
              .test('offence', 'Offence is required', isValidResourceValue)
              .required('Offence is required')
              .nullable(),
            date: yup
              .date()
              .nullable()
              .transform((_, dateString) => {
                return dateString ? parse(dateString) : null;
              })
              .max(
                startOfToday(),
                'Please enter a date of today or in the past',
              )
              .required('Date is required')
              .nullable(),
          }),
        )
        .required('Penalty points and offence required'),
      otherwise: yup.array().notRequired(),
    }),
  useOfOtherCar: yup
    .object()
    .test('useOfOtherCar', 'Use of car is required', isValidResourceValue)
    .nullable()
    .required('Use of car is required'),
});
