/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getItemFromSessionStorage, makeRequest } from '../../helpers';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

const getService = (insuranceType) => insuranceType === CAR_INSURANCE_TYPE ? 'motor' : insuranceType;


export const getPromoCodeLookup = async (promoCode, cognitoAccessTokenKey, insuranceType, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/${getService(insuranceType)}/promocode/${promoCode}`,
    authToken: authToken,
    history: props.history
  });
};
