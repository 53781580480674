import './AddAdditionalDriver.scss';

import { Title } from '@arachas/core/lib';
import { AnimatedSpinner } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';

import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { makeRequest } from '../../helpers';
import resourcesForJourney from '../../pages/GetGenericQuotePage/resourcesForJourney';
import { MobileBackButton } from '../MobileBackButton';
import AddAdditionalDriverForm from './AddAdditionalDriverForm';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
const className = 'c-AddAdditionalDriver';

const AddAdditionalDriverWrapper = props => {
  const { history } = props;
  const [resources, setResources] = useState(null);
  const resourcesList = resourcesForJourney[CAR_INSURANCE_TYPE];
  const titleText = "Add a driver";

  useEffect(() => {

    if (!resources) {
      const promisesArray = resourcesList.map((requiredResource) =>
        makeRequest({
          url: `${basePath}/${requiredResource.service}/resources/${
            requiredResource.group
          }`,
        }),
      );
      Promise.all(promisesArray).then((responses) => {
        const resourcesResults = responses.reduce(
          (reducer, value, index) => {
            reducer[resourcesList[index].group] = value;
            return reducer;
          },
          {},
        );
        setResources(resourcesResults);
      });
    }
  }, []);

  if (!resources) return <AnimatedSpinner/>;

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history}/>
      </div>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>

          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <Title align="left" type="h1" weight="weight500" variant="greyBrown">
                {titleText}
              </Title>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle ${className}__fontColor`}>
              {titleText}
            </div>
          </div>

          <div className={`${className}__subHeading`}> Please fill out the form below.</div>
          <AddAdditionalDriverForm {...props} resources={resources}/>
        </div>
      </div>
    </div>
  );
};

AddAdditionalDriverWrapper.propTypes = {
  history: PropTypes.object,
};

export default AddAdditionalDriverWrapper;
