import { addDays, endOfToday, parse, startOfToday } from 'date-fns';
import * as yup from 'yup';

import { MYSELF_AND_NAMED_DRIVERS, MYSELF_AND_SPOUSE, OPEN_DRIVING_AND_NAMED_DRIVERS } from '../../../constants/van';
import { getDateOfBirthMin, nullableBooleanRequired } from '../../../helpers/CommonYupValidation';
import { isValidResourceValue } from '../../../helpers/FieldErrorHelper';

const moreThanTwo = 'Please enter more than two characters';
const lessThanFifty = 'Please enter in less than fifty characters';


const checkIfYearsHeldWithinRange = (
  value,
  path,
  createError,
) => {
  if (value < 0) {
    return createError({ path, message: 'Please enter a larger number' });
  } else if (value > 100) {
    return createError({ path, message: 'Please enter a smaller number' });
  }
};


const additionalDriverYupSchema = yup.object().shape({
  title: yup
    .object()
    .test('title', 'Please select title', isValidResourceValue)
    .nullable(true)
    .required('Please select'),
  firstName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('Last name is required'),
  dob: getDateOfBirthMin(18, 'They'),
  traderOccupation: yup
    .object()
    .test('traderOccupation', 'Please select employment status', isValidResourceValue)
    .nullable()
    .required('Please select'),
  relationshipToProposer: yup
    .object()
    .test('relationshipToProposer', 'Relationship to proposer', isValidResourceValue)
    .nullable()
    .required('Please select'),
  driversLicenseType: yup
    .object()
    .test('driversLicenseType', 'Drivers licence type is required', isValidResourceValue)
    .nullable()
    .required('Please select'),
  licenceCountry: yup
    .object()
    .test('licenceCountry', 'Drivers licence country is required', isValidResourceValue)
    .nullable()
    .required('Please select'),
  yearsHeld: yup
    .string()
    .test('yearsHeldDrivingLicense', 'Years Held is required', function (
      value
    ) {
      if (!value) return false;
      const { path, createError } = this;
      const strippedValue = value.replace(/,/g, ``);
      const integerValue = parseInt(strippedValue, 10);
      const error = checkIfYearsHeldWithinRange(
        integerValue,
        path,
        createError,
      );
      if (error) return error;
      return true;
    })
    .nullable(true),
  penaltyPoints: yup
    .object()
    .test('penaltyPoints', 'Penalty points is required', isValidResourceValue)
    .required('Penalty points is required')
    .nullable()
});


export const YourVanCoverFormYupSchema = {
  coverType: yup.object()
    .test('coverType', 'Cover type is required', isValidResourceValue)
    .required('Cover type is required'),
  policyStart: yup
    .date()
    .transform((_, dateString) => parse(dateString))
    .min(startOfToday(), 'Please enter a date 28 or less days in the future')
    .max(
      addDays(endOfToday(), 28),
      'Please enter a date 28 or less days in the future',
    )
    .required('Policy start date is required'),
  mainDriverDisqualifiedOrConvicted: nullableBooleanRequired(
    'isDriverDisqualifiedOrConvicted',
    'You must select an answer to this field',
  ),
  penaltyPoints: yup
    .object()
    .test('penaltyPoints', 'Penalty points is required', isValidResourceValue)
    .required('Penalty points is required')
    .nullable(),
  motorClaims: yup.array().of(
    yup.object().shape({
      claim_date: yup.date()
      .transform((_, dateString)=> {
        return dateString ? parse(dateString) : null
      })
      .max(
        startOfToday(),
          'Please enter a date of today or in the past',
      )
      .required('Date is required'), 
      claim_type: yup.object().required('Motor claim type is required'),
      claim_status: yup.boolean().required('Motor claim status is required'),
      claim_amount: yup.string().required('Motor claim amount is required'),
    }),
  ),
  claimsCount: yup
    .object()
    .test('claimsCount', 'Number of claims is required', isValidResourceValue)
    .nullable()
    .required('Number of claims is required'),
  whoIsDrivingYourVan: yup.object().test('whoIsDrivingYourVan',
    'Please Select', isValidResourceValue).required('Please select'),
  additionalDrivers: yup.array().when('whoIsDrivingYourVan', {
    is: (whoIsDrivingYourVan) =>
      whoIsDrivingYourVan && (
        MYSELF_AND_SPOUSE === whoIsDrivingYourVan.alias ||
        MYSELF_AND_NAMED_DRIVERS === whoIsDrivingYourVan.alias ||
        OPEN_DRIVING_AND_NAMED_DRIVERS === whoIsDrivingYourVan.alias),
    then: yup
      .array()
      .of(additionalDriverYupSchema)
      .required('Additional driver is required'),
    otherwise: yup.array().notRequired(),
  }),
  disclosureRequirements: yup
    .boolean()
    .oneOf([true], 'You must accept the Disclosure Requriements'),
  acceptedTermsAndConditions: yup
    .boolean()
    .oneOf([true], 'You must accept the Terms and Conditions'),
  marketingConsent: yup.boolean().oneOf([true], '')
    .test('marketing-consent-function-home',
      'You must select a method of contact', function () {
        return this.parent.emailConsent ||
          this.parent.postConsent ||
          this.parent.phoneConsent ||
          this.parent.smsConsent;
      })
};

export default YourVanCoverFormYupSchema;
