"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _InputRadioButton = require("./InputRadioButton");

var _InputRadioButton2 = _interopRequireDefault(_InputRadioButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _InputRadioButton2.default;