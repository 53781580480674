/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { ButtonGroup, Dropdown } from '@arachas/core/lib';
import type { FormikProps } from 'formik';
import { connect } from 'formik';
import React, { useContext, useEffect, useState } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import {
  TRAVEL_INSURANCE_TYPE
} from '../../../../../constants/insuranceTypeConstants';
import { isFieldError, mapDropdownOptions } from '../../../../../helpers';
import { getInsuranceProviders } from '../../../../../helpers/TravelHelper';
import {
  inFormStructure,
  InsuranceTypeContext
} from '../../../../../pages/GetGenericQuotePage';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';

export const BaseInsuranceDetails = (props: FormikProps) => {
  const className = 'c-ContactDetails';
  const {
    touched,
    errors,
    handleChange,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    values,
  } = props.formik;

  const insuranceType = useContext(InsuranceTypeContext);
  const [showProvider, setShowProvider] = useState(false);
  const [insuranceProvidersForTripType, setInsuranceProvidersForTripType] = useState([{}]);



  useEffect(() => {
    const newShowProvider = values.coverAbroad === 'yes';

    setShowProvider(newShowProvider);

    if (newShowProvider === false) {
      setFieldValue('provider', '');
      setFieldTouched('provider', false);
    }

    if (insuranceType === TRAVEL_INSURANCE_TYPE) {
      const insuranceProviders = getInsuranceProviders(props.travelResources.trip_types);
      setInsuranceProvidersForTripType(insuranceProviders[values.tripType]);
    }
  }, [values.coverAbroad]);


  return inFormStructure(
    'coverAbroad',
    insuranceType,
    aboutYouFormIdentifier
  ) ? (
      <div className={className}>
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer `}
        >
          <label htmlFor="coverAbroad" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Do you have private medical insurance that covers you while abroad?
          </label>
          <ButtonGroup
            name="coverAbroad"
            onClick={(value: string) => {
              setFieldValue('coverAbroad', value);
              setFieldTouched('coverAbroad', true);
            }}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' }
            ]}
            value={values.coverAbroad}
            touched={touched.coverAbroad}
            selected={values.coverAbroad}
            error={isFieldError('coverAbroad', touched, errors)}
            errorMessage={errors.coverAbroad}
          />
        </div>
        {showProvider ? (
          <div
            className={`${commonFormStylesIdentifier}__dynamicFieldsContainer  ${commonFormStylesIdentifier}--withMarginTop`}>
            <label htmlFor="provider" className={`${commonFormStylesIdentifier}__fieldLabel`}>Health insurance
            provider</label>
            <Dropdown
              error={isFieldError('provider', touched, errors)}
              errorMessage={errors.provider}
              name="provider"
              placeholder="Type here"
              type="text"
              value={values.provider}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select here</option>
              {mapDropdownOptions(insuranceProvidersForTripType, 'provider')}
            </Dropdown>
          </div>
        ) : null}
      </div>
    ) : null;
};

const InsuranceDetails = connect(BaseInsuranceDetails);

export default InsuranceDetails;
