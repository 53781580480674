"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SimpleSwitch = require("./SimpleSwitch");

var _SimpleSwitch2 = _interopRequireDefault(_SimpleSwitch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SimpleSwitch2.default;