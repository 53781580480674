/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import idx from 'idx';

import { getItemFromSessionStorage, makeRequest } from '../helpers';
import { rawToIrishFormat } from '../helpers/DateHelper';

const getPayloadForAgent = email => (email ? { payload: { email } } : {});

// eslint-disable-next-line complexity
export const getLatestHomeQuote = async (
  cognitoAccessTokenKey,
  props = {},
  email = '',
  retreieveQuote = false,
  payloadData={}
) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const payload = getPayloadForAgent(email);
  if(retreieveQuote) {
    return await makeRequest({
      method: 'POST',
      url: `${basePath}/home/home_quotes/latest_quote`,
      payload: payloadData,
    });
  } else {
    return await makeRequest({
      method: email ? 'POST' : 'GET',
      url: `${basePath}/home/home_quotes/latest`,
      authToken: authToken,
      history: props.history,
      ...payload,
    });
  }

};

export const hasPurchasedHomePolicy = async (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return false because the call is going to fail
    return false;
  }
  return getLatestHomeQuote(cognitoAccessTokenKey, props).then(response => {
    if (response.home_quote) {
      const data = formatResponse(response.home_quote);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedHomeQuote = async (
  cognitoAccessTokenKey,
  props,
  email
) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return an object that specifies no token was passed
    return { hasNoAccessToken: true };
  }
  return getLatestHomeQuote(cognitoAccessTokenKey, props, email).then(
    response => {
      if (response.home_quote) {
        const data = formatResponse(response.home_quote);
        return !data.policy_reference ? data : {};
      }
      return {};
    }
  );
};

export const getRetrieveLatestUnpurchasedHomeQuote = async (
  payloadData
) => {
  return getLatestHomeQuote('', {}, '', true, payloadData).then(
    response => {
      if (response.home_quote) {
        const data = formatResponse(response.home_quote);
        return !data.policy_reference ? data : {};
      }
      return {};
    }
  )
};

// TODO will ne used later
// eslint-disable-next-line
const nullToEmptyString = value => {
  return value === null ? '' : value;
};

// TODO fix complexity error
// eslint-disable-next-line complexity
const formatResponse = homeQuoteData => {
  const { quote_reference, policy_reference, promotion_code } = homeQuoteData;

  const getRebuildCost = () => buildings_amount === null ? '' : `${buildings_amount}`;

  const {
    first_name,
    last_name,
    primary_address,
    mobile_phone,
    email,
    date_of_birth,
    employment_status,
    first_time_buyer,
    occupation,
    title,
  } = homeQuoteData.policy_holder_details || {};

  const {
    buildings_amount,
    contents_amount,
    additional_policy_holders_details,
    year_of_construction,
    num_bedrooms,
    num_bathrooms,
    heating_type,
    alarm_type,
    smoke_alarm,
    ncd_num_years,
  } = homeQuoteData.cover_details || {};

  const claims_details = idx(homeQuoteData, _ => _.cover_details.claims_details) || [];

  return {
    quote_reference,
    policy_reference,
    values: {
      title,
      firstName: first_name,
      lastName: last_name,
      addressLine1: primary_address.address_line1,
      addressLine2: primary_address.address_line2,
      addressLine3: primary_address.address_line3,
      addressCounty: primary_address.address_line4,
      addressTown: primary_address.town,
      addressEircode: primary_address.eircode,
      eircode: primary_address.eircode,
      phoneNo: mobile_phone,
      email: email,
      dob: rawToIrishFormat(date_of_birth),
      employmentStatus: employment_status,
      mainOccupation: occupation,
      rebuildCost: getRebuildCost(),
      contentsCoverOf: `${contents_amount}`,
      coverType: idx(homeQuoteData, _ => _.cover_details.dwelling_type) || '',
      homeNoClaimsBonus: ncd_num_years,
      // isFamilyUnit: nullToEmptyString(tenanted_property.family_unit),
      // numTenants: nullToEmptyString(risk_address.tenanted_property.number_of_tenants),
      // occupiedAtNight: nullToEmptyString(risk_address.tenanted_property.occupied_at_night),
      // tenancyAgreement: nullToEmptyString(risk_address.tenanted_property.tenancy_agreement),
      // isInUse: nullToEmptyString(risk_address.holiday_home.furnished),
      // occupationFrequency: nullToEmptyString(risk_address.holiday_home.occupancy_frequency),
      // holidayHomeIsRented: nullToEmptyString(risk_address.holiday_home.rented),
      // isLetMoreThan5PerYear: nullToEmptyString(risk_address.holiday_home.rented_num_times),
      homeType: idx(homeQuoteData, _ => _.cover_details.home_type) || '',
      yearOfConstruction: year_of_construction,
      numBedrooms: num_bedrooms,
      numBathrooms: num_bathrooms,
      heatingType: heating_type,
      houseAlarm: alarm_type,
      twoSmokeDetectors: smoke_alarm,
      firstTimeBuyer: first_time_buyer,
      // neighbourhoodWatch: risk_address.neighbourhood_watch,
      claims: claims_details.map(claim => {
        claim.claim_date = claim.claim_date.substr(0, 4);
        claim.claim_amount = `${claim.claim_amount}`;
        return claim;
      }),
      propertyJointlyOwned: getPropertyJointlyOwned(additional_policy_holders_details),
      ...getAdditionalHolderObject(additional_policy_holders_details),
      promoCode: promotion_code,
    },
  };
};

const getPropertyJointlyOwned = (additionalHolders = []) => {
  return additionalHolders.length > 0 ? 'true' : 'false';
};

const getAdditionalHolderObject = (additionalHolders = []) => {
  if (additionalHolders.length === 0) return {};

  const { first_name, surname, title, date_of_birth } = additionalHolders[0];
  return {
    jointlyOwnedDetailsTitle: title,
    jointlyOwnedDetailsFirstName: first_name,
    jointlyOwnedDetailsLastName: surname,
    jointlyOwnedDetailsDateOfBirth: rawToIrishFormat(date_of_birth)
  };
};
