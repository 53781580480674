"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PersonOneIcon = function PersonOneIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 50 50",
    width: "50",
    height: "50",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("path", {
    id: "Path_1572",
    transform: "translate(-4 -4)",
    d: "M29 29a12.5 12.5 0 1 0-12.5-12.5A12.537 12.537 0 0 0 29 29zm0-18.75a6.25 6.25 0 1 1-6.25 6.25A6.268 6.268 0 0 1 29 10.25zm0 21.875c-8.437 0-25 4.063-25 12.5V54h50v-9.375c0-8.437-16.562-12.5-25-12.5zM47.75 47.75h-37.5v-3.125c.625-2.187 10.312-6.25 18.75-6.25s18.125 4.063 18.75 6.25z",
    "data-name": "Path 1572"
  }));
};

exports.default = PersonOneIcon;