"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ChartLineIcon = require("./ChartLineIcon");

var _ChartLineIcon2 = _interopRequireDefault(_ChartLineIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ChartLineIcon2.default;