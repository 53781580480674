import { CarIcon, HomeIcon, MapIcon, MobileIcon, ShuttleIcon } from '@arachas/core/lib';
import React from 'react';

import { primaryPurple, white } from './colors';

export const iconsAndTitles = {
  home: {
    icon: <HomeIcon color={primaryPurple} />,
    title: 'Home',
  },
  car: {
    icon: <CarIcon color={primaryPurple} />,
    title: 'Car',
  },
  travel: {
    icon: <MapIcon color={primaryPurple} />,
    title: 'Travel',
  },
  device: {
    icon: <MobileIcon color={primaryPurple} />,
    title: 'Device',
  },
  van: {
    icon: <ShuttleIcon color={primaryPurple} />,
    title: 'Van',
  }
};

export const whiteIcons = {
  home: <HomeIcon color={white} solid={true} />,
  car: <CarIcon color={white} solid={true} />,
  travel: <MapIcon color={white} solid={true} />,
  device: <MobileIcon color={white} solid={true} />,
  van: <ShuttleIcon color={white} solid={true} />
};