const specifiedItemsDetailsList = [
  {
    displayName: 'Contact Lenses',
    dropdownValue: 'Contact Lenses',
    maxRequiredItemValue: 2000,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, type and full description'
  },
  {
    displayName: 'Digital Music player',
    dropdownValue: 'Digital Music player',
    maxRequiredItemValue: 5000,
    descriptionPrompt: 'Please provide full details of description, make and model in the item description field'
  },
  {
    displayName: 'Furs',
    dropdownValue: 'Furs',
    maxRequiredItemValue: 15000,
    descriptionPrompt: 'In the Item Description field, please provide details of the item and fur type where possible'
  },
  {
    displayName: 'Hearing Aid',
    dropdownValue: 'Hearing Aid',
    maxRequiredItemValue: 5000,
    descriptionPrompt: 'In the Item Description field, please provide manufacturer, type, serial number and full description including left/right ear'
  },
  {
    displayName: 'Jewellery',
    dropdownValue: 'Jewellery',
    maxRequiredItemValue: 20000,
    descriptionPrompt: 'In the Item Description field, please provide full details of jewellery type, metal, stones and carat'
  },
  {
    displayName: 'Laptop',
    dropdownValue: 'Laptop',
    maxRequiredItemValue: 2000,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, make, model and serial number'
  },
  {
    displayName: 'Mobile',
    dropdownValue: 'Mobile',
    maxRequiredItemValue: 2000,
    descriptionPrompt: 'In the Item Description field, please provide details of make, model, serial number and whether it is contract or ready to go'
  },
  {
    displayName: 'Tablet',
    dropdownValue: 'Tablet',
    maxRequiredItemValue: 2000,
    descriptionPrompt: 'In the Item Description field, please provide details of make, model, serial number'
  },
  {
    displayName: 'Musical Instrument',
    dropdownValue: 'Musical Instrument',
    maxRequiredItemValue: 5000,
    descriptionPrompt: 'In the Item Description field, please provide details such as instrument type, make and model where possible'
  },
  {
    displayName: 'Photographic Equipment',
    dropdownValue: 'Photographic Equipment',
    maxRequiredItemValue: 5000,
    descriptionPrompt: 'In the Item Description field, please provide details such as equipment type, make and model'
  },
  {
    displayName: 'Sports Equipment',
    dropdownValue: 'Sports Equipment',
    maxRequiredItemValue: 3000,
    descriptionPrompt: 'In the Item Description field, please provide details such as equipment type, make and model where possible'
  },
  {
    displayName: 'Pedal Cycle',
    dropdownValue: 'Pedal Cycle',
    maxRequiredItemValue: 2000,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, make, model and serial number'
  },
  {
    displayName: 'Camcorder',
    dropdownValue: 'Camcorder',
    maxRequiredItemValue: 5000,
    descriptionPrompt: 'In the Item Description field, please provide details such as equipment type, make and model'
  },
];

export default specifiedItemsDetailsList;
