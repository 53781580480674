/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './TravelDestinationPage.scss';
import '../../styles/commonPageStyles.scss';

import { Button, ButtonGroup, Label, MapIcon, Title } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import DestinationCountry from '../../components/Forms/YourTravelForm/DestinationCountry';
import { MobileBackButton } from '../../components/MobileBackButton';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { GET_QUOTE_VALUES_STORAGE } from '../../constants';
import { primaryPurple } from '../../constants/colors';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { TRIP_TYPE_CODE, ZONE_CHUBB_LABELS, ZONE_KEYS, ZONE_KEYS_ICONS } from '../../constants/travel/TravelConstants';
import {
  // getObjectFromSessionStorage,
  isFieldError,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';





const TravelDestinationPageForm = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    history,
    tripTypeOptions,
    zonesForTripType = {},
    destinationCountryList = [],

  } = props;

  const insuranceType = TRAVEL_INSURANCE_TYPE;
  const [destinationZones, setDestinationZones] = useState([]);
  const [destinationCountries, setDestinationCountries] = useState([]);


  useEffect(() => {
    const tripType = values.tripType || TRIP_TYPE_CODE.SINGLE_TRIP;
    if (zonesForTripType[tripType]) {
      const destinationZones = zonesForTripType[tripType].map((zone) => {
        const newZone = { ...zone };
        newZone.label = ZONE_KEYS[zone.label];
        newZone.icon = ZONE_KEYS_ICONS[zone.label];
        return newZone;
      });
      setDestinationZones(destinationZones);
      const countries = values.destinationZone
        ? countriesForDestinationZone(values.destinationZone, destinationZones)
        : [];
      setDestinationCountries(countries);
    }
    //setSessionStorageValues();
  }, [zonesForTripType]);


  //todo move to helper
  const countriesForDestinationZone = (
    zoneId,
    zones = destinationZones
  ) => {
    const destinationZone = zones.find(
      (zone) => zone.value === parseInt(zoneId, 10)
    );
    if (destinationZone) {
      const destinationZoneName = destinationZone.label;
      const zoneObject = destinationCountryList.find(
        (countriesForZone) =>
          countriesForZone.name === ZONE_CHUBB_LABELS[destinationZoneName]
      );
      return zoneObject
        ? zoneObject.countries.map((country) => ({
          value: country,
          text: country,
          key: country
        }))
        : [];
    }
    return [];
  };


  const goToGenericQuotePage = () => {
    saveInSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    props.history.push({
      pathname: `${routes.getGenericQuotePage.url}${insuranceType}`,
      state: {
        insuranceType: 'travel',
      }
    });
  };

  //todo move to helper
  const handleTripTypeChange = (selectedTripType) => {
    if (selectedTripType !== values.tripType) {
      const newZones = zonesForTripType[selectedTripType].map((zone) => {
        const newZone = { ...zone };
        newZone.label = ZONE_KEYS[zone.label];
        newZone.icon = ZONE_KEYS_ICONS[zone.label];
        return newZone;
      });
      setDestinationZones(newZones);
      setFieldValue('tripType', selectedTripType);
      if (selectedTripType === TRIP_TYPE_CODE.MULTI_TRIP) {
        setFieldValue('destinationCountry', '');
      }
    }
  };
  //todo move to helper

  const destinationZoneChange = (e) => {
    setDestinationCountries(countriesForDestinationZone(e));
    setFieldValue('destinationCountry', '');
    setFieldValue('destinationZone', e);
  };

  //@amanda todo save values and display when select back
  // const setSessionStorageValues = () => {
  //   const sessionStoredValues = getObjectFromSessionStorage(`${TRAVEL_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`);
  //   const newValues = {
  //     ...values,
  //     ...sessionStoredValues,
  //   };
  //   console.log("values", values);
  //   console.log(sessionStoredValues);
  //   if (sessionStoredValues.tripType || sessionStoredValues.destinationZone || sessionStoredValues.destinationCountry) {
  //   setFieldValue('destinationCountry', newValues.tripType);
  //   setFieldValue('destinationZone', newValues.destinationZone);
  //   setFieldValue('tripType', newValues.tripType);

  //   }
  // };

  const titleText = 'Get a Quote';
  const className = 'c-TravelDestinationPage';
  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>
            <MapIcon color={primaryPurple} /></div>
        </div>
      </div>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>

          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <Title align="left" type="h1" weight="weight500" variant="greyBrown">
                {titleText}
              </Title>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle ${className}__fontColor`}>
              {titleText}
            </div>
          </div>

          <div className={`${className}__subHeading`}>Just a few details to get your quote</div>
          <Form className={`${className}__form`}>

            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <Label
                htmlFor="tripType"
                className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Cover type
              </Label>
              <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
                <ButtonGroup
                  name="tripType"
                  error={isFieldError('tripType', touched, errors)}
                  errorMessage={errors.tripType}
                  options={tripTypeOptions}
                  onClick={handleTripTypeChange}
                  spaceBetween={true}
                  selected={values.tripType}
                  largerButton={true}
                />
              </div>
            </div>

            <div
              className={`${commonFormStylesIdentifier}__fieldContainer ${className}--collapseGridColumns`}
            >
              <Label
                htmlFor="destinationZone"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Destination zone
                {/* <AccordionText
                  label="Destination zone"
                  icon="info"
                  iconAlign="right"
                  customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                >
                  <div className={`${commonFormStylesIdentifier}__infoText`}>
                    For single trip cover please confirm your main country of destination in the box provided.
                For multi-trip cover please select the area to be covered by this policy.{' '}
                    <a href={definitionPdfLink} target={'_blank'} rel='noopener noreferrer'>
                      Full list of countries in our underwriters definition of Europe.
                </a>
                  </div>
                </AccordionText> */}
              </Label>
              <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
                <ButtonGroup
                  name="destinationZone"
                  options={destinationZones}
                  onClick={destinationZoneChange}
                  spaceBetween={true}
                  error={isFieldError('destinationZone', touched, errors)}
                  errorMessage={errors.destinationZone}
                  handleChange={handleChange}
                  disabled={!values.tripType}
                  selected={values.destinationZone}
                  largerButton={true}
                />
              </div>
            </div>
            <DestinationCountry
              touched={touched}
              errors={errors}
              values={values}
              destinationCountries={destinationCountries}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />

            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>

            </div>
            <div className={`${className}__button`}>
              <Button
                id="TravelDestinationPage__nextButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={() => {
                  goToGenericQuotePage();
                }}
                type="submit"
              >
                NEXT
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const TravelDestinationPage = withFormik({
  mapPropsToValues(props) {
    let initialValues = {
      tripType: '',
    };
    if (props.location && props.location.state && props.location.state.carReg) {
      initialValues.carReg = props.location.state.carReg;
    }
    const valuesObject = {};
    Object.keys(initialValues).forEach((key) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    tripType: yup
      .string()
      .required('Please select your cover type'),
    destinationZone: yup
      .string()
      .required('Please select your destination zone'),
    destinationCountry: yup
      .string()
      .when('tripType', {
        is: TRIP_TYPE_CODE.SINGLE_TRIP,
        then: yup.string().test('tripType', 'Please select a destination zone',
          function () {
            return this.parent.destinationZone !== undefined;
          }).required('Please select your destination country'),
        else: yup.string().notRequired()
      }),
  }),
  displayName: 'TravelDestinationPageForm'
})(TravelDestinationPageForm);

TravelDestinationPageForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  isValid: PropTypes.bool,
  dirty: PropTypes.bool,
  history: PropTypes.object,
  tripTypeOptions: PropTypes.array,
  zonesForTripType: PropTypes.object,
  destinationCountryList: PropTypes.array,
  insuranceOptions: PropTypes.object
};

export default TravelDestinationPage;
