import PropTypes from 'prop-types';
import React, {useCallback} from 'react'

import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';

interface Props {
    Number: string
}

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const ContactUs = (props: Props) => {
    const {Number} = props
    const fbEventContact = useCallback(() => {
        fbqTrackEvent('Contact');
        return true;
    },[])

    if(Number !== '') {
        return <a onClick={fbEventContact} href={`tel:${parseInt(Number)}`}>{Number}</a>
    } else {
        return null
    }
}

ContactUs.propTypes = {
    Number: PropTypes.string
  }
export default ContactUs;