/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './MyProfile.scss';

import { DesktopCard, PersonOneIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext,useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants';
import { primaryPurple } from '../../constants/colors';
import { getAccessToken } from '../../helpers';
import { routes } from '../../routes';
import { getRenewalDates } from '../../services/common/profileService';
import { getUserProfile } from '../../services/profile';


const MyProfile = (props) => {
  const className = 'c-MyProfile';
  const { history } = props;
  const { cognitoUser } = useContext(CognitoContext);

  const [enableRenewalDates, setEnableRenewalDates] = useState(false)
  const [emptyRenewalDates, setEmptyRenewalDates] = useState(false)

  const checkRenewalDates = () => {
    getRenewalDates(getAccessToken(cognitoUser)).then(res => {
      if (res.error === "No policy renewals found") {
        setEmptyRenewalDates(true)
      } 
    })
  }

  useEffect(() => {
    getUserProfile(getAccessToken(cognitoUser)).then(
      (res) => {
        const getPolicies = () => res && res.policies ? res.policies : []
        setEnableRenewalDates(getPolicies().length > 0)
        checkRenewalDates()
      }
    );
  }, [])

  const renderChangePassword = () => {
    return (
      <div className={`${className}__buttonSpacing`}>
        <Link to={routes.changePassword.url}>
          <ButtonWithIcons
            id={'MyProfile__changePasswordButton'}
          >
            Change Password
          </ButtonWithIcons>
        </Link>
      </div>
    )
  }

  const renderRenewalDates = () => {
    return (
      <div className={`${className}__buttonSpacing`}>
        <Link to={enableRenewalDates ? emptyRenewalDates ? routes.addRenewalDate.url : routes.renewalDates.url : ''}>
          <ButtonWithIcons
            id={'MyProfile__renewalDatesButton'}
            disabled={!enableRenewalDates}
          >
            Other Renewal Dates
          </ButtonWithIcons>
        </Link>
      </div>
    )
  }

  return (
    <div className={className}>
      <DesktopCard>
        <>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          <div className={`${className}__iconContainer`}>
            <div className={`${className}__icon`}>
              <PersonOneIcon color={primaryPurple} />
            </div>
          </div>
          <div className={`${className}__titleContainer`}>
            <h2 className={`${className}__title`}>My Profile</h2>
          </div>
          <div className={`${className}__buttonContainer`}>
            {renderChangePassword()}
            {renderRenewalDates()}
          </div>
        </>
      </DesktopCard>
    </div>
  );
};

MyProfile.propTypes = {
  history: PropTypes.object
}

export default withRouter(MyProfile);