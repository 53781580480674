"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _InputField = require("./InputField");

var _InputField2 = _interopRequireDefault(_InputField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _InputField2.default;