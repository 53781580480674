import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getItemFromSessionStorage, makeRequest } from '../../helpers';

export const cancelPolicy = async (type, policyData, accessToken) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const API_SERVICE = type === CAR_INSURANCE_TYPE ? "motor" : type;
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/${API_SERVICE}/mta/cancel`,
    authToken: authToken,
    payload: policyData
  });
};
