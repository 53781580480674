import { CAR_INSURANCE_TYPE } from '../constants/insuranceTypeConstants';
import { getItemFromSessionStorage, makeRequest, makeRequestResponseIsFile } from '../helpers';

const getService = (insuranceType) => insuranceType === CAR_INSURANCE_TYPE ? 'motor' : insuranceType;

export const signUploadURL = async (accessToken, insuranceType, payload) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/documents/upload_url`;

  return makeRequest({
    method: 'POST',
    url: URL,
    authToken: authToken,
    payload: payload
  });
};

export const scanDocument = async (accessToken, insuranceType, documentId) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/documents/scan_status?documentId=${documentId}`;

  return makeRequest({
    method: 'GET',
    url: URL,
    authToken: authToken,
  });
};

export const uploadDocument = async (accessToken, insuranceType, payload) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/documents/upload`;

  return makeRequest({
    method: 'POST',
    url: URL,
    authToken: authToken,
    payload: payload
  });
};

export const getUploadedDocumentsList = async (accessToken, insuranceType, policyReference) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/documents/uploaded_documents?policyReference=${policyReference}`;

  return makeRequest({
    method: 'GET',
    url: URL,
    authToken: authToken,
  });
};

export const getUploadedDocument = async (accessToken, insuranceType, policyReference, documentId) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/documents/uploaded_document?policyReference=${policyReference}&documentId=${documentId}`;

  return makeRequestResponseIsFile({
    method: 'GET',
    url: URL,
    authToken: authToken,
  });
};

export const deleteUploadedDocument = async (accessToken, insuranceType, policyReference, documentId) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const URL = `${basePath}/${getService(insuranceType)}/documents/uploaded_document?policyReference=${policyReference}&documentId=${documentId}`;

  return makeRequest({
    method: 'DELETE',
    url: URL,
    authToken: authToken,
  });
};