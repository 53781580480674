import { getItemFromSessionStorage, makeRequest } from '../../helpers';

export const addAdditionalDriver = async (policyData, accessToken) => {
  const authToken = getItemFromSessionStorage(accessToken) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/motor/mta/additional_driver`,
    authToken: authToken,
    payload: policyData
  });
};
