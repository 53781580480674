
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { AnimatedSpinner } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import {
  getAccessToken, getItemFromSessionStorage,
  makeRequest
} from '../../helpers';
import GenericClaimPage from './GenericClaimPage';


const GenericClaimPageWrapper = props => {
  const [resources, setResources] = useState(null);

  const { match } = props;
  const insuranceType = match.params.type || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const { cognitoUser } = useContext(CognitoContext);
  const resource = 'document_name';

  useEffect(() => {
    if (!resources) {
      let authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      makeRequest({
        url: insuranceType === 'car' ? `${basePath}/motor/resources/${resource}` : `${basePath}/${insuranceType}/resources/${resource}`,
        authToken: authToken,
      })
        .then(response => {
          if (response.length > 0) {
            const claimDocument = response.filter(resource => resource.alias === "Claim Document");
            setResources({ [resource]: claimDocument });
          } else {
            setResources({ [resource]: response });
          }
        });
    }
  }, [resources]);

  if (!resources)
    return (
      <div>
        <AnimatedSpinner />
      </div>
    );

  return <GenericClaimPage {...props} resources={resources} />;
};

GenericClaimPageWrapper.propTypes = {
  match: PropTypes.object,
};

export default GenericClaimPageWrapper;
