import { ButtonGroup, DatePicker,InputField } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { isFieldError } from '../../../helpers';
import ResourceDropdown from '../../ResourceDropdown';
import { className } from './YourHomeForm';

const resetFields = [
  'jointlyOwnedDetailsTitle',
  'jointlyOwnedDetailsFirstName',
  'jointlyOwnedDetailsLastName',
  'jointlyOwnedDetailsDateOfBirth',
];

const JointlyOwnedDetails = props => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    setFieldTouched,
  } = props;

  const setField = (fieldName, value) => {
    setFieldValue(fieldName, value);

    if (fieldName === 'propertyJointlyOwned') clearPropertyJoinedFields(value);
  };

  const clearPropertyJoinedFields = value => {
    if (value === 'false') {
      resetFields.forEach(field => {
        field === 'jointlyOwnedDetailsDateOfBirth'
          ? setFieldValue(field, undefined)
          : setFieldValue(field, '');
        setFieldTouched(field, false);
      });
    }
  };

  return (
    <>
      <div
        className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
      >
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin`}
        >
          <label
            htmlFor="propertyJointlyOwned"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Is the property jointly owned?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                name="propertyJointlyOwned"
                onClick={value => setField('propertyJointlyOwned', value)}
                options={[
                  { label: 'No', value: 'false' },
                  { label: 'Yes', value: 'true' },
                ]}
                touched={touched.propertyJointlyOwned}
                selected={values.propertyJointlyOwned}
                onBlur={handleBlur}
                error={isFieldError('propertyJointlyOwned', touched, errors)}
                errorMessage={errors.propertyJointlyOwned}
              />
            </div>
          </span>
        </div>
      </div>
      {values.propertyJointlyOwned === 'true' ? (
        <div className={`${className}`}>
          <div
            className={`${commonFormStylesIdentifier}__dynamicFieldsContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
          >
            <h2 className={`${className}__jointOwnerHeader`}>
              Please complete the following fields for the joint owner
            </h2>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="jointlyOwnedDetailsTitle"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Title
              </label>
              <span className={`${className}__input`}>
                <ResourceDropdown
                  error={isFieldError(
                    'jointlyOwnedDetailsTitle',
                    touched,
                    errors
                  )}
                  errorMessage={errors.jointlyOwnedDetailsTitle}
                  placeholder="Type here"
                  name="jointlyOwnedDetailsTitle"
                  value={values.jointlyOwnedDetailsTitle}
                  onChange={value => {
                    setFieldValue('jointlyOwnedDetailsTitle', value);
                  }}
                  onBlur={handleBlur}
                  values={props.resources.title}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="jointlyOwnedDetailsFirstName"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                First Name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError(
                    'jointlyOwnedDetailsFirstName',
                    touched,
                    errors
                  )}
                  errorMessage={errors.jointlyOwnedDetailsFirstName}
                  placeholder="Type here"
                  name="jointlyOwnedDetailsFirstName"
                  value={values.jointlyOwnedDetailsFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="jointlyOwnedDetailsLastName"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Last Name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError(
                    'jointlyOwnedDetailsLastName',
                    touched,
                    errors
                  )}
                  errorMessage={errors.jointlyOwnedDetailsLastName}
                  placeholder="Type here"
                  name="jointlyOwnedDetailsLastName"
                  value={values.jointlyOwnedDetailsLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </span>
            </div>
            <div
              className={`${commonFormStylesIdentifier}__fieldContainer ${className}__lastRepeatingFieldContainer`}
            >
              <label
                htmlFor="jointlyOwnedDetailsDateOfBirth"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Date of Birth
              </label>
              <span className={`${className}__input`}>
                <DatePicker
                  textType
                  error={errors.jointlyOwnedDetailsDateOfBirth}
                  name="jointlyOwnedDetailsDateOfBirth"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="DD/MM/YYYY"
                  touched={touched.jointlyOwnedDetailsDateOfBirth}
                  value={values.jointlyOwnedDetailsDateOfBirth}
                />
              </span>
            </div>
          </div>
        </div>
      ) : (
          <></>
        )}
    </>
  );
};

JointlyOwnedDetails.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  resources: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default JointlyOwnedDetails;
