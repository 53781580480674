/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { startOfDay } from 'date-fns';
import * as yup from "yup";

import { createDateTransformer } from '../../helpers/DateHelper';
import { POLICY_CHANGE_TYPE } from './MotorChangeCar';

const dateTransformer = createDateTransformer({ nullable: true });

export const MotorChangeCarYupSchema = yup.object().shape({
  temporaryOrPermanent: yup
    .string()
    .required('Please select'),
  carReg: yup
    .string()
    .required('Car registration is required'),
  vehicleValue: yup
    .string()
    .required('Vehicle value is required'),
  changeFromDate: yup.date()
    .transform(dateTransformer)
    .nullable()
    .required('Please select your change from date')
    .min(startOfDay(new Date()), 'Start date needs to be today or after.'),
  timeOfChangeFrom: yup
    .string()
    .required('Time from is required'),
  changeToDate: yup
    .date()
    .nullable()
    .when(
      'temporaryOrPermanent',
      (temporaryOrPermanent) =>
        temporaryOrPermanent === POLICY_CHANGE_TYPE.TEMPORARY
          ? yup.date().test('changeToDateFunction', 'Date of change to must be same or after Date of change from',
            function (value) {
              return this.parent.changeFromDate <= value;
            }
          ).required('Date of change to is required')
          : yup.date().nullable(),
    ),
  timeOfChangeTo: yup
    .string()
    .nullable()
    .when(
      'temporaryOrPermanent',
      (temporaryOrPermanent) =>
        temporaryOrPermanent === POLICY_CHANGE_TYPE.TEMPORARY
          ? yup.string().required('Time of change to is required')
          : yup.string().nullable(),
    ),
  assumptions: yup
    .boolean()
    .nullable()
    .when(
      'temporaryOrPermanent',
      (temporaryOrPermanent) =>
        temporaryOrPermanent === POLICY_CHANGE_TYPE.PERMANENT
          ? yup.boolean().oneOf([true], 'You must accept the assumptions')
          : yup.boolean().nullable(),
    )
});
