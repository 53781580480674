import './CancelPolicy.scss';

import {
  Button, DatePicker, DesktopCard,
  InputTextArea, Title,
} from '@arachas/core/lib';
import { startOfDay } from 'date-fns';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier, commonPageStylesIdentifier } from '../../constants/styleConstants';
import { getAccessToken, isFieldError } from '../../helpers';
import { routes } from '../../routes';
import { MobileBackButton } from '../MobileBackButton';
import { cancelPolicy } from './cancelPolicyService';

const className = 'c-CancelPolicy';

const CancelPolicyForm = ({ handleChange, values, errors, touched, handleBlur, isValid, insuranceType, policyNumber, history }) => {
  const { cognitoUser } = useContext(CognitoContext);
  const accessToken = getAccessToken(cognitoUser);

  const handleSubmitButtonClick = async () => {
    cancelPolicy(insuranceType, { ...values, policy_reference: policyNumber }, accessToken).then(() => {
      history.push({
        pathname: `${routes.mtaThankYouPage.url}${insuranceType}`,
        state: {
          pageType: 'cancelCar'
        }
      });
    });
  };

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${className}__titleContainer`}>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <Title align="left" type="h1" weight="weight500" variant="greyBrown">
            Cancel Policy
          </Title>
          <div className={`${className}__subHeading`}>
            <Title type="h4" align="left" variant="black">
              Please fill out the form below.
            </Title>
          </div>
        </div>
      </div>

      <Form>
        <DesktopCard>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="reason"
              className={`${commonFormStylesIdentifier}__fieldLabel`}>Reason
            </label>
            <InputTextArea
              error={isFieldError('reason', touched, errors)}
              errorMessage={errors.reason}
              name="reason"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Type Here"
              touched={touched.reason}
              values={values.reason}
            />
          </div>
          <div className={`${className}__fieldSpacing`}>
            <label
              htmlFor="date"
              className={`${commonFormStylesIdentifier}__fieldLabel`}>Policy End Date
            </label>
            <DatePicker
              error={errors.date}
              name="date"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="DD/MM/YYYY"
              touched={touched.date}
              values={values.date}
            />
          </div>
        </DesktopCard>

        <div className={`${className}__button`}>
          <Button
            id="CancelQuotePage__submitButton"
            fluid={true}
            quaternary
            disabled={!isValid}
            onClick={handleSubmitButtonClick}
            type="button"
          >
            CONFIRM
          </Button>
        </div>
      </Form>
    </div>
  );
};

const CancelPolicy = withFormik({
  mapPropsToValues() {
    return {};
  },
  validationSchema: yup.object().shape({
    reason: yup.string().required('Enter reason'),
    date: yup.date()
      .min(startOfDay(new Date()), 'Start date needs to be today or after.').required('Enter date'),
  }),
  displayName: 'CancelPolicyForm',
})(CancelPolicyForm);


CancelPolicyForm.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  history: PropTypes.object,
  handleBlur: PropTypes.func,
  isValid: PropTypes.func,
  insuranceType: PropTypes.string,
  policyNumber: PropTypes.string,
};

export default CancelPolicy;
