const oneToTenValues = [
  {
    displayName: '1',
    dropdownValue: 1
  },
  {
    displayName: '2',
    dropdownValue: 2,
  },
  {
    displayName: '3',
    dropdownValue: 3,
  },
  {
    displayName: '4',
    dropdownValue: 4,
  },
  {
    displayName: '5',
    dropdownValue: 5,
  },
  {
    displayName: '6',
    dropdownValue: 6,
  },
  {
    displayName: '7',
    dropdownValue: 7,
  },
  {
    displayName: '8',
    dropdownValue: 8,
  },
  {
    displayName: '9',
    dropdownValue: 9,
  },
  {
    displayName: '10',
    dropdownValue: 10,
  },
];

export default oneToTenValues;
