"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CarIcon = function CarIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? _colors.fontColorSecondary : _ref$color,
      _ref$solid = _ref.solid,
      solid = _ref$solid === void 0 ? true : _ref$solid;
  var style = solid ? {
    fill: color,
    enableBackground: 'new 0 0 63 63'
  } : {
    fill: 'none',
    stroke: color,
    strokeWidth: '1.5px'
  };
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 18 16",
    style: style
  }, _react2.default.createElement("g", {
    id: "prefix__directions_car-24px",
    transform: "translate(-3 -5)"
  }, _react2.default.createElement("path", {
    id: "prefix__Path_1596",
    d: "M5 17h14v-5H5zm11.5-4a1.5 1.5 0 1 1-1.5 1.5 1.538 1.538 0 0 1 1.5-1.5zm-9 0A1.5 1.5 0 1 1 6 14.5 1.538 1.538 0 0 1 7.5 13z",
    "data-name": "Path 1596",
    style: {
      opacity: .3
    }
  }), _react2.default.createElement("path", {
    id: "prefix__Path_1597",
    d: "M18.9 6a1.5 1.5 0 0 0-1.4-1h-11a1.412 1.412 0 0 0-1.4 1L3 12v8a1.075 1.075 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h12v1a1.075 1.075 0 0 0 1 1h1a1.075 1.075 0 0 0 1-1v-8zM6.8 7h10.3l1.1 3.1H5.8zM19 17H5v-5h14z",
    "data-name": "Path 1597"
  }), _react2.default.createElement("circle", {
    id: "prefix__Ellipse_26",
    cx: "1.5",
    cy: "1.5",
    r: "1.5",
    "data-name": "Ellipse 26",
    transform: "translate(6 13)"
  }), _react2.default.createElement("circle", {
    id: "prefix__Ellipse_27",
    cx: "1.5",
    cy: "1.5",
    r: "1.5",
    "data-name": "Ellipse 27",
    transform: "translate(15 13)"
  })));
};

exports.default = CarIcon;