"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TickIcon = function TickIcon(props) {
  var color = props.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "21.818",
    height: "18",
    viewBox: "0 0 21.818 18"
  }, _react2.default.createElement("path", {
    id: "prefix__done_outline-24px",
    d: "M18 4.645l1.273 1.273L7.636 17.554l-5.091-5.091 1.273-1.273 3.818 3.818L18 4.645M18 2.1L7.636 12.373 3.818 8.554 0 12.463 7.636 20.1 21.818 5.918z",
    transform: "translate(0 -2.1)",
    style: {
      fill: color ? color : "#600060"
    }
  }));
};

TickIcon.defaultProps = {
  color: _colors.fontColorSecondary
};
TickIcon.propTypes = {
  color: _propTypes2.default.string
};
exports.default = TickIcon;