import { Dropdown } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { mapDropdownOptions } from '../../helpers';


const ResourceDropdown = (props) => {
  const dropdownValues = props.values.map && props.values.map(option => (
    { dropdownValue: option.id, displayName: option.description }
  ));

  const findValueById = (id) => props.values.find(option => option.id === id);

  const dropdownProps = {
    ...props,
    onChange: (value) => {
      props.onChange(findValueById(value.target.value), props.name, props.index);
    },
    value: props.value ? props.value.id : ''
  };

  return (
    (<Dropdown {...dropdownProps} >
      <option value="">Select here</option>
      {mapDropdownOptions(dropdownValues)}
    </Dropdown>)
  );
};

ResourceDropdown.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  values: PropTypes.array
};

ResourceDropdown.defaultProps = {
  value: [{ id: 0, alias: "" }],
};

export default ResourceDropdown;
