/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './DocumentsLandingPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  DesktopCard,
} from '@arachas/core/lib';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, iconsAndTitles } from '../../constants';
import { dustyGrayColor } from '../../constants/colors';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';

const className = 'c-DocumentsLandingPage';

const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

const DocumentsLandingPage = (props) => {

  const { match, history,
    uploadedDocuments = {}
  } = props;
  const insuranceType = match.params.type || '';

  const customPageTitle = {
    [CAR_INSURANCE_TYPE]: "Car Documents",
    [HOME_INSURANCE_TYPE]: "Home Documents",
    '': ''
  };

  const renderCustomClass = () => {
    return insuranceType === DEVICE_INSURANCE_TYPE ?
      `${className}__deviceIcon` :
      `${className}__icon`;
  };

  const hasUploadedDocuments = () => {
    if (uploadedDocuments === {} || uploadedDocuments.error) return false;
    return uploadedDocuments && uploadedDocuments.document_details.length > 0;
  };

  return (
    <>
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__customTitleText`}>
          {customPageTitle[insuranceType]}
        </div>
      </div>
      <DesktopCard>
        <div className={className}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <MobileBackButton history={history} />
          </div>
          {insuranceType && iconsAndTitles[insuranceType] &&
            <div className={`${className}__iconContainer`}>
              <div className={renderCustomClass()}>
                {iconsAndTitles[insuranceType].icon}
              </div>
            </div>
          }
          <div className={`${className}__buttonContainer`}>

            <div className={`${className}__buttonSpacing`}>
              <Link to={`${routes.policyLinks.url}${insuranceType}`}>
                <ButtonWithIcons
                  id={'DocumentsLandingPage__policyDocumentsButton'}
                  IconRight={RightArrowIcon}
                >
                  Policy documents
                </ButtonWithIcons>
              </Link>
            </div>

            <div className={`${className}__buttonSpacing`}>
              <Link to={`${routes.viewUploadedDocumentsPage.url}${insuranceType}`}>
                <ButtonWithIcons
                  id={'DocumentsLandingPage_uploadedDocumentsButton'}
                  IconRight={RightArrowIcon}
                  disabled={!hasUploadedDocuments()}
                >
                  Uploaded documents
                </ButtonWithIcons>
              </Link>
            </div>

            <div className={`${className}__buttonSpacing`}>
              <Link to={`${routes.uploadDocumentsPage.url}${insuranceType}`}>
                <ButtonWithIcons
                  id={'DocumentsLandingPage_uploadDocumentsButton'}
                  IconRight={RightArrowIcon}

                >
                  Upload documents
                </ButtonWithIcons>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </>
  );
};

DocumentsLandingPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  uploadedDocuments: PropTypes.object
};

export default withRouter(DocumentsLandingPage);