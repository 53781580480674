/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './WebsiteLoadingPage.scss';

import { AnimatedSpinner } from "@arachas/core/lib";
import React from 'react';

import BrandLogo from '../../components/BrandLogo';

const WebsiteLoadingPage = () => {

  const className = 'c-WebsiteLoadingPage';

  return (<div className={`${className}__pageContainer`}>
    <div className={`${className}__iconAndSpinnerContainer`}>
      <div className={`${className}__logoContainer`}>
        <BrandLogo theme="dark" />
      </div>
      <AnimatedSpinner />
    </div>
  </div>);
};

export default WebsiteLoadingPage;
