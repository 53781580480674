"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GlobeEuropeIcon = function GlobeEuropeIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 75 75",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    }
  }, _react2.default.createElement("path", {
    id: "prefix__globe-europe-solid",
    d: "M37.5 8A37.5 37.5 0 1 0 75 45.5 37.494 37.494 0 0 0 37.5 8zm30.242 37.5a30.11 30.11 0 0 1-1.633 9.738h-3.07a2.389 2.389 0 0 1-1.724-.726l-4.839-4.929a1.8 1.8 0 0 1 .015-2.525l1.89-1.89v-1.316a1.717 1.717 0 0 0-.5-1.21l-1.421-1.421a1.717 1.717 0 0 0-1.21-.5h-2.417a1.711 1.711 0 0 1-1.21-2.918l1.421-1.421a1.717 1.717 0 0 1 1.21-.5h4.839a1.717 1.717 0 0 0 1.707-1.708v-1.421a1.717 1.717 0 0 0-1.709-1.709h-5.548a2.426 2.426 0 0 0-2.419 2.419v.68a2.426 2.426 0 0 1-1.648 2.3L44.7 38.03a1.2 1.2 0 0 0-.832 1.149v.333a1.213 1.213 0 0 1-1.21 1.21h-2.421a1.213 1.213 0 0 1-1.21-1.21 1.213 1.213 0 0 0-1.21-1.21h-.469a1.233 1.233 0 0 0-1.089.665l-1.42 2.833a2.408 2.408 0 0 1-2.162 1.331h-3.342a2.426 2.426 0 0 1-2.419-2.419v-3.831a2.428 2.428 0 0 1 .711-1.709l3.039-3.039a3.741 3.741 0 0 0 1.089-2.646 1.217 1.217 0 0 1 .832-1.149l6.048-2.011a1.759 1.759 0 0 0 .665-.408l4.052-4.052a1.711 1.711 0 0 0-1.21-2.918h-3.13l-2.419 2.419v1.21a1.213 1.213 0 0 1-1.21 1.21h-2.419a1.213 1.213 0 0 1-1.21-1.21v-3.026a1.217 1.217 0 0 1 .484-.968l4.37-3.281c.287-.015.575-.045.862-.045A30.279 30.279 0 0 1 67.742 45.5zm-48.07-16.164a1.717 1.717 0 0 1 .5-1.21l3.841-3.841a1.711 1.711 0 0 1 2.918 1.21v2.419a1.717 1.717 0 0 1-.5 1.21l-1.421 1.421a1.717 1.717 0 0 1-1.21.5h-2.419a1.717 1.717 0 0 1-1.709-1.709zm19.355 46.33v-1.073a2.426 2.426 0 0 0-2.419-2.419h-3.055a10.468 10.468 0 0 1-5.353-1.785l-3.357-2.525a6.868 6.868 0 0 1-2.752-5.5v-3.618a6.871 6.871 0 0 1 3.342-5.9l6.487-3.886a6.971 6.971 0 0 1 3.538-.983h4.718a6.9 6.9 0 0 1 4.476 1.648l6.532 5.61h2.767a4.83 4.83 0 0 1 3.417 1.421l2.616 2.616a2.77 2.77 0 0 0 1.951.8h2.026a30.188 30.188 0 0 1-24.934 15.59z",
    transform: "translate(0 -8)"
  }));
};

exports.default = GlobeEuropeIcon;