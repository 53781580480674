import './AddAdditionalDriverDetails.scss';

import { WideDivider } from '@arachas/core/lib';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { dividerColor } from '../../constants/colors';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { checkRepeatingFieldError, getRepeatingFieldErrorMessage } from '../../helpers';
import { getOccupationOptions } from '../../mocks/MainOccupationValues';
import { BaseDrivingDetails } from '../Forms/AboutYouForm/AboutYouFormSections/DrivingDetails/DrivingDetails';
import { BaseEmploymentDetails } from '../Forms/AboutYouForm/AboutYouFormSections/EmploymentDetails/EmploymentDetails';
import { BasePersonalDetails } from '../Forms/AboutYouForm/AboutYouFormSections/PersonalDetails/PersonalDetails';
import { PenaltyPoints } from '../Forms/YourCarCoverForm/YouCarCoverFormSections';
import {
  emptyAdditionalDriver,
  untouchedAdditionalDriver,
} from '../Forms/YourCarCoverForm/YouCarCoverFormSections/AdditionalDrivers/AdditionalDriversInitialValues';
import MotorClaims from '../MotorClaims';
import ResourceButtonGroup from '../ResourceButtonGroup';
import ResourceDropdown from '../ResourceDropdown';
import PolicyChangeDate from './PolicyChangeDate';

const AddAdditionalDriver = (props) => {

  const className = 'c-AdditionalDriverDetails';

  const {
    setFieldValue,
    values,
    errors,
    setFieldTouched,
    touched,
    handleBlur,
    setTouched,
    baseFieldName = 'additionalDrivers',
    fieldFilledBefore,
    resources,
    includeClaims,
    includeDateChange
  } = props;
  const mainOccupationOptions = getOccupationOptions();

  const handleCustomBlur = (
    fieldName,
    e,
  ) => {
    setFieldValue(fieldName, values[fieldName]);
    handleBlur(e);
  };


  useEffect(() => {
    let initialValues = values[baseFieldName] && values[baseFieldName].length > 0 ? values[baseFieldName] : [{ ...emptyAdditionalDriver }];
    setFieldValue(baseFieldName, initialValues);
    setFieldTouched(baseFieldName, [{ ...untouchedAdditionalDriver }]);
  }, [setFieldValue, setFieldTouched]);

  const getEntryValue = (allEntries = [], index = 0, fieldName = '') => {
    return allEntries.length > index && fieldName !== '' ? allEntries[index][fieldName] : '';
  };

  const getAdditionalDriverFormAttribute = (attribute, index) => {
    return attribute && attribute[baseFieldName] && attribute[baseFieldName][index] ? attribute[baseFieldName][index] : {};
  };

  const getAdditionalDriverProps = (index) => {
    return {
      ...props,
      values: getAdditionalDriverFormAttribute(values, index),
      touched: getAdditionalDriverFormAttribute(touched, index),
      errors: getAdditionalDriverFormAttribute(errors, index),
    };
  };

  const handleChangeResource = (value, name) => {
    setFieldValue(name, value);
  };

  const getUseOfOtherCarResources = () => {
    return [
      {
        ...resources.full_time_use_of_other_car.find((who) => who.alias === 'false'),
      },
      {
        ...resources.full_time_use_of_other_car.find((who) => who.alias === 'true'),
      },
    ];
  };

  const renderUseOfOtherCar = (index) => {
    return (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="useOfOtherCar" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Does this driver have full time use of any other car?
        </label>

        <ResourceButtonGroup
          error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'useOfOtherCar', index)}
          errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'useOfOtherCar', index)}
          onBlur={handleBlur}
          name="useOfOtherCar"
          selected={values.useOfOtherCar}
          onClick={(obj) => {
            setFieldValue(`${baseFieldName}[${index}].useOfOtherCar`, obj);
            setFieldTouched(`${baseFieldName}[${index}].useOfOtherCar`, true);
          }}
          options={getUseOfOtherCarResources()}
        />
      </div>
    );
  };


  const getRelationshipToProposerField = (index) => {
    const relationshipFieldName = `${baseFieldName}[${index}].relationshipToProposer`;
    return (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={relationshipFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Relationship to you
        </label>
        <span className={`${className}__input`}>
          <ResourceDropdown
            key={`relationshipToProposer_${index}`}
            name={relationshipFieldName}
            placeholder="Select here"
            error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'relationshipToProposer', index)}
            errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'relationshipToProposer', index)}
            value={getEntryValue(values[baseFieldName], index, 'relationshipToProposer')}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={resources.relationship}
          />
        </span>
      </div>
    );
  };

  const getAdditionalDriverComponent = (arrayHelpers, index, additionalDriversCount) => {
    return (
      <>
        <div className={`${commonFormStylesIdentifier}__itemCountContainer ${className}__title`}>
          <div className={`${commonFormStylesIdentifier}__itemCountLabel`}>
            About driver
          </div>
        </div>
        <div>
          <BasePersonalDetails
            formik={getAdditionalDriverProps(index)}
            fieldNamePrefix={`${baseFieldName}[${index}].`}
            resources={resources}
            handleChangeResource={handleChangeResource}
          />
          <BaseEmploymentDetails
            formik={getAdditionalDriverProps(index)}
            occupationOptions={mainOccupationOptions}
            insuranceType={CAR_INSURANCE_TYPE}
            fieldNamePrefix={`${baseFieldName}[${index}].`}
            isSubFormComponent={true}
            resources={resources}
            handleChangeResource={handleChangeResource}
          />
          <BaseDrivingDetails
            formik={getAdditionalDriverProps(index)}
            fieldNamePrefix={`${baseFieldName}[${index}].`}
            isSubFormComponent={true}
            resources={resources}
            insuranceType={CAR_INSURANCE_TYPE}
            handleChangeResource={handleChangeResource}
          />
          {getRelationshipToProposerField(index)}
          <PenaltyPoints
            {...getAdditionalDriverProps(index)}
            fieldNamePrefix={`${baseFieldName}[${index}].`}
            onBlur={handleCustomBlur}
            driverTypePrefix={`additionalDriver`}
            repeatingFieldsCustomClass={`${className}__penaltyPointsRepeatingContainer`}
            fieldFilledBefore={fieldFilledBefore}
          />
          {renderUseOfOtherCar(index)}
          {index < additionalDriversCount - 1 &&
            <div className={`${className}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
          }
        </div>
      </>);
  };

  const getClaimsComponent = () => {
    return (
      <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <div className={`${className}__motorClaimsLabelContainer`}>
            <label
              className={`${commonFormStylesIdentifier}__fieldLabel`}
              htmlFor="motorClaims"
            >
              How many accidents or claims have you or anyone that may drive the car, had in the last five years?
            </label>
          </div>
          <MotorClaims
            errors={errors}
            fieldName="motorClaims"
            onChange={(e) => setFieldValue('motorClaims', e)}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            touched={touched}
            values={values}
            onBlur={handleCustomBlur}
            resources={resources}
          />
        </div>
      </>
    );
  };

  return (
    <FieldArray
      name="additionalDrivers"
      render={(arrayHelpers) => (
        <div className={className}>
          {getAdditionalDriverComponent(arrayHelpers, 0, 1)}
          {includeClaims && getClaimsComponent()}
          {includeDateChange &&
            <PolicyChangeDate {...props} changeType={values.changeType} />}
        </div>
      )}
    />
  );
};

AddAdditionalDriver.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  setFieldTouched: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setTouched: PropTypes.func,
  baseFieldName: PropTypes.string,
  fieldFilledBefore: PropTypes.bool,
  resources: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  changeType: PropTypes.string,
  includeClaims: PropTypes.bool,
  includeDateChange: PropTypes.bool
};

export default AddAdditionalDriver;
