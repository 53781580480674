/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './WalletLandingPage.scss';

import {
  AnimatedSpinner
} from '@arachas/core/lib';
import React, { useContext } from 'react';

import type { RouterPropsType } from '../../types/commonTypes';
import { UserProfileContext } from '../../UserProfile';
import WalletLandingPage from './WalletLandingPage';

const WalletLandingPageWrapper = ({ history }: RouterPropsType) => {
  const className = 'c-WalletLandingPage';
  const { userProfile, loadingUserProfile } = useContext(UserProfileContext);

  /* Show spinner until user profile is loaded */
  return !loadingUserProfile ? <WalletLandingPage userProfile={userProfile} history={history} /> :
    <div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner />
    </div>;
};

export default WalletLandingPageWrapper;
