/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ResetPassword.scss';

import { Button, InputField, TitleWithUnderLine } from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import { FormikProps, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import BrandLogo from '../../components/BrandLogo';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { routes } from '../../routes';

interface Props {
  email: string;
}

interface Values {
  email: string;
  password: string;
}

const RESET_FRESH = 'ResetPassword/RESET_FRESH';
const RESET_USER_INVALID = 'ResetPassword/RESET_USER_INVALID';
const RESET_CODE_SENDING = 'ResetPassword/RESET_CODE_SENDING';
const RESET_CODE_SENT = 'ResetPassword/RESET_CODE_SEND';

const ResetPasswordForm = ({
  dirty,
  errors,
  handleBlur,
  handleChange,
  isValid,
  setStatus,
  status,
  touched,
  setFieldError,
  values,
  history,
  sendStatus
}: Props & FormikProps<Values>) => {
  const className = 'c-ResetPassword';

  const titleText = 'Reset your password';

  useEffect(() => {
    if (sendStatus)
      setStatus(RESET_CODE_SENT)
  }, [sendStatus])

  const isFieldError = (status: string) => {
    return dirty && touched[status] && errors[status] !== undefined;
  };

  const sendResetCode = async () => {
    setStatus(RESET_CODE_SENDING);
    try {
      await Auth.forgotPassword(values.email);
      setStatus(RESET_CODE_SENT);
    } catch (e) {
      let msg: string = e.message;
      if (e.code === 'InvalidParameterException') {
        setStatus(RESET_USER_INVALID);
        msg =
          'Your account needs to be verified. Please check your email for your verification code before resetting your password.';
      }

      setFieldError('email', msg);
    }
  };

  const resetPassword = async () => {
    Auth.forgotPasswordSubmit(
      values.email,
      values.verificationCode,
      values.newPassword
    )
      .then(() => {
        history.push(routes.loginPage.url);
      })
      .catch((error: Error) => {
        setFieldError('confirmNewPassword', error.message);
      });
  };

  const renderVerifyEmailLink = () => {
    if (status !== RESET_USER_INVALID) return <></>;
    return (
      <Link
        to={{
          pathname: routes.verifyEmail.url,
          state: { email: values.email }
        }}
        className={`${className}__link`}
        data-ga
        id="ResetPassword__verifyEmailLink"
        data-testid="ResetPassword__verifyEmailLink"
      >
        Verify email
      </Link>
    );
  };

  const showPasswordChange = () => {
    return status === RESET_CODE_SENT;
  };

  const renderPasswordChange = () => {
    if (!showPasswordChange()) {
      return (
        <div>
          <div className={`${className}__button`}>
            <Button
              id="ResetPassword__button"
              fluid={true}
              quaternary
              disabled={!isValid}
              onClick={async () => {
                await sendResetCode();
              }}
            >
              {'Send verification code'}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="verificationCode"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Verification Code
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('verificationCode')}
              errorMessage={errors.verificationCode}
              name="verificationCode"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter verification code"
              touched={touched.verificationCode}
              type="text"
              value={values.verificationCode}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="newPassword"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            New Password
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('newPassword')}
              errorMessage={errors.newPassword}
              name="newPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter new password"
              touched={touched.newPassword}
              type="password"
              value={values.newPassword}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="confirmNewPassword"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Confirm New Password
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('confirmNewPassword')}
              errorMessage={errors.confirmNewPassword}
              name="confirmNewPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Confirm new password"
              touched={touched.confirmNewPassword}
              type="password"
              value={values.confirmNewPassword}
            />
          </span>
        </div>
        <div className={`${className}__button`}>
          <Button
            id="ResetPassword__button"
            fluid={true}
            quaternary
            disabled={!isValid}
            onClick={async () => {
              await resetPassword();
            }}
          >
            {'Change password'}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo`}>
            <BrandLogo theme="dark" />
          </div>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <TitleWithUnderLine>
                {titleText}
              </TitleWithUnderLine>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
              {titleText}
            </div>
          </div>

          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="email"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              Email address
            </label>
            <span className={`${className}__input`}>
              <InputField
                error={isFieldError('email')}
                errorMessage={errors.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter email address"
                touched={touched.email}
                type="email"
                value={values.email}
              />
            </span>
          </div>
          {renderPasswordChange()}
          {renderVerifyEmailLink()}
        </div>
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object
};

const ResetPassword = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    return {
      email: '',
      verificationCode: '',
      newPassword: '',
      confirmNewPassword: '',
      sendStatus: false
    };
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  mapPropsToStatus(): string {
    return RESET_FRESH;
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    verificationCode: yup.string().when('showPasswordChange', {
      is: true,
      then: yup.string().required()
    }),
    newPassword: yup.string().when('showPasswordChange', {
      is: true,
      then: yup
        .string()
        .required('Please enter a new password')
        .min(8, 'Requires a minimum of 8 characters')
    }),
    confirmNewPassword: yup.string().when('showPasswordChange', {
      is: true,
      then: yup
        .string()
        .required('Please confirm new password')
        .min(8, 'Requires a minimum of 8 characters')
        .test('passwords-match', 'Passwords must match', function (
          value: string
        ): boolean {
          return this.parent.newPassword === value;
        })
    })
  }),
  displayName: 'ResetPasswordForm'
})(ResetPasswordForm);

export default ResetPassword;
