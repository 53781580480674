/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from 'yup';

import { isValidResourceValue } from '../../../helpers/FieldErrorHelper';

let SERIAL_NUMBER_ERROR = 'Serial number has 15 characters';
export const yourDeviceFormYupSchema = {
  network: yup.object().test('network', 'Field is required', isValidResourceValue).required('Please select'),
  serialNumber: yup.string()
    .min(15, SERIAL_NUMBER_ERROR)
    .max(15, SERIAL_NUMBER_ERROR)
    .required('Serial number is required'),
  deviceType: yup.string().required('Required field'),
  deviceMake: yup.string().required('Required field'),
  deviceModel: yup.string().required('Required field'),
};

export default yourDeviceFormYupSchema;
