/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MtaChangeCarThankYou.scss';

import { Button, DesktopCard, Title } from '@arachas/core/lib';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { backToWallet } from '../../constants/buttonLabelConstants';
import { routes } from '../../routes';
import UserContext from '../../UserContext';

const className = 'c-MtaChangeCarThankYou';

const MtaChangeCarThankYou = () => {
  const { getUserName } = useContext(UserContext);


  const getTitleText = () => {
    const name = getUserName();
    return name ? `Thanks ${name},` : 'Thanks!';
  };

  return (
    <div>
      <DesktopCard>
        <div className={className}>
          <div className={`${className}__cancelPolicyPageTextContainer`}>
            <div className={`${className}__primaryTextContainerOne`}>
              <Title type="h1" weight="500">
                <span id="PaymentsThankYouPage__thankYouText">{getTitleText()}</span>
              </Title>
            </div>
            <div className={`${className}__primaryTextContainerTwo`}>
              If you have submitted a request to temporarily change your vehicle.
              We are unable to process this immediately online but please call us on  01 486 9775 and one of our agents will assist with processing your change.
          </div>

            <div className={`${className}__primaryTextContainerThree`}>
              Otherwise, if you have submitted a permanent change to your vehicle we will be in touch shortly to confirm your updated cover and outline any change in premium if applicable.
              Please note requests for changes must be confirmed by us before cover is amended.
            </div>

          </div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.Wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToWallet}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </div>
  );
};

export default MtaChangeCarThankYou;
