import '../../../styles/commonPageStyles.scss';
import '../../../styles/commonFormStyles.scss';
import '../SummaryPage.scss';

import { HomeIcon, TitleWithUnderLine } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import TagManager from 'react-gtm-module';

import { MobileBackButton } from '../../../components/MobileBackButton';
import ProgressBar from '../../../components/ProgressBar';
import QuoteDisplay from '../../../components/QuoteDisplay';
import {
  commonPageStylesIdentifier
} from '../../../constants';
import { primaryPurple } from '../../../constants/colors';
import { HOME_COVER_TYPE, isLiving } from '../../../constants/home';
import {
  HOME_INSURANCE_TYPE
} from '../../../constants/insuranceTypeConstants';
import { ALLIANZ_HOME_SCHEMA_ID, AVIVA_HOME_SCHEMA_ID, AXA_HOME_SCHEMA_ID, ZURICH_HOME_SCHEMA_ID } from '../../../constants/insurers/homeInsurerIDs';
import { JOURNEY_YOUR_PRODUCT } from '../../../constants/journey';
import {
  getSinglePayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../../helpers';
import { routes } from '../../../routes';
import DataLayers from '../../../scripts/googleAnalyticsDataLayerObjects';
import { Divider, renderLine, Tick } from '../SummaryComponents';
import { getlistOfInsured, getListOfIPID } from './ListOfHomeInsured';


const SummaryPage = (props) => {
  const { history, className } = props;

  const payload = getSinglePayloadObjFromSessionStorage(HOME_INSURANCE_TYPE);
  const values = getValuesObjFromSessionStorage(HOME_INSURANCE_TYPE);
  const coverType = values.coverType.description;

  const checkForInsurerId = {
    [AXA_HOME_SCHEMA_ID]: isLiving(coverType),
    [ZURICH_HOME_SCHEMA_ID]: isLiving(coverType),
    [ALLIANZ_HOME_SCHEMA_ID]: isLiving(coverType),
    [AVIVA_HOME_SCHEMA_ID]: isLiving(coverType),
  };

  const getLivingState = () => checkForInsurerId[payload.scheme_code] ? 'living' : 'notLiving';

  const getPriceFromPayload = () => payload.cover_premium || 0;
  
  TagManager.dataLayer({
    dataLayer: DataLayers.landsOnSummaryPage({
      insuranceType: HOME_INSURANCE_TYPE,
      cover_premium: getPriceFromPayload()
    }),
    dataLayerName: 'dataLayer'
  })

  const onProceedClicked = () => {
    history.push({ pathname: `${routes.Assumptions.url}${HOME_INSURANCE_TYPE}` });
  };

  const renderSpecifiedItems = () => {
    if (!values.specifiedItemsSwitch) return <> </>;
    return (
      <Tick>
        Add Cover for Specified Items <br />
        <div className={`${className}--specifiedItems`}>
          {values.specifiedItems.map((item, index) => {
            return <><span key={index}>{item.item.description} <b>€{item.value}</b></span> <br /></>;
          })}
        </div>
      </Tick>
    );
  };

  const renderUnspecifiedItems = () => {
    if (!values.unspecifiedItemsSwitch) return <> </>;
    return (
      <Tick>
        Add Cover for Unspecified Items <br />
        <div className={`${className}--specifiedItems`}>
          <span>Unspecified Items<b> {values.unspecifiedItems.description}</b></span> <br />
        </div>
      </Tick>
    );
  };

  const shouldRenderBuildingInsurance = () => HOME_INSURANCE_TYPE && values.rebuildCost && (
    <>
      <Divider />
      <p>Your Buildings is insured for <b>€{values.rebuildCost}</b></p>
    </>
  );


  const renderOptionalArea = () => {
    if (!values.unspecifiedItemsSwitch && !values.specifiedItemsSwitch) return <></>;
    const shouldRenderDivider = () => values.specifiedItemsSwitch && values.unspecifiedItemsSwitch && <Divider />;
    return (
      <>
        <div className={`${className}__title`}>{`Optional Extras You’ve Selected`}</div>
        <div className={`${className}__optionalArea`}>
          {renderSpecifiedItems()}
          {shouldRenderDivider()}
          {renderUnspecifiedItems()}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Product Summary</TitleWithUnderLine>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__titleArea`}>
            <div className={`${className}__titleArea__icon ${className}__titleArea__icon--${HOME_INSURANCE_TYPE}`}>
              <HomeIcon color={primaryPurple} />
            </div>
            <h2>{"Home"}</h2>
          </div>
          <div className={`${className}__title`}>Just to remind you</div>
          <div className={`${className}__infoArea`}>
            <p>Your Premium is <b>€{getPriceFromPayload().toFixed(2)}</b></p>
            <Divider />
            <p>Your Cover type is <b>{values.coverType.description}</b></p>
            <Divider />
            <p>Your Eircode is <b>{values.eircode}</b></p>
            <Divider />
            <p>Your Contents is insured for <b>€{values.contentsCoverOf}</b></p>
            {shouldRenderBuildingInsurance()}
          </div>
          <div className={`${className}__title`}>{`What's Insured`}</div>
          <div className={`${className}__coverageArea`}>
            {getlistOfInsured[payload.scheme_code][getLivingState()].map((line, index) =>
              <React.Fragment key={index}>
                {renderLine(line)}
              </React.Fragment>
            )}
            <Tick className={className}>
              <p>
                {payload.scheme_code === AXA_HOME_SCHEMA_ID && values.coverType.description === HOME_COVER_TYPE.OWN_RENT ?
                  <a href={getListOfIPID[payload.scheme_code]['rentOut'].url} target="_blank" rel="noopener noreferrer">
                    {getListOfIPID[payload.scheme_code][getLivingState()].label}
                  </a>
                  :
                  <a href={getListOfIPID[payload.scheme_code][getLivingState()].url} target="_blank" rel="noopener noreferrer">
                    {getListOfIPID[payload.scheme_code][getLivingState()].label}
                  </a>
                }
              </p>
            </Tick>
          </div>
          {renderOptionalArea()}
        </div>
      </div>
      <QuoteDisplay
        price={getPriceFromPayload()}
        sticky
        onClickFunction={onProceedClicked}
        title='Total'
      />
    </div>
  );
};

SummaryPage.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
};

export default SummaryPage;
