/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import './GenericClaimPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  AccordionCard,
  Button,
  DesktopCard,
  PhoneButton
} from '@arachas/core/lib';
import { endOfToday, parse } from 'date-fns';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import { HomeClaimHelpContent, MotorClaimHelpContent, VanClaimHelpContent } from '../../components/ClaimContent';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { primaryPurple } from '../../constants/colors';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { arachasAgent, arachasHomeSubmitClaim } from '../../constants/phoneNumbers';
import { motorInsurancePhoneNumber } from '../../constants/phoneNumbers';
import { getAccessToken, getObjectFromSessionStorage, isFieldError } from '../../helpers';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import {
  postCancelClaim
} from "../../services/cancelClaimMTA";
import GenericClaimForm from './GenericClaimForm';

const className = 'c-GenericClaimPage';

const pageTitle = 'Submit a claim';


const GenericClaimPageForm = (props) => {

  const { handleChange, handleBlur, match,
    history, errors, setTouched,
    touched, values, isValid,
    setFieldValue, setFieldTouched, resources } = props;
  const insuranceType = match.params.type || '';

  const { cognitoUser } = useContext(CognitoContext);
  const accessToken = getAccessToken(cognitoUser);
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);
  const policyNumber = policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;


  const getLabel = {
    [HOME_INSURANCE_TYPE]: 'Whats next?',
    [CAR_INSURANCE_TYPE]: 'What if involved in an accident?',
    [VAN_INSURANCE_TYPE]: 'What if involved in an accident?'
  };

  const getHelpContent = {
    [HOME_INSURANCE_TYPE]: <HomeClaimHelpContent />,
    [CAR_INSURANCE_TYPE]: <MotorClaimHelpContent />,
    [VAN_INSURANCE_TYPE]: <VanClaimHelpContent />
  };

  const phoneButtonOneNumbers = {
    [CAR_INSURANCE_TYPE]: motorInsurancePhoneNumber,
    [HOME_INSURANCE_TYPE]: arachasHomeSubmitClaim,
    [VAN_INSURANCE_TYPE]: arachasAgent
  };

  const handleSubmit = async () => {

    const fileArray = values.docUpload.map(docUpload => {
      if (docUpload.applicationFile[0] && docUpload.applicationFile[0].options) {
        return docUpload.applicationFile[0].options.fileID;
      }
      return null;
    }
    );

    var filteredArray = fileArray.filter(i => i !== null);

    const payload =
    {
      "policy_reference": policyNumber,
      "incident_description": values.descriptionOfIncident,
      "incident_date": values.dateOfIncident,
      "document_ids": filteredArray
    };

    const response = await postCancelClaim(accessToken, insuranceType, payload);
    if (response) {
      history.push({
        pathname: `${routes.mtaThankYouPage.url}${HOME_INSURANCE_TYPE}`,
        state: {
          pageType: 'submitClaim'
        }
      });
    }
    else {
      history.push({
        pathname: `${routes.genericErrorPage.url}${insuranceType}`,
        state: {
          errorType: 'mta'
        }
      });
    }
  };

  const renderForm = () => {
    return insuranceType !== VAN_INSURANCE_TYPE ? (
      <>
        <div className={`${className}__customTitleTextContainer`}>
          <div className={`${className}__titleText`}>
            Fill out the details below to submit <br />
          your claim or call us on the number below.
        </div>
        </div>
        <DesktopCard>
          <div className={`${className}__content`}>
            <GenericClaimForm values={values}
              isFieldError={isFieldError}
              handleBlur={handleBlur}
              errors={errors}
              setTouched={setTouched}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              handleChange={handleChange}
              insuranceType={insuranceType}
              policyNumber={policyNumber}
              accessToken={accessToken}
              resources={resources}
              match={match}
            />
          </div>

        </DesktopCard >
        <div className={`${className}__customButtonContainer ${className}--spacing`}>
          <div className={`${className}__button`}>
            <Button
              id="HomeUpdateSpecifiedItems__nextButton"
              fluid
              disabled={!isValid}
              quaternary
              type="button"
              onClick={handleSubmit}
            >
              SUBMIT CLAIM
          </Button>
          </div>
        </div>
      </>
    ) : (
      <div className={`${className}__customTitleTextContainer`}>
        <div className={`${className}__titleText ${className}__titleText--van`}>
          Call us on the number below to submit
        </div>
      </div>
    )
  }

  return (
    <><div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${className}__titleSubTitleContainer`}>
        <div className={`${className}__titleSubTitle`}>
          {pageTitle}
        </div>
      </div>
      {renderForm()}
      <div className={`${className}__accordions`}>
        <div className={`${className}--spacing`}>
          <AccordionCard
            status="none"
            childContainerClass={`${className}__noPaddingAccordion`}
            customLabelClass={`${className}__accordionLabel`}
            label={getLabel[insuranceType]}
          >
            {getHelpContent[insuranceType]}
          </AccordionCard>
        </div>
      </div>
      <div className={`${className}__phoneButtonContainer`}>
        <br />
        <PhoneButton
          color={primaryPurple}
          phoneNumber={phoneButtonOneNumbers[insuranceType]}
          isWhite
          onClick={() => {
            fbqTrackEvent('Contact')
          }}>Please call our Arachas team
        </PhoneButton>
      </div>
    </div></>
  );
};



const GenericClaimPage = withFormik({
  mapPropsToValues() {
    let initialValues = {
      validationSchema: '',
      dateOfIncident: '',
      docUpload: [
        {
          documentType: {},
          applicationFile: []
        }
      ],
    };
    return initialValues;
  },


  validationSchema: yup.object().shape({
    descriptionOfIncident: yup.string()
      .min(10, 'Please enter in more than 10 characters')
      .max(250, 'Please enter in less than 250 characters')
      .required('Enter Description'),
    dateOfIncident: yup.date()
      .transform((_, originalValue) => {
        if (originalValue.length > 10)
          return;
        else
          return parse(originalValue, new Date());
      })
      .max(endOfToday(new Date()),
        'Claim date needs to be today or before.')
      .required('A valid date is required'),
    docUpload: yup.array().of(
      yup.object().shape({
        documentType: yup.object(),
        applicationFile: yup.array(),
      }))
  }),
  displayName: 'GenericClaimPageForm'
})(GenericClaimPageForm);

GenericClaimPageForm.propTypes = {
  values: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setTouched: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  policyNumber: PropTypes.string,
  isValid: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  resources: PropTypes.object
};

export default withRouter(GenericClaimPage);