// @flow
import { AccordionText, InputField } from "@arachas/core/lib";
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants';
const className = "c-CarRegLookup";

const fields = ["carMake",
  "carModel",
  "carYear",
  "carFuelType",
  "carEngineSize",
  "carVersion"];

const compressedFields = [
  "carMake",
  "carModel"];

const fieldLabels = {
  carMake: "Your car make",
  carModel: "Your car model",
  carYear: "Your car year",
  carFuelType: "Your car fuel type",
  carEngineSize: "Your car engine size",
  carVersion: "Your car version"
};

const fieldsShown =
  ["carYear",
    "carFuelType",
    "carEngineSize",
    "carVersion"];

const CarDetailsInfo = (props: {
  carMake: string,
  carModel: string,
  carYear: string,
  carFuelType: string,
  carEngineSize: string,
  carVersion: string,
  compressedView: any;
  readonly: boolean;
}) => {
  const mappingFields = props.compressedView ? compressedFields : fields;

  const getCarFields = () => {
    return mappingFields.map((field: string, index: number) => {
      return (
        <div key={index}>
          <div htmlFor={`carDetailField-${field}`}
            className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={field} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              {fieldLabels[field]}
            </label>
            <InputField
              type="text"
              name={field}
              value={props[field]}
              readOnly={true}
            />
          </div>
        </div>);
    });
  };

  const showAccordion = () => {
    return (
      <>
        <div className={`${className}__brokenLine ${commonFormStylesIdentifier}__fieldContainer`} >
          <div className={`${className}__readMore ${commonFormStylesIdentifier}__fieldLabel`}>
            <AccordionText
              label="SEE MORE"
              openLabel="SEE LESS"
              inverted
            >
              <div className={`${className}__readMoreContent`}>
                {fieldsShown.map((field: string) => {
                  return (
                    <>
                      <div key={`carDetailField-${field}`}
                        className={`${commonFormStylesIdentifier}__fieldContainer`}>
                        <label htmlFor={field} className={`${commonFormStylesIdentifier}__fieldLabel`}>
                          {fieldLabels[field]}
                        </label>
                        <InputField
                          type="text"
                          name={field}
                          value={props[field]}
                          readOnly={true}
                        />
                      </div>
                    </>);
                })}
              </div>
            </AccordionText>
          </div>
        </div>
      </>);

  };


  return (
    <div className={className}>
      {getCarFields()}
      {(props.readonly) ?
        showAccordion() : <></>}
    </div>
  );
};

export default CarDetailsInfo;
