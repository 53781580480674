import { ButtonGroup } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

const ResourceButtonGroup = (props) => {
  const buttonGroupOptions = props.options.map && props.options.map(option => (
    { value: option.id, label: option.description, icon: option.icon }
  ));

  const findOptionById = (id) => props.options.find(option => option.id === id);

  const buttonGroupProps = {
    ...props,
    options: buttonGroupOptions,
    onClick: (id) => {
      props.onClick(findOptionById(id));
    },
    selected: props.selected ? props.selected.id : ''
  };

  return <ButtonGroup {...buttonGroupProps} />
};

ResourceButtonGroup.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  selected: PropTypes.object,
  onClick: PropTypes.func,
  options: PropTypes.array
};

ResourceButtonGroup.defaultProps = {
  value: [{ id: 0, alias: "" }],
};

export default ResourceButtonGroup;
