export const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png",
  "application/pdf"
];

export const SUPPORTED_FORMATS_SHORT = [
  ".jpeg",
  ".png",
  ".pdf"
];

export const DOCUMENT_UPLOAD = {
  //category: "EDECL", //TODO @amanda to remove if not used
  allowed_formats: SUPPORTED_FORMATS,
  labels: SUPPORTED_FORMATS_SHORT
};
