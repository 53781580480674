/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './ManageMyInsuranceDashboard.scss';

import {
  CarIcon,
  DesktopCard,
  HomeIcon,
  MapIcon,
  MobileIcon,
  ShuttleIcon,
  TitleWithUnderLine
} from '@arachas/core/lib';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, policyTypeToInsuranceTypeLookup } from '../../constants';
import { dustyGrayColor, primaryPurple } from '../../constants/colors';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  removeAllSessionStorage,
} from '../../helpers';
import { routes } from '../../routes';

const listOfManageMyInsuranceButtons = [
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
];

const defaultDisabledButtons = {
  [HOME_INSURANCE_TYPE]: true,
  [CAR_INSURANCE_TYPE]: true,
  [TRAVEL_INSURANCE_TYPE]: true,
  [DEVICE_INSURANCE_TYPE]: true,
  [VAN_INSURANCE_TYPE]: true
};

const getButtonLabelAndIcon = {
  [CAR_INSURANCE_TYPE]: { label: 'Car', icon: CarIcon },
  [HOME_INSURANCE_TYPE]: { label: 'Home', icon: HomeIcon },
  [TRAVEL_INSURANCE_TYPE]: { label: 'Travel', icon: MapIcon },
  [DEVICE_INSURANCE_TYPE]: { label: 'Device', icon: MobileIcon },
  [VAN_INSURANCE_TYPE]: { label: 'Van', icon: ShuttleIcon }
};

const rightArrowIcon = () => <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />;

const colorIconPurple = (Component) => <Component color={primaryPurple} />;

const ManageMyInsuranceDashboard = (props) => {
  const className = 'c-ManageMyInsuranceDashboard';
  const { userProfile = {}, history } = props;
  const [disabledButtons, setDisabledButtons] = useState(defaultDisabledButtons);

  useEffect(() => {
    removeAllSessionStorage();
  }, []);
  const goToPolicyPage = (insuranceType) => {
    history.push({
      pathname: `${routes.viewMultiplePolicies.url}${insuranceType}`
    });
  };

  const updateDisabledButtons = () => {
    const disabledButtons = { ...defaultDisabledButtons };
    if (!userProfile.policies || userProfile.policies.length === 0) {
      return setDisabledButtons(disabledButtons);
    }
    userProfile.policies.forEach((policyDetails) => {
      const insuranceType = policyTypeToInsuranceTypeLookup[policyDetails.policy_type];
      disabledButtons[insuranceType] = false;
    });
    setDisabledButtons(disabledButtons);
  };

  useEffect(() => {
    updateDisabledButtons();
  }, [userProfile]);

  return (
    <DesktopCard>
      <div className={className}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__buttonContainer`}>
          <TitleWithUnderLine>Manage My Insurance</TitleWithUnderLine>

          {listOfManageMyInsuranceButtons.map((insuranceType, index) => {
            return (
              <div key={index} className={`${className}__buttonSpacing`}>
                <ButtonWithIcons
                  id={`ManageMyInsuranceDashboardButton${insuranceType}`}
                  IconLeft={() => colorIconPurple(getButtonLabelAndIcon[insuranceType].icon)}
                  IconRight={rightArrowIcon}
                  onClick={() => { goToPolicyPage(insuranceType); }}
                  disabled={disabledButtons[insuranceType]}
                >
                  {getButtonLabelAndIcon[insuranceType].label}
                </ButtonWithIcons>
              </div>
            );
          })
          }
        </div>
      </div>
    </DesktopCard>
  );
};

ManageMyInsuranceDashboard.propTypes = {
  history: PropTypes.object,
  userProfile: PropTypes.object
}

export default ManageMyInsuranceDashboard;
