/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@arachas/core/lib";
import React from 'react';

const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
  isCardError?: boolean;
}

const DeviceErrorPage = ({ firstName = "" }: Props) => {


  const firstNameMessage = () =>
    firstName ? `We're sorry ${firstName}.` : 'We\'re sorry.';

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      <div className={`${className}__referenceTextContainer`}>
        We have not been able to get an online quote from our insurance partner due to a technical fault.
        Please retry later.
      </div>
    </div>
  );
};

export default DeviceErrorPage;
