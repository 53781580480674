/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from 'yup';

import {
  occupationEmployed, occupationPartTime,
  occupationSelfEmployed,
  occupationUnemployed,
} from '../../../../constants/OccupationTypeConstants';
import { dateOfBirthYup } from '../../../../helpers';
import { isValidDynamicResourceValue, isValidResourceValue } from '../../../../helpers/FieldErrorHelper';
import { COMPANY, PARTNERSHIP, SOLE_TRADER } from '../AboutYouFormSections/ProposerCompanyDetails/proposerConstants';

// Regex to match Irish phone numbers in order of
// 1. International Code if provided (otherwise leading zero)
// 2. Area Code (1-3 numbers)
// 3. Local number (7 numbers usually, can be 6 or 5)
// See https://en.wikipedia.org/wiki/Telephone_numbers_in_the_Republic_of_Ireland for details

// Irish Phone regex   | Int. Code or Lead. Zero   || Area Code           ||  Local Number             |
const phoneNoRegex = /^(?:0|00[ ]?353[ ]?|\+353[ ]?)(?:[1-9]?[\d]{1,3}[ ]?)(?:[\d]{5,7}|[\d]{3} [\d]{4})$/;
const moreThanTwo = 'Please enter more than two characters';
const lessThanFifty = 'Please enter in less than fifty characters';

const shouldHaveOccupation = (status: any) => {
  const occupationStatuses: Array<string> = [occupationEmployed, occupationSelfEmployed, occupationUnemployed, occupationPartTime];
  return occupationStatuses.includes(status);
};

const listOfTradingTypes = [COMPANY, SOLE_TRADER, PARTNERSHIP];
const listofCompanyTypes = [COMPANY, PARTNERSHIP];

export const isOneOfTradingTypes = (type: any) =>
  listOfTradingTypes.includes(type);

export const isOneOfCompanyTypes = (type: any) =>
  listofCompanyTypes.includes(type);


const personalDetailsYupSchema = {
  title: yup.object().test('title', 'Please select title', isValidResourceValue).required('Please select'),
  //First Name and Last Name: can't be empty, less than 50 chars
  firstName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('Last name is required'),
  gender: yup.object().required('Gender is required.'),
  dob: dateOfBirthYup
};

const addressDetailsYupSchema = {
  addressLine1: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .required('Address is required'),
  addressLine2: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty),
  addressLine3: yup
    .string()
    .min(0, moreThanTwo)
    .max(50, lessThanFifty)
    .nullable(),
  addressTown: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty),
  addressCounty: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .required('County is required'),
  addressEircode: yup
    .string()
    .matches(
      // Matches eircode:
      /^[A-Z]{1}[0-9W]{2}[ ]?[0-9A-Z]{4}$/,
      'Please enter a valid Eircode or address',
    )
    .required('Eircode or address is required'),
  isThisYourPostalAddress: yup
    .boolean()
    .test(
      'isThisYourPostalAddress',
      'You must answer is this your postal address',
      function (value: boolean): boolean {
        return value !== null;
      },
    )
    .nullable(true),
  postalAddressLine1: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .required('Address is required'),
    otherwise: yup.string().notRequired(),
  }),
  postalAddressLine2: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty),
    otherwise: yup.string().notRequired(),
  }),
  postalAddressLine3: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty),
    otherwise: yup.string().notRequired(),
  }),
  postalAddressTown: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty),
    otherwise: yup.string().notRequired(),
  }),
  postalAddressCounty: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .required('County is required'),
    otherwise: yup.string().notRequired(),
  }),
  postalAddressEircode: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .matches(
        // Matches eircode:
        /^[A-Z]{1}[0-9W]{2}[ ]?[0-9A-Z]{4}$/,
        'Please enter a valid Eircode or address',
      )
      .required('Eircode or address is required'),
    otherwise: yup.string().notRequired(),
  }),
};

const contactDetailsYupSchema = {
  phoneNo: yup
    .string()
    .matches(phoneNoRegex, 'Must be a valid Irish phone number')
    .required('Phone number is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .max(50, '50 characters limit')
    .required('We will use your email address to send quote details to you. A valid email address is required to purchase the policy.'),
};

const employmentDetailsYupSchema = {
  employmentStatus: yup.object().test('employmentStatus', 'Please select employment status', isValidResourceValue).required('Please select employment status'),
  mainOccupation: yup
    .object().nullable()
    .when(
      'employmentStatus',
      (employmentStatus: any) =>
        employmentStatus && shouldHaveOccupation(employmentStatus.alias)
          ? yup.object().test('mainOccupation', 'Occupation is required', isValidResourceValue).required('Occupation is required')
          : yup.object().nullable(),
    ),
};

const drivingDetailsYupSchema = {
  driversLicenseType: yup.object().test('driversLicenseType', 'Drivers licence type is required', isValidResourceValue).required('Drivers licence type is required'),
  licenceCountry: yup.object().test('licenceCountry', 'Drivers licence country is required', isValidDynamicResourceValue),
  yearsHeld: yup
    .object()
    .test('yearsHeld', 'Years Held is required', isValidResourceValue)
};

const boiCustomerAndPromoYupSchema = {
  hasMortgage: yup
    .boolean()
    .oneOf([false, true])
    .required('Do you have mortgage answer is required'),
  mortgageApplicationNumber: yup.string().when('hasMortgage', {
    is: true,
    then: yup
      .string()
      .matches(/^[0-9]{8}$/, 'Please enter a valid Mortgage Application Number')
      .required('Mortgage Application Number is required'),
  }),
};

const insuranceDetailsYupSchema = {
  coverAbroad: yup
    .string()
    .oneOf(['yes', 'no'])
    .required('Please provide an answer.'),
  provider: yup.string().when('coverAbroad', {
    is: 'yes',
    then: yup
      .string()
      .required('Private medical insurance provider is required'),
    otherwise: yup.string().notRequired(),
  }),
};

const traderDetails = {
  traderOccupation: yup
    .object().test('traderOccupation',
      'Please Select', isValidResourceValue)
    .required("Please Select"),
  vatRegistered: yup
    .boolean()
    .oneOf([false, true])
    .required('Please Select'),
  // yearsOfResidency: yup
  //   .object().test('yearsOfResidency',
  //     'Please Select', isValidResourceValue)
  //   .required('Please Select')
};


const proposerCompanyDetails = {
  proposerType: yup.object().test('proposerType',
    'Please select proposer type', isValidResourceValue)
    .required('Please select proposer type'),
  tradingAs: yup
    .object().nullable()
    .when(
      'proposerType',
      (proposerType: any) =>
        proposerType && isOneOfTradingTypes(proposerType.alias)
          ? yup.string().required('Trading type is required')
          : yup.string().nullable(),
    ),
  companyName: yup
    .object().nullable()
    .when(
      'proposerType',
      (proposerType: any) =>
        proposerType && isOneOfCompanyTypes(proposerType.alias)
          ? yup.string().required('Company name is required')
          : yup.string().nullable(),
    ),
};

export const aboutYouFormYupSchema = {
  ...personalDetailsYupSchema,
  ...addressDetailsYupSchema,
  ...contactDetailsYupSchema,
  ...employmentDetailsYupSchema,
  ...drivingDetailsYupSchema,
  ...insuranceDetailsYupSchema,
  ...boiCustomerAndPromoYupSchema,
  ...proposerCompanyDetails,
  ...traderDetails
};
