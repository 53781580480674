// @flow
import './MarketingConsentForm.scss';

import { Button, SimpleSwitch, TitleWithUnderLine } from "@arachas/core/lib";
import { Form, FormikProps, withFormik } from "formik";
import React, {
  useEffect
} from 'react';
import type { Location } from 'react-router-dom';
import * as yup from "yup";

import { MobileBackButton } from '../../components/MobileBackButton';
import { marketingPhoneNumber } from '../../constants/phoneNumbers';
import { DIRECT_DEBIT_STORAGE } from '../../constants/sessionStorage';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { getObjectFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import type { DirectDebitType } from '../../types/commonTypes';
import ConsentText from './ConsentText';
import ContactType from './ContactType';

const className = "c-MarketingConsentForm";

interface Props {
  location: Location
}

const MarketingConsent = (props: FormikProps & Props) => {
  const { values, setFieldValue, match, history, setValues, location } = props;

  const insuranceType = match.params.type;
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${insuranceType}${DIRECT_DEBIT_STORAGE}`;
  const state = location.state || getObjectFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  useEffect(() => {
    if (state.consent) {
      setFormValues();
    }

  }, [location.state]);

  if (Object.keys(state).length === 0) {
    history.push({ pathname: routes.Wallet.url });
    return <></>;
  }

  const setFormValues = () => {
    const { email, phone, text, post } = state.consent;
    let storedConsent = false;
    if ([email, phone, text, post].includes(true)) {
      storedConsent = true;
    }
    setValues({ ...state.consent, consent: storedConsent });
  };


  const goToNextPage = () => {
    const quoteData = {
      ...state,
      consent: {
        email: values.email,
        phone: values.phone,
        post: values.post,
        text: values.text
      }
    };
    saveInSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER, JSON.stringify(quoteData));
    history.push({
      pathname: `${routes.MakePayment.url}${insuranceType}`,
      state: quoteData
    });
  };

  const isNextBtnDisabled = () =>
    !(!values.consent || (values.consent && [
      values.email, values.phone, values.post, values.text].includes(true)));

  return (
    <Form>
      <div className={`${className}`}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Marketing Preferences</TitleWithUnderLine>
        </div>
        <div>
          <ConsentText />
          <div className={`${className}__giveConsentSwitch`}>
            <label>Will you give us consent?</label>
            <SimpleSwitch
              name='consent'
              onLabel="on"
              on={values.consent}
              onClick={() => {
                const newConsentValue = !values.consent;
                if (newConsentValue === false) {
                  setFieldValue('post', false);
                  setFieldValue('email', false);
                  setFieldValue('text', false);
                  setFieldValue('phone', false);
                }
                setFieldValue('consent', newConsentValue);
              }}
              isYesNoQuestion
            />
          </div>

          {values.consent ? (
            <ContactType {...props} />
          ) : (
            <div>You can change your mind at any time, just call us
              <a className={`${className}--letterSpaceBefore`}
                 href={`tel:${marketingPhoneNumber}`}>{marketingPhoneNumber}</a>
            </div>)}

          <div className={`${className}__consentNextButton`}>
            <Button id="MarketingConsent__nextButton" disabled={isNextBtnDisabled()} fluid={true}
                    onClick={goToNextPage}>Next</Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

const marketingConsentYupSchema = yup.object().shape({});

const MarketingConsentForm = withFormik({
  mapPropsToValues(): {} {
    return {
      consent: false,
      email: false,
      phone: false,
      text: false,
      post: false
    };
  },
  handleSubmit(
    values: DirectDebitType,
    { setSubmitting }: FormikProps
  ) {
    setSubmitting(false);
  },
  validationSchema: marketingConsentYupSchema,
  displayName: 'MarketingConsentForm'
})(MarketingConsent);

export default MarketingConsentForm;
