export const vanConditions = [
  {
    name: 'resident_ireland',
    label: 'Are you (the Proposer) and any other person(s) driving the vehicle permanently resident in the Republic of Ireland?'
  },

  {
    name: 'registered_vehicle',
    label: 'Is the Vehicle for which insurance cover is sought owned by and registered to the name listed under Proposer Details?',
  },
  {
    name: 'right_hand_drive',
    label: 'Is the Vehicle for which insurance cover is sought a Right Hand Drive Vehicle?'
  },
  {
    name: 'carry_own_goods',
    label: `Will the Vehicle for which insurance cover is sought be used to carry 'own goods' specific to your business, profession or Trade?`
  },

  {
    name: 'roadworthy_condition',
    label: 'Is the Vehicle for which insurance cover is sought in a roadworthy condition and will it be maintained as such?'
  },

  {
    name: 'not_modified',
    label: 'Is it correct that the Vehicle for which insurance cover is sought has not been modified in any way from the manufacturer\'s specification, is not a tipper or does not have any mechanical plant or auxiliary equipment such as grabs, stabilisers or cherry pickers?'
  },
  {
    name: 'not_for_hire_reward',
    label: 'Is it correct that the Vehicle for which insurance cover is sought will not be used for hire and reward, in close proximity to aircraft/airfields, for carriage of any dangerous, toxic, explosive or hazardous goods or carry out deliveries?'
  },
  {
    name: 'not_private_use',
    label: 'Is it correct that the Vehicle for which insurance cover is sought will not be used for private use only?'
  },
  {
    name: 'excluding_crew_cabs',
    label: 'Is it correct that the Vehicle for which cover is sought, excluding Crew Cabs, does not have any fixed seats in the rear?'
  },
  {
    name: 'refused_motor_insurance',
    label: 'Is it correct that you (the Proposer) or any other person(s) driving the vehicle have never been refused motor insurance of any type or been refused renewal of an existing motor insurance policy of any type by an insurer?'
  },
  {
    name: 'cancelled_insurance_policy',
    label: 'Is it correct that you (the Proposer) or any other person(s) driving the vehicle never had special or unusual terms or restrictions applied to a motor insurance policy of any type or had a motor insurance policy of any type cancelled or declared void by an insurer? (Note: this does not include any action taken for Direct Debit Default)'
  },
  {
    name: 'pending_insurance_claims',
    label: 'Is it correct that you (the Proposer) or any other person(s) driving the vehicle currently do not have any outstanding or pending motor insurance claims?'
  },
  {
    name: 'no_medical_condition',
    label: 'Is it correct that you or any of the named drivers do not suffer from any disability or medical condition that may impair your/their ability to drive, and if you/they do, it has been reported to the relevant driver licensing authority and they have issued a licence to drive?'
  },
  {
    name: 'no_restrictions',
    label: 'Is it correct that you or any of the named drivers have no restrictions on your driving licence(s)?'
  },
  {
    name: 'not_goods_carrying_services',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not use the vehicle to provide any goods carrying services for hire or reward?'
  },
  {
    name: 'no_airfields',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not use/drive the vehicle on airfields?'
  },
  {
    name: 'no_republic_ireland',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not drive outside of the Republic of Ireland for more than 30 days in any one year?'
  },
  {
    name: 'not_other_occupation',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not use the vehicle for any other occupation other than already described in the schedule?'
  },
  {
    name: 'carry_goods',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not carry goods of a corrosive, toxic, explosive or flammable nature?'
  },
  {
    name: 'not_carriage_plants',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not use the vehicle for carriage of plant or machinery?'
  },
  {
    name: 'not_commercial_travelling',
    label: 'Is it correct that you and everyone else who may drive the vehicle will not use the vehicle for commerical travelling?'
  },
  {
    name: 'answered_honestly',
    label: `Do you confirm that all questions asked in connection with this application for insurance have been answered honestly 
            and with reasonable care and any information voluntarily provided has been provided honestly and with reasonable care? 
            (Note: The Proposal Form and/or Statement of Fact that will be issued to the Policyholder will accurately record the 
            information which they or anyone acting on their behalf has provided to AXA in advance of the inception or renewal of this Policy)`
  }];

export const allConditions = [...vanConditions];
