/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { iconsAndTitles } from '../../constants';
import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import {
  deviceInsurancePhoneNumber,
  homeInsurancePhoneNumber,
  insurancePhoneNumber,
  travelInsuranceInternationalPhoneNumber,
  travelInsurancePhoneNumber,
} from '../../constants/phoneNumbers';
import { AmendProductPage } from '../AmendProductPage';

const getInsuranceNumber = (type: string) => {
  const phoneNumber = {
    [TRAVEL_INSURANCE_TYPE]: travelInsurancePhoneNumber,
    [HOME_INSURANCE_TYPE]: homeInsurancePhoneNumber,
    [DEVICE_INSURANCE_TYPE]: deviceInsurancePhoneNumber,
    [""]: ""
  };
  return phoneNumber[type] || insurancePhoneNumber;
};

const getAfterContent = (type: string) => {
  const afterContentText = {
    [TRAVEL_INSURANCE_TYPE]: 'The Chubb Customer Service Team are available to help you with any travel policy related queries or changes you need to make. Agents are available Monday to Friday 9am to 5pm',
    [HOME_INSURANCE_TYPE]: 'Our Personal Lines team are available to help you with any queries or changes you need to make to your policy. Our agents are available Monday – Friday 9am to 5pm.',
    [DEVICE_INSURANCE_TYPE]: '',
    [""]: ""
  };

  return afterContentText[type] !== undefined ? afterContentText[type] :
    'Our Personal Lines team are available to help you with any queries or changes you need to make to your policy. Our agents are available Monday – Friday 9am to 5pm.';
};

const getContentText = (type: string) => {
  const afterContentText = {
    [DEVICE_INSURANCE_TYPE]: 'To amend or cancel your Policy please call us on',
    [""]: ""
  };

  return afterContentText[type] !== undefined ? afterContentText[type] :
    'If you wish to amend your policy please call us on';
};

const getInsuranceInternationalNumber = (type: string) => {
  return type === TRAVEL_INSURANCE_TYPE ? travelInsuranceInternationalPhoneNumber : undefined;
};
const AmendPolicyPage = ({ match, history }: { match: Match, history: RouterHistory; }) => {
  const type = match.params.type || '';
  const amendProductPages = [CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE];

  const renderCallUsPage = () => {
    return <CallUsPage
      history={history}
      icon={type && iconsAndTitles[type].icon}
      cardTitle={type && iconsAndTitles[type].title}
      title="Amend Policy"
      firstLineContentText="If you wish to amend your policy please call us on"
      contentText={getContentText(type)}
      afterContent={getAfterContent(type)}
      phoneNumber={getInsuranceNumber(type)}
      internationalPhoneNumber={getInsuranceInternationalNumber(type)}
    />;
  };

  return (
    amendProductPages.includes(type) ?
      <AmendProductPage insuranceType={type} /> :
      renderCallUsPage()
  );
};
export default withRouter(AmendPolicyPage);
