/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 **/

import { addDays, endOfToday, parse, startOfToday } from 'date-fns';
import * as yup from "yup";

const oneOfCostRequired = 'Cost must be an whole number';
const eitherContentsOrBuildings = 'Fill in Contents Cover OR Rebuild Cost OR Both';

export const yourHomeCoverFormYupSchema = {
  policyStart: yup
    .date()
    .transform((_, dateString) => parse(dateString))
    .min(startOfToday(), 'Please enter a date 28 or less days in the future')
    .max(
      addDays(endOfToday(), 28),
      'Please enter a date 28 or less days in the future'
    )
    .required('Policy start date is required'),
  rebuildCost: yup
    .number()
    .transform((_, ov) => {
      const strippedValue = ov.replace(/,/g, ``);
      return strippedValue !== 'null' && strippedValue !== 'undefined' ? parseInt(strippedValue, 10) : null;
    })
    .nullable()
    // Need a limit on number size to keep integer validation working
    // Not sure why, but above 100 million seems like a good limit
    .min(150000, 'Requires minimum value of 150,000')
    .integer(oneOfCostRequired)
    .test(
      'rebuildOrContents',
      eitherContentsOrBuildings,
      function (val) {
        const { contentsCoverOf } = this.parent;
        if (!contentsCoverOf)
          return val != null && val !== 0;
        return true;
      },
      eitherContentsOrBuildings
    ),
  contentsCoverOf: yup
    .number()
    .transform((_, ov) => {
      const strippedValue = ov.replace(/,/g, ``);
      return strippedValue !== 'null' && strippedValue !== 'undefined' ? parseInt(strippedValue, 10) : null;
    })
    .min(15000, 'Requires minimum value of 15,000')
    .nullable(true)
    .integer(oneOfCostRequired)
    .test(
      'contentsOrRebuild',
      eitherContentsOrBuildings,
      function (val) {
        const { rebuildCost } = this.parent;
        if (!rebuildCost)
          return val != null && val !== 0;
        return true;
      },
      eitherContentsOrBuildings
    ),
  acceptedAssumptions: yup
    .boolean()
    .required()
    .oneOf([true], 'You must accept the Assumptions'),
  acceptedTermsAndConditions: yup
    .boolean()
    .required()
    .oneOf([true], 'You must accept the Terms and Conditions'),
  marketingConsent: yup.boolean().oneOf([true], '')
    .test('marketing-consent-function-home',
      'You must select a method of contact', function () {
        return this.parent.emailConsent ||
          this.parent.postConsent ||
          this.parent.phoneConsent ||
          this.parent.smsConsent;
      })
};

export default yourHomeCoverFormYupSchema;
