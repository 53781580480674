import { createNumberMask } from 'text-mask-addons';

export const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: false
});

export const decimalNumberMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: true
});

export const phoneNumberMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: false,
  includeThousandsSeparator: false
});

export const yearLimit = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: false,
  includeThousandsSeparator: false,
  integerLimit: 4
});
