import '../PremiumBreakdown.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionCard } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React from 'react';

import { PremiumBreakdownConfigs } from '../../../configs/PremiumBreakdownConfigs';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';

const className = 'c-PremiumBreakdown';

const levyText =
  'The above includes the applicable government levy and stamp duty';

const DevicePremiumBreakdown = ({
  premiumDetails,
}) => {
  return (
    <AccordionCard
      id={`DevicePremiumBreakdown__viewPremium`}
      label="View premium breakdown"
      status="none"
      labelStyle="black"
      on={true}
      toggleByDefault={!PremiumBreakdownConfigs.accordionCollapse.device}
      childContainerClass={`${className}__noPaddingAccordion`}
      customLabelClass={`${className}__accordionLabel`}
    >
      <div className={`${className}`} id={'TravelBreakdown'}>
        <div className={`${className}__section`}>
          <PremiumBreakdownRow
            label={`Total Premium`}
            price={premiumDetails.annual_premium}
          />
          <PremiumBreakdownRow
            label={`Broker set up fee`}
            price={premiumDetails.service_fee}
          />
          <PremiumBreakdownRow
            label={`Total Payable`}
            price={premiumDetails.annual_total}
          />
          <PremiumBreakdownRow label={levyText} />
        </div>
      </div>
    </AccordionCard>
  );
};

DevicePremiumBreakdown.propTypes = {

  premiumDetails: PropTypes.object,

};

export default DevicePremiumBreakdown;
