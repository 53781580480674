/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../../styles/commonFormStyles.scss';
import './YourVanCoverForm.scss';

import { AccordionText, Button, ButtonGroup, Checkbox, DatePicker, InputErrorMessage } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import ResourceDropdown from '../../../components/ResourceDropdown';
import { VAN_INSURANCE_TYPE } from '../../../constants';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants/commonClassNames';
import { COMPREHENSIVE, COMPREHENSIVE_DESCRIPTION, MYSELF_AND_NAMED_DRIVERS, MYSELF_AND_SPOUSE, MYSELF_ONLY, OPEN_DRIVING, OPEN_DRIVING_AND_NAMED_DRIVERS, THIRD_PARTY, THIRD_PARTY_DESCRIPTION } from '../../../constants/van';
import { isFieldError } from '../../../helpers';
import { InsuranceTypeContext } from '../../../pages/GetGenericQuotePage';
import { MarketingConsent } from '../../MarketingConsent';
import MotorClaims from '../../MotorClaims';
import { AdditionalDrivers } from '../YourCarCoverForm/YouCarCoverFormSections';
import { emptyVanAdditionalDriver } from '../YourCarCoverForm/YouCarCoverFormSections/AdditionalDrivers/AdditionalDriversInitialValues';



const className = 'c-YourVanCoverForm';
const dutyOfDisclosureURL = '//www.arachas.ie/media/ga3n3tzk/duty-of-disclosure.pdf';
const termsOfBusinessURL = '//www.arachas.ie/media/cphenioo/terms-of-business_2020v4.pdf';
const dataPrivacy = '//www.arachas.ie/compliance/privacy-policy/';
const scheduleOfFees = '//www.arachas.ie/media/1d4bp40u/arachas-schedule-of-fees-16th-november-2020.pdf';
const remuneration = '//www.arachas.ie/compliance/remuneration/';
const termsOfUse = '//www.arachas.ie/media/wp1jm3m1/terms-of-use.pdf';

const checkboxLabelFontSize = 16;
const additionalDriversName = 'additionalDrivers';

const YourVanCoverForm = (props) => {
  const {
    errors,
    handleBlur,
    setFieldValue,
    touched,
    values,
    setTouched,
    setFieldTouched,
    formFilledBefore,
    resources
  } = props;

  const insuranceType = useContext(InsuranceTypeContext);

  const noDriversNeeded = whoIsDriving => [MYSELF_ONLY, OPEN_DRIVING].includes(whoIsDriving);
  const driversNeeded = whoIsDriving => [MYSELF_AND_SPOUSE, MYSELF_AND_NAMED_DRIVERS, OPEN_DRIVING_AND_NAMED_DRIVERS].includes(whoIsDriving);
  const oneDriverNeeded = (whoIsDriving) => whoIsDriving === MYSELF_AND_SPOUSE;

  const fillDriverFields = (whoIsDriving) => {
    if (noDriversNeeded(whoIsDriving)) return;
    setFieldValue(additionalDriversName, [{
      ...emptyVanAdditionalDriver,
      ...values[additionalDriversName]
    }]);
  };

  const handleCustomBlur = (
    fieldName,
    e,
  ) => {
    setFieldValue(fieldName, values[fieldName]);
    handleBlur(e);
  };

  const updateDriverValues = (whoIsDrivingObj) => {
    const cleanDrivers = () => !whoIsDrivingObj || noDriversNeeded(whoIsDrivingObj.alias);
    if (cleanDrivers()) {
      // Clear additional drivers
      setFieldTouched(additionalDriversName, []);
      setFieldValue(additionalDriversName, []);
    } else if (oneDriverNeeded(whoIsDrivingObj.alias)) {
      // Limit additional driver to one
      setFieldValue(additionalDriversName, [values[additionalDriversName][0]]);
    }
    setFieldValue('whoIsDrivingYourVan', whoIsDrivingObj);
    if (whoIsDrivingObj) fillDriverFields(whoIsDrivingObj.alias);
  };

  const renderAdditionalDrivers = () => {
    const hasAlias = () => values.whoIsDrivingYourVan && values.whoIsDrivingYourVan.alias;
    if (hasAlias() && driversNeeded(values.whoIsDrivingYourVan.alias)) {
      return (<div className={`${className}__additionalDriversContainer`}>
        <AdditionalDrivers
          {...props}
          errors={errors}
          baseFieldName={additionalDriversName}
          fieldFilledBefore={formFilledBefore}
          maxDrivers={oneDriverNeeded(values.whoIsDrivingYourVan.alias) ? 1 : 5}
          hideRelationship
          isVanDriver
          insuranceType={VAN_INSURANCE_TYPE}
        />
      </div>);
    } else return <></>;
  };

  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <label htmlFor="useOfVehicle" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Use of Vehicle
        </label>
        <div className={`${className}__compButton`}>
          <div className={`${className}__disableHover`}>
            <Button
              fluid
              id="useOfVehicle"
            >
              <b>SOCIAL, DOMESTIC AND PLEASURE AND CARRIAGE OF OWN GOODS ONLY</b>
            </Button>
          </div>
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <label htmlFor="coverType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Cover type
        </label>
        <div id="coverType">
          <InputErrorMessage id={`coverTypeErrorMessage`} error={isFieldError('coverType', touched, errors)} errorMessage={errors.coverType} />
          <div className={`${className}__compButton`}>
            <Button
              fluid
              value={values.coverType}
              id={`coverType__Comprehensive`}
              error={isFieldError('coverType', touched, errors)}
              errorMessage={errors.coverType}
              onClick={() => setFieldValue('coverType', COMPREHENSIVE)}
              secondary={values.coverType.description !== COMPREHENSIVE_DESCRIPTION}
            >
              <b>COMPREHENSIVE</b>
            </Button>
          </div>
          <div className={`${className}__compButton`}>
            <Button
              fluid
              value={values.coverType}
              id={`coverType__ThirdParty`}
              error={isFieldError('coverType', touched, errors)}
              errorMessage={errors.coverType}
              onClick={() => setFieldValue('coverType', THIRD_PARTY)}
              secondary={values.coverType.description !== THIRD_PARTY_DESCRIPTION}
            >
              <b>THIRD PARTY, FIRE AND THEFT</b>
            </Button>
          </div>

        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <label htmlFor="policyStart" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Policy start date
        </label>
        <DatePicker
          error={errors.policyStart}
          name="policyStart"
          onBlur={handleBlur}
          onChange={(e) =>
            setFieldValue('policyStart', e.currentTarget.value)
          }
          placeholder="Type here"
          touched={touched.policyStart}
          value={values.policyStart}
        />
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer  ${className}__componentContainer`}>
        <label htmlFor="penaltyPoints" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Number of penalty points
        </label>
        <ResourceDropdown
          placeholder="Select here"
          name="penaltyPoints"
          error={isFieldError('penaltyPoints', touched, errors)}
          errorMessage={errors.penaltyPoints}
          onBlur={handleBlur}
          value={values.penaltyPoints}
          onChange={val => {
            setFieldValue('penaltyPoints', val);
            setFieldTouched('penaltyPoints');
          }}
          values={resources.penalty_points}
        />
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <label htmlFor="mainDriverDisqualifiedOrConvicted" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Have you or any person who will drive the vehicle, ever been disqualified from driving, ever been convicted of a criminal or motoring offence or have any prosecutions pending?"
            secondLineLabel={"Please do not disclose convictions that are deemed spent" + "under the Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016."}
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <br />
            <div className={`${commonFormStylesIdentifier}__infoText`}>
              A spent conviction is a conviction which does not have to be disclosed after 7 years for the purposes of obtaining insurance cover.
              You must disclose any conviction that you or any person who will drive the vehicle,
              have received that is not spent under the Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016.
            </div>
          </AccordionText>
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name="mainDriverDisqualifiedOrConvicted"
            onClick={(value) => {
              setFieldValue('mainDriverDisqualifiedOrConvicted', value === 'true');
              setFieldTouched('mainDriverDisqualifiedOrConvicted', true);
            }}
            options={[
              { label: 'No', value: false },
              { label: 'Yes', value: true }
            ]}
            value={values.mainDriverDisqualifiedOrConvicted}
            touched={touched.mainDriverDisqualifiedOrConvicted}
            selected={values.mainDriverDisqualifiedOrConvicted}
            error={isFieldError('mainDriverDisqualifiedOrConvicted', touched, errors)}
            errorMessage={errors.mainDriverDisqualifiedOrConvicted}
          />
        </div>
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <div className={`${className}__motorClaimsLabelContainer`}>
          <label className={`${commonFormStylesIdentifier}__fieldLabel`} htmlFor="motorClaims">
            How many accidents or claims have you or anyone that may drive the van, had in the last four years?
          </label>
        </div>
        <MotorClaims
          errors={errors}
          fieldName="motorClaims"
          onChange={(e) => setFieldValue('motorClaims', e)}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          touched={touched}
          values={values}
          onBlur={handleCustomBlur}
          resources={resources}
          insuranceType={insuranceType}
        />
      </div>
      <div className={`${className}__componentContainer ${commonFormStylesIdentifier}__dividerContainer`}>
        <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="whoIsDrivingYourVan" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            {`Who will be driving your van?`}
            <div className={`${className}__infoText`}>
              {`Please note that 'open driving' covers any driver aged 25-70. Drivers outside of this age group must be covered under 'open driving plus'.`}
            </div>
          </label>
          <ResourceDropdown
            name="whoIsDrivingYourVan"
            error={isFieldError('whoIsDrivingYourVan', touched, errors)}
            errorMessage={errors.whoIsDrivingYourVan}
            onBlur={handleBlur}
            value={values.whoIsDrivingYourVan}
            onChange={updateDriverValues}
            values={resources.who_driving}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          {renderAdditionalDrivers()}
        </div>
      </div>
      <div
        className={`${commonFormStylesIdentifier}__termsAndConditionsContainer ${className}__termsAndConditionsContainer--padding`}>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="disclosureRequirements"
            name="disclosureRequirements"
            label={
              <>
                Your quote and cover is based on your
                <a href={dutyOfDisclosureURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Duty of Disclosure
                </a>. Please tick to confirm you have read and accepted these.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.disclosureRequirements}
            onChange={() => setFieldValue('disclosureRequirements', !values.disclosureRequirements)}
            value={values.disclosureRequirements}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            label={
              <>
                Please tick to confirm acceptance of our
                <a href={termsOfBusinessURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Business
                </a>,
                <a href={termsOfUse}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Use,
                </a>
                &nbsp;

                <a href={dataPrivacy}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Data Privacy
                </a>,
                <a href={scheduleOfFees}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Schedule of Fees
                </a> and
                <a href={remuneration}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Remuneration documents
                </a>
                {' '} before proceeding.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedTermsAndConditions}
            onChange={() => setFieldValue('acceptedTermsAndConditions', !values.acceptedTermsAndConditions)}
            value={values.acceptedTermsAndConditions}
          />
        </div>
        <MarketingConsent values={values} checkboxLabelFontSize={checkboxLabelFontSize} setFieldValue={setFieldValue} />
      </div>
    </div>
  );
};

YourVanCoverForm.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  formFilledBefore: PropTypes.bool,
  resources: PropTypes.object,
  latestQuote: PropTypes.object
};

export default YourVanCoverForm;
