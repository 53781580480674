/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MtaCancelPolicyThankYou.scss';

import { Button, DesktopCard, Title } from '@arachas/core/lib';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { backToWallet } from '../../constants/buttonLabelConstants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';
import UserContext from '../../UserContext';

type MtaCancelPolicyThankYouProps = {
  insuranceType: string;
};

const className = 'c-MtaCancelPolicyThankYou';

const MtaCancelPolicyThankYou = ({ insuranceType }: MtaCancelPolicyThankYouProps) => {
  const { getUserName } = useContext(UserContext);


  const getTitleText = () => {
    const name = getUserName();
    return name ? `Thanks ${name},` : 'Thanks!';
  };

  const renderCarContent = () => {
    return (
      <> <div className={`${className}__primaryTextContainerTwo`}>
        You have submitted a request to cancel your policy.
        We will take care of this but please note requests for cancellation must be confirmed by us .
          </div>

        <div className={`${className}__primaryTextContainerThree`}>
          We will be in touch with you shortly to confirm your cancellation has been processed.
          If paying by direct debit we will continue to collect your instalments until cancellation is confirmed.
          Any premium changes which may be owed or due will be notified or settled.
            </div>

        <div className={`${className}__primaryTextContainerFour`}>
          Your certificate and disc must be returned to us at following address.
          Arachas, Marine Point, Belview , Waterford, Ireland before we can process cancellation.
          Please be aware cancelation will only take effect from the day that we receive certificate and disc
            </div></>
    );

  };

  const renderHomeContent = () => {
    return (
      <> <div className={`${className}__primaryTextContainerTwo`}>
        You have submitted a request to cancel your policy.
        We will take care of this but please note requests for cancellation must be confirmed by us before they take effect..
        </div>

        <div className={`${className}__primaryTextContainerThree`}>
          We will be in touch with you shortly to confirm your cancellation has been processed.
          If paying by direct debit we will continue to collect your instalments until cancellation is confirmed.
          Any premium changes which may be owed or due will be notified or settled.
        </div>
      </>
    );
  };

  const getPageContent = {
    [HOME_INSURANCE_TYPE]: renderHomeContent(),
    [CAR_INSURANCE_TYPE]: renderCarContent(),
  };

  const renderDefaultBody = () => (
    <div>
      <DesktopCard>
        <div className={className}>
          <div className={`${className}__cancelPolicyPageTextContainer`}>
            <div className={`${className}__primaryTextContainerOne`}>
              <Title type="h1" weight="500">
                <span id="PaymentsThankYouPage__thankYouText">{getTitleText()}</span>
              </Title>
            </div>
            {getPageContent[insuranceType]}

          </div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.Wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToWallet}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </DesktopCard>
    </div>
  );

  return renderDefaultBody();
};

export default MtaCancelPolicyThankYou;
