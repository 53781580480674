/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './LegalText.scss';

import type { StatelessFunctionalComponent } from 'react';
import React from 'react';

import {
  CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE, VAN_INSURANCE_TYPE
} from '../../constants';
import {
  legalTextDeviceContent, legalTextHomeContent, legalTextHomeContentTwo,
  legalTextMotorContent, legalTextTravelContent, legalTextVanContent
} from './legalTextContent';

interface Props {
  insuranceType: string;
}

const className = 'c-LegalText';

const legalText = {
  [HOME_INSURANCE_TYPE]: legalTextHomeContent,
  [CAR_INSURANCE_TYPE]: legalTextMotorContent,
  [TRAVEL_INSURANCE_TYPE]: legalTextTravelContent,
  [DEVICE_INSURANCE_TYPE]: legalTextDeviceContent,
  [VAN_INSURANCE_TYPE]: legalTextVanContent
};

const LegalText: StatelessFunctionalComponent<{ insuranceType: string; }> = (props: Props) => (
  legalText[props.insuranceType] ?
    <div>
      <p className={className}>{legalText[props.insuranceType]}</p>
      <p className={className}>{legalTextHomeContentTwo}</p>
    </div> : ''
);

export default LegalText;
